import { AtLeastOne } from '../types';
import './paginationSizeSelect.scss';
import { useTheme } from '../utility/themeContext';
import cs from 'classnames';

type Props = {
  id: string;
  itemsPerPageOptions: AtLeastOne<number>;
  value: number;
  onChange: (selected: number) => void;
  label?: string;
  sliceInfo?: string;
};

export const CpxPaginationSizeSelect = ({
  id,
  itemsPerPageOptions,
  value,
  label,
  sliceInfo,
  onChange,
}: Props) => {
  const theme = useTheme();

  return (
    <div
      className={cs(
        'paginationSizeSelect',
        `paginationSizeSelect-le--${theme}`
      )}
    >
      {label && <label htmlFor={`${id}--select`}>{label}</label>}
      <select
        {...{ value }}
        id={`${id}--select`}
        className={cs('select', `select-le--${theme}`)}
        onChange={event => {
          onChange(parseInt(event.target.value, 10));
        }}
      >
        {itemsPerPageOptions.map(option => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      {sliceInfo && (
        <span className={cs('sliceInfo', `sliceInfo-le--${theme}`)}>
          {sliceInfo}
        </span>
      )}
    </div>
  );
};
