import './centrexxOptions.scss';
import cs from "classnames";
import { useTranslations } from "../../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../../constants/transitions/uiTranslations";
import { useTheme } from "../../../../../../core/utility/themeContext";
import { ReactComponent as Check } from "../../../../../assets/icons/check.svg";
import { ReactComponent as CheckDts } from "../../../../../assets/icons/check_dts.svg";
import { CpxSelectBox } from "../../../../../../core/components/selectBox.component";
import React, { useEffect, useState } from "react";
import { Basket, BasketEntry, OptionGroup, SelectableBasketOption } from "compax-api";
import { useOEOptionsOfOptions, useOEProductOptions, useOrderEntryBasket, useSelectError, useSelectLang, useStep1Data, useStep3Data } from "../../../../../redux/store";
import { ID_CONTRACT_OPTION } from "../../../../../constants/configs/config.constants";
import { apiCallAction } from "../../../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../../../constants/action.constants";
import { useDispatch } from "react-redux";
import { NfoOptionGroups } from "../optionGroups/optionGroups.component";
import { onValidateProductOption, selectProductOption } from "../../../../../redux/actions/step.action";
import { CpxButton } from "../../../../../../core/components/button.component";
import { NfoEditBox } from "../editBox/editBox.component";
import { selectCurrentLanguage } from "../../../../../../core/uiLanguage/lang.slice";
import { Alert } from "../../../../common/Alert/Alert.component";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

type Props = {}
type AppDispatch = ThunkDispatch<any, any, AnyAction>;

export const NfoCentrexxOptions = ({}: Props) => {
  const translationsStepper = useTranslations(TRANSLATIONS.stepper.newCustomer);
  const theme = useTheme();
  const dispatch: AppDispatch = useDispatch();
  const basket: Basket = useOrderEntryBasket();
  const oEProductOptions = useOEProductOptions();
  const oEOptionsOfOptions = useOEOptionsOfOptions();
  const [productOptions, setProductOptions] = useState<Array<OptionGroup> & any>([]);
  const [isBeingConfigured, setIsBeingConfigured] = useState(false);
  const currentLang = selectCurrentLanguage(useSelectLang());
  const error = useSelectError()
  const stepOneData = useStep1Data();
  const step3Data = useStep3Data();

  const filteredProductOptions = Array.isArray(oEProductOptions) && Object.values(
    oEProductOptions?.reduce((basketOptions: OptionGroup[], item: any) =>
      basketOptions?.concat(item.basketOptions?.filter((basketOption: any) => basketOption?.product?.productType?.id === +ID_CONTRACT_OPTION)), [])
  );


  const getSelectedProductOption = () => {
    return Array.isArray(productOptions) && productOptions?.find((option: SelectableBasketOption) => option?.selected);
  }

  const isOptionInBasket = !!getSelectedProductOption()
  useEffect(() => {
    setIsBeingConfigured(isOptionInBasket)
  }, [isOptionInBasket, productOptions])


  useEffect(() => {
    dispatch(apiCallAction(ACTION_CONST.API_GET_OE_PRODUCT_OPTIONS,
      {
        basketId: basket?.id,
        uuid: step3Data?.activeEntryUuid,
        language: currentLang
      },
      true))
  }, [currentLang]);

  useEffect(() => {
    setProductOptions(filteredProductOptions);
  }, [oEProductOptions])

  useEffect(() => {
    const options = basket?.entries[step3Data?.activeIndex]?.options
    if (basket && Array.isArray(options) && options.length > 0) {
      options?.map(option => dispatch(onValidateProductOption(option, basket, stepOneData, step3Data?.activeIndex)))
    }
  }, [])


  const validatedOption = Array.isArray(productOptions) && !!productOptions?.find((p: any) => p?.validated);
  const optionsGroups = useOEProductOptions();

  const optionUuid = basket?.entries[step3Data?.activeIndex]?.options?.
  find((option: any) => option?.product?.id === getSelectedProductOption()?.product?.id)?.uuid || '';


  return (
    <>
      {error?.errorData && error?.errorData?.length > 0 &&
        error?.requestData?.currentData?.stepper === "VALIDATE_BASKET_OPTION" && <Alert errors={error?.errorData}/>}
      <div className={cs(`options-wrapper`, `options-wrapper-le--${theme}`)}>
        <h5 className={cs(`options-title`, `options-title-le--${theme}`)}>Paket</h5>
      </div>
      {validatedOption &&
        <NfoEditBox
          name={getSelectedProductOption()?.product?.displayValue}
          basket={basket}
          optionId={getSelectedProductOption()?.product?.id}
          optionUuid={optionUuid}
        />}

      {!validatedOption && <div className={cs(`productOptionsBox`, `productOptionsBox-le--${theme}`)}>
        <p className={'productOptionsBox__heading'}>{translationsStepper.selectPackageText()} *</p>
        <div className='boxes'>
          {Array.isArray(productOptions) && productOptions?.map((p: SelectableBasketOption, index: any) => (
              <CpxSelectBox
                tabIndex={index}
                product={p.product}
                title={p.product?.displayValue + ""}
                active={p.selected}
                onClick={async () => {
                  const newBasket = await dispatch(selectProductOption(p, basket, setProductOptions, currentLang, step3Data?.activeEntryUuid, optionUuid));
                  //get product option again to get the select information
                  dispatch(apiCallAction(ACTION_CONST.API_GET_OE_PRODUCT_OPTIONS,
                    {
                      basketId: basket?.id,
                      uuid: step3Data?.activeEntryUuid,
                      language: currentLang
                    }, true));

                  const newOptions = Object.values(newBasket?.entries)?.find((entry: BasketEntry) => entry.uuid === step3Data?.activeEntryUuid)?.options;
                  if (newBasket && Array.isArray(newOptions) && newOptions.length > 0) {
                    newOptions?.map((option: any) => {
                      //validate all options in the basket, except the current option
                      if (p?.product.id !== option?.product?.id && !option.selected)
                        dispatch(onValidateProductOption(option, basket, stepOneData, step3Data?.activeIndex));
                    })
                  }

                }}
              >
                {p?.product?.keyFeatures?.split('|').map((keyFeature: any) => (
                    <div className="box-item">
                      {theme === 'nfon' ? <Check/> : <CheckDts/>}
                      <li>{keyFeature}</li>
                    </div>
                  )
                )}
              </CpxSelectBox>
            )
          )}
        </div>
        <div>
          {oEOptionsOfOptions &&
            <NfoOptionGroups
              optionGroups={oEOptionsOfOptions}
              parentOptionId={getSelectedProductOption()?.product?.id}  parentOptionUuid={optionUuid}/>}
        </div>
        <div className={cs(`productOptionsBox__actions`, `productOptionsBox__actions-le--${theme}`)}>
          <p className={'productOptionsBox__text'}>* {translationsStepper.mandatoryFieldsText()}</p>
        </div>
        {oEOptionsOfOptions && isBeingConfigured && <div className={"configurationBox__buttons"}>
          <CpxButton
            type="button" className={`cpxButton-le--${theme} secondary secondary-le--${theme}`}
            onClick={() => {
              dispatch(selectProductOption(getSelectedProductOption(), basket, setProductOptions, currentLang, step3Data?.activeIndex, optionUuid));
              setIsBeingConfigured(false)
            }}
          >
            {translationsStepper.cancel()}
          </CpxButton>

          <CpxButton
            type="button"
            onClick={() => dispatch(onValidateProductOption(getSelectedProductOption(), basket, stepOneData, step3Data?.activeIndex))}
          >
            {translationsStepper.apply()}
          </CpxButton>
        </div>}
      </div>}

    </>
  )
}