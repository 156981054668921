import React, { useState } from 'react';
import { CpxPaginatedTable } from '../../../../../core/components/paginatedTable.component';
import { TRANSLATIONS } from '../../../../constants/transitions/uiTranslations';
import { useTranslations } from '../../../../utils/helper/utils';
import './extensionsTable.scss';
import { BasicExtensionContract } from 'compax-api';
import { NfoStatus } from '../../../../../core/components/status.component';
import { useTheme } from '../../../../../core/utility/themeContext';
import cs from 'classnames';
import { SearchBox } from '../../../common/search/searchBox.component';
import { ICONS, ID_DATA_ENTITY } from '../../../../constants/configs/config.constants';
import { NfoEmptyState } from "../../../../../core/emptyState.component";
import { ReactComponent as Check } from "../../../../assets/icons/check.svg";
import { CpxIcon } from "../../../../../core/components/icon.component";



export const NfoExtensionsTable = ({ title, extensions }: any) => {
  const translations = useTranslations(TRANSLATIONS.extensions);
  const extensionsList = extensions?.usedExtensions;
  const theme = useTheme();
  const internalClassName = "extensionTable";
  const [searchedItems, setSearchedItems]: any = useState(extensionsList);


  const licensesTableRowData = (extension: BasicExtensionContract) => {
    if (
      extensions != null &&
      extensions.usedExtensions != null &&
      extensions.usedExtensions.length > 0
    ) {
      return {
        id: '' + extension.contractId,
        cellData: [
          <p
            className={cs(
              'tableFirstChild',
              `tableFirstChild-font-le--${theme}`
            )}
          >
            {extension.ossExtension}
          </p>,
          <p style={{ overflowWrap: 'anywhere' }}>{extension.ossDisplayName}</p>,
          <p style={{ overflowWrap: 'anywhere' }}>{extension.ossContract}</p>,
          <NfoStatus
            entity={{ id: ID_DATA_ENTITY.SERVICE }}
            status={extension.status}
          />,
          <p style={{ overflowWrap: 'anywhere' }}>{extension.occupied ? <CpxIcon icon={ICONS.CHECK}/> : ""}</p>,
        ],
      };
    }
  };
  const getSearchedItems = (data: any) => {
    setSearchedItems(data);
  };

  return (
    <>
      {(!extensionsList || extensionsList.length === 0) && (
        <>
          <br/>
        </>
      )}
      <NfoEmptyState
        data={extensionsList}
        pageTitle={title}
        subtitle={translations.emptyStateSubTitle()}
        boxStyling={true}
      >
        {extensionsList && (
          <SearchBox
            theme={theme}
            placeholder={
              translations.extension() +
              ', ' +
              translations.DisplayName()
            }
            id={'extensionsTable'}
            data={extensionsList}
            getFilteredItems={getSearchedItems}
            shouldBeSearched={['ossContract', 'ossDisplayName', 'ossExtension']}
          />
        )}
        {searchedItems && (
          <article className={cs(internalClassName, `${internalClassName}-le--${theme}`)}>
            <CpxPaginatedTable
              id="extensionsTable"
              tableHeader={[
                { label: translations.extension(), sort: true, sortBy: 'ossExtension', sortMethod: 'string' },
                { label: translations.DisplayName(), sort: true, sortBy: 'ossDisplayName', sortMethod: 'string' },
                { label: translations.ContractAccount(), sort: true, sortBy: 'ossContract', sortMethod: 'string' },
                { label: translations.status(), sort: true, sortBy: 'status.id', sortMethod: 'number' },
                { label: translations.occupied(), sort: true, sortBy: 'occupied', sortMethod: 'string' },
              ]}
              tableData={searchedItems.map(licensesTableRowData)}
              itemsPerPage={[10, 20]}
              pagination="bottom"
              itemsPerPageLabel={translations.paginationSelectLabel()}
              sliceToString={(from: number, to: number, total: number) =>
                translations.paginationSliceInfo(from + '', to + '', total + '')
              }
              rowData={searchedItems && searchedItems}
              getSortedRowsBack={(sortedRows: any) => setSearchedItems(sortedRows?.slice())}
            />
          </article>
        )}
      </NfoEmptyState>
    </>
  );
};
