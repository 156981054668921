import cs from "classnames";
import "./dataOverview.scss";
import { useTranslations } from "../../../../utils/helper/utils";
import { useTheme } from "../../../../../core/utility/themeContext";
import { CpxAddress } from "../../../../../core/components/address.component";
import { ReactComponent as Phone } from "../../../../assets/icons/phone_white.svg";
import { ReactComponent as Place } from "../../../../assets/icons/place_white.svg";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";

type Props = {
  partner: any;
  className?: string
}

export const NfoPartnerDataOverview = ({ partner, className, ...props }: Props) => {
  const translations = useTranslations(TRANSLATIONS.myData);
  const theme = useTheme();
  const internalClassName = "myDataOverview";

  return (
    <article
      className={cs(
        internalClassName,
        `${internalClassName}-boxStyling-le--${theme}`,
        className
      )}
      {...props}
    >
      <h4 className={cs(`${internalClassName}-title-le--${theme}`)}>{partner[0] && partner[0].name}</h4>
       <div className={"columnContainer"}>
        <div className={"column"}>
          <h5>
            <Phone/>
            {translations.contact()}
          </h5>
          <div>
            <p className={cs('mail', `content-le--${theme}`)}>
              {partner[0]?.partnerMainEmail}
            </p>
            <p className={`content-le--${theme}`}>
              {partner[0]?.mainContactPerson?.phone?.countryCode} {partner[0]?.mainContactPerson?.phone?.areaCode} {partner[0]?.mainContactPerson?.phone?.cli}
            </p>
          </div>
          <h5>
            <Place/>
            {translations.address()}
          </h5>
          <CpxAddress
            address={partner[0]?.address}
            className={`content-le--${theme}`}
          />
        </div>
      </div>
    </article>
  );
};
