import { useTheme } from "../../../../core/utility/themeContext";
import { FOOTER_LEGAL_NOTICE, LEGAL_ENTITIES } from "../../../constants/configs/config.constants";
import { useTranslations } from "../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../constants/transitions/uiTranslations";

export const FooterLegalNoticeComponents = () => {
  const theme = useTheme();
  const translations = useTranslations(TRANSLATIONS.footer);

  const getLegalEntityURL = () => {
    switch (theme) {
      case LEGAL_ENTITIES.NFON:
        return FOOTER_LEGAL_NOTICE.NFON;
        break;
      case LEGAL_ENTITIES.DTS:
        return FOOTER_LEGAL_NOTICE.DTS;
        break;
      case LEGAL_ENTITIES.TEF:
        return FOOTER_LEGAL_NOTICE.TEF;
        break;
      default:
        return FOOTER_LEGAL_NOTICE.NFON;
        break;
    }
  }

  return (
    <>
      <a href={getLegalEntityURL().IMPRINT}>{translations.imprint()}</a>
      <a href={getLegalEntityURL().PRIVACY}>{translations.dataProtection()}</a>
      <a href={getLegalEntityURL().AGB_SLA}>{theme === LEGAL_ENTITIES.TEF ? translations.AGB() : translations.AGB_SLA()}</a>
    </>
  )
}