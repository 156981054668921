import cs from 'classnames';
import React, { ForwardedRef, forwardRef, PropsWithoutRef, ReactNode } from 'react';
import './inputWithLabel.scss';
import { useTheme } from "../utility/themeContext";
import Cleave from 'cleave.js/react';
import { InputError } from "./inputError.component";

type Props = PropsWithoutRef<JSX.IntrinsicElements['input']> & {
  id?: string;
  children?: ReactNode;
  suffix?: ReactNode;
  labelClassName?: string;
  inputClassName?: string;
  buttonClassName?: string;
  onChange?: any;
  onPaste?: any;
  error?: any;
};

export const CpxIbanInputWithLabel = forwardRef(
  (
    {
      children,
      suffix,
      labelClassName,
      inputClassName,
      className,
      onChange,
      onPaste,
      error,
      buttonClassName,
      ...props
    }: Props,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const theme = useTheme();

    return (
      <div
        className={cs('inputWithLabel', className, {
          ['isDisabled']: props.disabled,
        })}
      >
        <label
          htmlFor={props.id}
          className={cs(labelClassName, { ['required']: !!props.required }, `label label-le--${theme}`)}
        >
          {children}
        </label>
        <Cleave
          type={props.type ? props.type : 'text'}
          {...props}
          className={cs('input', inputClassName, { ['isNotEmpty']: !!props.value, ['error']: error, [`error-le--${theme}`]: error }, `input-le--${theme}`)}
          onChange={onChange}
          onPaste={onPaste}
          options={{blocks: [4,4,4,4,4,4,4,4], delimiter: ' ', numericOnly: false}}
        >

        </Cleave>
        <div className={buttonClassName}>{suffix}</div>
        {error && <InputError errorMessage={error}/>}
      </div>
    )
  }
);
