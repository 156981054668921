import './contactPerson.scss';
import cs from 'classnames';
import { useTheme } from '../../../../../core/utility/themeContext';
import { ContactPerson } from "compax-api";
import { NfoInfoBlockWithIcon } from "../../../../../core/components/infoBlock.component";
import { CUSTOMER_CONTACT_PERSON_ROLE_IDS } from "../../../../constants/configs/config.constants";
import { ReactComponent as Tech } from "../../../../assets/icons/tech.svg";
import { ReactComponent as Crown } from "../../../../assets/icons/crown.svg";
import { ReactComponent as User } from "../../../../assets/icons/user.svg";
import { useTranslations } from "../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";

type Props = {
  contactPerson: ContactPerson
};

export const NfoContactPerson = ({
  contactPerson
}: Props) => {
  const theme = useTheme();
  const translations = useTranslations(TRANSLATIONS.myData);


  return (
    <NfoInfoBlockWithIcon
      key={contactPerson.id}
      title={
        <>
          {contactPerson?.role?.id === CUSTOMER_CONTACT_PERSON_ROLE_IDS.TECHNICAL && (
            <>
              <Tech/>
              <h5>{translations.technicalContactPerson()}</h5>
            </>
          )}
          {contactPerson?.role?.id === CUSTOMER_CONTACT_PERSON_ROLE_IDS.BUSINESS && (
            <>
              <Crown/>
              <h5>{translations.businessContactPerson()}</h5>
            </>
          )}
          {contactPerson?.role?.id === CUSTOMER_CONTACT_PERSON_ROLE_IDS.COMMERCIAL && (
            <>
              <User/>
              <h5>{translations.commercialContactPerson()}</h5>
            </>
          )}
        </>
      }
    >
      <>
        <p className={"break-anywhere"}>
          {contactPerson.firstName} {contactPerson.lastName}
        </p>
        <p>{contactPerson.mobile?.phone || contactPerson.phone?.phone}</p>
        <p className={cs('aColor', `aColor-le--${theme}`)}>
          {contactPerson.email + ''}
        </p>
      </>
    </NfoInfoBlockWithIcon>
  )
};
