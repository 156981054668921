import { LookupValue } from 'compax-api';
import { ReactNode } from 'react';
import { ICONS, ID_DATA_ENTITY, ID_STATUS_CUSTOMER, ID_STATUS_SERVICES } from '../../portal/constants/configs/config.constants';
import { TRANSLATIONS } from '../../portal/constants/transitions/uiTranslations';
import { useTranslations } from '../../portal/utils/helper/utils';
import { useTheme } from '../utility/themeContext';
import { CpxIcon } from "./icon.component";
import cs from 'classnames';
import './status.scss';

type Props = {
  status?: LookupValue;
  entity: LookupValue;
  activeIconOverride?: ReactNode;
  orderedIconOverride?: ReactNode;
  terminatedIconOverride?: ReactNode;
  statusClassName?: any;
};

export const NfoStatus = ({
                            status,
                            entity,
                            activeIconOverride,
                            orderedIconOverride,
                            terminatedIconOverride,
                            statusClassName,
                          }: Props) => {
  const translationsCustomer = useTranslations(TRANSLATIONS.status.customer);
  const translationsService = useTranslations(TRANSLATIONS.status.service);
  const theme = useTheme();

  const getStatusElement = (label?: any, icon?: any, overrideIcon?: any) => {
    return (
      <span className={cs('status', `status-le--${theme}`, statusClassName)}>
        {overrideIcon || icon}
        {label}
      </span>
    );
  };

  if (!status || !entity) {
    return null;
  }

  if (entity.id === ID_DATA_ENTITY.CUSTOMER) {
    switch (status.id) {
      case ID_STATUS_CUSTOMER.NEW:
        return getStatusElement(
          translationsCustomer.new(),
          <CpxIcon icon={ICONS.STATUS.ORDERED}/>,
          orderedIconOverride
        );
      case ID_STATUS_CUSTOMER.ACTIVE:
        return getStatusElement(
          translationsCustomer.active(),
          <CpxIcon icon={ICONS.STATUS.ACTIVE} />,
          activeIconOverride
        );
      case ID_STATUS_CUSTOMER.REJECTED:
        return getStatusElement(
          translationsCustomer.rejected(),
          <CpxIcon icon={ICONS.STATUS.ORDERED}/>,
          orderedIconOverride
        );
      case ID_STATUS_CUSTOMER.TERMINATED:
        return getStatusElement(
          translationsCustomer.terminated(),
          <CpxIcon icon={ICONS.STATUS.TERMINATED}/>,
          terminatedIconOverride
        );
      case ID_STATUS_CUSTOMER.NOT_CREATABLE:
        return getStatusElement(
          translationsCustomer.notCreatable(),
          <CpxIcon icon={ICONS.STATUS.ORDERED}/>,
          orderedIconOverride
        );
      case ID_STATUS_CUSTOMER.NEW_IN_REALISATION:
        return getStatusElement(
          translationsCustomer.newInRealisation(),
          <CpxIcon icon={ICONS.STATUS.ORDERED}/>,
          orderedIconOverride
        );
      case ID_STATUS_CUSTOMER.CANCELLED:
        return getStatusElement(
          translationsCustomer.cancelled(),
          <CpxIcon icon={ICONS.STATUS.ORDERED}/>,
          orderedIconOverride
        );
      case ID_STATUS_CUSTOMER.IN_CANCELLATION:
        return getStatusElement(
          translationsCustomer.inCancellation(),
          <CpxIcon icon={ICONS.STATUS.ORDERED}/>,
          orderedIconOverride
        );
      case ID_STATUS_CUSTOMER.BARRING_REQUESTED:
        return getStatusElement(
          translationsCustomer.barringRequested(),
          <CpxIcon icon={ICONS.STATUS.ORDERED}/>,
          orderedIconOverride
        );
      case ID_STATUS_CUSTOMER.BARRED:
        return getStatusElement(
          translationsCustomer.barred(),
          <CpxIcon icon={ICONS.STATUS.CANCELLED}/>,
          orderedIconOverride
        );
      case ID_STATUS_CUSTOMER.BARRING_REMOVAL_REQUESTED:
        return getStatusElement(
          translationsCustomer.barringRemovalRequested(),
          <CpxIcon icon={ICONS.STATUS.ORDERED}/>,
          orderedIconOverride
        );
      case ID_STATUS_CUSTOMER.PORTED_AWAY:
        return getStatusElement(
          translationsCustomer.portedAway(),
          <CpxIcon icon={ICONS.STATUS.ORDERED}/>,
          orderedIconOverride
        );
      case ID_STATUS_CUSTOMER.MARKED_FOR_PORTING_AWAY:
        return getStatusElement(
          translationsCustomer.markedForPortingAway(),
          <CpxIcon icon={ICONS.STATUS.ORDERED}/>,
          orderedIconOverride
        );
      case ID_STATUS_CUSTOMER.MARKED_FOR_DELETION:
        return getStatusElement(
          translationsCustomer.markedForDeletion(),
          <CpxIcon icon={ICONS.STATUS.ORDERED}/>,
          orderedIconOverride
        );
      case ID_STATUS_CUSTOMER.MARKED_AS_DELETED:
        return getStatusElement(
          translationsCustomer.markedAsDeleted(),
          <CpxIcon icon={ICONS.STATUS.ORDERED}/>,
          orderedIconOverride
        );
    }
  } else if (entity.id === ID_DATA_ENTITY.SERVICE) {
    switch (status.id) {
      case ID_STATUS_SERVICES.NOT_ORDERED:
        return getStatusElement(
          translationsService.notOrdered(),
          <CpxIcon icon={ICONS.STATUS.NOT_ORDERED}/>,
          orderedIconOverride
        );
      case ID_STATUS_SERVICES.ORDERED:
        return getStatusElement(
          translationsService.ordered(),
          <CpxIcon icon={ICONS.STATUS.ORDERED}/>,
          orderedIconOverride
        );
      case ID_STATUS_SERVICES.ACTIVE:
        return getStatusElement(
          translationsService.active(),
          <CpxIcon icon={ICONS.STATUS.ACTIVE}/>,
          activeIconOverride
        );
      case ID_STATUS_SERVICES.REJECTED:
        return getStatusElement(
          translationsService.rejected(),
          <CpxIcon icon={ICONS.STATUS.ORDERED}/>,
          orderedIconOverride
        );
      case ID_STATUS_SERVICES.BARRED:
        return getStatusElement(
          translationsService.barred(),
          <CpxIcon icon={ICONS.STATUS.CANCELLED}/>,
          orderedIconOverride
        );
      case ID_STATUS_SERVICES.BARRING_REQUESTED:
        return getStatusElement(
          translationsService.barringRequested(),
          <CpxIcon icon={ICONS.STATUS.ORDERED}/>,
          orderedIconOverride
        );
      case ID_STATUS_SERVICES.DEACTIVATION_REQUESTED:
        return getStatusElement(
          translationsService.deactivationRequested(),
          <CpxIcon icon={ICONS.STATUS.ORDERED}/>,
          orderedIconOverride
        );
      case ID_STATUS_SERVICES.DEACTIVATED:
        return getStatusElement(
          translationsService.deactivated(),
          <CpxIcon icon={ICONS.STATUS.ORDERED}/>,
          orderedIconOverride
        );
      case ID_STATUS_SERVICES.CANCELLATION_REQUESTED:
        return getStatusElement(
          translationsService.cancellationRequested(),
          <CpxIcon icon={ICONS.STATUS.ORDERED}/>,
          orderedIconOverride
        );
      case ID_STATUS_SERVICES.BARRING_REMOVAL_REQUESTED:
        return getStatusElement(
          translationsService.barringRemovalRequested(),
          <CpxIcon icon={ICONS.STATUS.ORDERED}/>,
          orderedIconOverride
        );
      case ID_STATUS_SERVICES.CANCELLED:
        return getStatusElement(
          translationsService.cancelled(),
          <CpxIcon icon={ICONS.STATUS.CANCELLED}/>,
          orderedIconOverride
        );
      case ID_STATUS_SERVICES.ACTIVATION_REQUESTED:
        return getStatusElement(
          translationsService.activationRequested(),
          <CpxIcon icon={ICONS.STATUS.ORDERED}/>,
          orderedIconOverride
        );
      case ID_STATUS_SERVICES.SENT:
        return getStatusElement(
          translationsService.sent(),
          <CpxIcon icon={ICONS.STATUS.ORDERED}/>,
          orderedIconOverride
        );
      case ID_STATUS_SERVICES.TERMINATION_REQUESTED:
        return getStatusElement(
          translationsService.terminationRequested(),
          <CpxIcon icon={ICONS.STATUS.ORDERED}/>,
          orderedIconOverride
        );
      case ID_STATUS_SERVICES.TERMINATED:
        return getStatusElement(
          translationsService.terminated(),
          <CpxIcon icon={ICONS.STATUS.TERMINATED}/>,
          terminatedIconOverride
        );
      case ID_STATUS_SERVICES.NOT_AVAILABLE:
        return getStatusElement(
          translationsService.notAvailable(),
          <CpxIcon icon={ICONS.STATUS.ORDERED}/>,
          orderedIconOverride
        );
      case ID_STATUS_SERVICES.DEFECTIVE:
        return getStatusElement(
          translationsService.defective(),
          <CpxIcon icon={ICONS.STATUS.ORDERED}/>,
          orderedIconOverride
        );
      case ID_STATUS_SERVICES.EXPORTED:
        return getStatusElement(
          translationsService.exported(),
          <CpxIcon icon={ICONS.STATUS.ORDERED}/>,
          orderedIconOverride
        );
      case ID_STATUS_SERVICES.USED_UP:
        return getStatusElement(
          translationsService.usedUp(),
          <CpxIcon icon={ICONS.STATUS.ORDERED}/>,
          orderedIconOverride
        );
      case ID_STATUS_SERVICES.EXPIRED:
        return getStatusElement(
          translationsService.expired(),
          <CpxIcon icon={ICONS.STATUS.ORDERED}/>,
          orderedIconOverride
        );
      case ID_STATUS_SERVICES.CHANGE_REQUESTED:
        return getStatusElement(
          translationsService.change_requested(),
          <CpxIcon icon={ICONS.STATUS.ORDERED}/>,
          orderedIconOverride
        );
    }
  }
  return null;
};
