import {
  createSlice,
  SliceCaseReducers,
  ValidateSliceCaseReducers,
} from "@reduxjs/toolkit";
import {
  GenericLanguages,
  LangState,
  SwitchLanguageAction,
  SwitchLanguageActionCreator,
} from "./types";

export const createLangStore = <TLanguages extends GenericLanguages>(
  defaultLanguage: keyof TLanguages
) => {
  const initialState = {
    selected: defaultLanguage,
  } as LangState<TLanguages>;

  const reducers = {
    switchLanguage: (
      state: LangState<TLanguages>,
      action: SwitchLanguageAction<TLanguages>
    ) => {
      state.selected = action.payload.language;
    },
  } as ValidateSliceCaseReducers<
    LangState<TLanguages>,
    SliceCaseReducers<LangState<TLanguages>>
  >;

  const slice = createSlice({
    name: "lang",
    initialState,
    reducers,
  });

  const { switchLanguage } = (slice.actions as unknown) as {
    switchLanguage: SwitchLanguageActionCreator<TLanguages>;
  };

  return {
    initialState,
    slice,
    switchLanguage,
  };
};

export const selectCurrentLanguage = <TLanguages extends GenericLanguages>(
  state: LangState<TLanguages>
) => state.selected;
export const selectCurrentLanguageName = <TLanguages extends GenericLanguages>(
  state: LangState<TLanguages>,
  languages: TLanguages
) => languages[state.selected];
