import { CpxIcon } from "../../../../core/components/icon.component";
import './partnerContactItem.scss'
import { CpxAddress } from "../../../../core/components/address.component";
import cs from "classnames";
import { useTheme } from "../../../../core/utility/themeContext";
import { PortalPartnerData } from "compax-api";
import { ICONS, ID_PARTNER_ROLES } from "../../../constants/configs/config.constants";
import { useTranslations } from "../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../constants/transitions/uiTranslations";

type Props = {
  partner: PortalPartnerData;
  partnersLength: number;
}

export const PartnerContactItem = ({ partner, partnersLength }: Props) => {
  const translations = useTranslations(TRANSLATIONS.yourPartner);
  const internalClassName = "partnerContactItem";
  const theme = useTheme();
  const partnerCustomContactPerson = partner.contactPersons?.find((partner) => partner.role.id === 700);

  const getPartnerIcon = (partnerRoleId: number | undefined) => {
    switch (partnerRoleId) {
      case ID_PARTNER_ROLES.SERVICE_PARTNER:
        return <CpxIcon icon={ICONS.TECH} />
      case ID_PARTNER_ROLES.SALES_PARTNER:
        return <CpxIcon icon={ICONS.PHONE} />
      default:
        return <CpxIcon icon={ICONS.TECH} />
    }
  };

  const getRoleTranslation = (partnerRoleId: number | undefined) => {
    if (partnersLength === 1) {
      return translations.serviceAndSalesPartner();
    }
    switch (partnerRoleId) {
      case ID_PARTNER_ROLES.SERVICE_PARTNER:
        return translations.servicePartner();
      case ID_PARTNER_ROLES.SALES_PARTNER:
        return translations.salesPartner();
      default:
        return translations.servicePartner();
    }
  }

  return (
    <div className={cs(`${internalClassName} ${internalClassName}-le--${theme}`)}>
      <h4 className={cs(`${internalClassName}-title`, `${internalClassName}-title-le--${theme}`)}>
        {getPartnerIcon(partner.partnerRole?.id)}
        {getRoleTranslation(partner.partnerRole?.id)}
      </h4>
      <div className={cs(`${internalClassName}-information`, `${internalClassName}-information-le--${theme}`)}>
        <p>{partnerCustomContactPerson?.lastName || partner.name}</p>
        <p>{partnerCustomContactPerson?.privatePhone?.phone || partner.partnerMainMobile}</p>
        <p>{partnerCustomContactPerson?.email || partner.partnerMainEmail}</p>
        <p>{partnerCustomContactPerson?.fax?.phone}</p>
        <br/>
        {(partner.mainContactPerson?.firstName && partner.mainContactPerson?.lastName) && <>
          <h5 className={cs(`${internalClassName}-subtitle`, `${internalClassName}-subtitle-le--${theme}`)}>{translations.yourContactPerson()}</h5>
          <p>{partnerCustomContactPerson?.department || `${partner.mainContactPerson?.firstName} ${partner.mainContactPerson?.lastName}`}</p>
          <p>{partnerCustomContactPerson?.phone?.phone || (partner.mainContactPerson?.mobile?.countryCode && `${partner.mainContactPerson?.mobile.countryCode} ${partner.mainContactPerson?.mobile?.areaCode} ${partner.mainContactPerson?.mobile?.cli}`)}</p>
          <p>{partner.mainContactPerson?.email && `${partner.mainContactPerson.email}`}</p>
          <br />
        </>}
        <CpxAddress address={partnerCustomContactPerson?.address || partner.address} usage={'yourPartner'} />
      </div>
    </div>
  )
}