import { CpxInputWithLabel } from "../../../../../../../core/components/inputWithLabel.component";
import { PRODUCT_CATEGORY, PRODUCT_CATEGORY_CALL_NUMBER_BLOCKS_MIGRATION } from "../../../../../../constants/configs/config.constants";
import { SelectableBasketOption } from "compax-api";
import { useTranslations } from "../../../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../../../constants/transitions/uiTranslations";


type Props = {
  option: SelectableBasketOption;
  formik: any;
  index: number;
}


export const MigrationPhoneNumberItem = ({ option, formik, index }: Props) => {

  const translation = useTranslations(TRANSLATIONS.stepper.newCustomer);
  return (
    <>
      {PRODUCT_CATEGORY.phoneNumberMigration === option?.product?.productCategory?.id &&
        <CpxInputWithLabel
          type="string"
          id={`configurations[${index}].cli`}
          onChange={formik.handleChange}
          value={formik.values?.configurations && formik?.values?.configurations[index]?.cli}
          error={formik.errors?.configurations && formik?.errors?.configurations[index]?.cli}
        >
          {translation.cli()} *
        </CpxInputWithLabel>
      }
      {option?.product?.productCategory?.id && PRODUCT_CATEGORY_CALL_NUMBER_BLOCKS_MIGRATION.includes(option?.product?.productCategory?.id) &&
        <>
          <CpxInputWithLabel
            type="string"
            id={`configurations[${index}].extensionNumber`}
            onChange={formik.handleChange}
            value={formik.values?.configurations && formik?.values?.configurations[index]?.extensionNumber}
            error={formik.errors?.configurations && formik?.errors?.configurations[index]?.extensionNumber}
          >
            {translation.callNumberExtension()} *
          </CpxInputWithLabel>
          <CpxInputWithLabel
            type="string"
            id={`configurations[${index}].blockFrom`}
            onChange={formik.handleChange}
            value={formik.values?.configurations && formik?.values?.configurations[index]?.blockFrom}
            error={formik.errors?.configurations && formik?.errors?.configurations[index]?.blockFrom}
          >
            {translation.blockFrom()} *
          </CpxInputWithLabel>
          <CpxInputWithLabel
            type="string"
            id={`configurations[${index}].blockTo`}
            onChange={formik.handleChange}
            value={formik.values?.configurations && formik?.values?.configurations[index]?.blockTo}
            error={formik.errors?.configurations && formik?.errors?.configurations[index]?.blockTo}
          >
            {translation.blockTo()} *
          </CpxInputWithLabel>
        </>
      }


    </>
  )
}