import { useTheme } from '../utility/themeContext';
import cs from 'classnames';
import { CpxInputWithLabel } from './inputWithLabel.component';
import './modalBox.scss';
import { ReactNode } from 'react';

type Props = {
  id?: string;
  inputValue?: string | number;
  tabIndex: number;
  maxValue: number;
  title: string;
  children: ReactNode;
  onFocusBox?: () => void;
  onBlurBox?: () => void;
  inputChange?: any;
  inputLabel?: string;
  onClick?: () => void;
  icon?: ReactNode;
  name?: string;
  visible?: boolean;
  disabled?: boolean;
  error?: any;
};

export const CpxModalBox = ({
                              id,
                              inputValue,
                              tabIndex,
                              maxValue,
                              title,
                              children,
                              onFocusBox,
                              onBlurBox,
                              inputChange,
                              inputLabel,
                              onClick,
                              icon,
                              name,
                              visible,
                              disabled,
                              error,
                              ...props
                            }: Props) => {
  const theme = useTheme();

  return (
    <div
      className={cs('box', `box-le--${theme}`, `${disabled ? "disabled disabled-le--" + theme : ""}`)}
      tabIndex={tabIndex}
      onFocus={onFocusBox}
      onBlur={onBlurBox}
      onClick={onClick}
      hidden={visible}
    >
      <div className={cs('modalBox', `modalBox-le--${theme}`)} id={'editBox'}>
        <h4>{title}</h4>
        <p>{children}</p>
        <div
          className={cs(
            'modalBoxInputField',
            `modalBoxInputField-le--${theme}`
          )}
        >
          <div className={'modalBoxFirstChild'}>
            {icon}
            {inputLabel && (
              <CpxInputWithLabel
                id={id}
                type="number"
                min="1"
                max={maxValue}
                value={inputValue}
                error={error}
                onChange={inputChange}
                className={'input'}
                labelClassName={'labelInput'}
                inputClassName={cs('modalBoxInput', `modalBoxInput-le--${theme}`)}
                disabled={disabled}
                {...props}
              >
                <span>{inputLabel}</span>
              </CpxInputWithLabel>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
