import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelectedMainContract, useSelectPartnerCustomer, useSelectSipTrunks } from "../../../redux/store";
import { TRANSLATIONS } from "../../../constants/transitions/uiTranslations";
import { useTranslations } from "../../../utils/helper/utils";
import { ICONS } from "../../../constants/configs/config.constants";
import { NfoConnection } from "../../../components/common/securitySettings/connection/connection.component";
import { CpxTitle } from "../../../../core/components/title/title.component";
import { CpxSubtitle } from "../../../../core/components/title/subtitle.component";
import commonStyles from "../../../sass/base/common.module.scss";
import "./securitySettings.scss";
import { apiCallAction } from "../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../constants/action.constants";
import { NfoEmptyState } from "../../../../core/emptyState.component";
import { SipTrunkContract } from "compax-api";
import { IPartnerCustomer } from "../../../constants/types/types.constants";

export const NfoSecuritySettingsPage = () => {
  const dispatch = useDispatch();
  const translations = useTranslations(TRANSLATIONS.securitySettings);
  const internalClassName = "securitySettings";
  const mySipTrunks = useSelectSipTrunks() as Array<SipTrunkContract>;

  const selectedPartnerCustomer: IPartnerCustomer = useSelectPartnerCustomer() as IPartnerCustomer;
  const selectedMainContract = useSelectedMainContract();

  useEffect(() => {
    if(selectedMainContract?.contractId){
      dispatch(apiCallAction(ACTION_CONST.API_GET_SIPTRUNK, { contractId: selectedMainContract?.contractId, statuses: ['ACTIVE', 'ORDERED', 'LOCKED'], selectedPartnerCustomerId: selectedPartnerCustomer?.id}));
    }
  }, [dispatch, selectedMainContract]);


  return (
    <>
      <CpxTitle
        title={translations.title()}
        internalClassName={internalClassName}
      />
      <CpxSubtitle
        subtitle={translations.subheading()}
        internalClassName={internalClassName}
      />
      <NfoEmptyState
        icon={ICONS.SETTINGS}
        data={mySipTrunks}
        pageTitle={translations.title()}
        boxStyling={true}
      >
        <div className={commonStyles.columnContainer}>
          <div className={commonStyles.column}>
            {Array.isArray(mySipTrunks) && mySipTrunks && mySipTrunks?.map((connection, key) => (
                <NfoConnection key={key} index={key+1} connection={connection}/>
              ))}
          </div>
        </div>
      </NfoEmptyState>
    </>
  );
};
