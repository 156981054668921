import cs from 'classnames';
import React, { PropsWithoutRef } from 'react';
import { useTheme } from '../utility/themeContext';
import { ReactComponent as ArrowLeft } from "../../portal/assets/icons/arrow_left.svg";
import { useHistory } from "react-router-dom";
import { useTranslations } from "../../portal/utils/helper/utils";
import { TRANSLATIONS } from "../../portal/constants/transitions/uiTranslations";
import './goBackLink.scss';

type Props = PropsWithoutRef<JSX.IntrinsicElements['button']> & {
  isSmall?: boolean;
  buttonType?: 'default' | 'secondary' | 'error';
  isLinkStyle?: boolean;
};

export const CpxGoBackLink = ({
                                buttonType,
                                isLinkStyle,
                                className,
                                children,
                                ...props
                              }: Props) => {
  const internalClassname = 'goBackLink';
  const history = useHistory();
  const theme = useTheme();
  const translations = useTranslations(TRANSLATIONS.common);

  return (
    <a className={cs(
      internalClassname,
      `${internalClassname}-le--${theme}`,
      className)}
       onClick={history.goBack}>
      <ArrowLeft width={24} height={24}/>{translations.back()}
    </a>
  );
};
