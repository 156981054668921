import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelectApiCallStatus, useSelectUserRole } from "../../redux/store";
import { useTheme } from "../../../core/utility/themeContext";
import { LOCALSTORAGE_REFRESHTOKEN, USER_CUSTOMER, USER_PARTNER, USER_WHOLESALER, USER_WHOLESALER_EVN } from "../../constants/configs/config.constants";


export const NfoHomePage = () => {
  const history = useHistory();
  const location = useLocation()
  const role = useSelectUserRole();
  const legalEntity = useTheme();
  const isLoggedIn = useSelectApiCallStatus().loggedIn && localStorage.getItem(LOCALSTORAGE_REFRESHTOKEN)


  useEffect(() => {
    if (isLoggedIn) {
      if (location.pathname === '/' || location.pathname === '/callback/oauth' || location.pathname.includes(legalEntity)) {
        if (role === USER_CUSTOMER) {
          history.push('/customer/my-contracts');
        } else if (role === USER_WHOLESALER || role === USER_PARTNER) {
          history.push('/partner/customers');
        } else if (role === USER_WHOLESALER_EVN) {
          history.push('/wholesalers/evn');
        }
      }
    } else {
      history.push('login');
    }

  }, [history, role, location, legalEntity]);


  return (
    <></>
  )
}


