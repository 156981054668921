import React from 'react';
import cs from 'classnames';
import { useTranslations } from "../../portal/utils/helper/utils";
import { TRANSLATIONS } from "../../portal/constants/transitions/uiTranslations";
import { CpxModal } from "./modal.component";
import { useTheme } from "../utility/themeContext";
import { useSelectApiCallStatus } from "../../portal/redux/store";
import './confirmationModal.scss'

export const NfoConfirmationModal = ({
                                       onConfirm,
                                       successful,
                                       selected,
                                       successTitle,
                                       successSubTitle,
                                       errorTitle,
                                       errorSubTitle,
                                     }: any) => {
  const internalClassName = 'modal';
  const theme = useTheme();
  const translations = useTranslations(TRANSLATIONS.confirmationModal);
  const loading = useSelectApiCallStatus().loading;

  const handleConfirmation = () => {
    onConfirm(false);
    if (successful) {
      window.location.reload();
    }
  }

  return (
    <>
      <CpxModal
        onConfirm={() => handleConfirmation()}
        disableButton={loading}
      >
        {successful === true ?
          <>
            <h3 className={cs('modal__title', `modal__title-le--${theme}`)}>
              {successTitle ? successTitle : (selected === 'increase' ? translations.increaseSuccess() : translations.reduceSuccess())}
            </h3>
            <p className={cs(`${internalClassName}-modal__subtitle`, `${internalClassName}-modal__subtitle-le--${theme}`)}>
              {successSubTitle ? successSubTitle : translations.close()}
            </p>
          </>
          :
          <>
            <h3 className={cs('modal__title', `modal__title-le--${theme}`)}>
              {errorTitle ? errorTitle : translations.orderError()}
            </h3>
            <p className={cs(`${internalClassName}-modal__subtitle`, `${internalClassName}-modal__subtitle-le--${theme}`)}>
              {errorSubTitle ? errorSubTitle : translations.tryAgain()}
            </p>
          </>
        }
      </CpxModal>
    </>
  );
};
