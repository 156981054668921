import cs from "classnames";
import { CpxButton } from "../../../../../core/components/button.component";
import { ReactComponent as Add } from "../../../../assets/icons/add.svg";
import { CpxPaginatedTable } from "../../../../../core/components/paginatedTable.component";
import React, { useEffect, useState } from "react";
import { trimStringProperties, useTranslations } from "../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";
import { CpxIcon } from "../../../../../core/components/icon.component";
import { ICONS, ID_ADDRESSES, ORDER_TYPE } from "../../../../constants/configs/config.constants";
import { useCurrentOrderType, useSelectCountries, useSelectLegalForms, useStep1Data, useStep2Data } from "../../../../redux/store";
import { useTheme } from "../../../../../core/utility/themeContext";
import "./locationsTable.scss";
import { SiteModal } from "../siteModal/siteModal.component";
import { RemoveSiteConfirmationModal } from "../removeSiteConfirmationModal/removeSiteConfirmationModal.component";
import { useDispatch } from "react-redux";
import { saveCurrentStep, saveStep2Data } from "../../../../redux/actions/step.action";
import { useFormik } from "formik";
import { createValidatorSchema } from "../../../../utils/validation";
import { CpxFormikForm } from "../../../../../core/components/formikForm.component";
import { clearError } from "../../../../redux/actions/error.action";
import { apiCallAction } from "../../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../../constants/action.constants";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

type Props = {
  readOnly?: boolean
}
type AppDispatch = ThunkDispatch<any, any, AnyAction>;
export const NfoLocationsTable = ({ readOnly = false }: Props) => {
  const theme = useTheme();
  const translation = useTranslations(TRANSLATIONS.stepper.newCustomer);
  const translationsCommon = useTranslations(TRANSLATIONS.common);
  const dispatch = useDispatch();
  const dispatchTemp: AppDispatch = useDispatch();
  const legalForms = useSelectLegalForms();
  const countries = useSelectCountries();
  const { mainAddress, customer } = useStep1Data();
  const dataOfStep2 = useStep2Data();
  const internalClassName = "locationsTable";
  const orderType = useCurrentOrderType();
  const dataOfStep1 = useStep1Data();

  const goForward = () => {
    dispatch(saveCurrentStep(5));
  }


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {},
    validationSchema: createValidatorSchema({}),
    onSubmit: async (values: any) => {


      const finalValues = {
        customer: dataOfStep1.customer,
        mainAddress: dataOfStep1.mainAddress,
        contactPersons: dataOfStep1.contactPersons,
        account: dataOfStep1.account,
        stepper: "SITE_ADDRESSES"
      }

      trimStringProperties(finalValues);

      validateAddress(finalValues, 1);


    }
  });

  const validateAddress = (finalValues: any, index: number) => {

    if (index >= locations.length) {
      goForward();
      return
    }

    const tempValues = {...finalValues, customDataPortal:{siteAddresses: [locations[index]]}};

    dispatchTemp(apiCallAction(ACTION_CONST.API_VALIDATE_CUSTOMER_DATA, tempValues))
      .then(() => {
        dispatch(clearError());
        validateAddress(finalValues, index + 1);
      }).catch((res: any) => {
      setShowModal(true);
      setEdit({ isActive: true, index: index });
    })

  }

  const getLegalFormById = (id: number) => {
    return legalForms?.filter((legalForm: any) => legalForm.id === id)[0]?.description;
  }

  const getCountryById = (id: number) => {
    return countries?.filter((country: any) => country.id === id)[0]?.description;
  }


  const getLocations = () => {
    if(orderType === ORDER_TYPE.ORDER_NEW_CUSTOMER) {
      return [{ ...mainAddress, ...customer }, ...dataOfStep2?.siteAddresses];
    }
    return [{ ...mainAddress }, ...dataOfStep2?.siteAddresses];
  }


  const [locations, setLocations] = useState(getLocations());

  const [showModal, setShowModal] = useState<boolean>(false);
  const [edit, setEdit] = useState({
    isActive: false,
    index: NaN,
  });
  const [remove, setRemove] = useState({
    isActive: false,
    index: NaN,
  });

  const getSiteAddresses = () => locations.filter((location) => location?.addressType?.id === ID_ADDRESSES.CONNECTION || (orderType === ORDER_TYPE.ORDER_NEW_MAIN_CONTRACT && !location?.id));

  useEffect(() => {
    dispatch(saveStep2Data({ ...dataOfStep2, siteAddresses: getSiteAddresses() }));
  }, [locations]);

  const editSite = (index: number) => {
    setShowModal(true);
    setEdit({ isActive: true, index: index });
  }

  const removeSite = (index: number) => {
    setLocations(locations.filter((location, i) => i !== index));
    setRemove({ isActive: false, index: NaN })
  }

  const locationsTableRowData = (address: any, index: number) => ({
    id: "" + index,
    ...(index !== 0 && {
      collapsible: [
        <></>,
        /*<></>,
        <></>,
        <></>,
        <></>,*/
        <>
          {!readOnly && !address?.id && ((orderType === ORDER_TYPE.ORDER_NEW_MAIN_CONTRACT && address.addressType.id === ID_ADDRESSES.MAIN) || address?.addressType?.id === ID_ADDRESSES.CONNECTION) && (
            <div className={cs(`${internalClassName}-actions`, `${internalClassName}-actions-le--${theme}`)}>
              <CpxButton className={"iconOnly"} type={'button'} onClick={() => editSite(+index)}><CpxIcon icon={ICONS.EDIT}/></CpxButton>
              <CpxButton className={"iconOnly"} type={'button'} onClick={() => setRemove({ isActive: true, index: +index })}><CpxIcon icon={ICONS.DELETE}/></CpxButton>
            </div>
          )}
        </>,
      ],
    }),
    cellData: [
      <p className={cs("tableFirstChild ", `tableFirstChild-font-le--${theme}`)}>
        {address.companyName1} {address.companyName2 ? address.companyName2 + ' ' : ''}{address.legalForm ? getLegalFormById(+address.legalForm?.id) : ''}
      </p>,
      <div>
        {address.street} {address?.houseNumber ? address.houseNumber : ''}, {address.zip} {address.city}, {getCountryById(+address.country.id)}<br/>
      </div>
    ],
  });

  return (
    <>
      {!readOnly && showModal && (
        <SiteModal setShowModal={setShowModal}
                   locations={locations} setLocations={setLocations}
                   edit={edit} setEdit={setEdit}
                   remove={remove} setRemove={setRemove} removeSite={removeSite}
        />
      )}
      {!readOnly && remove?.isActive && (
        <RemoveSiteConfirmationModal remove={remove} setRemove={setRemove} removeSite={removeSite}/>
      )}
      <CpxFormikForm handleSubmit={formik.handleSubmit}
                     initialValues={formik.initialValues}
                     id={'new-customer-form'}
      >
        <article className={cs(`${internalClassName}`, `${internalClassName}-le--${theme}`)}>
          <div className={cs(`${internalClassName}-container`, `${internalClassName}-container-le--${theme}`)}>
            <h4 className={cs(`${internalClassName}-title`, `${internalClassName}-title-le--${theme}`)}>
              {translation.locations()}
            </h4>
            {!readOnly && <CpxButton className={"buttonIcons"} onClick={() => setShowModal(true)} type={'button'}>
              <Add/>
              {translationsCommon.add()}
            </CpxButton>}
          </div>
          <CpxPaginatedTable
            id={internalClassName}
            tableHeader={[
              translation.locationsTableHeadingName(),
              translation.locationsTableHeadingAddress(),
              /*translation.locationsTableHeadingPostalCode(),
              translation.locationsTableHeadingCity(),
              translation.locationsTableHeadingCountry(),*/
              "",
            ]}
            tableData={locations?.map(locationsTableRowData)}
          />
        </article>
      </CpxFormikForm>
    </>
  )
}
