import React, { useState } from "react";
import cs from "classnames";
import { CpxModal } from "../../../../../core/components/modal.component";
import { useTheme } from "../../../../../core/utility/themeContext";
import { endOfMonth, useTranslations } from "../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";
import { CpxInputWithLabel } from "../../../../../core/components/inputWithLabel.component";
import { useFormik } from "formik";
import { createValidatorSchema, orderQuantityCheck } from "../../../../utils/validation";
import { ReactComponent as Negativ } from '../../../../assets/icons/negativ.svg';
import "./reduceLicensesModal.scss"
import { ReactComponent as Info } from '../../../../assets/icons/info_green.svg';
import { CpxFormikForm } from "../../../../../core/components/formikForm.component";
import { apiCallAction } from "../../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../../constants/action.constants";
import { terminateContractsBody } from "../../../../utils/helper/requestBodyHelper";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useDispatch } from "react-redux";
import { IPartnerCustomer, ToastTypes } from "../../../../constants/types/types.constants";
import { useSelectPartnerCustomer, useSelectUser } from "../../../../redux/store";
import { useToast } from "../../../common/header/toast/toast.component";

type Props = {
  reduceModal: { isShowing: boolean, page: number, option: any },
  setReduceModal: Function,
  loadLicenses: Function
}

type AppDispatch = ThunkDispatch<any, any, AnyAction>;

export const ReduceLicensesModal = ({reduceModal, setReduceModal, loadLicenses}: Props) => {
  const internalClassName = "reduceLicensesModal";
  const translationsModal = useTranslations(TRANSLATIONS.confirmationModal);
  const translation = useTranslations(TRANSLATIONS.stepper.newCustomer);
  const translationsLicenses = useTranslations(TRANSLATIONS.licenses);
  const theme = useTheme();
  const dispatch: AppDispatch = useDispatch();
  const userData = useSelectUser();
  const { showToast } = useToast();

  const [isSuccess, setIsSuccess] = useState(true);

  const selectedPartnerCustomer: IPartnerCustomer = useSelectPartnerCustomer() as IPartnerCustomer
  const customerId = selectedPartnerCustomer.id;

  const PAGES = {
    "QTY": 1,
    "CONFIRM": 2,
    "STATUS": 3,
  }

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      quantity: 1
    },
    validationSchema: createValidatorSchema({
      quantity: orderQuantityCheck(translation.quantityErrorMessage("1", reduceModal.option.quantity.toString()), 1, reduceModal.option.quantity)
    }),
    onSubmit: () => {
      setReduceModal({ ...reduceModal, page: PAGES["CONFIRM"] })
    }
  });

  const handleConfirmation = () => {
    dispatch(
      apiCallAction(ACTION_CONST.API_SEND_TERMINATION,
        {
          selectedPartnerCustomerId: customerId,
          ...terminateContractsBody(
            reduceModal.option.contractIds.slice(0, formik.values.quantity),
            endOfMonth, userData?.legalEntity.id, userData?.sub, 10048
          )
        })
    ).then(() => {
      setIsSuccess(true)
      setReduceModal({ ...reduceModal, isShowing: false })
      showToast(ToastTypes.SUCCESS, translationsLicenses.successTitle(), translationsLicenses.successInfo());
      loadLicenses();
    })
  }

  const handleStatusModal = () => {
    setReduceModal({ ...reduceModal, isShowing: false })
    window.location.reload();
  }

  const renderModalPage = () => {
    switch (reduceModal.page) {
      case PAGES["QTY"]:
        return (
          <CpxFormikForm handleSubmit={formik.handleSubmit}
                         initialValues={formik.initialValues}
                         id={'license-quantity-form'}
          >
            <CpxModal
              onConfirm={formik.handleSubmit}
              onCancel={() => setReduceModal({ ...reduceModal, isShowing: false })}
              confirmText={translationsLicenses.next()}
              disableButton={!formik.values.quantity}
              formId={'license-quantity-form'}
              className={internalClassName}
            >
              <h3 className={cs('modalTitle', `modalTitle-le--${theme}`)}>
                {translationsLicenses.reduce()}
              </h3>
              <p className={cs(`modalInfoText modalInfoText-le--${theme}`)}>{translationsLicenses.reduceSubTitle()}</p>
              <div className={cs(`${internalClassName}-input-wrapper`)}>
                <Negativ/>
                <CpxInputWithLabel
                  min={1}
                  max={reduceModal.option.quantity}
                  id={`quantity`}
                  name={`quantity`}
                  type={'number'}
                  value={formik.values?.quantity && formik?.values?.quantity}
                  error={formik.errors?.quantity && formik?.touched?.quantity && formik?.errors?.quantity}
                  onChange={formik.handleChange}
                  onKeyDown={e => e.key === 'Enter' && formik.handleChange}
                >
                  {translationsLicenses.number()}
                </CpxInputWithLabel>
              </div>
            </CpxModal>
          </CpxFormikForm>
        )
      break;
      case PAGES["CONFIRM"]:
        return (
          <CpxModal
            onConfirm={handleConfirmation}
            onCancel={() => setReduceModal({ ...reduceModal, isShowing: false })}
            disableButton={!formik.values.quantity}
            onBack={() => setReduceModal({ ...reduceModal, page: PAGES.QTY })}
            formId={'license-quantity-form'}
            className={internalClassName}
          >
            <h3 className={cs('modalTitle', `modalTitle-le--${theme}`)}>
              {translationsLicenses.titleSummary()}
            </h3>
            <p className={cs(`modalInfoText modalInfoText-le--${theme}`)}>{translationsLicenses.subTextSummary()}</p>
            <div className={cs(`reduceInfo reduceInfo-le--${theme}`)}>
              <Info/>
              <div className={'infoReduce'}>
                <p className={cs('infoTitle', `infoTitle-le--${theme}`)}>
                  {translationsLicenses.reduceConfirmTitle(reduceModal.option.product.description, formik.values.quantity.toString())}
                </p>
                <p className={cs('modalInfoText', `modalInfoText-le--${theme}`)}>{translationsLicenses.reduceConfirmSubtitle((reduceModal.option.quantity - formik.values.quantity).toString())}</p>
              </div>
            </div>
          </CpxModal>
        )
        break;
      case PAGES["STATUS"]:
        return (<>
          {!isSuccess && (
            <CpxModal
              onConfirm={handleStatusModal}>
              <h3 className={cs('modalTitle', `modalTitle-le--${theme}`)}>
                {translationsLicenses.errorTitle()}
              </h3>
              <p className={cs(`modalInfoText modalInfoText-le--${theme}`)}>{translationsModal.tryAgain()}</p>
            </CpxModal>
          )}
          {isSuccess && (
            <CpxModal
              onConfirm={handleStatusModal}>
              <h3 className={cs('modalTitle', `modalTitle-le--${theme}`)}>
                {translationsLicenses.successTitle()}
              </h3>
              <p className={cs(`modalInfoText modalInfoText-le--${theme}`)}>{translationsLicenses.successInfo()}</p>
            </CpxModal>
          )}
        </>)
        break;
      default:
        reduceModal.option.quantityModel
          ? setReduceModal({ ...reduceModal, page: 1 })
          : setReduceModal({ ...reduceModal, page: 2 });
    }
  }

  return (
      <>
        {renderModalPage()}
      </>
  )
}