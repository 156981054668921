import { useTheme } from "../../../../../core/utility/themeContext";
import commonStyles from "../../../../sass/base/common.module.scss";
import cs from "classnames";
import React from "react";
import "./orderOverviewCustomerDetails.scss";
import { NfoCustomerDetails } from "../customerData/customerDetails.component";

export const OrderOverviewCustomerDetails = ({}: any) => {
  const theme = useTheme();
  const internalClassName = "orderOverviewCustomerDetails";
  
  return (
    <div className={commonStyles.columnContainer}>
      <div className={commonStyles.column}>
        <div className={cs('step', `step-le--${theme}`)}>
          <NfoCustomerDetails className={internalClassName}/>
        </div>
      </div>
    </div>
  );
};