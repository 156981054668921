import React, { useEffect, useState } from "react";
import { CpxCollapsible } from "../../../../core/components/collapsible.component";
import { ReactComponent as Partner } from "../../../assets/icons/partner_white.svg";
import { useTranslations } from "../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../constants/transitions/uiTranslations";
import { useDispatch } from "react-redux";
import { useSelectCustomer, useSelectPartners } from "../../../redux/store";
import { apiCallAction } from "../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../constants/action.constants";
import { IPartners, ToastTypes } from "../../../constants/types/types.constants";
import './yourPartner.scss';
import cs from "classnames";
import { useTheme } from "../../../../core/utility/themeContext";
import { PartnerContactItem } from "./partnerContactItem.component";
import { PortalCustomerData } from "compax-api";
import { CpxButton } from "../../../../core/components/button.component";
import { CpxIcon } from "../../../../core/components/icon.component";
import { ICONS } from "../../../constants/configs/config.constants";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useFormik } from "formik";
import { createValidatorSchema, servicePinCheck } from "../../../utils/validation";
import { CpxFormikForm } from "../../../../core/components/formikForm.component";
import { Alert } from "../Alert/Alert.component";
import { useToast } from "../header/toast/toast.component";

type AppDispatch = ThunkDispatch<any, any, AnyAction>;

export const NfoYourPartner = () => {
  const internalClassName = "yourPartner";
  const dispatch: AppDispatch = useDispatch();
  const translations = useTranslations(TRANSLATIONS.yourPartner);
  const partners = useSelectPartners() as IPartners;
  const customer = useSelectCustomer() as PortalCustomerData;
  const theme = useTheme();
  const requiredMsg = translations.fieldRequired();
  const { showToast } = useToast();

  const [pinIsHidden, setPinIsHidden] = useState(true);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    dispatch(apiCallAction(ACTION_CONST.API_GET_PARTNERS));
  }, [dispatch]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      servicePin: customer?.servicePin || ""
    },
    validationSchema: createValidatorSchema({
      servicePin: servicePinCheck(requiredMsg, translations.invalidPin())
    }),
    onSubmit: async (values) => {
      dispatch(apiCallAction(ACTION_CONST.API_POST_CUSTOMER_SERVICE_PIN, { servicePin: values.servicePin }))
        .then(() => {
          setEdit(false);
          showToast(ToastTypes.SUCCESS, translations.pinChangedSuccess());
        })
        .catch(() => {
          showToast(ToastTypes.ERROR, translations.pinChangedFailedTitle(), translations.pinChangedFailedTryAgain());
        })
    }
  });

  return (
    <CpxCollapsible
      title={
        <>
          <Partner/>
          {translations.partnerHeading()}
        </>
      }
    >
      <div className={cs(`${internalClassName} ${internalClassName}-le--${theme}`)}>
        {Array.isArray(partners) && partners
          .map((partner) => (
            <PartnerContactItem key={partner.id} partner={partner} partnersLength={partners.length}/>
        ))}

        <div className={cs(`${internalClassName}-servicePin ${internalClassName}-servicePin-le--${theme}`)}>
          <div className={cs(`${internalClassName}-servicePin-wrapper ${internalClassName}-servicePin-wrapper-le--${theme}`)}>
            <p className={cs(`${internalClassName}-servicePin-title ${internalClassName}-servicePin-title-le--${theme}`)}>{translations.myServicePin()}</p>
            {!edit && <CpxButton className={"iconOnly"} type={'button'} onClick={() => setPinIsHidden(prev => !prev)}>
              {formik.values.servicePin && (pinIsHidden ? <CpxIcon icon={ICONS.EYE_OPEN} title={translations.showPin()}/> : <CpxIcon icon={ICONS.EYE_CLOSED} title={translations.hidePin()}/>)}
            </CpxButton>}
          </div>
          <h2 className={cs(`${internalClassName}-servicePin-pin ${internalClassName}-servicePin-pin-le--${theme}`)}>
            {edit ? <div className={"edit-pin"}>
              <CpxFormikForm handleSubmit={formik.handleSubmit}
                             initialValues={formik.initialValues}
                             id={'servicePin-form'}>
                <input
                  id={"servicePin"}
                  name={"servicePin"}
                  className={cs(`${internalClassName}-servicePin-input ${internalClassName}-servicePin-input-le--${theme}`)}
                  value={formik.values.servicePin}
                  placeholder={customer?.servicePin || formik.values?.servicePin}
                  onChange={formik.handleChange}
                  type="text"
                  maxLength={6}
                  autoFocus
                />
                <CpxButton type={"submit"} buttonType={theme === "nfon" ? "secondary" : "white"}>{translations.save()}</CpxButton>
              </CpxFormikForm>
              {(formik.touched?.servicePin && formik.errors?.servicePin) && <Alert errors={formik.errors?.servicePin} hideIcon={true}/>}
            </div> : <div className={"pin"}>
              <h3>{(pinIsHidden && formik.values.servicePin) ? "******" : formik.values.servicePin}</h3>
              {!pinIsHidden &&
                (<CpxButton className={"iconOnly"} type={'button'} onClick={() => setEdit(prev => !prev)}><CpxIcon icon={ICONS.EDIT}/></CpxButton>)
              }
            </div>}
          </h2>
          {(!formik.values.servicePin && !edit) && <div className={cs(`${internalClassName}-servicePin-emptystate ${internalClassName}-servicePin-emptystate-le--${theme}`)}>
            <p>{translations.noPinAvailable()}</p>
            <CpxButton buttonType={theme === "nfon" ? "default" : "white"} onClick={() => setEdit(prev => !prev)}>{translations.add()}</CpxButton>
          </div>}
        </div>
      </div>
    </CpxCollapsible>
  );
};
