import { CpxHelpBox } from "../../../../../core/components/helpBox.component";
import styles from "./sitesHelpBox.module.scss";
import {useTranslations} from "../../../../utils/helper/utils";
import {TRANSLATIONS} from "../../../../constants/transitions/uiTranslations";
import {ID_ADDRESSES} from "../../../../constants/configs/config.constants";

type Props = {
  labelName?: string;
  addressTypeId?: number;
};

export const NfoSitesHelpBox = ({ labelName, addressTypeId }: Props) => {

  const translations = useTranslations(TRANSLATIONS.helpBox);

  const displayHelpText = addressTypeId === ID_ADDRESSES.MAIN ? translations.mainAddress() :
      addressTypeId === ID_ADDRESSES.CONNECTION ? translations.connectionAddress() :
          addressTypeId === ID_ADDRESSES.BILLING ? translations.billingAddress() :
              addressTypeId === ID_ADDRESSES.SHIPPING ? translations.shippingAddress() : ""


  return (
      <CpxHelpBox className={styles.helpBox} labelName={labelName}>
        {displayHelpText
            .split(/\r?\n/)
            .map((paragraph, key) => (
                <p key={key}>{paragraph}</p>
            ))}
      </CpxHelpBox>
  );
};
