import cs from 'classnames';
import React, { ForwardedRef, forwardRef, PropsWithoutRef, ReactNode } from 'react';
import './inputWithLabel.scss';
import { useTheme } from "../utility/themeContext";
import { InputError } from "./inputError.component";
import { PopupDirections } from "../../portal/constants/types/types.constants";
import { CpxHelpBox } from "./helpBox.component";

type Props = PropsWithoutRef<JSX.IntrinsicElements['input']> & {
  id?: string;
  children?: ReactNode;
  suffix?: ReactNode;
  labelClassName?: string;
  inputClassName?: string;
  buttonClassName?: string;
  onChange?: any;
  onPaste?: any;
  error?: any;
  minLength?: number;
  maxLength?: number;
  showErrorMsg?: boolean;
  helpBoxText?: string | ReactNode;
};

export const CpxInputWithLabel = forwardRef(
  (
    {
      children,
      suffix,
      labelClassName,
      inputClassName,
      className,
      onChange,
      onPaste,
      error,
      buttonClassName,
      showErrorMsg = true,
      helpBoxText,
      ...props
    }: Props,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const theme = useTheme();

    return (
      <div
        className={cs('inputWithLabel', className, {
          ['isDisabled']: props.disabled,
        })}
      >
        <label
          htmlFor={props.id}
          className={cs(labelClassName, { ['required']: !!props.required, ['isHelpBox']: helpBoxText }, `label label-le--${theme}`)}
        >
          {children}
          {helpBoxText && <CpxHelpBox className={"helpBox"} popupDirection={PopupDirections.LEFT}>
            {helpBoxText}
          </CpxHelpBox>}
        </label>
        <input
          type={props.type ? props.type : 'text'}
          {...props}
          className={cs('input', inputClassName, { ['isNotEmpty']: !!props.value, ['error']: !showErrorMsg && error, [`error-le--${theme}`]: error }, `input-le--${theme}`)}
          onChange={onChange}
          onPaste={onPaste}
          ref={ref}
          maxLength={props?.maxLength}
          minLength={props?.minLength}
          min={props?.min}
          max={props?.max}
        />
        <div className={buttonClassName}>{suffix}</div>
        {showErrorMsg && <InputError errorMessage={error}/>}
      </div>
    )
  }
);
