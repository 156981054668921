import React, { useEffect } from "react";
import { useTranslations } from "../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../constants/transitions/uiTranslations";
import commonStyles from "../../../sass/base/common.module.scss";
import { NfoStepperComponent } from "../../../components/partner/newCustomer/stepper/stepper.component";
import cs from "classnames";
import { useCurrentStep, useOrderEntryBasket, useStep3Data } from "../../../redux/store";
import { NfoBasicDataStep } from "../../../components/partner/newCustomer/steps/basicData.step";
import { NfoCustomerLocationsStep } from "../../../components/partner/newCustomer/steps/locations.step";
import { NfoCustomerProductSelectionStep } from "../../../components/partner/newCustomer/steps/productSelection.step";
import { NfoCustomerOrderOverviewStep } from "../../../components/partner/newCustomer/steps/orderOverview.step";
import { NfoStepperMiniBasket } from "../../../components/partner/newCustomer/miniBasket/miniBasket.component";
import { NfoCustomerConfigureProductStep } from "../../../components/partner/newCustomer/steps/configureProduct.step";
import { NfoCustomerContractDocumentsStep } from "../../../components/partner/newCustomer/steps/contractDocuments.step";
import { NfoContactPersonsStep } from "../../../components/partner/newCustomer/steps/contactPersons.step";
import { NfoBillingAndPaymenttep } from "../../../components/partner/newCustomer/steps/billingAndPayment.step";
import { NfoCustomerConfigureTelephoneConnectionsStep } from "../../../components/partner/newCustomer/steps/configureTelephoneConnections.step";
import { deleteSelectedPartnerCustomer } from "../../../redux/actions/partnerCustomers.action";
import { useDispatch } from "react-redux";
import { Basket } from "compax-api";

export const NfoNewCustomerPage = () => {
  const translations = useTranslations(TRANSLATIONS.stepper.newCustomer);
  const dispatch = useDispatch();
  const currentStep = useCurrentStep();
  const basket: Basket = useOrderEntryBasket();
  const step3Data = useStep3Data();

  useEffect(() => {
    dispatch(deleteSelectedPartnerCustomer());
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  const stepTitles = [
    {stepNumber: 1, stepTitle: translations.basicData()},
    {stepNumber: 2, stepTitle: translations.contactPersons()},
    {stepNumber: 3, stepTitle: translations.billingAndPayment()},

    {stepNumber: 4, stepTitle: translations.locations()},
    {stepNumber: 5, stepTitle: translations.productSelection([6,7].includes(currentStep) ? ' (' + (step3Data?.activeProduct?.displayValue + ' - ' + (1 + Object.values(basket.entries).filter(entry => entry.product.id === step3Data?.activeProduct?.id).findIndex(entry => entry.uuid === step3Data?.activeEntryUuid))) + ')' : '') },
    {stepNumber: 6, stepTitle: translations.productConfiguration()},
    {stepNumber: 7, stepTitle: translations.telephoneConnectionConfiguration()},
    {stepNumber: 8, stepTitle: translations.documents()},
    {stepNumber: 9, stepTitle: translations.orderSummary()}
  ];

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <NfoBasicDataStep/>;
      case 2:
        return <NfoContactPersonsStep/>;
      case 3:
        return <NfoBillingAndPaymenttep/>;
      case 4:
        return <NfoCustomerLocationsStep/>;
      case 5:
        return <NfoCustomerProductSelectionStep/>;
      case 6:
          return <NfoCustomerConfigureProductStep/>;
      case 7:
        return <NfoCustomerConfigureTelephoneConnectionsStep/>;
      case 8:
        return <NfoCustomerContractDocumentsStep/>;
      case 9:
        return <NfoCustomerOrderOverviewStep/>;
      default:
        return <NfoBasicDataStep/>;
    }
  }


  return (
    <>
      <div className={cs(commonStyles.columnContainer)}>
        <div className={commonStyles.column}>
          {renderStep()}
        </div>
        <div className={cs(commonStyles.column)}>
          <NfoStepperComponent active={currentStep} steps={stepTitles}/>
          {currentStep !== 1 && currentStep !== 2 && currentStep !== 3 &&  currentStep !== 4  && currentStep !== 9 && <NfoStepperMiniBasket tmpBasket={basket}/>}
        </div>
      </div>
    </>
  )
};
