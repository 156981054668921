import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import './changePasswordForm.scss';
import { CpxChangePassword } from '../../../../../core/components/changePassword.component';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { apiCallAction } from '../../../../redux/actions/apiCall.action';
import { ACTION_CONST } from '../../../../constants/action.constants';
import cs from "classnames";
import { useTheme } from "../../../../../core/utility/themeContext";
import { useTranslations } from "../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";

type AppDispatch = ThunkDispatch<any, any, AnyAction>;

export const NfoChangePasswordForm = () => {
  const dispatch: AppDispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const internalClassName = 'myProfileBox'
  const theme = useTheme();
  const translations = useTranslations(TRANSLATIONS.profile);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    dispatch(
      apiCallAction(ACTION_CONST.API_CHANGE_PASSWORD, {
        oldPassword,
        newPassword,
      })
    )
      .then(() => {
        setSuccess(true);
      })
      .catch(() => {
        setHasError(true);
      });
  };

  return (
    <div className={cs(internalClassName, `${internalClassName}-le--${theme}`)}>
      <div className={cs(`${internalClassName}-header`, `${internalClassName}-header-le--${theme}`)}>
        <h4 className={`${internalClassName}-subtitle-le--${theme}`}>
          {translations.passwordHeading()}
        </h4>
      </div>
      <CpxChangePassword
        formSubmit={handleSubmit}
        resetPasswordPage={false}
        currentPwInput={true}
        buttonClassName={'hideButton'}
        passwordCriteriaClass={'passwordCriteria'}
        onCurrentPwdInput={setOldPassword}
        onNewPwdInput={setNewPassword}
        successPassword={success}
        hasError={hasError}
      />
    </div>
  );
};
