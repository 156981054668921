import cs from "classnames";
import { CpxModal } from "../../../../../core/components/modal.component";
import React from "react";
import { useTheme } from "../../../../../core/utility/themeContext";
import { useTranslations } from "../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";


export const RemoveSiteConfirmationModal = ({ remove, setRemove, removeSite }: any) => {
  const theme = useTheme();
  const translationsCommon = useTranslations(TRANSLATIONS.common);
  const translations = useTranslations(TRANSLATIONS.stepper.newCustomer);


  return (
    <CpxModal
      onConfirm={() => removeSite(remove?.index)}
      confirmText={translationsCommon.delete()}
      onCancel={() => setRemove({isActive: false, index: NaN})}
    >
      <h3 className={cs('modalTitle', `modalTitle-le--${theme}`)}>
        {translations.deleteSiteHeading()}
      </h3>
      <p className={cs(`modalInfoText modalInfoText-le--${theme}`)}>{translations.deleteSiteText()}</p>
    </CpxModal>
  )
}
