import { useTheme } from '../../../../../../core/utility/themeContext';
import cs from 'classnames';
import './partnerCustomerContractOverview.scss';
import { routes } from '../../../../../constants/routes/routes';
import { TRANSLATIONS } from '../../../../../constants/transitions/uiTranslations';
import { useTranslations } from '../../../../../utils/helper/utils';
import { NfoInfrastructureBox } from './components/infrastructureBox.component';
import { NfoDetailBox } from './components/detailBox.component';
import { useSelectedMainContract, useSelectLang, useSelectPartnerCustomer, useSelectPartnerCustomerAddress, useSelectPartnerCustomerContract, useSelectPartnerCustomerNumbers, } from '../../../../../redux/store';
import { useEffect } from 'react';
import { apiCallAction } from '../../../../../redux/actions/apiCall.action';
import { ACTION_CONST } from '../../../../../constants/action.constants';
import { CpxClipboard } from '../../../../../../core/components/clipboard.component';
import { CpxStart } from '../../../../../../core/components/start.component';
import { CpxEnd } from '../../../../../../core/components/end.component';
import { useDispatch } from 'react-redux';
import { IPartnerCustomer, } from '../../../../../constants/types/types.constants';
import { ID_ADDRESS_CONNECTION, ID_ADDRESS_MAIN, } from '../../../../../constants/configs/config.constants';
import { Address, PortalContractData } from "compax-api";
import { formatDate } from "../../../../../../core/utils";
import { selectCurrentLanguage } from "../../../../../../core/uiLanguage/lang.slice";
import { CpxButton } from "../../../../../../core/components/button.component";

export const NfoPartnerCustomerContractOverview = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const translations = useTranslations(TRANSLATIONS.myContract);
    const translationsPartners = useTranslations(TRANSLATIONS.partnerCustomer);
    const translationsPartnersCustomer = useTranslations(TRANSLATIONS.partnerNav);

    const selectedPartnerCustomer: IPartnerCustomer =
        useSelectPartnerCustomer() as IPartnerCustomer;
    const selectedPartnerCustomerContract: PortalContractData[] =
        useSelectPartnerCustomerContract() as unknown as PortalContractData[];
    const selectedPartnerCustomerAddresses = useSelectPartnerCustomerAddress();
    const selectedPartnerCustomerNumbers = useSelectPartnerCustomerNumbers();
    const language = useSelectLang().selected;
    const selectedMainContract = useSelectedMainContract();


  const customerId = selectedPartnerCustomer.id;
  const currentLanguage = selectCurrentLanguage(useSelectLang());


  const mainContract =
        Array.isArray(selectedPartnerCustomerContract) &&
        selectedPartnerCustomerContract !== undefined &&
        selectedPartnerCustomerContract.find(
            (entry: any) => entry.componentOf === undefined
        );

    const mainAddresses =
        selectedPartnerCustomerAddresses &&
        Array.isArray(selectedPartnerCustomerAddresses) &&
        selectedPartnerCustomerAddresses.filter((address: Address) => {
            return (
                address.role?.id + '' === ID_ADDRESS_CONNECTION ||
                address.role?.id + '' === ID_ADDRESS_MAIN
            );
        });

    useEffect(() => {
        // tslint:disable-next-line:no-unused-expression
        customerId &&
        dispatch(
          apiCallAction(ACTION_CONST.API_GET_ADDRESSES,  { language: currentLanguage, selectedPartnerCustomerId: selectedPartnerCustomer?.id})
        );
        // tslint:disable-next-line:no-unused-expression
        customerId && selectedMainContract?.contractId &&
        dispatch(
          apiCallAction(ACTION_CONST.API_GET_CLIS, { selectedPartnerCustomerId: selectedPartnerCustomer?.id, contractId: selectedMainContract?.contractId })
        );
    }, [customerId]);

    return (
        <div className={cs(`customerContract`, `customerContract-le--${theme}`)}>
            <h4 className={cs('title', `title-le--${theme}`)}>
                {translationsPartnersCustomer.contract()}
            </h4>

            <h4 className={cs('label-row', `label-row-le--${theme}`)}>
                {mainContract && mainContract.contractName}
            </h4>

            <div className={cs(`detail-row`, `detail-row-le--${theme}`)}>
                <NfoDetailBox
                    label={translationsPartners.contractNumber()}
                    leftIcon={<CpxClipboard whiteOverride={true} />}
                >
                  {mainContract && mainContract?.knumber !== undefined ? mainContract.knumber + '' : ''}
                </NfoDetailBox>

                <NfoDetailBox
                    leftIcon={<CpxStart whiteOverride={true}/>}
                    label={translationsPartners.contractStart()}
                >
                  {mainContract && mainContract.startDateBilling
                    ? formatDate(new Date(mainContract.startDateBilling), language)
                    : ''}
                </NfoDetailBox>
                <NfoDetailBox
                    leftIcon={<CpxEnd whiteOverride={true}/>}
                    label={translationsPartners.contractEnd()}
                >
                  {mainContract && mainContract.endDateBilling
                    ? formatDate(new Date(mainContract.endDateBilling), language)
                    : ''}
                </NfoDetailBox>
            </div>

            <div
                className={cs('infrastructure-row', `infrastructure-row-le--${theme}`)}
            >
                <NfoInfrastructureBox
                    label={translations.overviewConnections()}
                    amount={1}
                />
                <NfoInfrastructureBox
                    label={translations.overviewLocations()}
                    amount={
                        mainAddresses && mainAddresses.length ? mainAddresses.length : 0
                    }
                />
                <NfoInfrastructureBox
                    label={translations.overviewNumbers()}
                    amount={
                        selectedPartnerCustomerNumbers &&
                        selectedPartnerCustomerNumbers.length
                            ? selectedPartnerCustomerNumbers.reduce((prev: any, cur: any) => prev + (cur.numberCount || 0), 0)
                            : 0
                    }
                />
            </div>

            <div className={'bottom-row'}>
              <CpxButton
                buttonType={'moreLink'}
                to={routes.partnerCustomersContracts.path}
              >
                {translations.moreLink()}
              </CpxButton>
            </div>
        </div>
    );
};
