import { ACTION_CONST } from "../../constants/action.constants";
import { IAction } from "../../constants/types/types.constants";

const initialState: never[] = [];

function partnerCustomContactPersonReducer(state = initialState, action: IAction) {
  switch (action.type) {
    case ACTION_CONST.UPDATE_PARTNER_CUSTOM_CONTACT_PERSON:
    case ACTION_CONST.API_GET_PARTNER_CUSTOM_CONTACT_PERSON:
      return action.data;
    default:
      return state;
  }
}

export default partnerCustomContactPersonReducer;
