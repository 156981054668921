import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

export const usePopupMenu = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(false);
  const location = useLocation();

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [ref]);

  useEffect(() => {
    if (location.pathname) {
      setExpanded(false);
    }
  }, [location.pathname]);

  return {
    expanded,
    setExpanded,
    ref,
  };
};
