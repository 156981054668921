import { useTheme } from '../../../../../../core/utility/themeContext';
import { TRANSLATIONS } from '../../../../../constants/transitions/uiTranslations';
import { useTranslations } from '../../../../../utils/helper/utils';
import { NfoInfoBlockWithIcon } from '../../../../../../core/components/infoBlock.component';
import { ReactComponent as Mail } from '../../../../../assets/icons/mail.svg';
import { ReactComponent as Tax } from '../../../../../assets/icons/tax.svg';
import { ReactComponent as Note } from '../../../../../assets/icons/note_nfon.svg';
import cs from 'classnames';
import './contractAccount.scss';
import { Account } from 'compax-api';
import { CpxAddress } from "../../../../../../core/components/address.component";
import React from "react";

type Props = {
  selectedPartnerCustomerContractAccount: Account;
  className?: string;
};

export const NfoPartnerContractAccount = ({
                                            selectedPartnerCustomerContractAccount,
                                            className,
                                            ...props
                                          }: Props) => {
  const translations = useTranslations(TRANSLATIONS.partnerPages.data);
  const internalClassName = 'contactAccount';
  const theme = useTheme();

  return (
    <article className={cs(internalClassName, `${internalClassName}-le--${theme}`, className)} {...props}>
      <div className={cs('titleBox', `titleBox-le--${theme}`)}>
        <h4 className={`componentTitle-le--${theme}`}>
          {translations.contractAccount()}
        </h4>
        <p>
          ID:{' '}
          {selectedPartnerCustomerContractAccount &&
            selectedPartnerCustomerContractAccount.id}
        </p>
      </div>
      <div className={'rowTable'}>
        <div className={'contactAccountBox'}>
          <NfoInfoBlockWithIcon
            title={
              <>
                <Note/>
                {translations.settledContracts()}
              </>
            }
            className={cs(`componentTitle-le--${theme}`)}
          >
            <p>{translations.mainContract()}</p>
          </NfoInfoBlockWithIcon>

          <NfoInfoBlockWithIcon
            title={
              <>
                <Mail/>
                {translations.billingAddress()}
              </>
            }
            className={`componentTitle-le--${theme}`}
          >

            <>
              <CpxAddress address={{ ...selectedPartnerCustomerContractAccount?.billToAddress, email: selectedPartnerCustomerContractAccount?.email }}/>
              <p>
                {translations.billingMedium()}:{' '}
                {selectedPartnerCustomerContractAccount?.billmedia?.description}
              </p>
            </>
          </NfoInfoBlockWithIcon>

          <NfoInfoBlockWithIcon
            title={
              <>
                <Tax/>
                {translations.taxData()}
              </>
            }
            className={cs(`componentTitle-le--${theme}`)}
          >
            <>
              <p>
                {translations.currency()}:{' '}
                {selectedPartnerCustomerContractAccount &&
                  selectedPartnerCustomerContractAccount.currency &&
                  selectedPartnerCustomerContractAccount.currency.description}
              </p>
              <p>
                {' '}
                {selectedPartnerCustomerContractAccount &&
                  selectedPartnerCustomerContractAccount.taxIdentificationNumber}
              </p>
              <p>
                {translations.EVNDeletionPeriod()}:{' '}
                {selectedPartnerCustomerContractAccount &&
                  selectedPartnerCustomerContractAccount.dataStoringOption &&
                  selectedPartnerCustomerContractAccount.dataStoringOption
                    .description}
              </p>
              <p>
                {translations.EVNArt()}:{' '}
                {selectedPartnerCustomerContractAccount &&
                  selectedPartnerCustomerContractAccount.itemizedBillFormat &&
                  selectedPartnerCustomerContractAccount.itemizedBillFormat
                    .description}
              </p>
              <p>
                {translations.paymentTerm()}:{' '}
                {selectedPartnerCustomerContractAccount &&
                  selectedPartnerCustomerContractAccount.paymentTarget &&
                  selectedPartnerCustomerContractAccount.paymentTarget
                    .description}
              </p>
            </>
          </NfoInfoBlockWithIcon>
        </div>
      </div>
    </article>
  );
};
