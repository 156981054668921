import { TRANSLATIONS } from "../../../constants/transitions/uiTranslations";
import { useTranslations } from "../../../utils/helper/utils";
import { CpxTitle } from "../../../../core/components/title/title.component";
import "./myAccount.scss";
import React, { useEffect } from "react";
import { useSelectFeatures, useSelectPartners, useSelectUserRole, useSelectWholesaleModel } from "../../../redux/store";
import commonStyles from "../../../sass/base/common.module.scss";
import { CpxSubtitle } from "../../../../core/components/title/subtitle.component";
import { NfoPartnerDataOverview } from "../../../components/partner/myContactPerson/overview/dataOverview.component";
import { NfoPartnerPersonInChargeList } from "../../../components/partner/myContactPerson/personInChargeList/personInChargeList.component";
import { NfoCommissionTable } from "../../../components/partner/commissionTable/commissionTable.component";
import { NfoSDRTable } from "../../../components/wholesaler/sdrTable/sdrTable.component";
import { FEATURES_FLAG, ROLE_WHOLESALE_MODEL_AIRTIME_RESELLING, USER_WHOLESALER } from "../../../constants/configs/config.constants";
import { NfoBillAccount } from "../../../components/customer/myData/billAccount/billAccount.component";
import { apiCallAction } from "../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../constants/action.constants";
import { useDispatch } from "react-redux";
import { NfoUpdateContactData } from "../../../components/common/profile/updateContactData/updateContactData.component";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { NfoCDRTable } from "../../../components/wholesaler/cdrTable/cdrTable.component";

type AppDispatch = ThunkDispatch<any, any, AnyAction>;

export const NfoMyAccountPage = () => {
  const dispatch: AppDispatch = useDispatch();
  const myAccountTranslations = useTranslations(TRANSLATIONS.myAccount);
  const internalClassName = "myAccount";
  const partners = useSelectPartners();
  const userRole = useSelectUserRole();
  const wholesaleModel = useSelectWholesaleModel();
  const features = useSelectFeatures();

  useEffect(() => {
    dispatch(apiCallAction(ACTION_CONST.API_PORTAL_FEATURE, { featureName: FEATURES_FLAG.PORTAL_PARTNER_CUSTOM_INFO }));
    dispatch(apiCallAction(ACTION_CONST.API_GET_ACCOUNTS));
  }, [])

    return (
    <div className={internalClassName}>
      <CpxTitle
        title={myAccountTranslations.title()}
        internalClassName={internalClassName}
      />
      <div className={'subTitleBox'}>
        <CpxSubtitle
          subtitle={myAccountTranslations.subheading()}
          internalClassName={'internalClassName'}
        />
      </div>
      <div className={commonStyles.columnContainer}>
        <NfoPartnerDataOverview
          className={commonStyles.column}
          partner={partners}
        />
        <NfoPartnerPersonInChargeList
          className={commonStyles.column}
          partner={partners}
        />
      </div>
      <NfoBillAccount />
      <NfoCommissionTable />
      {userRole === USER_WHOLESALER &&
        <>
          {wholesaleModel && wholesaleModel === ROLE_WHOLESALE_MODEL_AIRTIME_RESELLING && <NfoCDRTable/>}
          <NfoSDRTable/>
        </>}
      {features?.PORTAL_PARTNER_CUSTOM_INFO && <NfoUpdateContactData />}
    </div>
  );
};
