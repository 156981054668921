import { CpxInputWithLabel } from "./inputWithLabel.component";
import { CpxPhoneInputWithLabel } from "./phoneInputWithLabel.component";
import React from "react";
import { useTranslations } from "../../portal/utils/helper/utils";
import { TRANSLATIONS } from "../../portal/constants/transitions/uiTranslations";
import './portalUserInputGroup.scss';
import cs from "classnames";
import { useTheme } from "../utility/themeContext";


export const CommercialPortalUserInputGroup = ({ values, handleChange, touched, errors, }: any) => {
  const translation = useTranslations(TRANSLATIONS.stepper.newCustomer);
  const theme = useTheme();

  values = values?.contactPersons?.commercialContactPerson
  touched = touched?.contactPersons?.commercialContactPerson && touched?.contactPersons?.commercialContactPerson
  errors = errors?.contactPersons?.commercialContactPerson && errors?.contactPersons?.commercialContactPerson

  return (
    <div className="admin-portal-user">
       <h5 className={cs(`sub-title sub-title-le--${theme}`)}>{translation.businessContactPersonUser()} *</h5>

       <CpxInputWithLabel
         id="contactPersons.commercialContactPerson.email"
         type="text"
         onChange={handleChange}
         value={values?.email.trimStart()}
         error={touched?.email && errors?.email}
       >
         {translation.login() + " *"}
       </CpxInputWithLabel>
      <CpxInputWithLabel
        id="contactPersons.commercialContactPerson.firstName"
        type="text"
        onChange={handleChange}
        value={values?.firstName.trimStart()}
        error={touched?.firstName && errors?.firstName}
      >
        {translation.firstName() + " *"}
      </CpxInputWithLabel>
      <CpxInputWithLabel
        id="contactPersons.commercialContactPerson.lastName"
        type="text"
        onChange={handleChange}
        value={values?.lastName.trimStart()}
        error={touched?.lastName && errors?.lastName}
      >
        {translation.lastName() + " *"}
      </CpxInputWithLabel>
      <CpxPhoneInputWithLabel
        fields={[
          { name: 'contactPersons.commercialContactPerson.mobile.countryCode', value: values?.mobile?.countryCode, id: 'countryCode', placeholder: translation.countryCode() },
          { name: 'contactPersons.commercialContactPerson.mobile.areaCode', value: values?.mobile?.areaCode, id: 'areaCode', placeholder: translation.areaCode() },
          { name: 'contactPersons.commercialContactPerson.mobile.cli', value: values?.mobile?.cli, id: 'cli', placeholder: translation.cli() }
        ]}
        onChange={handleChange}
        errors={errors}
        touched={touched}
        label={translation.mobileNumber()}
        phoneType={values.mobile.phoneType.id}
      >
      </CpxPhoneInputWithLabel>
      <CpxPhoneInputWithLabel
        fields={[{ name: 'contactPersons.commercialContactPerson.phone.countryCode', value: values?.phone?.countryCode, id: 'countryCode', placeholder: translation.countryCode() },
          { name: 'contactPersons.commercialContactPerson.phone.areaCode', value: values?.phone?.areaCode, id: 'areaCode', placeholder: translation.areaCode() },
          { name: 'contactPersons.commercialContactPerson.phone.cli', value: values?.phone?.cli, id: 'cli', placeholder: translation.cli() }
        ]}
        onChange={handleChange}
        errors={errors}
        touched={touched}
        label={translation.phoneNumber()}
        phoneType={values.phone.phoneType.id}
      >
      </CpxPhoneInputWithLabel>
     </div>
    )
  };
