import React from "react";
import { useTranslations } from "../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";
import { NfoCustomerWrapperComponent } from "../wrapper/newCustomerWrapper.component";
import { OrderOverviewCustomerDetails } from "../orderOverviewCustomerDetails/orderOverviewCustomerDetails.component";
import { NfoLocationsTable } from "../locationsTable/locationsTable.component";
import { OrderOverviewBasket } from "../orderOverviewBasket/orderOverviewBasket.component";
import { useCurrentOrderType } from "../../../../redux/store";
import { ORDER_TYPE } from "../../../../constants/configs/config.constants";
import { useOrderEntryBasket } from "../../../../redux/store";


export const NfoCustomerOrderOverviewStep = () => {
  const translationsStepper = useTranslations(TRANSLATIONS.stepper.newCustomer);

  const orderType = useCurrentOrderType();

  const basket = useOrderEntryBasket();



  return (
    <>
      <NfoCustomerWrapperComponent
        title={translationsStepper.orderSummary()}
        subtitle={translationsStepper.orderSummarySubTitle()}
      >
        <>
          <OrderOverviewBasket basket={basket}/>
          <NfoLocationsTable readOnly={true}/>
          { orderType === ORDER_TYPE.ORDER_NEW_CUSTOMER && <OrderOverviewCustomerDetails/>}
        </>
      </NfoCustomerWrapperComponent>
    </>
  );
};
