import { useTheme } from '../utility/themeContext';
import React, { ReactNode } from 'react';
import { useTranslations } from "../../portal/utils/helper/utils";
import { TRANSLATIONS } from "../../portal/constants/transitions/uiTranslations";
import { ICONS, PRODUCT_CATEGORY_CALL_NUMBER_BLOCKS, SERVICE_TYPE_PORTING } from "../../portal/constants/configs/config.constants";
import { CpxIcon } from "./icon.component";
import cs from 'classnames';
import './selectBox.scss';
import parse from 'html-react-parser';
import { SelectBoxCallNumberBlockComponent } from "../../portal/components/partner/newCustomer/configureProduct/callNumberBlock/selectBoxCallNumberBlock.component";
import { SelectableBasketOption } from "compax-api";
import { PopupDirections } from "../../portal/constants/types/types.constants";
import { CpxHelpBox } from "./helpBox.component";
import { SelectBoxPortingComponent } from "../../portal/components/partner/newCustomer/configureProduct/porting/selectBoxPorting.component";


type Props = {
  className?: string;
  tabIndex: number;
  product?: any;
  title?: string;
  subtitle?: string;
  children?: ReactNode;
  onFocusBox?: () => void;
  onBlurBox?: () => void;
  onClick?: any;
  active?: boolean;
  current?: boolean;
  disabled?: boolean;
  isIncluded?: boolean;
  selectOptionOfOption?: any;
  option?: SelectableBasketOption;
  QtyInput?: ReactNode;
  dependencyText?: string;
};

export const CpxSelectBox = ({
                               className,
                               product,
                               tabIndex,
                               title,
                               subtitle,
                               children,
                               onClick,
                               active,
                               current,
                               disabled,
                               isIncluded,
                               selectOptionOfOption,
                               option,
                               QtyInput,
                               dependencyText,
                             }: Props) => {
  const internalClassName = "selectBox";
  const theme = useTheme();
  const translations = useTranslations(TRANSLATIONS.common);

  const onClickSelectBox = (e: any) => {
    if (typeof onClick !== "undefined" && e.target.nodeName !== "INPUT") {
      onClick();
    }
  }

  return (
    (active && PRODUCT_CATEGORY_CALL_NUMBER_BLOCKS.includes(product?.productCategory?.id)) ? (
      <SelectBoxCallNumberBlockComponent product={product} option={option} tabIndex={tabIndex} disabled={disabled} className={className} title={title} onClickSelectBox={onClickSelectBox} active={active} selectOptionOfOption={selectOptionOfOption}/>
    ) : ( active && SERVICE_TYPE_PORTING === product?.productType?.id ? (
      <SelectBoxPortingComponent product={product} option={option} tabIndex={tabIndex} disabled={disabled} className={className} title={title} onClickSelectBox={onClickSelectBox} active={active} selectOptionOfOption={selectOptionOfOption}/>
        ) : (
      <div
        className={cs(internalClassName, `${internalClassName}-le--${theme}`, className && className, `${active ? "active-le--" + theme : ""}`, `${disabled ? "disabled disabled-le--" + theme : ""}`)}
        tabIndex={tabIndex}
        onClick={(disabled || isIncluded) ? (() => {
        }) : (e) => onClickSelectBox(e)}
      >
        <div className={cs('innerBox', `innerBox-le--${theme}`)} id={'editBox'}>
          {current && (
            <p className={'current'}><CpxIcon icon={ICONS.STATUS.ACTIVE}/>{translations.current()}</p>
          )}
          <div className={cs('selectBox__text-wrapper', `selectBox__text-wrapper-le--${theme}`)}>
            <div className={cs('selectBox__title-wrapper', `selectBox__title-wrapper-le--${theme}`)}>
              <h4 className={cs('selectBox__title', `selectBox__title-le--${theme}`)}>{title}</h4>
              {dependencyText && <CpxHelpBox className={"helpbox"} popupDirection={PopupDirections.LEFT}>
                {dependencyText}
              </CpxHelpBox>}
            </div>
            <p className={cs('selectBox__subtitle', `selectBox__subtitle-le--${theme}`)}>{subtitle && parse(subtitle)}</p>
          </div>
          <div className={cs(`selectBox-children selectBox-children-le--${theme}`)}>{children}</div>
          {
            (QtyInput) ? (
              <div className={cs("selectFooter")}>
                {QtyInput}
                <p className={cs("selectText", `selectText-le--${theme}`)}>{active ? translations.deselect() : translations.select()}</p>
              </div>
            ) : (!disabled && !isIncluded) ? (
              <p className={cs("selectText", `selectText-le--${theme}`)}>{active ? translations.deselect() : translations.select()}</p>
            ) : (isIncluded) ? (
                <p className={cs("selectText", `selectText-le--${theme}`)}>{translations.included()}</p>
            ) : (
            <p></p>
            )
          }
        </div>
      </div>
    ))
  );
};
