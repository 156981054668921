import { ACTION_CONST } from "../../constants/action.constants";
import { IAction } from "../../constants/types/types.constants";

const initialState: never[] = [];

function licensesReducer(state = initialState, action: IAction) {
  switch (action.type) {
    case ACTION_CONST.SAVE_LICENSES_BASKET:
      return action.data;
      break;
    case ACTION_CONST.DELETE_LICENSES_BASKET:
      return {};
      break;
    default:
      return state;
  }
}

export default licensesReducer;
