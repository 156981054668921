import { CpxInputWithLabel } from "../../../../../../../core/components/inputWithLabel.component";
import React from "react";
import { useTranslations } from "../../../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../../../constants/transitions/uiTranslations";
import { CpxCheckboxGroup } from "../../../../../../../core/components/checkboxGroup.component";
import { useTheme } from "../../../../../../../core/utility/themeContext";
import { CLIENTS_IDS } from "../../../../../../constants/configs/config.constants";


type Props = {
  values: any;
  touched: any;
  errors: any;
  handleChange: any;
  formik: any;
  setShortenOnConsole: any;
  shortenOnConsole: any;
  client: any
}

export const PortingNumberBlockForm = ({
                                         values,
                                         touched,
                                         errors,
                                         handleChange,
                                         formik,
                                         setShortenOnConsole,
                                         shortenOnConsole,
                                         client
                                       }: Props) => {

  const translation = useTranslations(TRANSLATIONS.stepper.newCustomer);
  const internalClassName = "portingNumberBlockForm";
  const theme = useTheme();
  const onChangeShortenOnConsole = () => {
    formik.setFieldValue('numberBlock.shortenOnConsole', !shortenOnConsole);
    setShortenOnConsole((x: any) => !x);
  }

  return (

    <div className={`${internalClassName}`}>
      <div className={`${internalClassName}-section`}>
        <CpxInputWithLabel
          className={'localAreaCode'}
          id={"numberBlock.localAreaCode"}
          type="text"
          value={values.localAreaCode}
          error={touched?.localAreaCode && errors?.localAreaCode}
          onChange={handleChange}
          required
        >
          {translation.localAreaCode()}
        </CpxInputWithLabel>

      </div>
      <div className={'addressInputGroup'}>
        <CpxInputWithLabel
          className={`${internalClassName}-input`}
          id={'numberBlock.extensionNumber'}
          type="text"
          onChange={formik.handleChange}
          value={values?.extensionNumber}
          error={touched?.extensionNumber && errors?.extensionNumber}
          required
        >
          {translation.extensionNumber()}
        </CpxInputWithLabel>

        <CpxInputWithLabel
          className={`${internalClassName}-input`}
          id={'numberBlock.console'}
          type="text"
          onChange={formik.handleChange}
          value={values?.console}
          error={touched?.console && errors?.console}
          required
        >
          {translation.console()}
        </CpxInputWithLabel>
      </div>
      <div className={'addressInputGroup'}>
        <CpxInputWithLabel
          className={`${internalClassName}-input`}
          id={'numberBlock.from'}
          type="text"
          onChange={formik.handleChange}
          value={values?.from}
          error={touched?.from && errors?.from}
          required
          disabled={client.id === CLIENTS_IDS.AUT}
        >
          {translation.from()}
        </CpxInputWithLabel>

        <CpxInputWithLabel
          className={`${internalClassName}-input`}
          id={'numberBlock.to'}
          type="text"
          onChange={formik.handleChange}
          value={values?.to}
          error={touched?.to && errors?.to}
          required
          disabled={client.id === CLIENTS_IDS.AUT}
        >
          {translation.to()}
        </CpxInputWithLabel>

      </div>
      <CpxCheckboxGroup
        name="numberBlock.shortenOnConsole"
        options={[
          {
            name: 'numberBlock.shortenOnConsole',
            title: translation.shortenOnConsole(),
            checked: shortenOnConsole,
          },
        ]}
        onChange={onChangeShortenOnConsole}
        className={`${internalClassName}-label-le--${theme} ${internalClassName}-checkbox ${internalClassName}-checkbox-le--${theme}`}
      />
    </div>

  );
}