import cs from "classnames";
import { CpxModal } from "../../../../../core/components/modal.component";
import React, { useEffect, useState } from "react";
import { useTheme } from "../../../../../core/utility/themeContext";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { apiCallAction } from "../../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../../constants/action.constants";
import { IPartnerCustomer, PopupDirections, ToastTypes } from "../../../../constants/types/types.constants";
import { useLicensesBasket, useSelectError, useSelectLicensesByServiceGroup, useSelectPartnerCustomer } from "../../../../redux/store";
import { currency, CurrencyWithChargeModeWithAmountNet } from "../../../../../core/utils";
import { CpxSelectBox } from "../../../../../core/components/selectBox.component";
import { deleteLicensesBasket, saveLicensesBasket } from "../../../../redux/actions/licenses.action";
import { BasketEntry, LicenseAndServiceContract, SelectableBasketOption } from "compax-api";
import { useTranslations } from "../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";
import { CpxInputWithLabel } from "../../../../../core/components/inputWithLabel.component";
import { addOptionsToBasket, removeOptionsFromBasket } from "../../../../redux/actions/step.action";
import "./addLicensesModal.scss"
import { useFormik } from "formik";
import { createValidatorSchema, createValidatorSchemaArray, requiredOptionCheck } from "../../../../utils/validation";
import { CpxFormikForm } from "../../../../../core/components/formikForm.component";
import { CpxPaginatedTable } from "../../../../../core/components/paginatedTable.component";
import { NfoEmptyState } from "../../../../../core/emptyState.component";
import { ICONS, ID_STATUS_SERVICES, PRODUCT_CATEGORY_QUANTITY_ID } from "../../../../constants/configs/config.constants";
import { CpxHelpBox } from "../../../../../core/components/helpBox.component";
import * as Yup from 'yup'
import { Alert } from "../../../common/Alert/Alert.component";
import { useToast } from "../../../common/header/toast/toast.component";
import {NfoActivationDate} from "../../../partner/newCustomer/configureProduct/activationDate/activationDate.component";

type AppDispatch = ThunkDispatch<any, any, AnyAction>;

type Props = {
  addModal: { isShowing: boolean, page: number, serviceGroup: any },
  setAddModal: Function,
  loadLicenses: Function
}

export const AddLicensesModal = ({ addModal, setAddModal, loadLicenses }: Props) => {
  const internalClassName = "addLicensesModal";
  const theme = useTheme();
  const dispatch: AppDispatch = useDispatch();
  const selectedPartnerCustomer: IPartnerCustomer = useSelectPartnerCustomer() as IPartnerCustomer;
  const licensesBasket = useLicensesBasket();
  const basket = licensesBasket?.basket;
  const error = useSelectError();
  const translationLicenses = useTranslations(TRANSLATIONS.licenses);
  const translation = useTranslations(TRANSLATIONS.stepper.newCustomer);
  const requiredMsg = translation.fieldRequired();
  const translationsCommon = useTranslations(TRANSLATIONS.common);
  const translations = useTranslations(TRANSLATIONS.charges);
  const translationsExtensions = useTranslations(TRANSLATIONS.extensionsModal);
  const translationsModal = useTranslations(TRANSLATIONS.confirmationModal);
  const [availableOptions, setAvailableOptions] = useState<any>(licensesBasket?.availableOptions);
  const [selectedOption, setSelectedOption] = useState<any>();
  const [isSuccess, setIsSuccess] = useState(true);
  const licenses = useSelectLicensesByServiceGroup();
  const optionIsSelected = () => !!availableOptions?.find((o: any) => o.selected);
  const isFormikError = () => !!formik.errors.options?.find((option: any) => option?._quantity);
  const { showToast } = useToast();

  const PAGES = {
    "ADD_OPTIONS": 1,
    "BASKET_OVERVIEW": 2,
    "STATUS": 3,
  }
  const optionsAreEmpty = !(availableOptions && availableOptions.length > 0);
  const [productParentId, setProductParentId] = useState<number>(0);
  const [activationDate, setActivationDate] = useState();
  const [activationDateErrors, setActivationDateErrors] = useState();

  useEffect(() => {
    dispatch(apiCallAction(ACTION_CONST.API_POST_LICENSES_PREPARE_ORDER, { serviceGroup: addModal.serviceGroup.id, option: addModal.serviceGroup.componentOf, selectedPartnerCustomerId: selectedPartnerCustomer?.id }))
      .then((res) => {
        setProductParentId(res.basket.entries[0].product.id)
        dispatch(saveLicensesBasket(res));
        setAvailableOptions(res.availableOptions);
      })
  }, [])

  const handleNextStep = () => {
    if (addModal.page === PAGES["BASKET_OVERVIEW"]) {

      /**
       * Add requested start date
       */
      dispatch(apiCallAction(ACTION_CONST.API_BASKET_PUT_ACTIVATION_DATE,
          {uuid: basket?.entries[0].uuid, basketId: basket.id, activationDate}
      ))
      .then(() => {
        dispatch(
            apiCallAction(ACTION_CONST.API_SEND_ORDER, {
              basketId: basket.id,
            })
        ).then(() => {
          setIsSuccess(true);
          showToast(ToastTypes.SUCCESS, translationsModal.increaseSuccess(), translationLicenses.successInfo());
          loadLicenses();
        }).catch(() => {
          setIsSuccess(false);
          showToast(ToastTypes.ERROR, translationsModal.orderError(), translationsModal.tryAgain());
        });
        setAddModal({ ...addModal, isShowing: false })
      })
      .catch(() => {
        setIsSuccess(false);
        showToast(ToastTypes.ERROR, translationsModal.orderError(), translationsModal.tryAgain());
      })

    } else {
      if (optionsAreEmpty) {
        setAddModal({ ...addModal, isShowing: false })
      } else {
        setAddModal({ ...addModal, page: addModal.page + 1 })
      }
    }
  }
  const handlePreviousStep = () => setAddModal({ ...addModal, page: addModal.page - 1 })
  const handleCancellation = () => {
    setAddModal({ ...addModal, isShowing: false })
    dispatch(deleteLicensesBasket());
  }

  const selectOption = async (option: any, quantity: number) => {

    const optionId = option?.product?.id;
    let newBasket: any;

    if (optionId === basket?.entries[0]?.options.find((o: SelectableBasketOption) => o.product.id === optionId)?.product?.id) {
      //remove from basket
      newBasket = await dispatch(removeOptionsFromBasket(option, basket));
    } else {
      // add to basket
      newBasket = await dispatch(addOptionsToBasket(option, basket, quantity));
    }
    dispatch(saveLicensesBasket({...licensesBasket, basket: newBasket}));


    setAvailableOptions((prevState: any) => prevState.map((o: any) => {
      if (newBasket.entries[0].options.find((newO: any) => newO?.product?.id === o.product.id)) {
        return {...o, selected: true};
      } else {
        return {...o, selected: false}
      }
    }));
  }

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      options: availableOptions?.map((option: any) => { return {...option, isQtyOption: option?.product?.productCategory?.id === PRODUCT_CATEGORY_QUANTITY_ID, _quantity: !option.selected ? option?.servicePropertySip?.defaultQuantity : licensesBasket.basket.entries[0].options.find((o: any) => o.product.id === option.product.id)?.quantity }})
    },
    validationSchema: createValidatorSchema({
      options: createValidatorSchemaArray({
        _quantity: Yup.lazy((values: any, option: any): any => {
          const min = option?.parent?.servicePropertySip?.orderMin;
          const max = option?.parent?.servicePropertySip?.orderMax;

          return requiredOptionCheck(requiredMsg, translation.quantityErrorMessage(min?.toString(), max?.toString()), translationLicenses.invalidQuantity(), min, max)
        })
      })
    }),
    onSubmit: ({ options }) => {
      selectOption(selectedOption.option, options[selectedOption.index]?._quantity)
    }
  });


  const handleSelection = (option: any, index: number) => {
    setSelectedOption({ index, option })
    formik.handleSubmit();
  }

  const checkDependency = (dependentServiceId :any) : boolean => {
    return (!basket.entries[0].options.find((opt : BasketEntry) => opt.product.id === dependentServiceId) && !licenses?.find((license: LicenseAndServiceContract) => (license?.status?.id === ID_STATUS_SERVICES.ACTIVE || license?.status?.id === ID_STATUS_SERVICES.ORDERED) && license?.product?.id === dependentServiceId));
  }

  const renderModalPage = () => {
    switch (addModal.page) {
      case PAGES["ADD_OPTIONS"]:
        return (
          <CpxFormikForm handleSubmit={() => {}}
                         initialValues={formik.initialValues}>
            <CpxModal
              className={internalClassName}
              onConfirm={handleNextStep}
              onCancel={handleCancellation}
              confirmText={optionsAreEmpty ? translationsCommon.close() : translationsCommon.next()}
              disableButton={!optionsAreEmpty && (!optionIsSelected() || isFormikError())}
            >
              <h3 className={cs('modalTitle', `modalTitle-le--${theme}`)}>
                {translationLicenses.addOption()}
              </h3>
              {!optionsAreEmpty && <p className={cs('modalInfoText', `modalInfoText-le--${theme}`)}>{translationLicenses.addSubTitlte()}</p>}
                <NfoEmptyState
                  data={availableOptions}
                  icon={ICONS.STATUS.ORDERED_WHITE}
                  pageTitle={translationLicenses.options()}
                  subtitle={translationLicenses.optionsEmptyState()}
                  boxStyling={false}
                >
                  <div className='boxes'>
                    { availableOptions?.map((o: any, index: number) => {
                      return (
                        <CpxSelectBox
                          key={index}
                          tabIndex={index}
                          option={o && o}
                          product={o?.product}
                          title={o?.product?.displayValue}
                          active={o.selected}
                          onClick={() => handleSelection(o, index)}
                          disabled={(o.product?.parentService?.id && checkDependency(o.product.parentService.id))}
                          dependencyText={(o.product?.parentService?.id && checkDependency(o.product.parentService.id)) ? translationLicenses.dependencyText(o.product.parentService.description) : ""}
                          QtyInput={ o.product.productCategory.id === PRODUCT_CATEGORY_QUANTITY_ID ? (
                            <>
                            <div className={"qty"}>
                              <CpxInputWithLabel
                                min={o.servicePropertySip?.orderMin}
                                max={o.servicePropertySip?.orderMax}
                                id={`options[${index}]._quantity`}
                                name={`options[${index}]._quantity`}
                                value={formik.values?.options && formik?.values?.options[index]?._quantity}
                                error={formik.errors?.options && formik?.touched?.options && formik?.errors?.options[index]?._quantity}
                                showErrorMsg={false}
                                onChange={formik.handleChange}
                                disabled={o.selected || (o.product?.parentService?.id && checkDependency(o.product.parentService.id))}
                                onKeyDown={e => e.key === 'Enter' && handleSelection(o, index)}
                              >
                                {translationLicenses.number()} *
                              </CpxInputWithLabel>
                              {formik.errors?.options && formik?.touched?.options && formik?.errors?.options[index]?._quantity &&
                                <CpxHelpBox className={"helpbox"} popupDirection={PopupDirections.RIGHT}>
                                  {formik.errors?.options && formik?.touched?.options && formik?.errors?.options[index]?._quantity}
                                </CpxHelpBox>
                              }
                            </div>
                            </>
                          ) : ''
                          }
                        >
                          <div
                            className={cs(`${internalClassName}-charges`, `${internalClassName}-charges-le--${theme}`)}>
                            {
                              o?.product?.charges?.length !== 0
                                ? CurrencyWithChargeModeWithAmountNet(o?.product?.charges, productParentId, translations)?.map((charge: any, index: number) => (
                                  <p key={index}>{charge}</p>
                                ))
                                : ''
                            }
                          </div>
                        </CpxSelectBox>
                      )
                    })}
                    {error?.errorData && error?.errorData?.length > 0 && <Alert errors={error?.errorData}/> }
                  </div>
                </NfoEmptyState>
            </CpxModal>
          </CpxFormikForm>
        )
        break;
      case PAGES["BASKET_OVERVIEW"]:

        const overviewTableData = (option: any) => {

          return {
            id: '' + option.product.id,
            cellData: [
              <p>{option.product.description}</p>,
              <p>+{option.quantity}</p>,
              <div className={cs('amount-discount-wrapper')}><p>{option.oneTimeCharges ? currency(option.oneTimeCharges[0]?.amountNet, basket?.currency?.description) : ''}</p></div>,
              <div className={cs('amount-discount-wrapper')}><p>{option.recurringCharges ? currency(option.recurringCharges[0]?.amountNet, basket?.currency?.description) : ''}</p></div>,
            ],
          }
        }

        const basketEntriesOptions = basket.entries[0].options;

        return (
          <CpxModal
            className={internalClassName}
            onConfirm={handleNextStep}
            onCancel={handleCancellation}
            onBack={handlePreviousStep}
            confirmText={translationLicenses.order()}
            disableButton={!activationDateErrors}
          >
            <h3 className={cs('modalTitle', `modalTitle-le--${theme}`)}>
              {translationLicenses.titleSummary()}
            </h3>
            <p className={cs('modalInfoText', `modalInfoText-le--${theme}`)}>{translationLicenses.subTextSummary()}</p>
            <NfoActivationDate setActivationDate={setActivationDate} className={'basketView'} startDtRequested={basket.entries[0].startDtRequested} isValid={setActivationDateErrors}/>
            {basket && (
              <article className={'modalTable'}>
                <CpxPaginatedTable
                  allTitleClass={`allTitle-le--${theme}`}
                  id="modalTable"
                  tableHeader={[
                    translationLicenses.option(),
                    translationsExtensions.difference(),
                    translationLicenses.oneTime(),
                    translationLicenses.monthly(),
                  ]}
                  tableFooter={[
                    translationLicenses.total(),
                    "",
                    currency(basket?.total?.oneTime?.amountNet, basket?.currency?.description),
                    currency(basket?.total?.recurring?.amountNet, basket?.currency?.description),
                  ]}
                  tableData={basketEntriesOptions.map(overviewTableData)}
                />
              </article>
            )}
          </CpxModal>
        )
        break;
      default:
        break;
    }
  }

  return (
    <>
      {renderModalPage()}
    </>
  )
}