import { CpxModal } from "../../../../../core/components/modal.component";
import cs from "classnames";
import React, { useState } from "react";
import { useTheme } from "../../../../../core/utility/themeContext";
import { CpxInputWithLabel } from "../../../../../core/components/inputWithLabel.component";
import { useFormik } from "formik";
import { CpxFormikForm } from "../../../../../core/components/formikForm.component";
import { createValidatorSchema, requiredCheck } from "../../../../utils/validation";
import { useTranslations } from "../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";
import "./sipTrunkDirectRoutingModal.scss"
import { apiCallAction } from "../../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../../constants/action.constants";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { IPartnerCustomer } from "../../../../constants/types/types.constants";
import { useSelectPartnerCustomer } from "../../../../redux/store";
import { ID_CONTRACT_MS_TEAMS } from "../../../../constants/configs/config.constants";


type Props = {
  setShowModal: Function;
  selectedSipTrunk: any;
}

type AppDispatch = ThunkDispatch<any, any, AnyAction>;


export const SipTrunkDirectRoutingModal = ({ setShowModal, selectedSipTrunk }: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const translationsSipTrunk = useTranslations(TRANSLATIONS.sipTrunk);
  const translationsConfirmation = useTranslations(TRANSLATIONS.confirmationModal);
  const translationsStepper = useTranslations(TRANSLATIONS.stepper.newCustomer);
  const theme = useTheme();
  const requiredMsg = translationsStepper.fieldRequired();
  const internalClassName = "directRoutingModal"
  const selectedPartnerCustomer: IPartnerCustomer = useSelectPartnerCustomer() as IPartnerCustomer;
  const msTeamsDirectRoutingOption = selectedSipTrunk?.sipTrunkOptions.find((option: any) => option.serviceType.id == ID_CONTRACT_MS_TEAMS)


  const [isSuccessful, setIsSuccesfull] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleModalSuccess = () => {
    setIsSuccesfull(false);
    setShowModal(false);
    window.location.reload();
  }

  const handleModalError = () => {
    setIsError(false);
    setShowModal(false);
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      cname: ""
    },
    validationSchema: createValidatorSchema({
      cname: requiredCheck(requiredMsg)
    }),
    onSubmit: async (values: any) => {
      dispatch(apiCallAction(ACTION_CONST.API_POST_CUSTOMER_MSTEAMS_CNAME, { contractId: Number(msTeamsDirectRoutingOption.contractId), cname: values.cname, selectedPartnerCustomerId: selectedPartnerCustomer?.id }))
        .then(() => {
          setIsSuccesfull(true);
          dispatch(apiCallAction(ACTION_CONST.API_GET_SIPTRUNK, { statuses: ['ACTIVE', 'ORDERED'], selectedPartnerCustomerId: selectedPartnerCustomer?.id }));
        })
        .catch(() => {
          setIsError(true);
        })
    }
  });

  return (
    <>
      {isSuccessful && <CpxModal
        onConfirm={handleModalSuccess}
      >
        <h3 className={cs('modalTitle', `modalTitle-le--${theme}`)}>
          {translationsConfirmation.addedSuccess()}
        </h3>
        <p className={cs('modalInfoText', `modalInfoText-le--${theme}`)}>{translationsConfirmation.close()}</p>
      </CpxModal>}
      {isError && <CpxModal
        onConfirm={handleModalError}
      >
        <h3 className={cs('modalTitle', `modalTitle-le--${theme}`)}>
          {translationsConfirmation.orderError()}
        </h3>
        <p className={cs('modalInfoText', `modalInfoText-le--${theme}`)}>{translationsConfirmation.tryAgain()}</p>
      </CpxModal>}
      {(!isSuccessful && !isError) && <CpxModal
        onCancel={() => setShowModal((prev: boolean) => !prev)}
        onConfirm={formik.handleSubmit}
        className={`${internalClassName}`}
      >
        <CpxFormikForm handleSubmit={formik.handleSubmit}
                       initialValues={formik.initialValues}
        >
          <h3 className={cs('modalTitle', `modalTitle-le--${theme}`)}>
            {translationsSipTrunk.addCnameTitle()}
          </h3>
          <p className={cs('modalInfoText', `modalInfoText-le--${theme}`)}>{translationsSipTrunk.addCnameSubtitle()}</p>
          <CpxInputWithLabel
            id={`cname`}
            className={`${internalClassName}-input`}
            required
            value={formik.values.cname}
            onChange={formik.handleChange}
            error={formik.touched?.cname && formik.errors?.cname}
          >
            CNAME
          </CpxInputWithLabel>
        </CpxFormikForm>
      </CpxModal>}
    </>
  )
}