import cs from "classnames";
import { CpxButton } from "../../../../../../core/components/button.component";
import { CpxIcon } from "../../../../../../core/components/icon.component";
import { ICONS, TELEPHONE_CONNECTIONS_IDS } from "../../../../../constants/configs/config.constants";
import React, { useState } from "react";
import { useTheme } from "../../../../../../core/utility/themeContext";
import { useOrderEntryBasket, useSelectLang, useStep1Data, useStep3Data } from "../../../../../redux/store";
import { ProductOptionModalType } from "../../../../../constants/types/types.constants";
import { Basket, BasketEntry, SelectableBasketOption } from "compax-api";
import { CallNumberBlockConfigurationItem } from "../callNumberBlock/callNumberBlockConfigurationItem";
import { PortingNumbersForms } from "../productOptionModal/portingFrom/portingNumbersForm.component";
import { useDispatch } from "react-redux";
import "./selectBoxPorting.scss";
import { apiCallAction } from "../../../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../../../constants/action.constants";
import { saveOEOptionsOfOption } from "../../../../../redux/actions/step.action";
import { selectCurrentLanguage } from "../../../../../../core/uiLanguage/lang.slice";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { NfoRemoveConfigurationConfirmationModal } from "../../confirmModal/confirmationModal.component";
import { useTranslations } from "../../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../../constants/transitions/uiTranslations";

type Props = {
  product?: any;
  tabIndex: number;
  disabled?: boolean;
  className?: string;
  active?: boolean;
  isIncluded?: boolean;
  title?: string;
  onClickSelectBox: any;
  selectOptionOfOption: Function;
  option: any;
};
type AppDispatch = ThunkDispatch<any, any, AnyAction>;

export const SelectBoxPortingComponent = ({ product, tabIndex, disabled, className, active, isIncluded, title, onClickSelectBox, selectOptionOfOption, option}: Props) => {
  const theme = useTheme();
  const basket = useOrderEntryBasket();
  const internalClassName = "selectBox";
  const classNamePorting = "selectBoxPorting";
  const step1Data = useStep1Data();
  const step3Data = useStep3Data();
  const dispatch = useDispatch<AppDispatch>();
  const translationsPortingNumbers = useTranslations(TRANSLATIONS.portingNumbersModal);

  const [showModal, setShowModal] = useState(false);
  const [currentEntry, setCurrentEntry] = useState<BasketEntry>();
  const [portingModalType, setPortingModalType] = useState<ProductOptionModalType>(ProductOptionModalType.TELCO_DATA);
  const [edit, setEdit] = useState({
    isActive: false,
    productId: NaN,
  });
  const [confirmDeleteConfigurationModal, setConfirmDeleteConfigurationModal] = useState(false);
  const currentLang = selectCurrentLanguage(useSelectLang());

  const getBasketData = () => {
    const callNumberObjectBasket = basket?.entries[step3Data?.activeIndex]?.options
      .filter((item: BasketEntry) => TELEPHONE_CONNECTIONS_IDS.includes(item.product.id))[0].options
      .filter((option: SelectableBasketOption) => option.product.id === product?.id)
    return callNumberObjectBasket;
  }

  const getBasketDataByProductId = (productId: number) => {
    const callNumberObjectBasket = basket?.entries[step3Data?.activeIndex]?.options
      .filter((item: BasketEntry) => TELEPHONE_CONNECTIONS_IDS.includes(item.product.id))[0].options
      .filter((option: SelectableBasketOption) => option.product.id === productId);

    return callNumberObjectBasket;
  }

  const editPortingEntry = (entry: BasketEntry) => {
    setCurrentEntry(entry);
    setPortingModalType(ProductOptionModalType.EDIT_TELCO_DATA);
    setShowModal(true);
  }

  const addNewPortingEntry = () => {
    setCurrentEntry(undefined);
    setPortingModalType(ProductOptionModalType.TELCO_DATA);
    setShowModal(true);
  }

  const deleteBasketEntry = (callNumberItem: BasketEntry) => {
    let parentEntry = basket.entries[step3Data.activeIndex].options?.find((option: BasketEntry) => option?.options?.find(e => e.uuid === callNumberItem.uuid))
    let parentUuid = parentEntry?.uuid;
    let parentOptionId = parentEntry?.product?.id;
    dispatch(apiCallAction(ACTION_CONST.API_BASKET_DELETE_OPTION_BY_UUID, {
      basketId: basket.id,
      parentUuid: parentUuid,
      optionUuid: callNumberItem.uuid
    })).then((newBasket: Basket) => {
      const action: string = (step1Data?.migrationCustomerId && step1Data?.partnerVoNumber && parentOptionId === 7706200) ? ACTION_CONST.API_GET_OE_OPTIONS_OF_OPTION_FOR_MIGRATION : ACTION_CONST.API_GET_OE_OPTIONS_OF_OPTION;
      dispatch(apiCallAction(action,
        {
          basketId: basket?.id,
          uuid: parentUuid,
          language: currentLang
        },
        true)).then((res) => {
        dispatch(saveOEOptionsOfOption({[parentUuid]: res?.optionGroups}));
      });
    })

  }

  const addOptionToBasketAndCloseModal = async (option: any, productOptionModalType:any, portingNumberObjectArray:any) => {
    return await selectOptionOfOption(option, productOptionModalType, portingNumberObjectArray).then((res: Basket) => {
      setShowModal(false);
      return res;
    })
  }

  return (
    <>
      {showModal &&
        <div>
          <PortingNumbersForms setShowModal={setShowModal} option={option} type={ProductOptionModalType.TELCO_DATA} selectOptionOfOption={addOptionToBasketAndCloseModal} entry={currentEntry}/>
        </div>
      }
      {confirmDeleteConfigurationModal && (
        <NfoRemoveConfigurationConfirmationModal onConfirm={(e:any) => {
          onClickSelectBox(e);
          setConfirmDeleteConfigurationModal(false);
        }} onCancel={() => setConfirmDeleteConfigurationModal(false)} confirmMessage={translationsPortingNumbers.confirmDeletePortingEntries()}/>
      )}

      <div
        className={cs(internalClassName, `${internalClassName}-le--${theme}`, className && className, `${active ? "active-le--" + theme : ""} large large-le--${theme}`, `${disabled ? "disabled" : ""}`)}
        tabIndex={tabIndex}
      >
        <div className={cs('innerBox', `innerBox-le--${theme}`)} id={'editBox'}>
          <div className={cs('selectBox_header')}>
            <div>
              <h4 className={cs('selectBox_header__title', `selectBox_header__title-le--${theme}`)}>{title}</h4>
            </div>
            <div className={cs('selectBox_header__actions')}>
              <CpxButton className={"iconOnly"} type={'button'} onClick={addNewPortingEntry}><CpxIcon icon={ICONS.ADD} className={cs(`selectBox_header__actions-icon`, `selectBox_header__actions-icon-le--${theme}`)}/></CpxButton>
              <CpxButton className={"iconOnly"} type={'button'} onClick={(disabled || isIncluded) ? (() => {
              }) : () => setConfirmDeleteConfigurationModal(true)}><CpxIcon icon={ICONS.DELETE} className={cs(`selectBox_header__actions-icon`, `selectBox_header__actions-icon-le--${theme}`)}/></CpxButton>
            </div>
          </div>
          <div>
            {
              basket?.entries[step3Data?.activeIndex]?.options?.filter((item: BasketEntry) => TELEPHONE_CONNECTIONS_IDS.includes(item.product.id)).length > 0 && getBasketData().map((callNumberItem: any, index: number) => (
                <div className={classNamePorting}>
                  <CallNumberBlockConfigurationItem key={index} site={callNumberItem?.telcoData?.siteAddress} areaCode={callNumberItem?.telcoData?.lnpData?.portPhoneNumberBlock?.areaCode || callNumberItem?.telcoData?.lnpData?.portPhoneNumbers[0]?.areaCode} telcoData={callNumberItem?.telcoData}/>
                  <div className={"selectBox_header__actions"}>
                    <CpxButton className={"iconOnly"} type={'button'} onClick={() => editPortingEntry(callNumberItem)}><CpxIcon icon={ICONS.EDIT} className={cs(`selectBox_header__actions-icon`, `selectBox_header__actions-icon-le--${theme}`)}/></CpxButton>
                    <CpxButton className={"iconOnly"} type={'button'} onClick={() => {
                      deleteBasketEntry(callNumberItem);
                    }}><CpxIcon icon={ICONS.DELETE} className={cs(`selectBox_header__actions-icon`, `selectBox_header__actions-icon-le--${theme}`)}/></CpxButton>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </>
  )
}