import { TRANSLATIONS } from '../../../../constants/transitions/uiTranslations';
import { sortBasketEntries, useTranslations } from '../../../../utils/helper/utils';
import cs from 'classnames';
import './miniBasket.scss';
import { currency } from "../../../../../core/utils";
import { useTheme } from "../../../../../core/utility/themeContext";
import { Basket, BasketEntry, Product } from "compax-api";
import { ReactComponent as ArrowLeftBottom } from '../../../../assets/icons/arrow-left-down.svg'
import { ICONS, ID_CHARGE_MODES, SIP_TRUNK_PRODUCT_IDS, TELEPHONE_CONNECTIONS_IDS } from "../../../../constants/configs/config.constants";
import React, { Fragment, useEffect } from 'react';
import { selectCurrentLanguage } from "../../../../../core/uiLanguage/lang.slice";
import { useCurrentStep, useOEProducts, useSelectLang, useStep3Data } from "../../../../redux/store";
import { apiCallAction } from "../../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../../constants/action.constants";
import { useDispatch } from "react-redux";
import { CpxButton } from "../../../../../core/components/button.component";
import { CpxIcon } from "../../../../../core/components/icon.component";
import { saveCurrentStep, saveStep3Data } from "../../../../redux/actions/step.action";

type Props = {
  tmpBasket: Basket
}

export const NfoStepperMiniBasket = ({ tmpBasket }: Props) => {
  const translations = useTranslations(TRANSLATIONS.stepper.newCustomer);
  const internalClassName = 'miniBasket';
  const theme = useTheme();
  const dispatch = useDispatch();
  const currentLang = selectCurrentLanguage(useSelectLang());
  const currentStep = useCurrentStep();
  const oeProducts = useOEProducts() as Array<Product>;

  let basket: Basket = JSON.parse(JSON.stringify(tmpBasket))

  const step3Data = useStep3Data();

  useEffect(() => {
    dispatch(apiCallAction(ACTION_CONST.API_BASKET_SET_LANGUAGE, { basketId: basket?.id }));
  }, [currentLang]);

  const getCallNumberCountryAndAreaCode = (entry: BasketEntry) => {
    if(entry.telcoData){
      let telco = entry.telcoData;
      if(telco.newPhoneNumberBlock) {
        return ' (' + telco?.newPhoneNumberBlock?.countryCode + ' ' + telco?.newPhoneNumberBlock?.areaCode + ')'
      }
      if(telco.newPhoneNumber) {
        return ' (' + telco?.newPhoneNumber?.countryCode + ' ' + telco?.newPhoneNumber?.areaCode + ')'
      }
      if(telco.newLnpPhoneNumberBlock) {
        return ' (' + telco?.newLnpPhoneNumberBlock?.countryCode + ' ' + telco?.newLnpPhoneNumberBlock?.areaCode + ' ' + telco?.newLnpPhoneNumberBlock?.extensionNumber + ' ' + telco?.newLnpPhoneNumberBlock?.blockFrom + '-' + telco?.newLnpPhoneNumberBlock?.blockTo + ')'
      }
      if(telco.lnpData) {
        if(telco.lnpData.portPhoneNumberBlock) {
          return ' (' + telco?.lnpData?.portPhoneNumberBlock?.countryCode + ' ' + telco?.lnpData?.portPhoneNumberBlock?.areaCode + ' ' + telco?.lnpData?.portPhoneNumberBlock?.extensionNumber + ' ' + telco?.lnpData?.portPhoneNumberBlock?.blockFrom + '-' + telco?.lnpData?.portPhoneNumberBlock?.blockTo +')'
        }
        if(telco.lnpData.portPhoneNumbers && telco.lnpData.portPhoneNumbers.length > 0) {
          return ' (' + telco?.lnpData?.portPhoneNumbers[0]?.countryCode + ' ' + telco?.lnpData?.portPhoneNumbers[0]?.areaCode + ' ' + telco?.lnpData?.portPhoneNumbers[0]?.cli + (telco.lnpData.portPhoneNumbers.length > 1 ? ', ...' : '') + ')'
        }
      }

      return '';
    }
    return '';
  };
  const renderSkeletonRow = (entry: BasketEntry, hasArrowIcon: boolean = false, index: number = 0, isMainProduct: boolean = false) => {

    return (
      <>
        <td className={"product-name " + (entry.uuid === step3Data?.activeEntryUuid ? 'product-name-bold' : '')} colSpan={3}>
          {hasArrowIcon && <ArrowLeftBottom/>} <span>{(entry?.dependentQuantity && entry.dependentQuantity > 1) || (entry?.quantity && entry?.quantity > 1) ? (entry.dependentQuantity || entry.quantity) + "x" : ''} {entry?.product?.description}{SIP_TRUNK_PRODUCT_IDS.includes(entry?.product?.id) || isMainProduct ? ' - ' + (index + 1) : ''}{getCallNumberCountryAndAreaCode(entry)}</span>
        </td>
        <td className={cs(`product-action-le--${theme}`, "product-price")}>
          {currency(entry?.oneTimeCharges?.find((charge) => charge?.chargeMode?.id === ID_CHARGE_MODES.ONE_TIME)?.amountNet,
            basket?.currency?.description)}
          <p className="product-interval">
            {entry?.oneTimeCharges && entry?.oneTimeCharges[0].chargeMode?.id && chargeModeTranslation(entry.oneTimeCharges[0].chargeMode.id)}
          </p>
          {currency(entry?.recurringCharges?.find((charge) => charge?.chargeMode?.id === ID_CHARGE_MODES.MONTHLY)?.amountNet,
            basket?.currency?.description)}
          {currency(entry?.recurringCharges?.find((charge) => charge?.chargeMode?.id === ID_CHARGE_MODES.PER_INVOICE)?.amountNet,
            basket?.currency?.description)}
          <p className="product-interval">
            {entry?.recurringCharges && entry?.recurringCharges[0].chargeMode?.id && chargeModeTranslation(entry.recurringCharges[0].chargeMode.id)}
          </p>
          {currentStep === 5 && isMainProduct && <CpxButton className={"iconOnly"} type={'button'} onClick={() => editBasketEntry(entry)}><CpxIcon icon={ICONS.EDIT}/></CpxButton>}
          {currentStep === 5 && isMainProduct && <CpxButton className={"iconOnly"} type={'button'} onClick={() => deleteBasketEntry(entry)}><CpxIcon icon={ICONS.DELETE}/></CpxButton>}
        </td>
      </>)
  }

  const deleteBasketEntry = (entry: BasketEntry) => {
    dispatch(apiCallAction(ACTION_CONST.API_BASKET_DELETE_PRODUCT, { basketId: basket?.id, index: Object.keys(basket.entries).find(key => basket.entries[key].uuid === entry.uuid) }));
  }

  const editBasketEntry = (entry: BasketEntry) => {
    const product = oeProducts.find(p => p?.id === entry?.product?.id)
    dispatch(saveStep3Data({ activeProduct: product, activeEntryUuid: entry?.uuid, activeIndex: Object.keys(basket.entries).find(key => basket.entries[key].uuid === entry.uuid) }))
    dispatch(saveCurrentStep(currentStep + 1))
  }

  const chargeModeTranslation = (chargeMode: number) => {

    switch (chargeMode) {
      case ID_CHARGE_MODES.ONE_TIME:
        return translations.oneTime();

      case ID_CHARGE_MODES.MONTHLY:
        return translations.monthly();

      case ID_CHARGE_MODES.PER_INVOICE:
        return translations.perInvoice();

      case ID_CHARGE_MODES.USAGE_BASED:
        return translations.usageBased();

    }
  }


  const renderBasketHierarchy = (entry: BasketEntry, index: number, indexOfEntryByProduct: number) => {

    const mainProduct = (
      <tr className="product">
        {renderSkeletonRow(entry, false, indexOfEntryByProduct, true)}
      </tr>
    )

    const renderOtherHierarchies = () => {
      return (
        <>
          {Array.isArray(entry?.options) && entry?.options.length > 0 && sortBasketEntries(entry) && entry?.options.sort((o1,o2) => TELEPHONE_CONNECTIONS_IDS.includes(o1.product.id) ? 1 : -1).map((option: BasketEntry, i:number) => (
            <>
              <tr className={"second-hierarchy"}>
                {renderSkeletonRow(option, true, i)}
              </tr>

              {Array.isArray(option.options) && option?.options.length > 0 && sortBasketEntries(option) && option?.options.map(optionOfOption => (
                <tr className={"third-hierarchy"}>
                  {renderSkeletonRow(optionOfOption, true)}
                </tr>
              ))}
            </>
          ))}
        </>)

    }

    return (
      <Fragment key={index}>
        {entry?.product?.description && mainProduct}
        {renderOtherHierarchies()}
      </Fragment>
    )
  }

  return (
    <aside className={cs(internalClassName, `${internalClassName}-le--${theme}`)}>
      <h4 className={cs(`${internalClassName}-heading`, `${internalClassName}-heading-le--${theme}`)}>
        {translations.orderSummary()}
      </h4>

      <table className={`table table-le--${theme}`}>
        <tbody>
        {
          basket.entries !== undefined && Object.values(basket?.entries).map((entry: any, index: number) => {
            const indexOfEntryByProduct = Object.values(basket?.entries).filter(e => e.product.id === entry.product.id).findIndex(e => e.uuid === entry.uuid);
            return renderBasketHierarchy(entry, index, indexOfEntryByProduct);
          })
        }
          <tr className={`divider`}/>
          <tr className={`sum sum-one-time`}>
            {<>
              <td style={{ display: 'flex' }} colSpan={3}>{translations.oneTimeNet()}</td>
              <td><h4>{currency(basket?.total?.oneTime?.amountNet, basket?.currency?.description, true)}</h4></td>
            </>}
          </tr>
          <tr className={`sum sum-curring`}>
            {<>
              <td style={{ display: 'flex' }} colSpan={3}>{translations.monthlyNet()}</td>
              <td><h4>{currency(basket?.total?.recurring?.amountNet, basket?.currency?.description, true)}</h4></td>
            </>}
          </tr>
        </tbody>
      </table>
    </aside>
  );
};
