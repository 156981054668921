import { LEGAL_ENTITIES } from '../../portal/constants/configs/config.constants';
import { useTheme } from '../utility/themeContext';
import { ReactComponent as Clipboard } from '../../portal/assets/icons/clipboard.svg';
import { ReactComponent as ClipboardWhite } from '../../portal/assets/icons/clipboard_white.svg';

type Props = {
  className?: string;
  whiteOverride?: boolean;
};

export const CpxClipboard = ({ className, whiteOverride }: Props) => {
  const le = useTheme();

  if (le === LEGAL_ENTITIES.DTS) {
    return <Clipboard className={className} />;
  } else {
    if (whiteOverride) {
      return <ClipboardWhite className={className} />;
    } else {
      return <Clipboard className={className} />;
    }
  }
};
