import { ReactNode } from 'react';
import { useTheme } from "../utility/themeContext";
import cs from 'classnames';
import './textWithLabel.scss';

type Props = {
  label: string;
  children?: ReactNode;
  className?: string;
  labelClassName?: string;
};

export const CpxTextWithLabel = ({
                                   label,
                                   children,
                                   labelClassName,
                                   className,
                                   ...props
                                 }: Props) => {
  const internalClassName = "textWithLabel";
  const theme = useTheme();

  return (
    <div
      className={cs(internalClassName, className)}
    >
        <span
          className={cs(labelClassName)}
        >
          {label}
        </span>
      <p className={cs('text', `text-le--${theme}`)}>{children}</p>
    </div>
  )
}
