import React from "react";
import { useTranslations } from "../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";
import { NfoCustomerWrapperComponent } from "../wrapper/newCustomerWrapper.component";
import { NfoLocationsTable } from "../locationsTable/locationsTable.component";

export const NfoCustomerLocationsStep = () => {
  const translation = useTranslations(TRANSLATIONS.stepper.newCustomer);

  return (
    <>
      <NfoCustomerWrapperComponent
        title={translation.locations()}
        subtitle={translation.locationsSubtitle()}
      >
        <NfoLocationsTable/>
      </NfoCustomerWrapperComponent>
    </>
  );
};
