import cs from "classnames";
import { CpxAddress } from "../../../../../../core/components/address.component";
import { ReactComponent as Phone } from "../../../../../assets/icons/phone_white.svg";
import { ReactComponent as Place } from "../../../../../assets/icons/place_white.svg";
import { TRANSLATIONS } from "../../../../../constants/transitions/uiTranslations";
import { useTranslations } from "../../../../../utils/helper/utils";
import { useTheme } from "../../../../../../core/utility/themeContext";
import "./dataOverview.scss";
import { IPartnerCustomer } from "../../../../../constants/types/types.constants";
import { NfoStatus } from "../../../../../../core/components/status.component";
import { ID_DATA_ENTITY, LEGAL_ENTITIES } from "../../../../../constants/configs/config.constants";
import { ReactComponent as StatusActiveDTS } from "../../../../../assets/icons/status-active_dark.svg";
import { ReactComponent as StatusActive } from "../../../../../assets/icons/status-active_white.svg";
import { ReactComponent as StatusOrderDTS } from "../../../../../assets/icons/status-order-dark.svg";
import { ReactComponent as StatusOrder } from "../../../../../assets/icons/status-order-white.svg";

type Props = {
  selectedCustomer: IPartnerCustomer;
  className?: string
}

export const NfoPartnerCustomerDataOverview = ({ selectedCustomer, className, ...props }: Props) => {
  const translations = useTranslations(TRANSLATIONS.myData);
  const theme = useTheme();
  const internalClassName = "myDataOverview";
  const iconActive =
      theme === LEGAL_ENTITIES.DTS ? <StatusActiveDTS/> : <StatusActive/>;
  const iconOrdered =
      theme === LEGAL_ENTITIES.DTS ? <StatusOrderDTS/> : <StatusOrder/>;


  return (
    <article
      className={cs(
        internalClassName,
        `${internalClassName}-boxStyling-le--${theme}`,
        className
      )}
      {...props}
    >
      <h4 className={cs(`${internalClassName}-heading-le--${theme}`)}>{selectedCustomer?.companyName}</h4>
      <div className={"columnContainer"}>
        <div className={"column"}>
          <h5>
            {translations.customerId()}
          </h5>
          <p className={`content-le--${theme}`}>
            {selectedCustomer?.customerId}
          </p>
        </div>
        <div className={"column"}>
          <h5>
            {translations.customerStatus()}
          </h5>
          <NfoStatus
            entity={{ id: ID_DATA_ENTITY.SERVICE }}
            status={selectedCustomer.status}
            statusClassName={`content-le--${theme}`}
            activeIconOverride={iconActive}
            orderedIconOverride={iconOrdered}
          />
        </div>
      </div>
      <div className={"columnContainer"}>
        <div className={"column"}>
          <h5>
            <Phone/>
            {translations.contact()}
          </h5>
          <p className={`content-le--${theme}`}>
            {selectedCustomer?.mainContact?.email?.email}
          </p>
          <p className={`content-le--${theme}`}>
            {selectedCustomer?.mainContact?.phone?.phone}
          </p>
        </div>
        <div className={'column'}>
          <h5>
            <Place/>
            {translations.address()}
          </h5>
          <CpxAddress
            address={selectedCustomer?.mainAddress}
            className={`content-le--${theme}`}
          />
        </div>
      </div>
    </article>
  );
};
