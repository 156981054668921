import { useSelectContracts } from "../../../redux/store";
import { IContracts } from "../../../constants/types/types.constants";

type Props = {
  parentId: string;
  className?: string;
};

export const NfoSubContracts = ({ parentId, className }: Props) => {
  const contracts = useSelectContracts() as IContracts;
  const childContracts = contracts.filter(entry => entry.componentOf === parseInt(parentId, 10));

  return (
    <>
      {childContracts.map(contract => (
        <div key={contract.id} className={className}>
          <span>{contract.contractName}</span>
          {contract.contractDescription && (
            <span>{contract.contractDescription}</span>
          )}
          <NfoSubContracts parentId={contract.id + ""}/>
        </div>
      ))}
    </>
  );
};
