import { useEffect, useMemo } from 'react';
import './passwordStrength.scss';
import { passwordStrength } from '../utility/passwordStrength';
import { CpxProgressBar } from './progressBar.component';
import { ReactComponent as Yes } from '../../portal/assets/icons/check.svg';
import { ReactComponent as YesDTS } from '../../portal/assets/icons/check_dts.svg';
import { ReactComponent as No } from '../../portal/assets/icons/no.svg';
import { useTheme } from "../utility/themeContext";

type Props = {
  password: string;
  textPasswordCriteria: string;
  textMinChars: string;
  minChars: number;
  textUpperLower: string;
  textSpecial: string;
  textPasswordStrength: string;
  onChangeValid: (valid: boolean) => void;
};

type passwordStrengthType = {
  contains?: string[];
  length: number;
  value?: string;
};

export const CpxPasswordStrenght = ({
  password,
  minChars,
  onChangeValid,
  textPasswordCriteria,
  textMinChars,
  textUpperLower,
  textSpecial,
  textPasswordStrength,
}: Props) => {

  const theme = useTheme();
  const strength: passwordStrengthType = useMemo(
    () => (password ? passwordStrength(password) : []),
    [password]
  );

  const contains = useMemo(
    () => (strength && strength.contains) || [],
    [strength]
  );
  const hasLowerAndUpper = useMemo(
    () => contains.includes('lowercase') && contains.includes('uppercase'),
    [contains]
  );
  const hasMinChars = useMemo(
    () => !!strength.length && strength.length >= minChars,
    [strength, minChars]
  );

  const hasSpecial = useMemo(() => contains.includes('symbol'), [contains]);

  const YesOrNo = ({ yes }: { yes: boolean }) => {
    if (theme === 'nfon') {
      return yes ? <Yes title="yes" className={'criteria-icon-nfon'} /> : <No title="no" />;
    } else {
      return yes ? <YesDTS title="yes" className={'criteria-icon-dts'} /> : <No title="no" />;
    }
  }

  const strengthNumber = useMemo(() => {
    switch (strength.value) {
      case 'Too weak':
        return 1;
      case 'Weak':
        return 2;
      case 'Medium':
      case 'Strong':
        return 3;
    }
    return 0;
  }, [strength.value]);

  const isValid = useMemo(
    () => hasLowerAndUpper && hasMinChars && hasSpecial,
    [hasLowerAndUpper, hasMinChars, hasSpecial]
  );

  useEffect(() => void onChangeValid(isValid), [isValid, onChangeValid]);

  return (
    <div className={`passwordStrength passwordStrength-le--${theme}`}>
      <div className={'criteriaWrapper'}>
        <div>{textPasswordCriteria}</div>
        <div>
          <ul>
            <li>
              <YesOrNo yes={hasMinChars} />
              &nbsp;{textMinChars}
            </li>
            <li>
              <YesOrNo yes={hasLowerAndUpper} />
              &nbsp;{textUpperLower}
            </li>
            <li>
              <YesOrNo yes={hasSpecial} />
              &nbsp;{textSpecial}
            </li>
          </ul>
        </div>
      </div>
      <div className={'progressWrapper'}>
        <p>{textPasswordStrength}</p>
        <CpxProgressBar
          max={3}
          current={strengthNumber}
          isWarningColor={strengthNumber < 3}
          className={'progressBar'}
          useSecurityBehaviour={true}
        />
      </div>
    </div>
  );
};
