import { useTheme } from "../utility/themeContext";
import { ICONS, LEGAL_ENTITIES } from "../../portal/constants/configs/config.constants";
import { ReactComponent as Add } from '../../portal/assets/icons/add.svg';
import { ReactComponent as StatusNotOrdered } from '../../portal/assets/icons/status-not-ordered.svg';
import { ReactComponent as StatusOrdered } from '../../portal/assets/icons/status-order.svg';
import { ReactComponent as StatusOrderedDts } from '../../portal/assets/icons/status-order-dts.svg';
import { ReactComponent as StatusOrderedDark } from '../../portal/assets/icons/status-order-dark.svg';
import { ReactComponent as StatusOrderedWhite } from '../../portal/assets/icons/status-order-white.svg';
import { ReactComponent as StatusCancelled } from '../../portal/assets/icons/cancelled.svg';
import { ReactComponent as StatusTerminated } from '../../portal/assets/icons/status-terminated.svg';
import { ReactComponent as Close } from '../../portal/assets/icons/close.svg';
import { ReactComponent as CloseDts } from '../../portal/assets/icons/close_dts.svg';
import { ReactComponent as Edit } from '../../portal/assets/icons/edit.svg';
import { ReactComponent as Delete } from '../../portal/assets/icons/delete.svg';
import { ReactComponent as EditDts } from '../../portal/assets/icons/edit_dts.svg';
import { ReactComponent as Active } from '../../portal/assets/icons/status-active.svg';
import { ReactComponent as ActiveDts } from '../../portal/assets/icons/status-active-dts.svg';
import { ReactComponent as ActiveDark } from '../../portal/assets/icons/status-active_dark.svg';
import { ReactComponent as ActiveWhite } from '../../portal/assets/icons/status-active_white.svg';
import { ReactComponent as Link } from '../../portal/assets/icons/link.svg';
import { ReactComponent as Settings } from '../../portal/assets/icons/settings.svg';
import { ReactComponent as Contract } from '../../portal/assets/icons/contract.svg';
import { ReactComponent as Customer } from '../../portal/assets/icons/customer.svg';
import { ReactComponent as Search } from '../../portal/assets/icons/search.svg';
import { ReactComponent as Cross } from '../../portal/assets/icons/cross.svg';
import { ReactComponent as Attention } from '../../portal/assets/icons/attention.svg';
import { ReactComponent as AttentionWhite } from '../../portal/assets/icons/attention_white.svg';
import { ReactComponent as Calendar } from '../../portal/assets/icons/calendar.svg';
import { ReactComponent as Place } from '../../portal/assets/icons/place.svg';
import { ReactComponent as Minus } from '../../portal/assets/icons/minus.svg';
import { ReactComponent as User } from '../../portal/assets/icons/user.svg';
import { ReactComponent as Tech } from "../../portal/assets/icons/tech_white.svg";
import { ReactComponent as Phone } from "../../portal/assets/icons/phone_white.svg";
import { ReactComponent as ArrowUp } from "../../portal/assets/icons/arrows-up.svg";
import { ReactComponent as ArrowDown } from "../../portal/assets/icons/arrows-down.svg";
import { ReactComponent as Note } from "../../portal/assets/icons/note.svg";
import { ReactComponent as EyeClosed } from '../../portal/assets/icons/visibility-off.svg';
import { ReactComponent as Truck } from '../../portal/assets/icons/truck.svg';
import { ReactComponent as EyeOpen } from '../../portal/assets/icons/visibility-on.svg';
import { ReactComponent as Check } from '../../portal/assets/icons/check.svg';
import { ReactComponent as CheckDTS } from '../../portal/assets/icons/check_dts.svg';
import { ReactComponent as SortArrowUnsorted } from '../../portal/assets/icons/sort_arrow_unsorted.svg';
import { ReactComponent as SortArrowAsc } from '../../portal/assets/icons/sort_arrow_asc.svg';
import { ReactComponent as SortArrowDesc } from '../../portal/assets/icons/sort_arrow_desc.svg';
import { ReactComponent as Info } from '../../portal/assets/icons/info.svg';


type Props = {
  id?: string;
  icon: string;
  className?: string;
  onClick?: React.MouseEventHandler<any>;
  title?: string;
};

export const CpxIcon = (props: Props) => {
  const theme = useTheme();

  const getAddIcon = () => {
    if (theme === LEGAL_ENTITIES.DTS) {
      return <Add className={props.className}/>;
    }
    return <Add className={props.className}/>;
  }

  const getActiveIcon = () => {
    if (theme === LEGAL_ENTITIES.DTS) {
      return <ActiveDts className={props.className}/>;
    }
    return <Active className={props.className}/>;
  }

  const getActiveDarkIcon = () => {
    return <ActiveDark className={props.className}/>;
  }

  const getActiveWhiteIcon = () => {
    return <ActiveWhite className={props.className}/>;
  }

  const getCloseIcon = () => {
    if (theme === LEGAL_ENTITIES.DTS) {
      return <CloseDts className={props.className}/>;
    }
    return <Close className={props.className}/>;
  }

  const getEditIcon = () => {
    if (theme === LEGAL_ENTITIES.DTS) {
      return <EditDts className={props.className}/>;
    }
    return <Edit className={props.className}/>;
  }

  const getDeleteIcon = () => {
    return <Delete className={props.className} onClick={props.onClick}/>;
  }

  const getStatusNotOrderedIcon = () => {
    return <StatusNotOrdered className={props.className}/>;
  }

  const getStatusOrderedIcon = () => {
    if (theme === LEGAL_ENTITIES.DTS) {
      return <StatusOrderedDts className={props.className}/>;
    }
    return <StatusOrdered className={props.className}/>;
  }

  const getStatusOrderedDarkIcon = () => {
    return <StatusOrderedDark className={props.className}/>;
  }

  const getStatusOrderedWhiteIcon = () => {
    return <StatusOrderedWhite className={props.className}/>;
  }

  const getLinkIcon = () => {
    return <Link className={props.className}/>;
  }

  const getSettingsIcon = () => {
    return <Settings className={props.className}/>;
  }

  const getContractIcon = () => {
    return <Contract className={props.className}/>;
  }

  const getCustomerIcon = () => {
    return <Customer className={props.className}/>;
  }

  const getSearchIcon = () => {
    return <Search className={props.className} onClick={props.onClick}/>;
  }

  const getCrossIcon = () => {
    return <Cross className={props.className} onClick={props.onClick}/>;
  }

  const getAttentionIcon = () => {
    return <Attention className={props.className} onClick={props.onClick}/>;
  }

  const getAttentionWhiteIcon = () => {
    return <AttentionWhite className={props.className} onClick={props.onClick}/>;
  }

  const getCalendarIcon = () => {
    return <Calendar className={props.className} onClick={props.onClick}/>;
  }

  const getPlaceIcon = () => {
    return <Place className={props.className} onClick={props.onClick}/>;
  }

  const getMinusIcon = () => {
    return <Minus className={props.className} onClick={props.onClick}/>;
  }

  const getUserIcon = () => {
    return <User className={props.className} onClick={props.onClick}/>;
  }

  const getTechIcon = () => {
    return <Tech className={props.className} onClick={props.onClick}/>;
  }

  const getPhoneIcon = () => {
    return <Phone className={props.className} onClick={props.onClick}/>;
  }

  const getArrowUpIcon = () => {
    return <ArrowUp className={props.className} onClick={props.onClick}/>;
  }

  const getArrowDownIcon = () => {
    return <ArrowDown className={props.className} onClick={props.onClick}/>;
  }

  const getNoteIcon = () => {
    return <Note className={props.className} onClick={props.onClick}/>;
  }

  const getEyeOpenIcon = () => {
    return <EyeOpen className={props.className} onClick={props.onClick} title={props.title}/>;
  }

  const getEyeClosedIcon = () => {
    return <EyeClosed className={props.className} onClick={props.onClick} title={props.title}/>;
  }

  const getTruckIcon = () => {
    return <Truck className={props.className} onClick={props.onClick} title={props.title}/>;
  }

  const getCheckIcon = () => {
    if (theme === LEGAL_ENTITIES.DTS) {
      return <CheckDTS className={props.className} onClick={props.onClick} title={props.title}/>;
    }
    return <Check className={props.className} onClick={props.onClick} title={props.title}/>;
  }

  const getSortedArrowUnsortedIcon = () => {
    return <SortArrowUnsorted className={props.className}/>;
  }

  const getSortedArrowDescIcon = () => {
    return <SortArrowDesc className={props.className}/>;
  }

  const getSortedArrowAscIcon = () => {
    return <SortArrowAsc className={props.className}/>;
  }

  const getInfoIcon = () => {
    return <Info className={props.className} onClick={props.onClick}/>;
  }

  switch (props.icon) {
    case ICONS.ADD:
      return (getAddIcon());
    case ICONS.ACTIVE_DARK:
      return (getActiveDarkIcon());
    case ICONS.ACTIVE_WHITE:
      return (getActiveWhiteIcon());
    case ICONS.CLOSE:
      return (getCloseIcon());
    case ICONS.STATUS.NOT_ORDERED:
      return (getStatusNotOrderedIcon());
    case ICONS.STATUS.ORDERED:
      return (getStatusOrderedIcon());
    case ICONS.STATUS.ORDERED_DARK:
      return (getStatusOrderedDarkIcon());
    case ICONS.STATUS.ORDERED_WHITE:
      return (getStatusOrderedWhiteIcon());
    case ICONS.STATUS.ACTIVE:
      return (getActiveIcon());
    case ICONS.EDIT:
      return (getEditIcon());
    case ICONS.DELETE:
      return (getDeleteIcon());
    case ICONS.LINK:
      return (getLinkIcon());
    case ICONS.SETTINGS:
      return (getSettingsIcon());
    case ICONS.CONTRACT:
      return (getContractIcon());
    case ICONS.CUSTOMER:
      return (getCustomerIcon());
    case ICONS.SEARCH:
      return (getSearchIcon());
    case ICONS.CROSS:
      return (getCrossIcon());
    case ICONS.ATTENTION:
      return (getAttentionIcon());
    case ICONS.ATTENTION_WHITE:
      return (getAttentionWhiteIcon());
    case ICONS.STATUS.CANCELLED:
      return (<StatusCancelled className={props.className}/>);
    case ICONS.STATUS.TERMINATED:
      return (<StatusTerminated className={props.className}/>);
    case ICONS.CALENDAR:
      return (getCalendarIcon());
    case ICONS.PLACE:
      return (getPlaceIcon());
    case ICONS.MINUS:
      return (getMinusIcon());
    case ICONS.USER:
      return (getUserIcon());
    case ICONS.TECH:
      return (getTechIcon());
    case ICONS.PHONE:
      return (getPhoneIcon());
    case ICONS.ARROW_UP:
      return (getArrowUpIcon());
    case ICONS.ARROW_DOWN:
      return (getArrowDownIcon());
    case ICONS.NOTE:
      return (getNoteIcon());
    case ICONS.EYE_OPEN:
      return (getEyeOpenIcon());
    case ICONS.EYE_CLOSED:
      return (getEyeClosedIcon());
    case ICONS.CHECK:
      return (getCheckIcon());
    case ICONS.TRUCK:
      return (getTruckIcon());
    case ICONS.SORT_ARROWS.UNSORTED:
      return (getSortedArrowUnsortedIcon());
    case ICONS.SORT_ARROWS.DESC:
      return (getSortedArrowDescIcon());
    case ICONS.SORT_ARROWS.ASC:
      return (getSortedArrowAscIcon());
    case ICONS.INFO:
      return (getInfoIcon());
    default:
      return null;
  }
};
