import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./portal/sass/layout/_table.scss";
import "./portal/sass/base/_typography.scss";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { HashRouter, Route, Switch } from 'react-router-dom'
import { store } from "./portal/redux/store";
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from "redux-persist";

const persistor = persistStore(store)

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <HashRouter>
          <Switch>
            <Route path={`/:entity?`}>
              <App/>
            </Route>
          </Switch>
        </HashRouter>
      </PersistGate>
    </Provider>
  </StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
