import cs from 'classnames';
import { PropsWithoutRef } from 'react';
import './switch.scss';
import { useTheme } from '../utility/themeContext';

type Props = Omit<
  PropsWithoutRef<JSX.IntrinsicElements['input']>,
  'onChange'
> & {
  onChange?: (checked: boolean) => void;
};

export const CpxSwitch = ({
  onChange,
  checked,
  className,
  ...props
}: Props) => {
  const theme = useTheme();
  const internalClassName = 'switchWrapper';

  const handleClick = () => {
    if (onChange && !props.disabled) {
      onChange(!checked);
    }
  };

  return (
    <span className={cs(className, internalClassName)} onClick={handleClick}>
      <span
        className={cs(
          'switch',
          `switch-le--${theme}`,
          `switch-le`,
          { on: !!checked },
          { [`switch-le--${theme}-on`]: !!checked },
          { disabled: props.disabled },
          { [`switch-le--${theme}-disabled`]: props.disabled }
        )}
      />
      <input
        type="checkbox"
        checked={!!checked}
        {...props}
        onChange={handleClick}
      />
    </span>
  );
};
