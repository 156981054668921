import { ACTION_CONST } from "../../constants/action.constants";

export const saveLicensesBasket = (data: any) => {
  return {
    type: ACTION_CONST.SAVE_LICENSES_BASKET,
    data,
  };
};

export const deleteLicensesBasket = () => {
  return {
    type: ACTION_CONST.DELETE_LICENSES_BASKET
  };
};