import { CpxProgressBar } from '../../../../../core/components/progressBar.component';
import { useTranslations } from '../../../../utils/helper/utils';
import { TRANSLATIONS } from '../../../../constants/transitions/uiTranslations';
import { NfonPackageInformation } from 'compax-api';
import { CpxIcon } from "../../../../../core/components/icon.component";
import { ICONS } from "../../../../constants/configs/config.constants";
import cs from 'classnames';
import './packageListing.scss';
import { useTheme } from "../../../../../core/utility/themeContext";
import { CpxValid } from "../../../../../core/components/valid.component";
import { CpxLimit } from "../../../../../core/components/limit.component";
import { formatDate } from "../../../../../core/utils";
import { useSelectLang } from "../../../../redux/store";

type Props = {
  minutePackage: NfonPackageInformation;
};

export const NfoPackageListing = ({ minutePackage }: Props) => {
  const translations = useTranslations(TRANSLATIONS.packages);
  const language = useSelectLang().selected;

  const validFrom = formatDate(minutePackage?.validFrom as Date, language);
  const validTill = formatDate(minutePackage?.validTill as Date, language);
  const currentDate = formatDate(minutePackage?.currentDate as Date, language);

  const theme = useTheme();
  const internalClassName = "tableBox";

  return (
    <article className={cs("packageListing", internalClassName, `${internalClassName}-le--${theme}`)}>
      <p>{minutePackage.parentName}</p>
      <h4 className={cs("packageSummary", `packageSummary-le--${theme}`)}>{minutePackage.quantity} x {Number(minutePackage.packageSummary).toLocaleString("DE")} min</h4>
      <h5>
        <CpxValid/>
        {translations.validFromTo()}
      </h5>
      <div className={"columnContainer"}>
        <div className={"column"}>
          <div className={cs(`font-light-le--${theme}`)}>
            {validFrom} - {validTill}
          </div>
        </div>
        <div className={"column"}>
          <div className={cs(`font-light-le--${theme}`)}>
            {minutePackage.active ? (
              <div className={'status'}>
                <CpxIcon icon={ICONS.STATUS.ACTIVE}/>
                {translations.valid()}
              </div>
            ) : (
              <div className={'status'}>
                <CpxIcon icon={ICONS.STATUS.ORDERED}/>
                {translations.invalid()}
              </div>
            )}
          </div>
        </div>
      </div>
      <h5 className={cs(`font-medium-le--${theme}`)}>
        <CpxLimit/>
        {translations.balance()}
      </h5>
      <div className={"columnContainer"}>
        <div className={"column"}>
          <p className={cs(`font-light-le--${theme}`)}>
            {minutePackage.amountRemaining?.toLocaleString("DE")} / {minutePackage.amountComplete?.toLocaleString("DE")}
          </p>
        </div>
        <div className={cs("column", "small")}>
          <p className={cs(`font-light-le--${theme}`)}>
            {translations.asOf()} {currentDate}
          </p>
        </div>
      </div>
      <CpxProgressBar
        current={minutePackage.amountRemaining || 0}
        max={minutePackage.amountComplete || 0}
        isWarningColor={
          (minutePackage.amountRemaining || 0) /
          (minutePackage.amountComplete || 0) <
          0.2
        }
        useSecurityBehaviour={true}
        className={"progressBar"}
      />
    </article>
  );
};
