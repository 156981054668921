import { ReactNode } from 'react';
import './infoBlock.scss';
import cs from 'classnames';
import { useTheme } from '../utility/themeContext';

type Props = {
  title?: ReactNode;
  children?: ReactNode;
  className?: string;
  classNameBox?: string;
};

export const NfoInfoBlockWithIcon = ({
                                       title,
                                       children,
                                       className,
                                       classNameBox,
                                     }: Props) => {
  const theme = useTheme();

  return (
    <section
      className={cs(
        'personInCharge',
        `personInCharge-le--${theme}`,
        classNameBox
      )}
    >
      <div className={cs('columnTitle', `columnTitle-le--${theme}`)}>
        <h5 className={className}>{title}</h5>
      </div>
      {children}
    </section>
  );
};
