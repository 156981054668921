import './editBox.scss'
import cs from "classnames";
import { useTheme } from "../../../../../../core/utility/themeContext";
import { ReactComponent as EditWhite } from '../../../../../../portal/assets/icons/edit_white.svg';
import React from "react";
import { saveOEOptionsOfOption, setProductOptionToInvalidated, setSipTrunkProductOptionToInvalidated } from "../../../../../redux/actions/step.action";
import { useDispatch } from "react-redux";
import { apiCallAction } from "../../../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../../../constants/action.constants";
import { selectCurrentLanguage } from "../../../../../../core/uiLanguage/lang.slice";
import { useSelectLang, useStep1Data, useStep3Data } from "../../../../../redux/store";
import { ICONS, TELEPHONE_CONNECTIONS_IDS } from "../../../../../constants/configs/config.constants";
import { CpxIcon } from "../../../../../../core/components/icon.component";
import { extracted } from "../sipTrunkOption/sipTrunkOption";

type Props = {
    name: string;
    parentUuid?: string;
    index?: number;
    optionId?: number;
    optionUuid?: string;
    basket?: any;
    isSipTrunk?: boolean;
    onDelete?: () => void;
}

export const NfoEditBox = ({name, parentUuid, index, optionUuid, optionId, basket, isSipTrunk = false, onDelete}: Props) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const currentLang = selectCurrentLanguage(useSelectLang());
    const step3Data = useStep3Data();
    const stepOneData = useStep1Data();
    const isMigration = !!stepOneData?.migrationCustomerId;

    const onclick = async () => {

        if (isSipTrunk && optionUuid && index === 0) {
            await extracted(dispatch, basket, step3Data, optionUuid, currentLang, isMigration);
        } else {
            const res: any = await dispatch(apiCallAction(isMigration && optionId &&
              TELEPHONE_CONNECTIONS_IDS.includes(optionId) ? ACTION_CONST.API_GET_OE_OPTIONS_OF_OPTION_FOR_MIGRATION :
                ACTION_CONST.API_GET_OE_OPTIONS_OF_OPTION,
              {
                  basketId: basket?.id,
                  uuid: optionUuid,
                  language: currentLang
              }, true));
            optionUuid && dispatch(saveOEOptionsOfOption({ [optionUuid]: res?.optionGroups }));

        }
        if (isSipTrunk) {
            dispatch(setSipTrunkProductOptionToInvalidated(optionUuid))
        } else {
            dispatch(setProductOptionToInvalidated(optionId))

        }
    }

    return (
        <div className={cs(`editBox`, `editBox-le--${theme}`)}>
            <div className={cs(`editBox__wrapper`)}>
                <h4 className={cs(`editBox-title`, `editBox-title-le--${theme}`)} style={{flexGrow: 5}}>
                    {name}
                </h4>
                <div style={{display: "flex", alignItems: "center", justifyContent: "space-around", cursor: "pointer", flexGrow: 0.5}}>
                    {onDelete && <CpxIcon icon={ICONS.DELETE} onClick={onDelete}/>}
                    {<EditWhite className="button" onClick={onclick}/>}
                </div>

            </div>
        </div>
    )
}