import commonStyles from "../../../../../sass/base/common.module.scss";
import cs from "classnames";
import React from "react";
import { useTheme } from "../../../../../../core/utility/themeContext";
import { useTranslations } from "../../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../../constants/transitions/uiTranslations";
import { CpxAddressInputGroup } from "../../../../../../core/components/addressInputGroup.component";
import { PopupDirections } from "../../../../../constants/types/types.constants";

export const RegisteredAddressForm = (
  {
    values, handleChange, touched, errors, countryOptions, selectedCountry, setSelectedCountry
  }: any) => {
  const theme = useTheme();
  const translation = useTranslations(TRANSLATIONS.stepper.newCustomer);

  const inputNames = {
    country: "mainAddress.country.id",
    houseNumber: "mainAddress.houseNumber",
    street: "mainAddress.street",
    additionalAddress: "mainAddress.additionalAddress",
    zip: "mainAddress.zip",
    city: "mainAddress.city",
    city2: "mainAddress.city2",
    city3: "mainAddress.city3",
    district: "mainAddress.district",
    county: "mainAddress.county",
    province: "mainAddress.province",
  }

  return (
    <div className={commonStyles.columnContainer}>
      <div className={commonStyles.column}>
        <div className={cs('step', `step-le--${theme}`)}>
          <h4 className={`step-subtitle-le--${theme}`}>
            {translation.registeredAddress()}
          </h4>
          <CpxAddressInputGroup
            values={values?.mainAddress} touched={touched?.mainAddress} errors={errors?.mainAddress}
            inputNames={inputNames}
            countryOptions={countryOptions}
            handleChange={handleChange}
            popupDirection={PopupDirections.RIGHT}
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
          />
        </div>
      </div>
    </div>
  )
}