import './terminationWarningBox.scss'
import { ReactComponent as Warning } from '../../../../assets/icons/attention_white.svg'
import { useTranslations } from '../../../../utils/helper/utils'
import { TRANSLATIONS } from '../../../../constants/transitions/uiTranslations'
import { PortalContractData } from "compax-api";
import { useTheme } from '../../../../../core/utility/themeContext';
import cs from "classnames";

type Props = {
   terminatedExtensions: any;
    orderedExtensionsAmount: any;
};

export const TerminationWarningBox = (
  {
    terminatedExtensions,
    orderedExtensionsAmount,
  }: Props) => {
  const translations = useTranslations(TRANSLATIONS.extensions)
  const theme = useTheme();
  const uniqueTerminationDates: string[] = []
  let terminationInfo: any[] = []
  if (
    (typeof terminatedExtensions === 'undefined' ||
      terminatedExtensions.length === 0) &&
    (typeof orderedExtensionsAmount === 'undefined' &&
      orderedExtensionsAmount === 0)
  ) {
    return <> </>
  } else {
    const terminationReqExtensions = terminatedExtensions
      ? terminatedExtensions.sort(
        (
          c1: { endDateRequested: string },
          c2: { endDateRequested: string }
        ) => Date.parse(c1.endDateRequested) - Date.parse(c2.endDateRequested)
      )
      : []

    if (
      typeof terminatedExtensions !== 'undefined' &&
      terminatedExtensions !== []
    ) {
      terminationReqExtensions.map((contract: PortalContractData) => {
        if (contract.endDateRequested) {
          const date = contract?.endDateRequested.toString().slice(0, 10)
          if (uniqueTerminationDates.indexOf(date) === -1) {
            uniqueTerminationDates.push(date)
          }
          return uniqueTerminationDates
        }

      })

      terminationInfo = uniqueTerminationDates.map(date => ({
        date: date
          .slice(8, 10)
          .concat('.')
          .concat(date.slice(5, 7))
          .concat('.')
          .concat(date.slice(0, 4)),
        amount:
          terminationReqExtensions.filter(
            (extension: PortalContractData) =>
              extension.endDateRequested && extension.endDateRequested.toString().slice(0, 10) === date
          ).length + '',
      }))
    }
    return (
      <>
        {(terminationInfo.length !== 0 || orderedExtensionsAmount !== 0) && <div className={cs('terminationWarningBox', `terminationWarningBox-le--${theme}`)}>
          {terminationInfo.map(info => (
            <div className={'warningEntry'} key={info.date}>
              <Warning className={'warningIcon'}/>
              <div className={'amount'}>
                {info.amount === 1 + ''
                  ? translations.termination_single_1(info.amount)
                  : translations.termination_multiple_1(info.amount)}
              </div>
              {info.amount === 1 + ''
                ? translations.termination_single_2(info.date)
                : translations.termination_multiple_2(info.date)}
            </div>
          ))}
          {orderedExtensionsAmount > 0 ? (
            <div className={'warningEntry'} key={orderedExtensionsAmount}>
              <Warning className={'warningIcon'}/>

              <div className={'amount'}>
                {orderedExtensionsAmount === 1
                  ? translations.ordered_single_1(orderedExtensionsAmount + '')
                  : translations.ordered_multiple_1(
                    orderedExtensionsAmount + ''
                  )}
              </div>
              {orderedExtensionsAmount === 1
                ? translations.ordered_single_2()
                : translations.ordered_multiple_2()}
            </div>
          ) : null}
        </div>}
      </>
    )
  }
}
