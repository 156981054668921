import { preventDefault } from "../../../../core/utils";
import { TRANSLATIONS } from "../../../constants/transitions/uiTranslations";
import { useTranslations } from "../../../utils/helper/utils";
import React from "react";
import { useTheme } from "../../../../core/utility/themeContext";
import { selectCurrentLanguage } from "../../../../core/uiLanguage/lang.slice";
import { useSelectLang } from "../../../redux/store";
import { routes } from '../../../constants/routes/routes';
import MainConfigConstants from "../../../constants/configs/main.config.constants";
import Cookie from "cookies-js";
import storage from 'redux-persist/lib/storage';
import {
  LOCALSTORAGE_ACCESSTOKEN,
  LOCALSTORAGE_CLIENTTOKEN,
  LOCALSTORAGE_REFRESHTOKEN
} from "../../../constants/configs/config.constants";

type Props = {
  id?: string;
};

export const NfoLogout = ({ id }: Props) => {
  const translations = useTranslations(TRANSLATIONS.auth);
  const theme = useTheme();
  const lang = selectCurrentLanguage(useSelectLang());

  const accessTokenClientName = LOCALSTORAGE_CLIENTTOKEN;
  const accessTokenPasswordName = LOCALSTORAGE_ACCESSTOKEN;
  const accessTokenRefreshName = LOCALSTORAGE_REFRESHTOKEN;

  const handleClick = preventDefault(async () => {
    const currentURL = window.location.href.split(theme)[0];
    const redirectUrl = encodeURIComponent(`${currentURL}${theme}${routes.login.path}`)

    localStorage.removeItem(accessTokenRefreshName);
    Cookie(accessTokenClientName, undefined);
    Cookie(accessTokenPasswordName, undefined);

    await storage.removeItem('persist:nfon');

    window.location.href = process.env.REACT_APP_AUTH_URL + MainConfigConstants.API.OAUTH2.REQUESTS.LOGOUT.URL_APPENDIX +
      "?response_type=code" +
      "&client_id=" + process.env.REACT_APP_CLIENT_ID +
      "&post_logout_redirect_uri=" + redirectUrl +
      "&user_language=" + lang.toLowerCase() +
      "&client_style=" + theme;
  });

  return (
    <a id={id} onClick={handleClick}>
      {translations.logout()}
    </a>
  );
};
