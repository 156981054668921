import cs from "classnames";
import {CpxModalBox} from "../../../../../core/components/modalBox.component";
import { ReactComponent as Negativ } from '../../../../assets/icons/negativ.svg';
import { ReactComponent as Positiv } from '../../../../assets/icons/positiv.svg';
import React, {useEffect, useState} from "react";
import {CpxModal} from "../../../../../core/components/modal.component";
import {useTheme} from "../../../../../core/utility/themeContext";
import {CpxFormikForm} from "../../../../../core/components/formikForm.component";
import {useFormik} from "formik";
import {createValidatorSchema, orderQuantityCheck} from "../../../../utils/validation";
import {
    useSelectEditSipTrunk,
    useSelectError,
    useSelectSipTrunks,
    useStepperActivationSelection
} from "../../../../redux/store";
import {useTranslations} from "../../../../utils/helper/utils";
import {TRANSLATIONS} from "../../../../constants/transitions/uiTranslations";
import {InputError} from "../../../../../core/components/inputError.component";
import {apiCallAction} from "../../../../redux/actions/apiCall.action";
import {ACTION_CONST} from "../../../../constants/action.constants";
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {ID_SERVICE_GROUPS} from "../../../../constants/configs/config.constants";
import {Alert} from "../../../../components/common/Alert/Alert.component";

type Props = {
    internalClassName: string;
    setShowVoiceChannelModal: any;
    setShowVoiceChannelConfirmationModal: any;
    setShowModal: any;
    selectedSipTrunkId: number;
    sipTrunkOptionId: number;
}

type AppDispatch = ThunkDispatch<any, any, AnyAction>;

export const NfoEditVoiceChannelModal = ({ internalClassName, setShowVoiceChannelModal, setShowVoiceChannelConfirmationModal, setShowModal, selectedSipTrunkId, sipTrunkOptionId }: Props) => {
    const theme = useTheme();
    const activationDateSelection = useStepperActivationSelection();
    const sipTrunks: any = useSelectSipTrunks();
    const sipTrunkOptions = useSelectEditSipTrunk();
    const translations = useTranslations(TRANSLATIONS.editSipTrunk);
    const dispatch: AppDispatch = useDispatch();
    const error = useSelectError();
    const [selected, setSelected] = useState('');
    const [currentQuantity, setCurrentQuantity] = useState<number>(sipTrunks.find((item: any) => item.id === selectedSipTrunkId)?.sipTrunkOptions?.find((item: any) => item.serviceGroup.id === ID_SERVICE_GROUPS.VOICE_CHANNELS).quantity);
    const [newQuantity, setNewQuantity] = useState<number>(currentQuantity);

    const currentChannel: any = sipTrunkOptions.possibleOptions.find((option: any) => option.serviceGroup.id === ID_SERVICE_GROUPS.VOICE_CHANNELS)?.basketOptions.find((basketOption: any) => basketOption.product.id === sipTrunkOptionId);

    const selectReduceBox = () => {
        if (selected !== 'reduce') {
            setSelected('reduce');
            formik.setFieldValue('increaseQuantity', '');
            setNewQuantity(currentQuantity)
        }
    };

    const selectIncreaseBox = () => {
        if (selected !== 'increase') {
            setSelected('increase');
            formik.setFieldValue('reduceQuantity', '');
            setNewQuantity(currentQuantity)
        }
    };

    const goBack = () => {
        setShowVoiceChannelModal(false);
        setShowModal(true);
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            reduceQuantity: '',
            increaseQuantity: '',
            activationDate: '',
        },
        validationSchema: createValidatorSchema({
            reduceQuantity: orderQuantityCheck(translations.errorReduce("1", `${currentQuantity-currentChannel?.servicePropertySip?.orderMin}`), 1, currentQuantity-currentChannel?.servicePropertySip?.orderMin),
            increaseQuantity: orderQuantityCheck(translations.errorIncrease("1", `${currentChannel?.servicePropertySip?.orderMax-currentQuantity}`), 1, currentChannel?.servicePropertySip?.orderMax-currentQuantity),
            //activationDate: activationDateSelection?.isPlanned && dateCheck(requiredMsg, translationsStepper.dateValidationText()),
        }),
        onSubmit: async (values) => {
            // add to basket TODO: IMPLEMENT ACTIVATION DATE
            dispatch(
                apiCallAction(ACTION_CONST.API_BASKET_PUT_ADD_OPTION,
                    {
                        basketId: sipTrunkOptions.basket.id,
                        uuid: currentChannel?.parentUuid,
                        optionId: currentChannel?.product.id,
                        quantity: newQuantity,
                    },
                    true, null, true)
                ).then((res: any) => {
                    // show confirmation modal
                    setShowVoiceChannelModal(false);
                    setShowVoiceChannelConfirmationModal(true);
                });
        }
    })

    useEffect(() => {
        if (formik.values.increaseQuantity !== '') {
            setNewQuantity(currentQuantity + Number(formik.values.increaseQuantity))
        } else if (formik.values.reduceQuantity !== '') {
            setNewQuantity(currentQuantity - Number(formik.values.reduceQuantity))
        }
    }, [formik.values])

    return (
        <CpxFormikForm handleSubmit={formik.handleSubmit}
                       initialValues={formik.initialValues}
                       id={'change-voice-channel-form'}
        >
            <CpxModal
                className={`${internalClassName}-voiceChannelModal`}
                confirmText={translations.next()}
                onCancel={() => setShowVoiceChannelModal(false)}
                onBack={goBack}
                formId={'change-voice-channel-form'}
                disableButton={(formik.values.reduceQuantity || formik.values.increaseQuantity) === '' || Object.keys(formik.errors).length !== 0}
            >
                <h3 className={cs('modalTitle', `modalTitle-le--${theme}`)}>
                    {translations.changeChannelTitle()}
                </h3>
                <p className={cs('modalInfoText', `modalInfoText-le--${theme}`)}>{translations.changeChannelText()}</p>

                <div className={cs('modalExtensionsLegend', `modalExtensionsLegend-le--${theme}`)}>
                    <div className={cs('freeExtensionsLegend', `freeExtensionsLegend-le--${theme}`)}/>
                    <p>{translations.previousQty()}</p><span>{currentQuantity}</span>
                    <p>/</p>
                    <div className={cs('usedExtensionsLegend', `usedExtensionsLegend-le--${theme}`)}/>
                    <p>{translations.newQty()}</p> <span>{newQuantity}</span>
                </div>
                <div className={'modalBoxes'}>
                    <CpxModalBox
                        id={'reduceQuantity'}
                        inputValue={formik.values.reduceQuantity}
                        error={formik.touched.reduceQuantity && formik.errors.reduceQuantity}
                        name={'reduceQuantity'}
                        inputLabel={translations.amount()}
                        maxValue={currentQuantity-currentChannel?.servicePropertySip?.orderMin}
                        tabIndex={1}
                        title={translations.reduce()}
                        inputChange={formik.handleChange}
                        onClick={() => selectReduceBox()}
                        icon={<Negativ/>}
                        disabled={currentQuantity === 2}
                    >
                        {translations.reduceText()}
                    </CpxModalBox>
                    <CpxModalBox
                        id={'increaseQuantity'}
                        inputValue={formik.values.increaseQuantity}
                        error={formik.touched.increaseQuantity && formik.errors.increaseQuantity}
                        name={'increaseQuantity'}
                        inputLabel={translations.amount()}
                        maxValue={currentChannel?.servicePropertySip?.orderMax-currentQuantity}
                        tabIndex={2}
                        title={translations.increase()}
                        inputChange={formik.handleChange}
                        onClick={() => selectIncreaseBox()}
                        icon={<Positiv/>}
                        disabled={currentQuantity === currentChannel?.servicePropertySip?.orderMax}
                    >
                        {translations.increaseText()}
                    </CpxModalBox>
                </div>
                <InputError errorMessage={formik.errors.reduceQuantity || formik.errors.increaseQuantity}/>
                {error?.errorData && error?.errorData?.length > 0 && <Alert errors={error?.errorData}/> }
            </CpxModal>
        </CpxFormikForm>
    )
}