import './pagination.scss';
import { useEffect, useState } from "react";
import { CpxIcon } from "./icon.component";
import { ICONS } from "../../portal/constants/configs/config.constants";


export const CpxSort = ({ cell, onSort, reset, sortColumns }: any) => {
  const [dir, setDir] = useState('none');
  const onClick = (e: any, cell: any) => {
    reset(cell.label);
  }


  useEffect(() => {
    if(sortColumns.get(cell.label)){
      setDir(dir === 'asc' ? 'desc' : 'asc');
      onSort(cell, dir);
    }else{
      setDir('none');
    }
  }, [sortColumns]);


  const sortStatus = {
    desc: { title: "Ascending", icon: <CpxIcon icon={ICONS.SORT_ARROWS.ASC} /> },
    asc: { title: "Desceding", icon: <CpxIcon icon={ICONS.SORT_ARROWS.DESC} /> },
    unsorted: { title: "Unsorted", icon: <CpxIcon icon={ICONS.SORT_ARROWS.UNSORTED} /> },
  }

  return (
    <>
      {cell?.sort &&
        <span className={'sorting_arrows_wrapper'} onClick={(e) => onClick(e, cell)} style={{cursor: "pointer"}}>
          {cell?.label ? cell.label + " " : cell}
          {dir === 'asc' && <span title={sortStatus.asc.title}>{sortStatus.asc.icon}</span>}
          {dir === 'desc' && <span title={sortStatus.desc.title}>{sortStatus.desc.icon}</span>}
          {dir === 'none' && <span title={sortStatus.unsorted.title}>{sortStatus.unsorted.icon}</span>}
        </span>
      }
    </>
  );
};
