import React from "react";
import { useTheme } from "../../../../../core/utility/themeContext";
import { ICONS } from "../../../../constants/configs/config.constants";
import { CpxIcon } from "../../../../../core/components/icon.component";
import "./notification.scss"
import { useSelectUser } from "../../../../redux/store";

export const NotificationComponent = () => {
  const internalClassName = "notification";
  const theme = useTheme();
  const theUser = useSelectUser();


  return (
    <>
    {theUser?.deployDateText && <div className={`${internalClassName} ${internalClassName}-le--${theme}`}>
      <div className={`${internalClassName}-container ${internalClassName}-container-le--${theme}`}>
        <CpxIcon icon={ICONS.INFO}/>
        <p>
          {theUser?.deployDateText}
        </p>
      </div>
    </div>}
    </>
  );
}