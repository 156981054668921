import cs from "classnames";
import { CpxModal } from "../../../../../core/components/modal.component";
import React, { useEffect, useState } from "react";
import { useTheme } from "../../../../../core/utility/themeContext";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { apiCallAction } from "../../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../../constants/action.constants";
import { IPartnerCustomer } from "../../../../constants/types/types.constants";
import { useProductChangeBasket, useSelectedMainContract, useSelectError, useSelectPartnerCustomer } from "../../../../redux/store";
import { CurrencyWithChargeModeWithAmountNet } from "../../../../../core/utils";
import { CpxSelectBox } from "../../../../../core/components/selectBox.component";
import { SelectableBasketOption } from "compax-api";
import { useTranslations } from "../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";
import { addOptionsToBasket, removeOptionsFromBasket } from "../../../../redux/actions/step.action";
import "./changeProductModal.scss"
import { useFormik } from "formik";
import { createValidatorSchema, createValidatorSchemaArray, requiredOptionCheck } from "../../../../utils/validation";
import { CpxFormikForm } from "../../../../../core/components/formikForm.component";
import { NfoEmptyState } from "../../../../../core/emptyState.component";
import { ICONS, PRODUCT_CATEGORY_QUANTITY_ID } from "../../../../constants/configs/config.constants";
import * as Yup from 'yup'
import { Alert } from "../../../common/Alert/Alert.component";
import { saveBasket } from "../../../../redux/actions/productChange.action";
import { OrderOverviewBasket } from "../../../partner/newCustomer/orderOverviewBasket/orderOverviewBasket.component";
import { CpxIcon } from "../../../../../core/components/icon.component";
import {NfoActivationDate} from "../../../partner/newCustomer/configureProduct/activationDate/activationDate.component";

type AppDispatch = ThunkDispatch<any, any, AnyAction>;

type Props = {
  changeModal: { isShowing: boolean, page: number },
  setChangeModal: Function,
}

export enum pages {
  SELECT_OPTION = 1,
  BASKET_OVERVIEW,
  STATUS
}

export const ChangeProductModal = ({ changeModal, setChangeModal }: Props) => {
  const internalClassName = "changeProductModal";
  const theme = useTheme();
  const dispatch: AppDispatch = useDispatch();
  const selectedPartnerCustomer: IPartnerCustomer = useSelectPartnerCustomer() as IPartnerCustomer;
  const productChangeBasket = useProductChangeBasket();
  let basket = productChangeBasket?.basket;
  const error = useSelectError();
  const translationLicenses = useTranslations(TRANSLATIONS.licenses);
  const translation = useTranslations(TRANSLATIONS.stepper.newCustomer);
  const requiredMsg = translation.fieldRequired();
  const translationsCommon = useTranslations(TRANSLATIONS.common);
  const translations = useTranslations(TRANSLATIONS.charges);
  const translationsModal = useTranslations(TRANSLATIONS.confirmationModal);
  const changeProductModalTranslations = useTranslations(TRANSLATIONS.changeProductModal)
  const [availableOptions, setAvailableOptions] = useState<any>(productChangeBasket?.availableOptions);
  const [isSuccess, setIsSuccess] = useState(true);
  const selectedMainContract = useSelectedMainContract();
  const optionIsSelected = () => !!availableOptions?.find((o: any) => o.selected);
  const isFormikError = () => !!formik.errors.options?.find((option: any) => option?._quantity);
  const optionsAreEmpty = !(availableOptions && availableOptions.length > 0);
  const [selectedOption, setSelectedOption] = useState<any>();
  const [activationDate, setActivationDate] = useState();
  const [activationDateErrors, setActivationDateErrors] = useState();

  useEffect(() => {
    dispatch(apiCallAction(ACTION_CONST.API_PREPARE_CHANGE_PRODUCT, { selectedPartnerCustomerId: selectedPartnerCustomer?.id, optionContractId: selectedMainContract?.id, serviceId: selectedMainContract?.productWithPossibleSuccessor }))
      .then((res) => {
        setAvailableOptions(res.optionGroups[0].basketOptions);
        basket = res.basket;
        dispatch(saveBasket(res));
        /*setProductParentId(res.basket.entries[0].product.id)
        */
      })
  }, [])

  const handleNextStep = () => {
    if (changeModal.page === pages.BASKET_OVERVIEW) {
      dispatch(
        apiCallAction(ACTION_CONST.API_CHANGE_PRODUCT_SEND_ORDER, {
          selectedPartnerCustomerId: selectedPartnerCustomer?.id, contractId: selectedMainContract?.id, basketId: basket.id, changeRequestDate: activationDate
        })
      ).then(() => {
        setIsSuccess(true);
      }).catch(() => {
        setIsSuccess(false);
      });
      setChangeModal({ ...changeModal, page: pages.STATUS })
    } else {
      if (optionsAreEmpty) {
        setChangeModal({ ...changeModal, isShowing: false })
      } else {
        setChangeModal({ ...changeModal, page: changeModal.page + 1 })
      }
    }
  }
  const handlePreviousStep = () => setChangeModal({ ...changeModal, page: changeModal.page - 1 })
  const handleCancellation = () => {
    setChangeModal({ ...changeModal, isShowing: false })
    // dispatch(deleteLicensesBasket());
  }

  const selectOption = async (option: any) => {

    const optionId = option?.product?.id;
    let newBasket: any;

    if (optionId === basket?.entries[0]?.options.find((o: SelectableBasketOption) => o.product.id === optionId)?.product?.id) {
      //remove from basket
      newBasket = await dispatch(removeOptionsFromBasket(option, basket));
    } else {
      // add to basket
      newBasket = await dispatch(addOptionsToBasket(option, basket, 1));
    }
    dispatch(saveBasket({...productChangeBasket, basket: newBasket}));


    setAvailableOptions((prevState: any) => prevState.map((o: any) => {
      if (newBasket.entries[0].options.find((newO: any) => newO?.product?.id === o.product.id)) {
        return {...o, selected: true};
      } else {
        return {...o, selected: false}
      }
    }));
  }

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      options: availableOptions?.map((option: any) => { return {...option, isQtyOption: option?.product?.productCategory?.id === PRODUCT_CATEGORY_QUANTITY_ID, _quantity: !option.selected ? option?.servicePropertySip?.defaultQuantity : productChangeBasket.basket.entries[0].options.find((o: any) => o.product.id === option.product.id)?.quantity }})
    },
    validationSchema: createValidatorSchema({
      options: createValidatorSchemaArray({
        _quantity: Yup.lazy((values: any, option: any): any => {
          const min = option?.parent?.servicePropertySip?.orderMin;
          const max = option?.parent?.servicePropertySip?.orderMax;

          return requiredOptionCheck(requiredMsg, translation.quantityErrorMessage(min?.toString(), max?.toString()), translationLicenses.invalidQuantity(), min, max)
        })
      })
    }),
    onSubmit: ({ options }) => {
      selectOption(selectedOption.option)
    }
  });


  const handleSelection = (option: any, index: number) => {
    setSelectedOption({ index, option })
    formik.handleSubmit();
  }

  const handleStatusModal = () => {
    setChangeModal({ ...changeModal, isShowing: false })
    window.location.reload();
  }

  const checkDependency = (dependentServiceId :any) : boolean => {
    return true ; // (!basket.entries[0].options.find((opt : BasketEntry) => opt.product.id === dependentServiceId) && !licenses?.find((license: LicenseAndServiceContract) => (license?.status?.id === ID_STATUS_SERVICES.ACTIVE || license?.status?.id === ID_STATUS_SERVICES.ORDERED) && license?.product?.id === dependentServiceId));
  }

  const renderModalPage = () => {
    switch (changeModal.page) {
      case pages.SELECT_OPTION:
        return (
          <CpxFormikForm handleSubmit={() => {}}
                         initialValues={formik.initialValues}>
            <CpxModal
              className={internalClassName}
              onConfirm={handleNextStep}
              onCancel={handleCancellation}
              confirmText={optionsAreEmpty ? translationsCommon.close() : translationsCommon.next()}
              disableButton={!optionsAreEmpty && (!optionIsSelected() || isFormikError())}
            >
              <h3 className={cs('modalTitle', `modalTitle-le--${theme}`)}>
                {changeProductModalTranslations.title()}
              </h3>
              {!optionsAreEmpty && <p className={cs('modalInfoText', `modalInfoText-le--${theme}`)}>{changeProductModalTranslations.subTitle()}</p>}
                <NfoEmptyState
                  data={availableOptions}
                  icon={ICONS.STATUS.ORDERED_WHITE}
                  pageTitle={translationLicenses.options()}
                  subtitle={changeProductModalTranslations.optionsEmptyState()}
                  boxStyling={false}
                >
                  <div className='boxes'>
                    { availableOptions?.map((o: any, index: number) => {
                      return (
                        <CpxSelectBox
                          key={index}
                          tabIndex={index}
                          option={o && o}
                          product={o?.product}
                          title={o?.product?.displayValue}
                          active={o.selected}
                          onClick={() => handleSelection(o, index)}
                        >
                          <div
                            className={cs(`${internalClassName}-charges`, `${internalClassName}-charges-le--${theme}`)}>
                            {
                              o?.product?.charges?.length !== 0
                                ? CurrencyWithChargeModeWithAmountNet(o?.product?.charges, 0, translations)?.map((charge: any, index: number) => (  // todo edit parent
                                  <p key={index}>{charge}</p>
                                ))
                                : ''
                            }
                          </div>
                        </CpxSelectBox>
                      )
                    })}
                    {error?.errorData && error?.errorData?.length > 0 && <Alert errors={error?.errorData}/> }
                  </div>
                </NfoEmptyState>
            </CpxModal>
          </CpxFormikForm>
        )
      case pages.BASKET_OVERVIEW:

        return (
          <CpxModal
            className={internalClassName}
            onConfirm={handleNextStep}
            onCancel={handleCancellation}
            onBack={handlePreviousStep}
            confirmText={translationLicenses.order()}
            size={'large'}
            disableButton={!activationDateErrors}
          >
            <h3 className={cs('modalTitle', `modalTitle-le--${theme}`)}>
              {translationLicenses.titleSummary()}
            </h3>
            <NfoActivationDate setActivationDate={setActivationDate} className={'basketView'} startDtRequested={productChangeBasket.startDtRequested} isValid={setActivationDateErrors}/>
            <OrderOverviewBasket basket={basket} boxed={false} showTitle={false}/>
            <div className={cs(`${internalClassName}-notes-wrapper`)}><CpxIcon icon={ICONS.TRUCK} title={changeProductModalTranslations.truckInfo()}></CpxIcon>: {changeProductModalTranslations.truckInfo()}</div>
          </CpxModal>
        )
      case pages.STATUS:
        return (<>
          {!isSuccess && (
            <CpxModal
              onConfirm={handleStatusModal}>
              <h3 className={cs('modalTitle', `modalTitle-le--${theme}`)}>
                {translationsModal.orderError()}
              </h3>
              <p className={cs(`modalInfoText modalInfoText-le--${theme}`)}>{translationsModal.tryAgain()}</p>
            </CpxModal>
          )}
          {isSuccess && (
            <CpxModal
              onConfirm={handleStatusModal}>
              <h3 className={cs('modalTitle', `modalTitle-le--${theme}`)}>
                {translationsModal.increaseSuccess()}
              </h3>
              <p className={cs(`modalInfoText modalInfoText-le--${theme}`)}>{changeProductModalTranslations.successInfo()}</p>
            </CpxModal>
          )}
        </>)
      default:
        break;
    }
  }

  return (
    <>
      {renderModalPage()}
    </>
  )
}