import cs from 'classnames';
import { PropsWithoutRef } from 'react';
import './button.scss';
import './buttonDropdown.scss';
import { useTheme } from '../utility/themeContext';
import { ReactComponent as ArrowDown } from "../../portal/assets/icons/arrows_Tip.svg";
import { usePopupMenu } from "../../portal/components/common/header/utils";
import { CpxButton } from "./button.component";

type Props = PropsWithoutRef<JSX.IntrinsicElements['button']> & {
  isSmall?: boolean;
  buttonType?: 'default' | 'secondary' | 'error';
  isLinkStyle?: boolean;
  withIcon?: boolean;
  options: ({ name: string; label: string; subLabel?: string}| undefined)[];
  handleClickOption: any;
};

export const CpxButtonDropDown = ({
                            buttonType,
                            isLinkStyle,
                            className,
                            children,
                            withIcon,
                            ...props
                          }: Props) => {
  const internalClassName = 'cpxButtonDropDownComponent';
  const theme = useTheme();
  const { expanded, setExpanded, ref } = usePopupMenu();

  const handleClick = (option: any) => {
    setExpanded(false);
    props.handleClickOption(option);
  }

  return (
    <div className={cs(internalClassName, `${internalClassName}-le--${theme}`)} ref={ref}>
      <CpxButton className={cs(`buttonIcons`, {isExpanded: expanded})} onClick={() => setExpanded(cur => !cur)} {...props}>
        {children}
        <ArrowDown />
      </CpxButton>
      {expanded && (
        <ul className={cs(`list`, `list-le--${theme}`)}>
          {props.options.map((option: any) => (
            <li key={option.name}>
              <button
                onClick={() => handleClick(option)}
              >
                {option.label}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
