import cs from "classnames";
import { useTheme } from "../../../../../core/utility/themeContext";
import './footer.scss'
import { CpxButton } from "../../../../../core/components/button.component";
import { useAllDocumentsUpload, useCurrentStep, useIsProductSelected, useOEOptionsOfOptions, useOEProductOptions, useOptionalSteps, useOrderEntryBasket, useStep1Data, useStep3Data, useStep5Data } from "../../../../redux/store";
import { useDispatch } from "react-redux";
import { deleteOEOptionsOfOptionWithProductId, saveActivationDateSelection, saveCurrentStep, saveStep5Data, } from "../../../../redux/actions/step.action";
import React, { useState } from "react";
import { NfoCancelModal } from "../cancelModal/cancelModal.component";
import { useTranslations } from "../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";
import { NfoConfirmationModal } from "../confirmationModal/confirmationModal.component";
import { ACTION_CONST } from "../../../../constants/action.constants";
import { apiCallAction } from "../../../../redux/actions/apiCall.action";
import { clearError } from "../../../../redux/actions/error.action";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { ID_CONTRACT_TELEPHONE_CONNECTION } from "../../../../constants/configs/config.constants";
import { StepObj } from "../stepper/stepper.component";
import { NfoRemoveConfigurationConfirmationModal } from "../confirmModal/confirmationModal.component";

type Props = {
  steps: StepObj[],
};

type AppDispatch = ThunkDispatch<any, any, AnyAction>;

export const NfoStepperFooterComponent = ({ steps }: Props) => {
  const theme = useTheme();
  const dispatch: AppDispatch = useDispatch();
  const translations = useTranslations(TRANSLATIONS.common);
  const translationsStepper = useTranslations(TRANSLATIONS.stepper.newCustomer);
  const [cancelModal, setCancelModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmDeleteConfigurationModal, setConfirmDeleteConfigurationModal] = useState(false);
  const currentStep = useCurrentStep();
  const optionalSteps = useOptionalSteps();
  const basket = useOrderEntryBasket();
  const step3Data = useStep3Data();
  const oeOptionsOfOptions = useOEOptionsOfOptions();
  const translationsConfirmationModal = useTranslations(TRANSLATIONS.confirmationModal);

  const documents = useStep5Data();
  const optionsGroups = useOEProductOptions();
  const allDocumentsUpload = useAllDocumentsUpload();
  const isProductSelected = useIsProductSelected();
  const step1Data = useStep1Data();

  const goBack = async () => {
    if ([6,7].includes(currentStep)) {
      setConfirmDeleteConfigurationModal(true);
      return;
    }


    if (currentStep === 8) {
      let tempObjectArray = new Array<any>();

      documents.map((doc: any) => {
        handleDeleteDocument(doc.documentId);
        tempObjectArray.push({ documentId: doc.documentId, entries: doc.entries });
      });


      dispatch(apiCallAction(ACTION_CONST.API_BASKET_DELETE_DOCUMENT,
        {
          basketId: basket?.id,
          basketDocumentRemoves: tempObjectArray
        }
      ));


      dispatch((saveStep5Data([])));

    }


    dispatch(clearError());
    dispatch(saveCurrentStep((currentStep === 8 && !step1Data?.migrationCustomerId) ? 5 : (currentStep - 1)));
  }

  const goBackInProductConfiguration = async ()  => {
    if (currentStep === 6) {
      dispatch(apiCallAction(ACTION_CONST.API_BASKET_DELETE_PRODUCT, { basketId: basket?.id, index: step3Data?.activeIndex }));
      dispatch(saveActivationDateSelection({ isPlanned: false }));
    }

    if (currentStep === 7) {

      const productIdOfTelephoneConnection = optionsGroups.find((item: any) => item.basketOptions.find((option: any) => option?.product?.productType?.id === +ID_CONTRACT_TELEPHONE_CONNECTION)).basketOptions[0].product.id;
      const indexOfTelephoneConnection = basket.entries[step3Data?.activeIndex]?.options?.findIndex((option: any) => option?.product?.id === productIdOfTelephoneConnection)
      if(indexOfTelephoneConnection !== -1){
        await dispatch(apiCallAction(ACTION_CONST.API_BASKET_DELETE_OPTION_INDEX,
          { basketId: basket?.id, entryIndex: step3Data?.activeIndex, optionIndex: indexOfTelephoneConnection }
        ));
      }

      if(oeOptionsOfOptions && indexOfTelephoneConnection !== -1 && basket.entries[step3Data?.activeIndex]?.options[indexOfTelephoneConnection].uuid && oeOptionsOfOptions && oeOptionsOfOptions[basket.entries[step3Data?.activeIndex]?.options[indexOfTelephoneConnection].uuid]){
        dispatch(deleteOEOptionsOfOptionWithProductId(productIdOfTelephoneConnection));
      }
    }

    setConfirmDeleteConfigurationModal(false)
    dispatch(clearError());
    dispatch(saveCurrentStep(currentStep - 1));
  }

  const handleDeleteDocument = (documentId: any) => {
    dispatch(apiCallAction(ACTION_CONST.API_DOCUMENTS_DELETE_DOCUMENT, { documentId: documentId }));

  }

  const goOptionalStepForward = () => {
    optionalSteps.map(
      step => (step === currentStep) && dispatch(saveCurrentStep(currentStep + 1)))
  }

  function areNotAllDocumentsUploaded() {
    return (currentStep == 8 && !allDocumentsUpload) || (currentStep == 5 && !isProductSelected && Object.keys(basket.entries).length === 0);
  }

  const getGoForwardButtonText = () => {
    if(currentStep === 5) {
      if(isProductSelected){
        return translationsStepper.configureText();
      }
      return translationsStepper.goToContractDocuments();
    }
    if(currentStep === 7) {
      return translationsStepper.finaliseConfiguration();
    }
    return translations.next();
  }


  return (
    <>
      {cancelModal && (
        <NfoCancelModal
          setCancelModal={setCancelModal}
        />
      )}
      {confirmModal && (
        <NfoConfirmationModal
          confirmModal={confirmModal}
          setConfirmModal={setConfirmModal}
        />
      )}
      {confirmDeleteConfigurationModal && (
        <NfoRemoveConfigurationConfirmationModal onConfirm={goBackInProductConfiguration} onCancel={() => setConfirmDeleteConfigurationModal(false)} confirmMessage={translationsConfirmationModal.confirmGoBack()}/>
      )}
      <div className={cs('stepper-footer', `stepper-footer-le--${theme}`)}>
        <div className={cs('stepper-footer-content')}>
          <CpxButton onClick={() => setCancelModal(!cancelModal)} buttonType={theme === 'dts' ? "default" : "secondary"} className={'white-override'}>
            {translations.cancel()}
          </CpxButton>
          <div className={cs('stepper-footer-content--right')}>
            {(currentStep !== steps[0].stepNumber) && (
              <CpxButton onClick={goBack} buttonType={theme === 'dts' ? "default" : "secondary"}>
                {translations.back()}
              </CpxButton>
            )}
            {currentStep < steps.reduce((curr, prev) => curr.stepNumber > prev.stepNumber ? curr : prev).stepNumber ? (
              <CpxButton form={'new-customer-form'} disabled={areNotAllDocumentsUploaded()} onClick={() => goOptionalStepForward()} buttonType={theme === 'dts' ? 'white' : 'default'}>
                {getGoForwardButtonText()}
              </CpxButton>
            ) : (
              <CpxButton onClick={() => setConfirmModal(true)} buttonType={theme === 'dts' ? 'white' : 'default'}>
                {translationsStepper.order()}
              </CpxButton>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
