import { useTheme } from "../utility/themeContext";
import { ID_SALUTATIONS } from "../../portal/constants/configs/config.constants";

type Props = {
  address?: any;
  className?: string;
  usage?: string;
};

export const CpxAddress = ({ address, className, usage }: Props) => {
  const theme = useTheme();


  if (!address) {
    return null;
  }

  let name = '';
  if(address.salutation?.id === ID_SALUTATIONS.COMPANY) {
    name = address.companyName ? address.companyName : '';
  } else{
    name = address.firstName ? address.firstName : '';
    name = name + (address.lastName ? (address.firstName ? ' ' + address.lastName: address.lastName) : '');
  }

  return (
    <>
      <p className={className}>
          {name}
      </p>
      <p className={className}>
        {address?.street} {address?.houseNumber}
      </p>
      {<p className={className}>
        {usage !== "yourPartner" && address?.zip} {address?.city}
      </p>}
      <p className={className}>
        {address?.district && address?.district}
      </p>
      {usage !== "yourPartner" && <p className={className}>
        {address?.country?.description}
      </p>}
      <p className={className}>
       {address?.email}
      </p>
    </>
  );
};
