import { NfonCliInformation } from "compax-api";
import { CpxPaginatedTable } from "../../../../../core/components/paginatedTable.component";
import { NfoStatus } from "../../../../../core/components/status.component";
import { useSelectNumbers } from "../../../../redux/store";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";
import { useTranslations } from "../../../../utils/helper/utils";
import { NfoInbountOutbound } from "../inboundOutbound/inboundOutbound.component";
import "./numbersTable.scss";
import cs from "classnames";
import { useTheme } from "../../../../../core/utility/themeContext";
import { ICONS, ID_DATA_ENTITY } from "../../../../constants/configs/config.constants";
import { NfoSwitchAllProducts } from "../../../switchActiveProducts/switchActiveProducts.component";
import React, { useState } from "react";
import { NfoEmptyState } from "../../../../../core/emptyState.component";
import { NumbersAddButton } from "../../../../pages/customer/numbers/numbers.page";

export const NfoNumbersTable = ({ title, handleModalClick }: any) => {
  const translations = useTranslations(TRANSLATIONS.numbers);
  const numbers = useSelectNumbers();
  const internalClassName = "tableBox";
  const theme = useTheme();
  const [filteredItems, setFilteredItems] = useState([])



  const numbersTableRowData = (cli: NfonCliInformation) => {
    const address = cli.address;
    const addressString = `${address?.street || ""} ${
      address?.houseNumber || ""
    }, ${address?.zip || ""} ${address?.city}`;

    return {
      id: cli.id + "",
      cellData: [
        <p
          className={cs("tableFirstChild", `tableFirstChild-font-le--${theme}`)}
        >
          {cli.status?.id === 0 ? translations.cli() : cli.cli}
        </p>,
        addressString,
        <NfoInbountOutbound
          inbound={!!cli.inbound}
          outbound={!!cli.outbound}
        />,
        <span className={'status'}>
          <NfoStatus
            entity={{ id: ID_DATA_ENTITY.SERVICE }}
            status={cli.status}/>
        </span>,
      ],
    };
  };

  return (
    <NfoEmptyState
      icon={ICONS.PHONE}
      data={numbers}
      pageTitle={title}
      subtitle={translations.emptyStateSubTitle()}
      button={<NumbersAddButton handleModalClick={handleModalClick}/>}
      boxStyling={true}
    >
      <article className={cs(internalClassName, `${internalClassName}-le--${theme}`, `numbersTable`)}>
        <NfoSwitchAllProducts data={numbers} getFilteredDataBack={(data: any) => setFilteredItems(data)}/>
        <CpxPaginatedTable
          id="numbersTable"
          tableHeader={[
            { label: translations.number(), sort: true, sortBy: 'cli', sortMethod: 'string' },
            { label: translations.site(), sort: true, sortBy: 'address.street', sortMethod: 'string' },
            { label: translations.inOutbound(), sort: false },
            { label: translations.status(), sort: true, sortBy: 'status.id', sortMethod: 'number' },
          ]}
          tableData={filteredItems.map(numbersTableRowData)}
          rowData={filteredItems}
          getSortedRowsBack={(sortedRows: any) => setFilteredItems(sortedRows?.slice())}
        />
      </article>
    </NfoEmptyState>
  );
};
