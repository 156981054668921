import cs from 'classnames';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { AtLeastOne } from '../types';
import './paginatedTable.scss';
import { CpxPagination } from './pagination.component';
import { CpxPaginationSizeSelect } from './paginationSizeSelect.component';
import { useTheme } from '../utility/themeContext';
import { CpxSort } from "./sort.component";
import { sortTable } from "../../portal/utils/helper/utils";
import { CpxIcon } from "./icon.component";
import { ICONS } from "../../portal/constants/configs/config.constants";

export type TableRow = {
  id: string;
  active?: boolean;
  onClick?: (id: string) => void;
  cellData: ReactNode[];
  collapsible?: ReactNode[];
};

export type SliceToString = (
  sliceStart: number,
  sliceEnd: number,
  total: number
) => string;

type ItemsPerPage = number | AtLeastOne<number>;

type Props = {
  id: string;
  caption?: string;
  tableHeader?: ReactNode[];
  tableFooter?: ReactNode[];
  tableData: TableRow[] | any;
  itemsPerPage?: ItemsPerPage;
  pagination?: 'top' | 'bottom' | 'both';
  itemsPerPageLabel?: string;
  sliceToString?: SliceToString;
  allTitleClass?: string;
  className?: string;
  rowData?: any;
  getSortedRowsBack?: any;
  isDownloadTable?: boolean;
  downloadAllHeaderCol?: any;
};

const initItemsPerPageState = (itemsPerPage?: ItemsPerPage) =>
  !itemsPerPage
    ? undefined
    : typeof itemsPerPage === 'number'
      ? itemsPerPage
      : itemsPerPage[0];

const multiplyOrFallback = (x?: number) => (y: number, fallback: number) => {
  return !x ? fallback : x * y;
};

export const CpxPaginatedTable = ({
                                    id,
                                    caption,
                                    tableData,
                                    tableHeader,
                                    tableFooter,
                                    itemsPerPage,
                                    pagination = 'bottom',
                                    itemsPerPageLabel,
                                    sliceToString,
                                    allTitleClass,
                                    className,
                                    rowData,
                                    getSortedRowsBack,
                                    isDownloadTable = false,
                                    downloadAllHeaderCol
                                  }: Props) => {
  const theme = useTheme();
  const internalClassName = 'paginatedTable';
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPageState, setItemsPerPageState] = useState(
    initItemsPerPageState(itemsPerPage) as number | undefined
  );
  const [expandedRow, setExpandedRow] = useState<number | null>(null);

  const multiplyByItemsPerPageState = multiplyOrFallback(itemsPerPageState);
  const pages = useMemo(
    () =>
      itemsPerPageState ? Math.ceil(tableData?.length / itemsPerPageState) : 1,
    [itemsPerPageState, tableData]
  );
  // const lastLineClass = useTheme === 'dts' ? 'lastLineDark' : 'lastLine';

  const sliceStart = useMemo(() => {
    return multiplyByItemsPerPageState(
      currentPage === 0 ? 0 : currentPage - 1,
      0
    );
  }, [currentPage, multiplyByItemsPerPageState]);
  const sliceEnd = useMemo(() => {
    return Math.min(
      multiplyByItemsPerPageState(currentPage, tableData?.length),
      tableData?.length
    );
  }, [currentPage, multiplyByItemsPerPageState, tableData?.length]);
  const slice = useMemo(() => {
    return tableData?.slice(sliceStart, sliceEnd);
  }, [tableData, sliceStart, sliceEnd]);

  const paginationProps = {
    label: itemsPerPageLabel,
    sliceInfo: !sliceToString
      ? undefined
      : sliceToString(sliceStart + 1, sliceEnd, tableData.length),
    value: itemsPerPageState || 1,
    onChange: setItemsPerPageState,
  };

  useEffect(() => {
    setItemsPerPageState(initItemsPerPageState(itemsPerPageState));
  }, [itemsPerPageState]);

  useEffect(() => {
    if (currentPage > pages) {
      setCurrentPage(pages);
    } else if (currentPage === 0 && tableData.length > 0) {
      setCurrentPage(currentPage + 1);
    }
  }, [currentPage, pages, tableData]);

  const paginationComponent = (idPostfix: string) => (
    <div className={'paginationWrapper'}>
      {(Array.isArray(itemsPerPage) || pages > 1) && tableData.length > 0 && (
        <CpxPagination {...{ pages, currentPage }} onChange={setCurrentPage}/>
      )}
      {Array.isArray(itemsPerPage) && tableData.length > 0 && (
        <CpxPaginationSizeSelect
          {...paginationProps}
          itemsPerPageOptions={itemsPerPage}
          id={`${id}--${idPostfix}`}
        />
      )}
    </div>
  );

  const [sortColumns, setSortColumns] = useState(new Map<string, boolean>(tableHeader?.map((cell: any) => [cell?.label, false])))

  const resetColumns = (label: string) => {
    setSortColumns((prevMap) => {
      return new Map<string, boolean>(tableHeader?.map((cell: any) => [cell?.label, label === cell?.label]));
    })
  }

  const handleExpandRow = (rowId: number) => {
    setExpandedRow(expandedRow === rowId ? null : rowId)
  }

  return (
    <div {...{ id }}>
      {['top', 'both'].includes(pagination) &&
        itemsPerPage &&
        paginationComponent('paginationTop')}
      <table
        className={cs(internalClassName, className, {
          hasPages: pages > 1,
        })}
      >
        {!!caption && (
          <caption className={`${internalClassName}-caption-le--${theme}`}>
            <h4>{caption}</h4>
          </caption>
        )}
        {!!tableHeader && (
          <thead className={`${internalClassName}-tableHeader-le--${theme}`}>
          <tr>
            {isDownloadTable && downloadAllHeaderCol && /*header checkbox select all*/
              (downloadAllHeaderCol)
            }
            {tableHeader.map((cell: any, key) => (
              <th key={key}>
                {!cell?.label ? cell : ""}
                {cell?.sort ? <CpxSort cell={cell} rowData={rowData} reset={resetColumns} sortColumns={sortColumns} onSort={(cell: any, dir: string) => getSortedRowsBack(sortTable(cell, dir, rowData))}/> : <span>{cell?.label}</span>}
              </th>
            ))}
            {isDownloadTable && <th>Download</th>}
          </tr>
          </thead>
        )}
        <tbody className={`${internalClassName}-body-le--${theme}`}>
        {slice?.map((row: any) => {
          return (
            <>
              <tr
                key={row.id}
                className={cs(
                  `${internalClassName}-firstRow-le--${theme}`,
                  `${internalClassName}-row-le--${theme}`,
                  {
                    hasClickEvent: !!row.onClick,
                    isActive: row.active,
                  }
                )}
                onClick={
                  !!row.onClick
                    ? () => {
                      row.onClick!(row.id);
                    }
                    : undefined
                }
              >
                {row.cellData.map((cell: any, cellKey: any) => (
                  <td
                    className={cs(
                      `firstChild-le--${theme}`,
                      `secondToLastChild-le--${theme}`,
                      allTitleClass
                    )}
                    key={cellKey}
                  >
                    {cell}
                  </td>
                ))}
                {row.collapsible && row.collapsible && (
                  <td
                    className={cs(
                      `${internalClassName}-collapsible-button`,
                      `${internalClassName}-collapsible-button-le--${theme}`,
                      `firstChild-le--${theme}`,
                      `secondToLastChild-le--${theme}`,
                      allTitleClass
                    )}>
                    <div onClick={() => handleExpandRow(row.id)}>
                      { expandedRow === row.id ? <CpxIcon icon={ICONS.ARROW_UP}/> : <CpxIcon icon={ICONS.ARROW_DOWN} /> }
                    </div>
                  </td>
                )}
              </tr>
              {expandedRow === row.id && (
                <tr className={cs(`${internalClassName}-collapsible-row ${internalClassName}-collapsible-row-le--${theme}`)}>
                  {row.collapsible?.map((cell: any, index: number) => {
                    return (
                      <td
                        colSpan={(row.collapsible.length === index+1) ? 2 : undefined}
                      >
                        {cell}
                      </td>
                    )
                  })}
                </tr>
              )}
            </>
          )
        })}
        </tbody>
        {!!tableFooter && (
          <tfoot className={`${internalClassName}-tableFooter ${internalClassName}-tableFooter-le--${theme}`}>
            <tr>
              {isDownloadTable && downloadAllHeaderCol && /*header checkbox select all*/
                (downloadAllHeaderCol)
              }
              {tableFooter.map((cell: any, key) => (
                <td key={key}>
                  {!cell?.label ? cell : ""}
                </td>
              ))}
            </tr>
          </tfoot>
        )}
      </table>
      {['bottom', 'both'].includes(pagination) &&
        itemsPerPage &&
        paginationComponent('paginationBottom')}
    </div>
  );
};
