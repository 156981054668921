import { useTheme } from "../../../../../core/utility/themeContext";
import commonStyles from "../../../../sass/base/common.module.scss";
import cs from "classnames";
import React, { Fragment } from "react";
import "./orderOverviewBasket.scss";
import { Basket, BasketEntry } from "compax-api";
import { ReactComponent as ArrowLeftBottom } from "../../../../assets/icons/arrow-left-down.svg";
import { currency } from "../../../../../core/utils";
import '../../../../../core/components/paginatedTable.scss';
import { useTranslations } from "../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";
import { CpxIcon } from "../../../../../core/components/icon.component";
import { ICONS } from "../../../../constants/configs/config.constants";

type Props = {
  basket: Basket;
  boxed?: boolean;
  showTitle?: boolean;
}

export const OrderOverviewBasket = ({ basket, boxed = true, showTitle = true }: Props) => {
  const theme = useTheme();
  const internalClassName = "orderOverviewBasket";
  const translations = useTranslations(TRANSLATIONS.stepper.newCustomer);
  const changeProductModalTranslations = useTranslations(TRANSLATIONS.changeProductModal)


  const renderSkeletonRow = (entry: BasketEntry, hasArrowIcon: boolean = false, index: number = 0) => {

    return (
      <>
        <td className={cs('line', `line-le--${theme}`, "product-name")} colSpan={2}>
          <div className={cs(`${internalClassName}-product-name`)}>
            <span>{hasArrowIcon && <ArrowLeftBottom/>} {entry?.product?.description}{!hasArrowIcon ? ' - ' + (index + 1) : ''}</span>
            {hasArrowIcon && entry.existingServiceId && <CpxIcon icon={ICONS.TRUCK} title={changeProductModalTranslations.truckInfo()}></CpxIcon>}
          </div>
        </td>
        <td className={cs('line', `line-le--${theme}`, "product-quantity")}>
          {entry?.dependentQuantity ? entry.dependentQuantity : (entry?.quantity && entry?.quantity)}
        </td>

        <td className={cs('line', `line-le--${theme}`, 'centered-text', "product-price-onetime")}>
          {currency(entry?.oneTimeCharges?.find((charge) => charge?.chargeMode?.id === 1)?.amountNet,
            basket?.currency?.description, false, "-")}
        </td>
        <td className={cs('line', `line-le--${theme}`, 'centered-text', "product-price-monthly")}>
          {
            entry?.recurringCharges?.find(charge => charge?.chargeMode?.id === 2) ?
              currency(entry?.recurringCharges?.
              find((charge) => charge?.chargeMode?.id === 2)?.amountNet, basket?.currency?.description) :
              currency(entry?.recurringCharges?.
              find(charge => charge?.chargeMode?.id === 4)?.amountNet, basket?.currency?.description, false, "-")
          }
        </td>
      </>)
  }


  const renderBasketHierarchy = (entry: BasketEntry, index: number, indexOfEntryByProduct: number) => {

    const mainProduct = (
      <tr className="product">
        {renderSkeletonRow(entry, false, indexOfEntryByProduct)}
      </tr>
    )

    const renderOtherHierarchies = () => {
      return (
        <>
          {Array.isArray(entry?.options) && entry?.options.length > 0 && entry?.options.map((option, index) => (
            <Fragment key={index}>
              <tr className={"second-hierarchy"}>
                {renderSkeletonRow(option, true)}
              </tr>

              {Array.isArray(option.options) && option?.options.length > 0 && option?.options.map((optionOfOption, index) => (
                <tr key={index} className={"third-hierarchy"}>
                  {renderSkeletonRow(optionOfOption, true)}
                </tr>
              ))}
            </Fragment>
              ))}
        </>)

    }

    return (
      <Fragment key={index}>
        {entry?.product?.description && mainProduct}
        {renderOtherHierarchies()}
      </Fragment>
    )
  }


  return (
    <div className={cs(commonStyles.columnContainer, internalClassName, `${internalClassName}-le--${theme}`,
      {
        boxStyling: boxed
      }
      )}>
      <div className={cs(commonStyles.column, `basketWrapper`)}>
        {showTitle && <h4 className={cs('step-subtitle', `step-subtitle-le--${theme}`)}>
          {translations.shoppingCart()}
        </h4> }
        <table>
          <tbody>
          <tr className={cs('boxHeader', `boxHeader-le--${theme}`)}>
            <th className="product-name" colSpan={2}>{translations.product()}</th>
            <th className="product-quantity">{translations.quantity()}</th>
            <th className="product-price-onetime">{translations.oneTime()}</th>
            <th className="product-price-monthly">{translations.monthly()}</th>
          </tr>
          {
            basket.entries !== undefined && Object.values(basket?.entries)?.map((entry: any, index: number) => {
              const indexOfEntryByProduct = Object.values(basket?.entries).filter(e => e.product.id === entry.product.id).findIndex(e => e.uuid === entry.uuid);
              return renderBasketHierarchy(entry, index, indexOfEntryByProduct);
            })
          }

          <tr className={`sum`}>
            <td className={cs('line', `line-le--${theme}`)} colSpan={2}>{translations.totalNet()}</td>
            <td className={cs('line', `line-le--${theme}`)}></td>
            <td className={cs('line', `line-le--${theme}`)}>{currency(basket?.total?.oneTime?.amountNet, basket?.currency?.description, true)}</td>
            <td className={cs('line', `line-le--${theme}`)}>{currency(basket?.total?.recurring?.amountNet, basket?.currency?.description, true)}</td>
          </tr>
          </tbody>
        </table>

      </div>
    </div>
  );
};
