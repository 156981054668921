export const directDownload = (
  promise: () => Promise<string>,
  filename: string,
  mimeType: string
) => {
  promise().then(data => {
    const link = document.createElement("a");
    link.href = `data:${mimeType}/pdf;base64,${data}`;
    link.download = filename;
    link.click();
    link.remove();
  });
};
