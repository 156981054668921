import React from "react";
import { NfoNavigation } from "../components/common/navigation/navigation.component";
import { NfoYourPartner } from "../components/common/yourPartner/yourPartner.component";
import { NfoMyContactPerson } from "../components/partner/myContactPerson/myContactPerson.component";
import { CpxGoBackLink } from "../../core/components/goBackLink.component";
import { useHistory } from "react-router-dom";
import { useSelectUserRole } from "../redux/store";
import { routes } from "../constants/routes/routes";
import styles from "./container.module.scss";
import { USER_PARTNER, USER_WHOLESALER_EVN } from "../constants/configs/config.constants";

type Props = {
  children?: any;
};


export const NfoContainer = ({ children }: Props) => {
  const role = useSelectUserRole();
  const history = useHistory();

  return (
    <div className={styles.container}>

      {role !== USER_WHOLESALER_EVN &&
        <div className={styles.column}>
          {history.location.pathname !== routes.customerEditProfile.path ?
            <>
              <NfoNavigation className={styles.navigation}/>
              {role === USER_PARTNER ? <NfoMyContactPerson/> : <NfoYourPartner/>}
            </>
            :
            <CpxGoBackLink/>
          }
        </div>
      }

     <div className={role !== USER_WHOLESALER_EVN ? styles.column : styles.evn}>
        <main>
          {children}
        </main>
      </div>
    </div>
  );
};
