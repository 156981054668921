import './configurationBox.scss'
import cs from "classnames";
import { useTheme } from "../../../../../../core/utility/themeContext";
import { CpxButton } from "../../../../../../core/components/button.component";
import React, { useEffect, useState } from "react";
import { useTranslations } from "../../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../../constants/transitions/uiTranslations";
import { NfoOptionGroups } from "../optionGroups/optionGroups.component";
import { useOrderEntryBasket, useStep3Data } from "../../../../../redux/store";
import { clearError } from "../../../../../redux/actions/error.action";
import { useDispatch } from "react-redux";
import { Basket } from "compax-api";

type Props = {
  index?: number;
  name: string;
  configurable?: boolean;
  optionsOfOptions: any;
  parentOptionUuid: string;
  parentOptionId: number;
  onClick?: (e?: any) => void;
  onApplyProductOption?: () => void;
}

export const NfoConfigurationBox = ({ index, name, configurable, optionsOfOptions,parentOptionId, parentOptionUuid, onClick, onApplyProductOption }: Props) => {
  const translationsStepper = useTranslations(TRANSLATIONS.stepper.newCustomer);
  const theme = useTheme();
  const basket: Basket = useOrderEntryBasket();
  const step3Data = useStep3Data();
  const optionUuidInBasket = basket?.entries[step3Data?.activeIndex]?.options?.find((option: any) =>
    option.uuid === parentOptionUuid)?.uuid;
  const [isBeingConfigured, setIsBeingConfigured] = useState(false);
  const dispatch = useDispatch()
  useEffect(() => {
    const isOptionInBasket = (parentOptionUuid === undefined ) ? false : optionUuidInBasket === parentOptionUuid;
    setIsBeingConfigured(isOptionInBasket)
  }, [parentOptionUuid, optionUuidInBasket])

  return (
    <div className={cs(`configurationBox`, `configurationBox-le--${theme}`)}>
      <div className={cs(`configurationBox__wrapper`)}>
        <h4 className={cs(`configurationBox-title`, `configurationBox-title-le--${theme}`)}>
          {name}
        </h4>
        {!isBeingConfigured && <CpxButton
          type="button"
          disabled={!configurable}
          onClick={() => {
            if (onClick) {
              onClick();
            }
            setIsBeingConfigured(true)
          }}
        >
          {translationsStepper.configureText()}
        </CpxButton>}
      </div>
      {isBeingConfigured && <div className={cs(`optionGroups`, {'isBeingConfigured': optionsOfOptions && isBeingConfigured})}>
        <NfoOptionGroups optionGroups={optionsOfOptions} parentOptionId={parentOptionId}
                         parentOptionUuid={parentOptionUuid}/>
      </div>}
      {optionsOfOptions && isBeingConfigured && <div className={"configurationBox__buttons"}>
        <CpxButton type="button" disabled={!configurable} className={`cpxButton-le--${theme} secondary secondary-le--${theme}`}
                   onClick={(e) => {
                     if (onClick) {
                       onClick(true);
                     }
                     setIsBeingConfigured(false);
                     dispatch(clearError())
                   }}
        >
          {translationsStepper.cancel()}
        </CpxButton>

        <CpxButton type="button" onClick={() => {
          if (onApplyProductOption) {
            onApplyProductOption();
            window.scrollTo(0, 0);
          }
        }}
        >
          {translationsStepper.apply()}
        </CpxButton>
      </div>}
    </div>
  )
}