import { ContactDataItem } from "./contactDataItem.component";
import React, { useEffect, useState } from "react";
import { apiCallAction } from "../../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../../constants/action.constants";
import { useDispatch } from "react-redux";
import { useSelectCustomer, useSelectError, useSelectPartnerCustomContactPerson } from "../../../../redux/store";
import { useFormik } from "formik";
import { CpxFormikForm } from "../../../../../core/components/formikForm.component";
import { CpxButton } from "../../../../../core/components/button.component";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { areaCodeCheck, cliCheck, countryCodeCheck, createValidatorSchema, MaxLengthCheck, requiredCheck } from "../../../../utils/validation";
import { INPUT_MAX_LENGTHS } from "../../../../constants/configs/config.constants";
import { useTranslations } from "../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";
import { Alert } from "../../Alert/Alert.component";
import { saveCustomContactPerson } from "../../../../redux/actions/partnerCustomContactPerson.action";
import { PartnerCustomContactPersons, Phone, PortalCustomerData } from "compax-api";
import { useTheme } from "../../../../../core/utility/themeContext";
import cs from "classnames";
import './updateContactData.scss'
import { useToast } from "../../header/toast/toast.component";
import { ToastTypes } from "../../../../constants/types/types.constants";

type AppDispatch = ThunkDispatch<any, any, AnyAction>;

export const NfoUpdateContactData = () => {
  const internalClassName = "updateContactData";
  const translation = useTranslations(TRANSLATIONS.stepper.newCustomer);
  const translationsProfile = useTranslations(TRANSLATIONS.profile);
  const dispatch: AppDispatch = useDispatch();
  const partnerCustomContactPerson = useSelectPartnerCustomContactPerson() as PartnerCustomContactPersons;
  const error = useSelectError();
  const customer = useSelectCustomer() as PortalCustomerData;
  const theme = useTheme();
  const [errorShow, setErrorShow] = useState(false);
  const { showToast } = useToast();

  const [activeBillSwitchCounter, setActiveBillSwitchCounter] = useState(1);

  useEffect(() => {
    dispatch(apiCallAction(ACTION_CONST.API_GET_PARTNER_CUSTOM_CONTACT_PERSON));
  }, [])

  const itemData = [
    { label: translationsProfile.contactDataName(), name: "name", switches: { portal: true, bill: true }, phone: false},
    { label: translationsProfile.contactDataContactPerson(), name: "contactPerson", switches: { portal: true, bill: true }, phone: false},
    { label: translationsProfile.contactDataStreetAndHouseNumber(), name: "streetAndHouseNumber", switches: { portal: true, bill: false }, phone: false},
    { label: translationsProfile.contactDataCityAndZip(), name: "cityAndZip", switches: { portal: true, bill: false }, phone: false},
    { label: translationsProfile.contactDataContactNumber(), name: "contactNumber", switches: { portal: true, bill: true }, phone: true},
    { label: translationsProfile.contactDataEmail(), name: "email", switches: { portal: true, bill: true }, phone: false},
    { label: translationsProfile.contactDataInternetAddress(), name: "internetAddress", switches: { portal: true, bill: true }, phone: false},
    { label: translationsProfile.contactDataTechnicalHotline(), name: "technicalHotline", switches: { portal: true, bill: false }, phone: true},
  ]

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: partnerCustomContactPerson.name || customer?.customerName,
      namePortal: true,
      nameBill: true,
      contactPerson: partnerCustomContactPerson.contactPerson,
      contactPersonPortal: partnerCustomContactPerson.contactPersonPortal,
      contactPersonBill: partnerCustomContactPerson.contactPersonBill,
      streetAndHouseNumber: partnerCustomContactPerson.streetAndHouseNumber,
      streetAndHouseNumberPortal: partnerCustomContactPerson.streetAndHouseNumberPortal,
      cityAndZip: partnerCustomContactPerson.cityAndZip,
      cityAndZipPortal: partnerCustomContactPerson.cityAndZipPortal,
      contactNumber: partnerCustomContactPerson.contactNumber as Phone,
      contactNumberPortal: partnerCustomContactPerson.contactNumberPortal,
      contactNumberBill: partnerCustomContactPerson.contactNumberBill,
      email: partnerCustomContactPerson.email,
      emailPortal: partnerCustomContactPerson.emailPortal,
      emailBill: partnerCustomContactPerson.emailBill,
      internetAddress: partnerCustomContactPerson.internetAddress,
      internetAddressPortal: partnerCustomContactPerson.internetAddressPortal,
      internetAddressBill: partnerCustomContactPerson.internetAddressBill,
      technicalHotline: partnerCustomContactPerson.technicalHotline as Phone,
      technicalHotlinePortal: partnerCustomContactPerson.technicalHotlinePortal,
    },
    validationSchema: createValidatorSchema({
      name: MaxLengthCheck(INPUT_MAX_LENGTHS.customContactPerson, requiredCheck(translation.fieldRequired())),
      contactPerson: MaxLengthCheck(INPUT_MAX_LENGTHS.customContactPerson),
      streetAndHouseNumber: MaxLengthCheck(INPUT_MAX_LENGTHS.customContactPerson),
      cityAndZip: MaxLengthCheck(INPUT_MAX_LENGTHS.customContactPerson),
      contactNumber: createValidatorSchema({
        countryCode: countryCodeCheck(translation.countryCodeRequired()),
        areaCode: areaCodeCheck(translation.areaCodeRequired()),
        cli: cliCheck(translation.cliRequired()),
      }),
      email: MaxLengthCheck(INPUT_MAX_LENGTHS.customContactPerson),
      internetAddress: MaxLengthCheck(INPUT_MAX_LENGTHS.customContactPerson),
      technicalHotline: createValidatorSchema({
        countryCode: countryCodeCheck(translation.countryCodeRequired()),
        areaCode: areaCodeCheck(translation.areaCodeRequired()),
        cli: cliCheck(translation.cliRequired()),
      }),
    }),
    onSubmit: (values: any) => {
      setErrorShow(false);
      dispatch(apiCallAction(ACTION_CONST.API_POST_PARTNER_CUSTOM_CONTACT_PERSON, values, true))
        .then((res) => {
          dispatch(saveCustomContactPerson(res));
          showToast(ToastTypes.SUCCESS, translationsProfile.changeSuccess());
        })
        .catch(() => {
          setErrorShow(true);
        })
    }
  });

  return (
    <CpxFormikForm handleSubmit={formik.handleSubmit}
                   initialValues={formik.initialValues}
                   id={'partner-contact-data-form'}
    >
      <article className={cs(internalClassName, `${internalClassName}-le--${theme}`)}>
        <div className={"columnContainer"}>
          <h4 className={cs('componentTitle', `componentTitle-le--${theme}`)}>
            {translationsProfile.contactDataHeading()}
          </h4>
        </div>
        <div>{errorShow && error?.errorData && error?.errorData?.length > 0 && <Alert errors={error?.errorData}/>}</div>
        <div className={"columnContainer"}>
          <table className={`${internalClassName}-table`}>
            <tr className={`${internalClassName}-table-header`}>
              <th></th>
              <th>{translationsProfile.contactDataLabelPortal()}</th>
              <th>{translationsProfile.contactDataLabelBill()}</th>
            </tr>
            <tbody>
            {itemData.map((item, index) => <ContactDataItem key={index} formik={formik} handleChange={formik.handleChange} label={item.label} name={item.name} switches={item.switches} activeBillSwitchCounter={activeBillSwitchCounter}
                                                            setActiveBillSwitchCounter={setActiveBillSwitchCounter} isPhone={item.phone}/>)}
            </tbody>
          </table>
        </div>
        <div className={'columnContainer actions'}>
          <CpxButton type="submit">
            {translationsProfile.contactDataSaveButton()}
          </CpxButton>
        </div>
      </article>
    </CpxFormikForm>
  )
}
