import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import commonStyles from '../../../../sass/base/common.module.scss';
import { ReactComponent as Building } from '../../../../assets/icons/building.svg';
import { ReactComponent as Phone } from '../../../../assets/icons/phone_white.svg';
import { ReactComponent as World } from '../../../../assets/icons/world.svg';
import { routes } from '../../../../constants/routes/routes';
import { TRANSLATIONS } from '../../../../constants/transitions/uiTranslations';
import { useGetAmountOfNumbers, useGetAmountOfSites, useTranslations, } from '../../../../utils/helper/utils';
import { FEATURES_FLAG, ID_CONTRACT_TELEPHONE_CONNECTION, ID_MAIN_CONTRACT, } from '../../../../constants/configs/config.constants';
import './overview.scss';
import { useTheme } from '../../../../../core/utility/themeContext';
import cs from 'classnames';
import { IContracts, IPartnerCustomer } from "../../../../constants/types/types.constants";
import { apiCallAction } from "../../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../../constants/action.constants";
import { PortalContractData } from "compax-api";
import { selectCurrentLanguage } from "../../../../../core/uiLanguage/lang.slice";
import { ChangeProductModal, pages } from "../../productChange/changeProductModal/changeProductModal.component";
import { useSelectedMainContract, useSelectFeatures, useSelectLang, useSelectPartnerCustomer } from "../../../../redux/store";
import { CpxButton } from "../../../../../core/components/button.component";


type Props = {
  contracts: IContracts;
};

export const NfoOverview = ({ contracts }: Props) => {
  const dispatch = useDispatch();
  const translations = useTranslations(TRANSLATIONS.myContract);
  const translationsProductChange = useTranslations(TRANSLATIONS.changeProductModal)
  const theme = useTheme();
  const internalClassName = 'overview';

  const features = useSelectFeatures();
  const sites = useGetAmountOfSites();
  const numbers = useGetAmountOfNumbers();
  const connectionsLength =
    contracts && Array.isArray(contracts) && contracts.filter(contract => contract.serviceType?.id + '' === ID_CONTRACT_TELEPHONE_CONNECTION).length;
  const mainContract: PortalContractData = contracts && Array.isArray(contracts) ?
    contracts.filter(contract => contract.serviceType?.id + '' === ID_MAIN_CONTRACT)[0] : {};
  const currentLanguage = selectCurrentLanguage(useSelectLang());
  const selectedPartnerCustomer: IPartnerCustomer = useSelectPartnerCustomer() as IPartnerCustomer;
  const selectedMainContract = useSelectedMainContract();

  const [changeModal, setChangeModal] = useState({ isShowing: false, page: pages.SELECT_OPTION });

  useEffect(() => {
    if(selectedMainContract?.contractId){
      dispatch(apiCallAction(ACTION_CONST.API_GET_CLIS, { contractId: selectedMainContract?.id, selectedPartnerCustomerId: selectedPartnerCustomer?.id }));
      dispatch(apiCallAction(ACTION_CONST.API_GET_ADDRESSES,  { language: currentLanguage, selectedPartnerCustomerId: selectedPartnerCustomer?.id}));
    }
  }, [dispatch, currentLanguage, selectedMainContract]);

  useEffect(() => {
    dispatch(apiCallAction(ACTION_CONST.API_PORTAL_FEATURE, { featureName: FEATURES_FLAG.PORTAL_PRODUCT_CHANGE}));
  }, []);

  return (
    <>
      {changeModal.isShowing && <ChangeProductModal changeModal={changeModal} setChangeModal={setChangeModal}/>}
      <article
        className={cs(internalClassName, `${internalClassName}-le--${theme}`)}
      >
        <div className={cs(`${internalClassName}-header`, `${internalClassName}-header-le--${theme}`)}>
          <h4 className={cs(`${internalClassName}-contractTitle-le--${theme}`)}>{mainContract?.contractName} ({mainContract?.knumber})</h4>
          {features.PORTAL_PRODUCT_CHANGE && mainContract?.productWithPossibleSuccessor && selectedMainContract?.status?.id === 1 &&
            <CpxButton buttonType={"white"} onClick={() => setChangeModal({ isShowing: true, page: 1 })}>{translationsProductChange.upgrade()}</CpxButton>}
        </div>

          <section>
            <h1 className={commonStyles.bigFigure}>{connectionsLength}</h1>
            <h5 className={cs(`figureCaption`, `figureCaption-le--${theme}`)}>
              <World className={`figureCaption-icons-le--${theme}`}/>
              {translations.overviewConnections()}
            </h5>
          </section>
          <section>
            <h1 className={commonStyles.bigFigure}>{sites}</h1>
            <h5 className={cs(`figureCaption`, `figureCaption-le--${theme}`)}>
              <Building className={`figureCaption-icons-le--${theme}`}/>
              {translations.overviewLocations()}
            </h5>
          </section>
          <section>
            <h1 className={commonStyles.bigFigure}>{numbers}</h1>
            <h5 className={cs(`figureCaption`, `figureCaption-le--${theme}`)}>
              <Phone className={`figureCaption-icons-le--${theme}`}/>
              {translations.overviewNumbers()}
            </h5>
          </section>
          <CpxButton
        buttonType={'moreLink'}
        className={cs(`${internalClassName}-moreLink`, `${internalClassName}-moreLink-le--${theme}`, `white-override`)}
            to={routes.customerContractDetails.path}

          >
            {translations.moreLink()}
          </CpxButton>
      </article>
    </>
);
};
