import { ACTION_CONST } from "../../constants/action.constants";
import { Basket, OptionGroup, SignUpResponse } from 'compax-api';
import { IAction } from "../../constants/types/types.constants";

const initialState = {
  basket: {} as Basket,
  possibleOptions: [] as Array<OptionGroup>,
  startDtRequested: String,
  result: {} as SignUpResponse,
};

function editSipTrunkReducer(state = initialState, action: IAction) {
  switch (action.type) {
    case ACTION_CONST.API_OST_GET_PREPARE_CHANGE:
      state = {
        ...state,
        basket: action.data.basket,
        possibleOptions: action.data.possibleOptions,
        startDtRequested: action.data.startDtRequested,
      };
      break;
    case ACTION_CONST.API_BASKET_PUT_ADD_OPTION:
      state = {
        ...state,
        basket: action.data,
      };
      break;
    case ACTION_CONST.API_BASKET_DELETE_OPTION:
      state = {
        ...state,
        basket: action.data,
      };
      break;
    case ACTION_CONST.API_OST_POST_SUBMIT_CHANGE:
      state = {
        ...state,
        result: action.data,
      };
      break;
  }
  return state;
}

export default editSipTrunkReducer;
