import { ACTION_CONST } from "../../constants/action.constants";
import { IAction } from "../../constants/types/types.constants";

const initialState: never[] = [];

function changeProductReducer(state = initialState, action: IAction) {
  switch (action.type) {
    case ACTION_CONST.SAVE_CHANGE_PRODUCT_BASKET:
      return action.data;
      break;
    default:
      return state;
  }
}

export default changeProductReducer;
