import React from 'react';
import { TRANSLATIONS } from '../../../constants/transitions/uiTranslations';
import { useTranslations } from '../../../utils/helper/utils';
import { CpxTitle } from '../../../../core/components/title/title.component';

export const NfoTasksPartnerPage = () => {
  const translations = useTranslations(TRANSLATIONS.partnerPages.tasks);
  const internalClassName = 'partner-tasks';

  return (
    <>
      <CpxTitle
        title={translations.title()}
        internalClassName={internalClassName}
      />
    </>
  );
};
