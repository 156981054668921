import {LEGAL_ENTITIES} from "../../portal/constants/configs/config.constants";
import {useTheme} from "../utility/themeContext";
import {ReactComponent as ValidCalendar} from '../../portal/assets/icons/valid-calendar.svg';
import {ReactComponent as ValidCalendarWhite} from '../../portal/assets/icons/valid-calendar-white.svg';

type Props = {
  className?: string;
  whiteOverride?: boolean;
};

export const CpxValid = ({ className, whiteOverride }: Props) => {
  const le = useTheme();

  if (le === LEGAL_ENTITIES.DTS) {
    return <ValidCalendar className={className}/>
  } else {
    if (whiteOverride) {
      return <ValidCalendarWhite className={className} />
    } else {
      return <ValidCalendarWhite className={className} />
    }
  }
};
