import cs from "classnames";
import { CpxButton } from "../../../../../../core/components/button.component";
import { CpxIcon } from "../../../../../../core/components/icon.component";
import { ICONS, TELEPHONE_CONNECTIONS_IDS } from "../../../../../constants/configs/config.constants";
import { CallNumberBlockConfigurationItem } from "./callNumberBlockConfigurationItem";
import React, { useState } from "react";
import { useTheme } from "../../../../../../core/utility/themeContext";
import { useOrderEntryBasket, useStep3Data } from "../../../../../redux/store";
import { NumbersForm } from "../productOptionModal/numbersForm/numbersForm.component";
import { ProductOptionModalType } from "../../../../../constants/types/types.constants";
import { Basket, BasketEntry, SelectableBasketOption } from "compax-api";

type Props = {
  product?: any;
  tabIndex: number;
  disabled?: boolean;
  className?: string;
  active?: boolean;
  isIncluded?: boolean;
  title?: string;
  onClickSelectBox: any;
  selectOptionOfOption: () => Promise<Basket>;
  option: any;
}

export const SelectBoxCallNumberBlockComponent = ({ product, tabIndex, disabled, className, active, isIncluded, title, onClickSelectBox, selectOptionOfOption, option}: Props) => {
  const theme = useTheme();
  const basket = useOrderEntryBasket();
  const internalClassName = "selectBox";
  const step3Data = useStep3Data();

  const [showModal, setShowModal] = useState(false);
  const [edit, setEdit] = useState({
    isActive: false,
    productId: NaN,
  });

  const [addNumber, setAddNumber] = useState<boolean>(false)

  const getBasketData = () => {
    const callNumberObjectBasket = basket?.entries[step3Data?.activeIndex]?.options
      .filter((item: BasketEntry) => TELEPHONE_CONNECTIONS_IDS.includes(item.product.id))[0].options
      .filter((option: SelectableBasketOption) => option.product.id === product?.id)
      .map((option: BasketEntry) => option.telcoData);

    return callNumberObjectBasket;
  }

  const getBasketDataByProductId = (productId: number) => {
    const callNumberObjectBasket = basket?.entries[step3Data?.activeIndex]?.options
      .filter((item: BasketEntry) => TELEPHONE_CONNECTIONS_IDS.includes(item.product.id))[0].options
      .filter((option: SelectableBasketOption) => option.product.id === productId);

    return callNumberObjectBasket;
  }

  const editCallNumberBlock = (productId: number) => {
    setShowModal(true);
    setAddNumber(false)
    setEdit({ isActive: true, productId });
  }

  const addCallNumberBlock = (productId: number) => {
    setShowModal(true);
    setAddNumber(true);
    setEdit({ isActive: true, productId });
  }

  return (
    <>
      {showModal &&
        <div>
          <NumbersForm setShowModal={setShowModal} option={option} type={ProductOptionModalType.TELCO_DATA} selectOptionOfOption={selectOptionOfOption} getBasketDataByProductId={getBasketDataByProductId} edit={edit} setEdit={setEdit} addNumber={addNumber}
                       onClickSelectBox={onClickSelectBox}/>
        </div>
      }
      <div
        className={cs(internalClassName, `${internalClassName}-le--${theme}`, className && className, `${active ? "active-le--" + theme : ""} large large-le--${theme}`, `${disabled ? "disabled" : ""}`)}
        tabIndex={tabIndex}
      >
        <div className={cs('innerBox', `innerBox-le--${theme}`)} id={'editBox'}>
          <div className={cs('selectBox_header')}>
            <div>
              <h4 className={cs('selectBox_header__title', `selectBox_header__title-le--${theme}`)}>{title}</h4>
            </div>
            <div className={cs('selectBox_header__actions')}>
              <CpxButton className={"iconOnly"} type={'button'} onClick={() => addCallNumberBlock(option.product.id)}><CpxIcon icon={ICONS.ADD} className={cs(`selectBox_header__actions-icon`, `selectBox_header__actions-icon-le--${theme}`)}/></CpxButton>
              <CpxButton className={"iconOnly"} type={'button'} onClick={() => editCallNumberBlock(option.product.id)}><CpxIcon icon={ICONS.EDIT} className={cs(`selectBox_header__actions-icon`, `selectBox_header__actions-icon-le--${theme}`)}/></CpxButton>
              <CpxButton className={"iconOnly"} type={'button'} onClick={(disabled || isIncluded) ? (() => {
              }) : onClickSelectBox}><CpxIcon icon={ICONS.DELETE} className={cs(`selectBox_header__actions-icon`, `selectBox_header__actions-icon-le--${theme}`)}/></CpxButton>
            </div>
          </div>
          <div>
            {
              basket?.entries[step3Data?.activeIndex]?.options.filter((item: BasketEntry) => TELEPHONE_CONNECTIONS_IDS.includes(item.product.id)).length > 0 && getBasketData().map((callNumberItem: any, index: number) => (
                <CallNumberBlockConfigurationItem key={index} site={callNumberItem?.siteAddress} areaCode={callNumberItem?.newPhoneNumberBlock?.areaCode ||  callNumberItem?.newPhoneNumber?.areaCode || callNumberItem?.newLnpPhoneNumberBlock?.areaCode} telcoData={callNumberItem}/>
              ))
            }
          </div>
        </div>
      </div>
    </>
  )
}