import cs from "classnames";
import React, { useEffect, useState } from "react";
import { CpxModal } from "../../../../../core/components/modal.component";
import { useTheme } from "../../../../../core/utility/themeContext";
import { CpxDropdownWithLabel } from "../../../../../core/components/dropdown.component";
import { SelectOption, ToastTypes } from "../../../../constants/types/types.constants";
import { useTranslations } from "../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";
import { useFormik } from "formik";
import { useSelectBillMedia, useSelectError, useSelectLang, useSelectPaymentMode, useSelectUserRole } from "../../../../redux/store";
import { selectCurrentLanguage } from "../../../../../core/uiLanguage/lang.slice";
import { CpxFormikForm } from "../../../../../core/components/formikForm.component";
import { CpxInputWithLabel } from "../../../../../core/components/inputWithLabel.component";
import { CpxIbanInputWithLabel } from "../../../../../core/components/ibanInputWithLabel.component";
import { apiCallAction } from "../../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../../constants/action.constants";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { Account, BankAccount, PaymentMean } from "compax-api";
import { bicCheck, createValidatorSchema, ibanCheck, MaxLengthCheck, requiredCheck } from "../../../../utils/validation";
import { INPUT_MAX_LENGTHS, USER_CUSTOMER, USER_PARTNER } from "../../../../constants/configs/config.constants";
import { Alert } from "../../../common/Alert/Alert.component";
import { clearError } from "../../../../redux/actions/error.action";
import { useToast } from "../../../common/header/toast/toast.component";

type Props = {
  setShowModal: Function;
  billingAccount: Account;
}

type AppDispatch = ThunkDispatch<any, any, AnyAction>;

export const EditBillAccountModalComponent = ({ setShowModal, billingAccount }: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const theme = useTheme();
  const translationsCommon = useTranslations(TRANSLATIONS.common);
  const translation = useTranslations(TRANSLATIONS.stepper.newCustomer);
  const translations = useTranslations(TRANSLATIONS.myData);
  const internalClassName = "editBillAccountModal";
  const currentLang = selectCurrentLanguage(useSelectLang());
  const billMedia = useSelectBillMedia();
  const paymentModes = useSelectPaymentMode();
  const requiredMsg = translation.fieldRequired();
  const userRole = useSelectUserRole();
  const error = useSelectError();
  const [errorShow, setErrorShow] = useState(false);
  const { showToast } = useToast();

  useEffect(() => {
    dispatch(apiCallAction(ACTION_CONST.API_GET_BILL_MEDIA));
    dispatch(apiCallAction(ACTION_CONST.API_GET_PAYMENT_MODE));
  }, [currentLang]);

    const [isSEPA, setIsSEPA] = useState(true);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);

  const DEFAULT_SELECT_OPTION: SelectOption = {
    id: '',
    name: translationsCommon.defaultSelect(),
  }
  const paymentOptions = Array.isArray(paymentModes) ? paymentModes.filter(paymentMode => paymentMode.id === 1)
      .map(paymentMode => {
        return { name: paymentMode.description, id: paymentMode.id }
      })
    : [];

  const billMediaOptions = Array.isArray(billMedia) ? billMedia.map(billMedia => {
    return { name: billMedia.description, id: billMedia.id }
  }) : [];

  const onChangePaymentMode = (e: any) => {
    formik.handleChange(e);
    if(e.target.value === "1"){
      setIsSEPA(true);
    } else if(e.target.value === "2") {
      setIsSEPA(false);
    }
  }

  const compareBillingAccountObject = {
    account: {
      accountName: billingAccount?.paymentMean?.bankAccount?.accountHolder,
      iban: billingAccount?.paymentMean?.bankAccount?.iban,
      bic: billingAccount?.paymentMean?.bankAccount?.bic,
      paymentMode: {
        id: billingAccount?.paymentMode?.id,
        description: null,
      },
      paymentMedia: {
        id: billingAccount?.billmedia?.id
      }
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      account: {
        accountName: billingAccount?.paymentMean?.bankAccount?.accountHolder || '',
        iban: billingAccount?.paymentMean?.bankAccount?.iban || '',
        bic: billingAccount?.paymentMean?.bankAccount?.bic || '',
        paymentMode: {
          id: paymentOptions[0]?.id,
          description: null
        },
        paymentMedia: {
          id: billingAccount?.billmedia?.id || billMediaOptions[-1]?.id,
        },
      },
    },
    validationSchema: createValidatorSchema({
      account: createValidatorSchema({
        accountName: MaxLengthCheck(INPUT_MAX_LENGTHS.account.accountName, isSEPA && requiredCheck(requiredMsg)),
        iban: isSEPA && ibanCheck(requiredMsg, translation.ibanSyntax(), translation.validEUSepa()),
        bic: isSEPA && bicCheck(requiredMsg, translation.bicSyntax()),
        paymentMode: createValidatorSchema({
          id: requiredCheck(requiredMsg),
        }),
        paymentMedia: createValidatorSchema({
          id: requiredCheck(requiredMsg),
        }),
      })
    }),
    onSubmit: async (values: any) => {
      let bankAccount: BankAccount = {};

      bankAccount.accountHolder = values.account.accountName;
      bankAccount.iban = values.account.iban;
      bankAccount.bic = values.account.bic;

      let paymentMean: PaymentMean = {
        bankAccount: bankAccount,
        paymentMode: values.account.paymentMode
      };

      let  account: Account = {
        id: billingAccount.id,
        paymentMean: paymentMean,
        paymentMode: values.account.paymentMode,
        currency: billingAccount.currency,
        billmedia: billingAccount.billmedia
      };

      dispatch(apiCallAction(ACTION_CONST.API_POST_CUSTOMER_BANK_ACCOUNT, account, true))
        .then(() => {
          dispatch(apiCallAction(ACTION_CONST.API_GET_ACCOUNTS));
          dispatch(clearError());
          setErrorShow(false);
          setIsSuccessful(true);
          showToast(ToastTypes.SUCCESS, translations.editBillAccountSuccessHeading(), translations.editBillAccountSuccess());
          setShowModal(false)
        })
        .catch(() => {
          setErrorShow(true);
        })
    }
  });

  return (
    <CpxFormikForm handleSubmit={formik.handleSubmit}
                   initialValues={formik.initialValues}
                   id={'edit-bill-account-form'}
    >
      { showConfirmationModal &&
        <CpxModal
          onConfirm={() => setShowModal(false)}
          confirmText={translationsCommon.close()}
        >
          {isSuccessful ?
            <>
              <h3 className={cs('modalTitle', `modalTitle-le--${theme}`)}>
                {translations.editBillAccountSuccessHeading()}
              </h3>
              <p className={`${internalClassName}-modalSubTitle`}>
                {translations.editBillAccountSuccess()}
              </p>
            </>
            :
            <>
              <h3 className={cs('modalTitle', `modalTitle-le--${theme}`)}>
                {translations.editBillAccountFailedHeading()}
              </h3>
              <p className={`${internalClassName}-modalSubTitle`}>
                {translations.editBillAccountFailed()}
              </p>
            </>
        }
      </CpxModal>}
      { !showConfirmationModal && <CpxModal
        onConfirm={() => formik.handleSubmit()}
        onCancel={() => {
          dispatch(clearError());
          setErrorShow(false);
          setShowModal(false);
        }}
        className={internalClassName}
        disableButton={JSON.stringify(compareBillingAccountObject) === JSON.stringify(formik.values)}
      >
        <h3 className={cs('modalTitle', `modalTitle-le--${theme}`)}>
          {translations.editBillAccount()}
        </h3>
        <h4 className={`subtitle subtitle-le--${theme}`}>
          {userRole === USER_CUSTOMER && translations.payment()}
          {userRole === USER_PARTNER && (billingAccount?.debit ? translations.partnerDebitAccount() : translations.partnerCreditAccount())}
        </h4>

        <div className={`${internalClassName}-container`}>
          <div className={`${internalClassName}-ibanInputGroup`}>
            <CpxDropdownWithLabel
              id="account.paymentMode.id"
              name="account.paymentMode.id"
              defaultOption={paymentOptions[0]}
              options={paymentOptions}
              disabled={true}
              value={formik.values.account?.paymentMode?.id}
              onChange={onChangePaymentMode}
              error={formik.errors?.account?.paymentMode?.id}
            >
              {translation.paymentOption() + ' *'}
            </CpxDropdownWithLabel>
          </div>
          <CpxDropdownWithLabel
            id="account.paymentMedia.id"
            name="account.paymentMedia.id"
            defaultOption={DEFAULT_SELECT_OPTION}
            options={billMediaOptions}
            disabled={true}
            value={formik.values.account?.paymentMedia?.id}
            labelClassName={"label-helpBox"}
            onChange={formik.handleChange}
            error={formik.errors?.account?.paymentMedia?.id}
            required
          >
            {translation.billingMedium()}
          </CpxDropdownWithLabel>
          {isSEPA && (
            <>
              <CpxInputWithLabel
                id="account.accountName"
                type="text"
                onChange={formik.handleChange}
                value={formik.values?.account?.accountName.trimStart()}
                error={formik.touched?.account?.accountName && formik.errors?.account?.accountName}
                required={isSEPA}
              >
                {translation.accountOwner()}
              </CpxInputWithLabel>
              <CpxIbanInputWithLabel
                id="account.iban"
                type="text"
                onChange={formik.handleChange}
                value={formik.values?.account?.iban.trimStart()}
                error={formik.touched?.account?.iban && formik.errors?.account?.iban}
                required={isSEPA}
              >
                {translation.iban()}
              </CpxIbanInputWithLabel>
              <CpxInputWithLabel
                id="account.bic"
                type="text"
                onChange={formik.handleChange}
                value={formik.values?.account?.bic.trimStart()}
                error={formik.touched?.account?.bic && formik.errors?.account?.bic}
                required={isSEPA}
              >
                {translation.bic()}
              </CpxInputWithLabel>
              {errorShow && error?.errorData && error?.errorData?.length > 0 && <Alert errors={error?.errorData}/>}
            </>
          )}
        </div>
      </CpxModal>}
    </CpxFormikForm>
  )
}