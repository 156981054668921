import React, { useEffect, useState } from "react";
import { useTheme } from '../../../../core/utility/themeContext';
import { CUSTOMER_QUICKSEARCH_RESULTS_LIMIT, ICONS } from "../../../constants/configs/config.constants";
import { CpxIcon } from "../../../../core/components/icon.component";
import { useTranslations } from "../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../constants/transitions/uiTranslations";
import { useSelectPartnerCustomers } from "../../../redux/store";
import useSearch from "../../../utils/helper/useSearch";
import { saveSelectedPartnerCustomer } from "../../../redux/actions/partnerCustomers.action";
import { sortByStringParameter } from "../../../../core/utils";
import { routes } from "../../../constants/routes/routes";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { CpxButton } from "../../../../core/components/button.component";
import cs from 'classnames';
import './navigation.scss';


type Props = {
  id?: string;
  onClickOnResult: (collapse: boolean) => void;
}

export const NfoPartnerCustomerSearch = ({ id, onClickOnResult, ...props }: Props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const translations = useTranslations(TRANSLATIONS.common);
  const partnerCustomers = useSelectPartnerCustomers() as [];
  const [filteredCustomers, searchValue, setSearch] = useSearch(partnerCustomers, ['companyName']);
  const [dropdown, setDropdown] = useState();

  useEffect(() => {
    setDropdown(generatePartnerCustomerLinks());
  }, [filteredCustomers]);

  const onClickPartnerCustomer = (e: any, partnerCustomerData: any) => {
    onClickOnResult(true);
    dispatch(saveSelectedPartnerCustomer(partnerCustomerData));
    history.push(routes.partnerCustomersOverview.path);
  };

  const generatePartnerCustomerLinks: any = () => {
    let customerLinks: any[] = [];
    if (filteredCustomers.length > 0) {
      const sortedCustomerList = sortByStringParameter(Array.from(filteredCustomers), "companyName");
      const shortenedCustomerList = sortedCustomerList.splice(0, CUSTOMER_QUICKSEARCH_RESULTS_LIMIT);
      customerLinks = shortenedCustomerList.map((customer: any) => {
        return (
          <CpxButton
            key={customer.customerId}
            className={cs(
              'quickSearchButton',
              `quickSearchButton-le--${theme}`
            )}
            onClick={e => onClickPartnerCustomer(e, customer)}
          >
            {customer.companyName}
          </CpxButton>
        );
      });
    }
    return customerLinks;
  }

  const onInputChange = (event: any) => {
    const valueWithoutSpaces = event.target.value.toString().replace(/\ /g, '');
    if (!(event.target.value.length === 1 && valueWithoutSpaces.length === 0)) {
      setSearch(event);
    }
  }

  return (
    <>
      <div className={cs(`search`, `search-le--${theme}`)}>
        {searchValue && (
          <div onClick={() => setSearch('')}>
            <CpxIcon icon={ICONS.CLOSE}/>
          </div>
        )}
        <input className={cs(
          'searchCustomer',
          `searchCustomer-le--${theme}`
        )}
               placeholder={translations.quickSearch()}
               value={searchValue}
               onChange={onInputChange}
               autoFocus={true}
        />
      </div>
      {searchValue.length > 0 && (
        <div className={cs(`quickSearchResults`, `quickSearchResults-le--${theme}`)}>
          {dropdown && dropdown}
        </div>
      )}
    </>
  );
};
