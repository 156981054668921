import { FEATURES_FLAG, ID_CONTRACT_SIP_TRUNK, ID_CONTRACT_TELEPHONE_CONNECTION, } from '../../../../../constants/configs/config.constants';
import './mainPartnerContractDetails.scss';
import React, { useEffect, useState } from 'react';
import { useTheme } from '../../../../../../core/utility/themeContext';
import cs from 'classnames';
import { NfoPartnerSipTrunkBox } from '../partnerSipTrunk/partnerSipTrunk.component';
import { NfoPartnerTelephoneConnection } from '../partnerTelephoneConnection/partnerTelephoneConnection.component';
import { IPartnerCustomerContracts, } from '../../../../../constants/types/types.constants';
import { NfoMainPartnerContractInfo } from '../mainPartnerContractInfo/mainPartnerContractInfo.component';
import { PortalContractData } from "compax-api";
import { CpxButton } from "../../../../../../core/components/button.component";
import { useSelectedMainContract, useSelectFeatures } from "../../../../../redux/store";
import { ChangeProductModal, pages } from "../../../../customer/productChange/changeProductModal/changeProductModal.component";
import { apiCallAction } from "../../../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../../../constants/action.constants";
import { useDispatch } from "react-redux";
import { useTranslations } from "../../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../../constants/transitions/uiTranslations";

type Props = {
    mainContract: PortalContractData;
    contracts: IPartnerCustomerContracts;
};

export const NfoMainPartnerContract = ({ mainContract, contracts }: Props) => {
    const theme = useTheme();
    const internalClassName = 'mainContractDetails';
    const features = useSelectFeatures();
    const [changeModal, setChangeModal] = useState({ isShowing: false, page: pages.SELECT_OPTION });
    const dispatch = useDispatch();
    const translationsProductChange = useTranslations(TRANSLATIONS.changeProductModal);
    const selectedMainContract = useSelectedMainContract();

    const sipTrunkOptions =
        contracts !== undefined &&
        contracts.filter(entry =>
            entry.componentOf === mainContract.id &&
            entry?.serviceType?.id + '' === ID_CONTRACT_SIP_TRUNK
        );

    const telephoneConnections =
        contracts !== undefined &&
        contracts.filter(entry =>
            entry.componentOf === mainContract.id &&
            entry?.serviceType?.id + '' === ID_CONTRACT_TELEPHONE_CONNECTION
        );

    useEffect(() => {
        dispatch(apiCallAction(ACTION_CONST.API_PORTAL_FEATURE, { featureName: FEATURES_FLAG.PORTAL_PRODUCT_CHANGE}));
    }, []);

    return (
        <>
            {changeModal.isShowing && <ChangeProductModal changeModal={changeModal} setChangeModal={setChangeModal}/>}
            <article
              className={cs(internalClassName, `${internalClassName}-le--${theme}`)}
            >
                <div className={cs(`${internalClassName}-header`, `${internalClassName}-header-le--${theme}`)}>
                    <h4 className={cs(`${internalClassName}-mainContract-le--${theme}`)}>{mainContract.contractName}</h4>

                    {features.PORTAL_PRODUCT_CHANGE && mainContract?.productWithPossibleSuccessor && selectedMainContract?.status?.id === 1 &&
                      <CpxButton buttonType={"white"} onClick={() => setChangeModal({ isShowing: true, page: 1 })}>{translationsProductChange.upgrade()}</CpxButton>}
                </div>
                <NfoMainPartnerContractInfo
                  key={mainContract.id}
                  contract={mainContract}
                />
            </article>
            {sipTrunkOptions &&
              sipTrunkOptions.map((sipTrunk, index) => {
                  return (
                        <NfoPartnerSipTrunkBox
                            key={sipTrunk.id}
                            sipTrunkContract={sipTrunk}
                            countSip={index}
                            contracts={contracts}
                        />
                    );
                })}
            {telephoneConnections &&
                telephoneConnections.map((telephoneConnection, index) => {
                    return (
                        <NfoPartnerTelephoneConnection
                            key={telephoneConnection.id}
                            telephoneConnection={telephoneConnection}
                            contracts={contracts}
                        />
                    );
                })}
        </>
    );
};
