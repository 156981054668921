import React, { useEffect, useState } from 'react';
import { CpxModal } from '../../../../../../core/components/modal.component';
import { useTheme } from '../../../../../../core/utility/themeContext';
import { useTranslations } from '../../../../../utils/helper/utils';
import { TRANSLATIONS } from '../../../../../constants/transitions/uiTranslations';
import { CpxSelectBox } from '../../../../../../core/components/selectBox.component';
import { NfoOrderCallNumberPageTwo } from "../orderCallNumberPageTwo/orderCallNumberPageTwo.component";
import { NfoOrderCallNumberSummary } from "../orderCallNumberSummary/orderCallNumberSummary.component";
import cs from 'classnames';
import './numberOrder.scss';
import { deleteOCNStepData } from "../../../../../redux/actions/numberOrder.action";
import { useDispatch } from "react-redux";
import { NfoOrderPortingPageTwo } from "../orderPortingPageTwo/orderPortingPageTwo.component";
import { NfoOrderPortingSummary } from "../orderPortingSummary/orderPortingSummary.component";
import { useToast } from "../../../../../components/common/header/toast/toast.component";
import { ToastTypes } from "../../../../../constants/types/types.constants";

export const NfoNumbersOrder = ({ confirmModal, customerId, loadNumbers }: any) => {
  const internalClassName = "numberOrder";
  const theme = useTheme();
  const translations = useTranslations(TRANSLATIONS.orderCallNumberModal);
  const dispatch = useDispatch();
  const { showToast } = useToast();

  const [newSelected, setNewSelected] = useState(false);
  const [portedSelected, setPortedSelected] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [editModal, setEditModal] = useState(true);
  const [error, setError] = useState('');
  const [orderCallNumberPageTwoModal, setOrderCallNumberPageTwoModal] = useState(false);
  const [orderCallNumberSummaryModal, setOrderCallNumberSummaryModal] = useState(false);
  const [orderPortingSummaryModal, setOrderPortingSummaryModal] = useState(false);
  const [orderCallNumberConfirmationModal, setOrderCallNumberConfirmationModal] = useState(false);
  const [orderPortingPageTwoModal, setOrderPortingPageTwoModal] = useState(false);
  const [connection, setConnection] = useState('');
  const [address, setAddress] = useState('');
  const [product, setProduct] = useState('');
  const [numbers, setNumbers] = useState<string[]>([]);
  const [isSuccess, setIsSuccess] = useState(false);

  const confirmToPageTwo = () => {
    portedSelected ? setOrderPortingPageTwoModal(true) : setOrderCallNumberPageTwoModal(true);
    setEditModal(false);
  };

  const confirmToSummaryPage = (connection: string, address: string, product: string, numbers: Array<string>) => {
    setConnection(connection);
    setAddress(address);
    setProduct(product);
    setNumbers(numbers);
    portedSelected ? setOrderPortingSummaryModal(true): setOrderCallNumberSummaryModal(true);
    portedSelected ? setOrderPortingPageTwoModal(false) : setOrderCallNumberPageTwoModal(false);
  }

  const confirmToConfirmationPage = (isSuccess: boolean) => {
    setIsSuccess(isSuccess);
    setOrderCallNumberConfirmationModal(true);
    portedSelected ? setOrderPortingSummaryModal(false) : setOrderCallNumberSummaryModal(false);
  }

  const backFromPageTwo = () => {
    setEditModal(true);
    portedSelected ? setOrderPortingPageTwoModal(false) : setOrderCallNumberPageTwoModal(false);

  };

  const backFromSummaryPage = () => {
    portedSelected ? setOrderPortingPageTwoModal(true) : setOrderCallNumberPageTwoModal(true);
    portedSelected ? setOrderPortingSummaryModal(false) : setOrderCallNumberSummaryModal(false);
  }

  const selectPortingNumber = () => {
    setPortedSelected(true);
    setNewSelected(false);
    setDisabled(false);
  };

  const selectNewNumber = () => {
    setNewSelected(true);
    setPortedSelected(false);
    setDisabled(false);
  };

  useEffect(() => {
    if (orderCallNumberConfirmationModal) {
      if (isSuccess) {
        showToast(ToastTypes.SUCCESS, translations.orderSuccess());
        loadNumbers();
      } else {
        showToast(ToastTypes.ERROR, translations.orderError(), translations.tryAgain());
      }
      confirmModal(false);
      setEditModal(true);
    }
  }, [orderCallNumberConfirmationModal, isSuccess])

  return (
    <>
      <>
        {orderCallNumberSummaryModal && (
          <NfoOrderCallNumberSummary
            onConfirm={(isSuccess: boolean) => confirmToConfirmationPage(isSuccess)}
            backToPreviousPage={() => backFromSummaryPage()}
            cancelModal={confirmModal}
            connection={connection}
            address={address}
            product={product}
            customerId={customerId}
          />
        )}
        {orderPortingSummaryModal && (
          <NfoOrderPortingSummary
            onConfirm={(isSuccess: boolean) => confirmToConfirmationPage(isSuccess)}
            backToPreviousPage={() => backFromSummaryPage()}
            cancelModal={confirmModal}
            connection={connection}
            address={address}
            product={product}
            customerId={customerId}
            numbers={numbers}
          />
        )}
        {orderCallNumberPageTwoModal && (
          <NfoOrderCallNumberPageTwo
            onConfirm={(connection: string, address: string, product: string) => confirmToSummaryPage(connection, address, product, [])}
            backToPreviousPage={() => backFromPageTwo()}
            cancelModal={confirmModal}
            customerId={customerId}
          />
        )}
        {orderPortingPageTwoModal && (
          <NfoOrderPortingPageTwo
            onConfirm={(connection: string, address: string, product: string, numbers: Array<string>) => confirmToSummaryPage(connection, address, product, numbers)}
            backToPreviousPage={() => backFromPageTwo()}
            cancelModal={confirmModal}
            customerId={customerId}
          />
        )}
      </>
      <>
        {editModal && (
          <CpxModal
            onConfirm={() => confirmToPageTwo()}
            confirmText={translations.next()}
            onCancel={() => {
              confirmModal(false);
              dispatch(deleteOCNStepData());
            }}
            disableButton={disabled}
          >
            <h3 className={cs('modalTitle', `modalTitle-le--${theme}`)}>
              {translations.title()}
            </h3>
            <p className={cs(`modalInfoText modalInfoText-le--${theme}`)}>{translations.subText()}</p>
            <div className={`${internalClassName} modalBoxes`}>
              <CpxSelectBox
                tabIndex={1}
                title={translations.existingNumber()}
                onClick={() => selectPortingNumber()}
                active={portedSelected}
              >
                <p className={'subText'}>{translations.titlePage2Porting()}</p>
              </CpxSelectBox>
              <CpxSelectBox
                tabIndex={2}
                title={translations.newNumber()}
                onClick={() => selectNewNumber()}
                active={newSelected}
              >
              </CpxSelectBox>
            </div>
            <div className={'errorField'}>{error}</div>
          </CpxModal>
        )}
      </>
    </>
  );
};
