import React from 'react';
import { CpxModal } from '../../../../../../core/components/modal.component';
import { useDispatch } from 'react-redux';
import { useTheme } from '../../../../../../core/utility/themeContext';
import { useTranslations } from '../../../../../utils/helper/utils';
import { TRANSLATIONS } from '../../../../../constants/transitions/uiTranslations';
import { useSelectNumberOrder } from '../../../../../redux/store';
import { apiCallAction } from '../../../../../redux/actions/apiCall.action';
import { ACTION_CONST } from '../../../../../constants/action.constants';
import { currency, removeFirstOptionFromBasket } from "../../../../../../core/utils";
import { ReactComponent as Info } from '../../../../../assets/icons/info_green.svg';
import './orderCallNumberSummary.scss';
import cs from 'classnames';
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { deleteOCNStepData } from "../../../../../redux/actions/numberOrder.action";

type AppDispatch = ThunkDispatch<any, any, AnyAction>;

export const NfoOrderCallNumberSummary = ({
                                            onConfirm,
                                            backToPreviousPage,
                                            cancelModal,
                                            connection,
                                            address,
                                            product,
                                            customerId
                                          }: any) => {
  const internalClassName = 'orderCallNumberSummary';
  const theme = useTheme();
  const dispatch: AppDispatch = useDispatch();
  const translations = useTranslations(TRANSLATIONS.orderCallNumberModal);
  const translationsCommon = useTranslations(TRANSLATIONS.common);
  const numberOrder = useSelectNumberOrder();

  const charge = product.charges?.find((charge: any) => charge.chargeType.id === 4);

  const confirmForm = () => {
    if (typeof numberOrder.basket !== "undefined" && typeof numberOrder.basket.id !== "undefined") {
      dispatch(
        apiCallAction(ACTION_CONST.API_OCN_POST_COMPLETE, { basketId: numberOrder.basket.id, selectedPartnerCustomerId: customerId }, true)
      ).then(() => {
        onConfirm(true);
      }).catch(() => {
        onConfirm(false);
      });
      dispatch(deleteOCNStepData())
    }
  }

  const backToPageTwo = () => {
    removeFirstOptionFromBasket(numberOrder.basket, dispatch);
    backToPreviousPage();
  }

  const cancelModalHandler = () => {
    dispatch(deleteOCNStepData())
    removeFirstOptionFromBasket(numberOrder.basket, dispatch);
    cancelModal(false);
  }

  const handleDiscount = () => {
    if (charge?.amountNetDiscounts > 0) {
      return <p>1x {product.displayValue} (<span className={cs('line-through')}>{currency(charge.amountNet, charge.currency.description)}</span> {currency((charge.amountNet - charge.amountNetDiscounts), charge.currency.description)})</p>
    } else {
      return <p>1x {product.displayValue} ({currency(charge.amountNet, charge.currency.description)})</p>
    }
  }

  return (
    <>
      <CpxModal
        onCancel={() => cancelModalHandler()}
        onBack={() => backToPageTwo()}
        onConfirm={() => confirmForm()}
        className={cs(`${internalClassName}`)}
      >
        <h3 className={cs('modalTitle', `modalTitle-le--${theme}`)}>
          {translations.titleSummary()}
        </h3>
        <p className={cs(`modalInfoText modalInfoText-le--${theme}`)}>
          {translations.subTextSummary()}
        </p>
        <hr className={cs(`${internalClassName}-hr`, `${internalClassName}-hr-le--${theme}`)}/>
        <h5 className={`${internalClassName}-section-title-le--${theme}`}>{translations.connection()}</h5>
        <p className={`${internalClassName}-section-subTitle-le--${theme}`}>{connection && connection}</p>
        <br/>
        <h5 className={`${internalClassName}-section-title-le--${theme}`}>{translations.location()}</h5>
        <p className={`${internalClassName}-section-subTitle-le--${theme}`}>{address && address}</p>
        <hr className={cs(`${internalClassName}-hr`, `${internalClassName}-hr-le--${theme}`)}/>
        <h4 className={`${internalClassName}-section-title-le--${theme}`}>{translations.order()}</h4>
        {product && <p className={`${internalClassName}-section-subTitle-le--${theme}`}>
          { handleDiscount() }
        </p>}
        {(charge.amountNetDiscounts > 0) && <div className={cs(`discountInfo ${internalClassName}-discountInfo`, `discountInfo-le--${theme}`)}>
          <Info/>
          <div>
            <p className={cs('infoTitle', `infoTitle-le--${theme}`)}>
              {translationsCommon.discountedInfoText(currency(charge.amountNetDiscounts, charge?.currency?.description) + "")}</p>
          </div>
        </div>}
        <br/>
      </CpxModal>
    </>
  );
};
