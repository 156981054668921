import { CpxModal } from '../../../../../../core/components/modal.component';
import cs from 'classnames';
import { ReactComponent as Negativ } from '../../../../../assets/icons/negativ.svg';
import { ReactComponent as Positiv } from '../../../../../assets/icons/positiv.svg';
import React, { useState } from 'react';
import { useTheme } from '../../../../../../core/utility/themeContext';
import { useTranslations } from '../../../../../utils/helper/utils';
import { TRANSLATIONS } from '../../../../../constants/transitions/uiTranslations';
import './editExtensions.scss';
import { NfoConfirmExtensions } from '../confirmExtensions/confirmExtensions.component';
import { MAX_INCREASE } from '../../../../../constants/configs/config.constants';
import { CpxModalBox } from '../../../../../../core/components/modalBox.component';
import { ExtensionsProgressBar } from '../../extensionsProgressBar/extensionsProgressBar.component';
import { BasicExtensionContract } from "compax-api";

export const NfoEditExtensions = ({ confirmModal, parentId, childProductId, extensions, loadExtensions }: any) => {
  const theme = useTheme();
  const translations = useTranslations(TRANSLATIONS.extensionsModal);

  const [addExtensionsSummaryModal, setAddExtensionsSummaryModal] = useState(false);
  const [selected, setSelected] = useState('');
  const [editModal, setEditModal] = useState(true);
  const [error, setError] = useState('');
  const [increaseValue, setIncreaseValue] = useState('');
  const [reduceValue, setReduceValue] = useState('');
  const [disabled, setDisabled] = useState(true);
  const unusedExtensionIds = extensions.unusedExtensions
    ? extensions.unusedExtensions.map((extension: BasicExtensionContract) => extension.contractId) : [];
  const unusedExtensionsAmount: number = extensions.extensionsUnusedTotal as number;
  const terminatedExtensions = extensions.terminatedExtensions ? extensions.terminatedExtensions.length : 0;

  const confirmToNextPage = () => {
    setEditModal(false);
    setAddExtensionsSummaryModal(true);
  };

  const backFromAddSummary = () => {
    setEditModal(true);
    setAddExtensionsSummaryModal(false);
  };

  const selectReduceBox = () => {
    if (selected !== 'reduce') {
      setSelected('reduce');
      if (reduceValue === '') setDisabled(true);
      setIncreaseValue('');
    }
  };

  const selectIncreaseBox = () => {
    if (selected !== 'increase') {
      setSelected('increase');
      if (increaseValue === '') setDisabled(true);
      setReduceValue('');
    }
  };

  const handleInputValueChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    reduce: boolean
  ) => {
    const { value } = e.target;
    validateInput(reduce, value);
  };

  function validateInput(reduce: boolean | undefined, value: string) {
    const ifReduce = reduce ? unusedExtensionsAmount : MAX_INCREASE;
    reduce ? setReduceValue(value) : setIncreaseValue(value);

    if (unusedExtensionsAmount === 0 && reduce) {
      setError(translations.errorNoExtensions());
      setDisabled(true);
    } else {
      if (Number(value) > 0 && Number(value) <= ifReduce) {
        setDisabled(false);
        setError('');
      } else if (Number(value) === 0) {
        setDisabled(true);
        reduce
          ? setError(translations.errorReduce(String(unusedExtensionsAmount)))
          : setError(translations.errorIncrease());
      } else {
        setDisabled(true);
        reduce
          ? setError(translations.errorReduce(String(unusedExtensionsAmount)))
          : setError(translations.errorIncrease());
      }
    }
  }

  return (
    <>
      <>
        {addExtensionsSummaryModal && (
          <NfoConfirmExtensions
            showModal={confirmModal}
            cancelModal={confirmModal}
            setEditModal={setEditModal}
            backToEditModal={() => backFromAddSummary()}
            inputIncreaseValue={increaseValue}
            inputReduceValue={reduceValue}
            contractId={parentId}
            optionId={childProductId}
            unusedExtensionIds={unusedExtensionIds}
            selected={selected}
            loadExtensions={loadExtensions}
          />
        )}
      </>
      <>
        {editModal && (
          <CpxModal
            onConfirm={() => confirmToNextPage()}
            onCancel={() => confirmModal(false)}
            disableButton={disabled}
          >
            <h3 className={cs('modalTitle', `modalTitle-le--${theme}`)}>
              {translations.editExtensions()}
            </h3>
            <p className={cs('modalInfoText', `modalInfoText-le--${theme}`)}>{translations.modalText()}</p>

            <ExtensionsProgressBar extensions={extensions} showIcon={false}/>
            <div
              className={'terminationRequested'}
              hidden={terminatedExtensions === 0}
            >
              {terminatedExtensions === 1
                ? translations.termination_single()
                : translations.termination(terminatedExtensions + '')}
            </div>
            <div className={'modalBoxes'}>
              <CpxModalBox
                inputValue={reduceValue}
                inputLabel={translations.number()}
                maxValue={unusedExtensionsAmount}
                tabIndex={1}
                title={translations.reduce()}
                inputChange={(e: any) => handleInputValueChange(e, true)}
                onClick={() => selectReduceBox()}
                icon={<Negativ/>}
                name={'reduce'}
                visible={false}
              >
                {translations.reduceText()}
              </CpxModalBox>
              <CpxModalBox
                inputValue={increaseValue}
                inputLabel={translations.number()}
                maxValue={200}
                tabIndex={2}
                title={translations.increase()}
                inputChange={(e: any) => handleInputValueChange(e, false)}
                onClick={() => selectIncreaseBox()}
                icon={<Positiv/>}
                name={'increase'}
                visible={childProductId === null || childProductId === undefined}
              >
                {translations.increaseText()}
              </CpxModalBox>
            </div>
            <div className={'errorField'}>{error}</div>
          </CpxModal>
        )}
      </>
    </>
  );
};
