import React, { useEffect } from "react";
import { useSelectApiCallStatus, useSelectLang } from "../../redux/store";
import "../../components/auth/auth.scss";
import "../../../App.scss";
import { useTheme } from "../../../core/utility/themeContext";
import pkceChallenge from 'pkce-challenge';
import { useDispatch } from "react-redux";
import { setPkceCodeChallenge } from "../../redux/actions/config.action";
import { LOCALSTORAGE_REFRESHTOKEN } from "../../constants/configs/config.constants";
import { selectCurrentLanguage } from "../../../core/uiLanguage/lang.slice";
import { routes } from '../../constants/routes/routes';
import MainConfigConstants from "../../constants/configs/main.config.constants";
import { useLocation } from "react-router-dom";


export const NfoLoginPage = () => {
  const isLoggedIn = useSelectApiCallStatus().loggedIn && localStorage.getItem(LOCALSTORAGE_REFRESHTOKEN)
  const theme = useTheme();
  const pkceCodeChallenge = pkceChallenge(128);
  const dispatch = useDispatch();
  const lang = selectCurrentLanguage(useSelectLang());
  const currentURL = window.location.href.split(routes.login.path)[0];

  const query = new URLSearchParams(useLocation().search);
  let userLanguage = navigator.language == 'de-DE' || navigator.language === 'de' ? 'de' : 'en';

  if (userLanguage === null) {
    userLanguage = lang.toLowerCase();
  }

  useEffect(() => {
    if (!isLoggedIn) {
      const redirectUrl = encodeURIComponent(`${currentURL}${routes.callback.path}`)

      dispatch(setPkceCodeChallenge(pkceCodeChallenge));
      setTimeout(() => {
          window.location.href = process.env.REACT_APP_AUTH_URL + MainConfigConstants.API.OAUTH2.REQUESTS.AUTHORIZATION.URL_APPENDIX +
              "?response_type=code" +
              "&client_id=" + process.env.REACT_APP_CLIENT_ID +
              "&scope=all" +
              "&redirect_uri=" + redirectUrl +
              "&code_challenge=" + pkceCodeChallenge.code_challenge +
              "&code_challenge_method=S256" +
              "&user_language=" + userLanguage +
              "&client_style=" + theme;
      }, 200);
    }
  }, [])

  return (
    <></>
  );
};
