import React, { useEffect, useState } from "react";
import { TRANSLATIONS } from "../../../constants/transitions/uiTranslations";
import { useTranslations } from "../../../utils/helper/utils";
import { CpxTitle } from "../../../../core/components/title/title.component";
import { CpxSubtitle } from "../../../../core/components/title/subtitle.component";
import { apiCallAction } from "../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../constants/action.constants";
import { useDispatch } from "react-redux";
import { NfoSipTrunkTable } from "../../../components/customer/sipTrunk/sipTrunkTable/sipTrunkTable.component";
import { NfoEditSipTrunk } from "./editSipTrunk/editSipTrunk.component";
import "./sipTrunk.scss";
import { CpxWarningBox } from "../../../../core/components/terminationWarningBox.component";
import { useSelectedMainContract, useSelectPartnerCustomer, useSelectSipTrunks } from "../../../redux/store";
import { SipTrunkContract } from "compax-api";
import {ID_SERVICE_GROUPS, ID_STATUS_SERVICES} from "../../../constants/configs/config.constants";
import { IPartnerCustomer } from "../../../constants/types/types.constants";
import {useTheme} from "../../../../core/utility/themeContext";
import {NfoEditVoiceChannelModal} from "./editSipTrunk/editVoiceChannelModal.component";
import {EditVoiceChannelConfirmationModalComponent} from "./editSipTrunk/editVoiceChannelConfirmationModal.component";

export const NfoSipTrunkPage = () => {
  const translations = useTranslations(TRANSLATIONS.sipTrunk);
  const internalClassName = "sipTrunk";
  const dispatch = useDispatch();
  const sipTrunks: any = useSelectSipTrunks();
  const selectedMainContract = useSelectedMainContract();
  const [showModal, setShowModal] = useState(false);
  const [showVoiceChannelModal, setShowVoiceChannelModal] = useState(false);
  const [showVoiceChannelConfirmationModal, setShowVoiceChannelConfirmationModal] = useState(false);
  const [selectedSipTrunk, setSelectedSipTrunk] = useState(-1);
  const [sipTrunkProductId, setSipTrunkProductId] = useState(-1);
  const [sipTrunkOptionId, setSipTrunkOptionId] = useState(-1);
  const [selectedCustomerId, setSelectedCustomerId] = useState<number>();
  const [sipTrunkName, setSipTrunkName] = useState("");
  let optionsForTermination = [];
  const theme = useTheme();

  const selectedPartnerCustomer: IPartnerCustomer = useSelectPartnerCustomer() as IPartnerCustomer;

  if (Array.isArray(sipTrunks) && sipTrunks.length > 0) {
    for (let sipTrunk of sipTrunks) {
      if (typeof sipTrunk.sipTrunkOptions !== "undefined" && sipTrunk.sipTrunkOptions.length > 0)
        for (let sipTrunkOption of sipTrunk.sipTrunkOptions) {
          if (sipTrunkOption.status.id === ID_STATUS_SERVICES.CANCELLATION_REQUESTED) {
            optionsForTermination.push({
              product: sipTrunkOption.product.description,
              endDtReq: new Date(sipTrunkOption.endDateRequested).toLocaleDateString(),
            });
          }
        }
    }
  }

  const warnings = optionsForTermination.map(option => {
    return translations.terminationNotification(option.product, option.endDtReq);
  });

  useEffect(() => {
    loadSipTrunks();
  }, [selectedMainContract]);

  const loadSipTrunks = () => {
    dispatch(apiCallAction(ACTION_CONST.API_GET_SIPTRUNK, {contractId: selectedMainContract.contractId ,statuses: ['ACTIVE', 'ORDERED', 'LOCKED'], selectedPartnerCustomerId: selectedPartnerCustomer?.id}));
  }

  const clickEditHandler = (id: number, productId: number, optionId: number, name: string) => {
    setSelectedSipTrunk(id);
    setSipTrunkProductId(productId);
    setSipTrunkOptionId(optionId);
    setSipTrunkName(name);
    setShowModal(true);
    setSelectedCustomerId(selectedPartnerCustomer.id);

  };

  const changeVoiceChannel = () => {
    setShowModal(false);
    setShowVoiceChannelModal(true);
  }

  return (
    <>
      {showModal && (
        <NfoEditSipTrunk
          showModal={setShowModal}
          sipTrunk={selectedSipTrunk}
          productId={sipTrunkProductId}
          sipTrunkOptionId={sipTrunkOptionId}
          customerId={selectedCustomerId}
          name={sipTrunkName}
          loadSipTrunks={loadSipTrunks}
          changeVoiceChannel={changeVoiceChannel}
        />
      )}
      {showVoiceChannelModal && (
        <NfoEditVoiceChannelModal
            internalClassName={internalClassName}
            setShowVoiceChannelModal={setShowVoiceChannelModal}
            setShowVoiceChannelConfirmationModal={setShowVoiceChannelConfirmationModal}
            setShowModal={setShowModal}
            selectedSipTrunkId={selectedSipTrunk}
            sipTrunkOptionId={sipTrunkOptionId}
        />
      )}
      {showVoiceChannelConfirmationModal && (
          <EditVoiceChannelConfirmationModalComponent
              setShowVoiceChannelModal={setShowVoiceChannelModal}
              setShowVoiceChannelConfirmationModal={setShowVoiceChannelConfirmationModal}
              loadSipTrunks={loadSipTrunks}
              sipTrunkName={sipTrunkName}
              selectedSipTrunkId={selectedSipTrunk}
              sipTrunkOptionId={sipTrunkOptionId}
          />
      )}

      <CpxTitle
        title={translations.title()}
        internalClassName={internalClassName}
      />
      <div className={internalClassName}>
        <CpxSubtitle
          subtitle={translations.subheading()}
          internalClassName={internalClassName}
        />
      </div>
      {warnings.length > 0 && (
        <CpxWarningBox warnings={warnings}/>
      )}
      <NfoSipTrunkTable
        title={translations.title()}
        clickEditHandler={clickEditHandler}
      />
    </>
  );
};
