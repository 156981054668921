import { CpxInputWithLabel } from "../../../../../../../core/components/inputWithLabel.component";
import React from "react";
import { CpxCheckboxGroup } from "../../../../../../../core/components/checkboxGroup.component";
import { PortingCallNumberConfigurationComponent } from "./portingCallNumberConfiguration.component";
import { useTranslations } from "../../../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../../../constants/transitions/uiTranslations";

type Props = {
  values: any;
  touched: any;
  errors: any;
  handleChange: any;
  allNumbersIsChecked: any;
  setAllNumbersIsChecked: any;
  formik: any;
  callNumberConfigurations: any;
  setCallNumberConfigurations: any;
}

export const PortingIndividualNumberForm = ({ values, touched, errors, handleChange, allNumbersIsChecked, setAllNumbersIsChecked, formik, callNumberConfigurations, setCallNumberConfigurations }: Props) => {
  const translation = useTranslations(TRANSLATIONS.stepper.newCustomer);
  const internalClassName = "portingIndividualNumberForm";

  const onChangeCheckbox = () => {
    formik.setFieldValue('multipleSubscriberNumbers.portAllNumbers', !allNumbersIsChecked);
    setAllNumbersIsChecked((prev: any) => !prev);
  }

  return (
      <div className={`${internalClassName}`}>
          <div className={`${internalClassName}-section`}>
            <CpxInputWithLabel
              className={'localAreaCode'}
              id={"multipleSubscriberNumbers.localAreaCode"}
              type="text"
              value={values.localAreaCode}
              error={touched?.localAreaCode && errors?.localAreaCode}
              onChange={handleChange}
              required
            >
              {translation.localAreaCode()}
            </CpxInputWithLabel>
            <CpxCheckboxGroup
              className={`${internalClassName}-checkbox`}
              name="allNumbersCheckbox"
              options={[
                {
                  name: 'allNumbersCheckbox',
                  title: translation.portAllNumbers(),
                  checked: allNumbersIsChecked,
                },
              ]}
              onChange={onChangeCheckbox}
            />
          </div>
        <div className={`${internalClassName}`}>
          <PortingCallNumberConfigurationComponent formik={formik} callNumberConfigurations={callNumberConfigurations} setCallNumberConfigurations={setCallNumberConfigurations}/>
        </div>
      </div>
    );
}