import cs from "classnames";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import React, { ReactNode, useEffect, useState } from "react";
import { preventDefault } from "../utils";
import { CpxButton } from "./button.component";
import { CpxCheckboxWithIndeterminate } from "./checkboxWithIndeterminate.component";
import "./downloadTable.scss";
import { directDownload } from "./utils";
import { useTheme } from "../utility/themeContext";
import { NfoEmptyState } from "../emptyState.component";
import { CpxPaginatedTable } from "./paginatedTable.component";
import { useTranslations } from "../../portal/utils/helper/utils";
import { TRANSLATIONS } from "../../portal/constants/transitions/uiTranslations";

export type FileGetter = () => Promise<string>;

export type DownloadType = "application/pdf" | "application/csv";

export type FileDownload = {
  fileGetter: FileGetter;
  fileName: string;
  downloadType: DownloadType;
};

export type DownloadTableRow = {
  id: string;
  cols: string[];
  fileDownloads: [FileDownload, ...FileDownload[]];
};

type DownloadsProps = {
  linkName: ReactNode;
  zipLinkName?: ReactNode;
  zipFileName: string;
};

type Props = {
  headers: any;
  tableData: DownloadTableRow[];
  downloadColHeader?: string;
  downloadsProps: [DownloadsProps, ...DownloadsProps[]];
  additionalActions?: ReactNode;
  title?: string;
};

type CheckedIds = {
  [key: string]: boolean;
};

type ArrayWithIdField = { id: string }[];

const selectedObjectFromArray = (
  array: ArrayWithIdField,
  initialValue: boolean
) =>
  array.reduce(
    (prev, cur) => ({ ...prev, [cur.id]: initialValue }),
    {}
  ) as CheckedIds;

export const CpxDownloadTable = ({
                                   downloadColHeader,
                                   headers,
                                   tableData,
                                   downloadsProps,
                                   additionalActions, title,
                                 }: Props) => {
  const theme = useTheme();
  const internalClassName = "downloadTable";
  const translations = useTranslations(TRANSLATIONS.common);

  const [rowData, setRowData] = useState(tableData);

  useEffect(() => {
    setRowData(tableData)
  }, [tableData]);

  const [selected, setSelected] = useState(
    selectedObjectFromArray(rowData, false)
  );

  useEffect(() => {
    setSelected(selectedObjectFromArray(rowData, false));
  }, [rowData]);

  const isAllSelected = () => {
    const selectedArray = Object.values(selected);
    return selectedArray.reduce(
      (prev, cur) => (prev !== cur ? undefined : prev),
      selectedArray[0] as boolean | undefined
    );
  };

  const isNoneSelected = () => {
    const selectedArray = Object.values(selected);
    return selectedArray.reduce(
      (prev, cur) => (prev === cur ? undefined : prev),
      !selectedArray[0] as boolean | undefined
    );
  };

  const handleCheck = (id: string, isChecked: boolean) => {
    setSelected({ ...selected, [id]: isChecked });
  };

  const handleCheckAll = () => {
    setSelected(selectedObjectFromArray(rowData, isAllSelected() !== true));
  };

  const handleDownloadAll = (key: number) => {
    const zip = new JSZip();

    rowData
      .filter(row => selected[row.id])
      .forEach(row => {
        zip.file(
          row.fileDownloads[key].fileName,
          row.fileDownloads[key].fileGetter(),
          { base64: true }
        );
      });

    zip.generateAsync({ type: "blob" }).then(blob => {
      saveAs(blob, `${downloadsProps[key].zipFileName}.zip`);
    });
  };

  if (rowData.length === 0) {
    return null;
  }

  const downloadTableRowData = (row: DownloadTableRow) => {
    return {
      id: row.id + "",
      cellData: [
        <div>
          <CpxCheckboxWithIndeterminate
            checked={selected[row.id]}
            onChange={checked => handleCheck(row.id, checked)}
          />
        </div>,
        ...row.cols.map((col, colKey) => (
          <div
            key={colKey}
          >
            {col}
          </div>
        )),
        <div>
          {row.fileDownloads.map((fileDownload, key) => (
            <a
              className={cs(`highlight-le--${theme}`, `downloadLink downloadLink-le--${theme}`)}
              key={key}
              href={"./"}
              onClick={preventDefault(() => {
                directDownload(
                  fileDownload.fileGetter,
                  fileDownload.fileName,
                  fileDownload.downloadType
                );
              })}
            >
              {downloadsProps[key].linkName}
            </a>
          ))}
        </div>
      ]
    }
  }

  return (
    <>
      {title && <h4 className={`${internalClassName}-title ${internalClassName}-title-le--${theme}`}>{title}</h4>}
      <NfoEmptyState
        data={tableData}
        pageTitle={title ? title : ''}
      >
        <CpxPaginatedTable
          className={internalClassName}
          tableHeader={headers}
          id={'downloadTable'}
          downloadAllHeaderCol={<th>
            <CpxCheckboxWithIndeterminate
              checked={isAllSelected()}
              onChange={handleCheckAll}
            />
          </th>}
          tableData={rowData.map(downloadTableRowData)}
          isDownloadTable={true}
          rowData={rowData}
          getSortedRowsBack={(sortedRows: any) => setRowData(sortedRows.slice())}
          itemsPerPage={12}
          pagination="bottom"
        />

        <div className={"actions"}>
          <div>{additionalActions}</div>
          <div className={"buttonsContainer"}>
            {downloadsProps.map((zipLinks, key) => {
              return (
                zipLinks.zipLinkName && (
                  <CpxButton
                    key={key}
                    onClick={() => handleDownloadAll(key)}
                    disabled={isNoneSelected()}
                  >
                    {zipLinks.zipLinkName}
                  </CpxButton>)
              );
            })}
          </div>
        </div>
      </NfoEmptyState>
    </>
  );
};
