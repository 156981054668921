import { ACTION_CONST } from "../../constants/action.constants";
import { IAction } from "../../constants/types/types.constants";

const initialState = {
  userRole: "",
  mainProductId: 0,
  pkceCodeChallenge: { code_verifier: '', code_challenge: '' },
  partnerCollapsibleStatus: false,
  wholesaleModel: '',
};

function configReducer(state = initialState, action: IAction) {
  switch (action.type) {
    case ACTION_CONST.SET_USER_ROLE:
      state = {
        ...state,
        userRole: action.data,
      };
      break;
    case ACTION_CONST.SET_WHOLESALE_MODEL:
      state = {
        ...state,
        wholesaleModel: action.data,
      };
      break;
    case ACTION_CONST.SET_MAIN_PRODUCT:
      state = {
        ...state,
        mainProductId: action.data,
      };
      break;
    case ACTION_CONST.SET_PKCE_CODE_CHALLENGE:
      state = {
        ...state,
        pkceCodeChallenge: action.data,
      };
      break;
    case ACTION_CONST.PARTNER_COLLAPSIBLE_STATUS:
      state = {
        ...state,
        partnerCollapsibleStatus: action.data,
      };
      break;
  }

  return state;
}

export default configReducer;
