import "./helpBox.scss";
import { ReactComponent as Help } from "../../portal/assets/icons/help.svg";
import cs from "classnames";
import { usePopupMenu } from "../../portal/components/common/header/utils";
import { PropsWithoutRef } from "react";
import { useTheme } from "../utility/themeContext";
import { PopupDirections } from "../../portal/constants/types/types.constants";
import { CpxIcon } from "./icon.component";


type Props = PropsWithoutRef<JSX.IntrinsicElements["div"]> & {
  popupDirection?: PopupDirections,
  labelName?:string
};

export const CpxHelpBox = ({ children, className, popupDirection,labelName, ...props }: Props) => {
  const { expanded, setExpanded, ref } = usePopupMenu();
  const theme = useTheme();
  const internalClassName = "helpBox";

  return (
    <div className={cs(className, internalClassName, `${internalClassName}-le--${theme}`,)} {...props} ref={ref}>
        {labelName && <label htmlFor={"helpBoxLabel"}> {labelName} </label>}
        <Help onMouseEnter={() => setExpanded(cur => !cur)} onMouseLeave={() => setExpanded(cur => !cur)} className={`${internalClassName}-icon ${internalClassName}-icon-le--${theme}`}/>
      {expanded && (
        <div className={cs("popup", `popup-le--${theme}`, popupDirection && `turn-${popupDirection}`)}>{children}</div>
      )}
    </div>
  );
};
