import { ACTION_CONST } from '../../constants/action.constants'
import { Basket, BasketEntry, OptionGroup, SelectableBasketOption, TelcoData } from "compax-api";
import { apiCallAction } from "./apiCall.action";
import { clearError } from "./error.action";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

export const saveCurrentStep = (data: any) => {
    return {
        type: ACTION_CONST.SAVE_CURRENT_STEP,
        data: data
    }
}

export const saveCurrentOrderType = (data: any) => {
    return {
        type: ACTION_CONST.SAVE_CURRENT_ORDER_TYPE,
        data: data
    }
}

export const saveStep1Data = (data: any) => {
    return {
        type: ACTION_CONST.STORE_STEP_1_DATA,
        data: data
    }
}

export const saveStep2Data = (data: any) => {
    return {
        type: ACTION_CONST.STORE_STEP_2_DATA,
        data: data
    }
}

export const saveStep2FormData = (data: any) => {
    return {
        type: ACTION_CONST.STORE_STEP_2_FORM_DATA,
        data: data
    }
}

export const saveStep3Data = (data: any) => {
    return {
        type: ACTION_CONST.STORE_STEP_3_DATA,
        data: data
    }
}

export const saveStep4Data = (data: any) => {
    return {
        type: ACTION_CONST.STORE_STEP_4_DATA,
        data: data
    }
}
export const saveActivationDate = (data: any) => {
    return {
        type: ACTION_CONST.STORE_ACTIVATION_DATE,
        data: data
    }
}

export const saveOEOptionsOfOption = (data: any) => {
    return {
        type: ACTION_CONST.SAVE_OE_OPTIONS_OF_OPTION,
        data: data
    }
}
export const saveOENewSipTrunkOption = (data: any) => {
    return {
        type: ACTION_CONST.SAVE_OE_NEW_SIP_TRUNK_OPTION,
        data: data
    }
}
export const deleteOESipTrunkOption = (uuid: any) => {
    return {
        type: ACTION_CONST.DELETE_OE_SIP_TRUNK_OPTION,
        data: uuid
    }
}

export const deleteOEOptionsOfOptionWithProductId = (id: any) => {
    return {
        type: ACTION_CONST.DELETE_OE_OPTIONS_OF_OPTION_WITH_PRODUCT_ID,
        data: id
    }
}

export const saveOEConfigurationsOfOption = (data: any) => {
    return {
        type: ACTION_CONST.SAVE_OE_CONFIGURATIONS_OF_OPTION,
        data: data
    }
}

export const saveStep5Data = (data: any) => {
    return {
        type: ACTION_CONST.STORE_STEP_5_DATA,
        data: data
    }
}

export const saveStep6Data = (data: any) => {
    return {
        type: ACTION_CONST.STORE_STEP_6_DATA,
        data: data
    }
}

export const deleteStepData = () => {
    return {
        type: ACTION_CONST.DELETE_STEP_DATA,
    }
}

export const deleteStep4 = () => {
    return {
        type: ACTION_CONST.DELETE_STEP_4,
    }
}

export const reachedConfirmPage = (data: any) => {
    return {
        type: ACTION_CONST.REACHED_CONFIRM_PAGE,
        data: data
    }
}
export const allReqDocumentUploaded = (data: boolean) => {
    return {
        type: ACTION_CONST.ALL_REQ_DOCUMENTS_UPLOADED,
        data: data
    }
}

export const isProductSelected = (data: boolean) => {
    return {
        type: ACTION_CONST.IS_PRODUCT_SELECTED,
        data: data
    }
}

export const saveBillingInformationSelection = (data: any) => {
    return {
        type: ACTION_CONST.BILLING_INFORMATION_SELECTION,
        data: data
    }
}

export const saveActivationDateSelection = (data: any) => {
    return {
        type: ACTION_CONST.ACTIVATION_DATE_SELECTION,
        data: data
    }
}


export const addOptionsToBasket = (product: any, basket: Basket, quantity: number = 1) => (dispatch: any) => {
    return dispatch(
        apiCallAction(ACTION_CONST.API_PUT_OE_PRODUCT_OPTIONS,
            {
                basketId: basket?.id,
                uuid: product?.parentUuid,
                optionId: product?.product.id,
                quantity: quantity,
                updateBasket: true,
                expanded: true,
                stepper: product?.stepper
            },
            true, null, true)
    );
}

export const addOptionsCustomDataToBasket = (product: any, basket: Basket, customData: string) => (dispatch: any) => {
    return dispatch(
        apiCallAction(ACTION_CONST.API_BASKET_PUT_PRODUCT_OPTIONS_CUSTOM_DATA, {
                basketId: basket?.id,
                uuid: product?.parentUuid,
                optionId: product?.product.id,
                customData: customData,
                expanded: true,
                updateBasket: true
            },
            true, null, true)
    );
}

export const addOptionsTelcoDataToBasket = (product: any, basket: Basket, telcoDataArray: TelcoData) => (dispatch: any) => {
    return dispatch(
        apiCallAction(ACTION_CONST.API_BASKET_PUT_PRODUCT_OPTIONS_TELCO_DATA, {
                basketId: basket?.id,
                uuid: product?.parentUuid,
                optionId: product?.product.id,
                expanded: true,
                updateBasket: true,
                telcoDataArray: telcoDataArray,
            },
            true, null, false)
    );
}

export const editOptionFromBasket = (product: any, basket: Basket, telcoDataArray: TelcoData) => async (dispatch: AppDispatch) => {
    await dispatch(removeOptionsFromBasket(product, basket))
    return dispatch(addOptionsTelcoDataToBasket(product, basket, telcoDataArray))
}

export const removeOptionsFromBasket = (product: any, basket: Basket) => (dispatch: any) => {
    return dispatch(
        apiCallAction(ACTION_CONST.API_DELETE_OE_PRODUCT_OPTIONS,
            {
                basketId: basket?.id,
                uuid: product?.parentUuid,
                optionId: product?.product.id,
                //quantity: 1,
                updateBasket: true,
            },
            true, null, true)
    );
}


export const setProductOptionToValidated = (optionId: any) => {
    return {
        type: ACTION_CONST.SET_PRODUCT_OPTION_TO_VALIDATED,
        data: optionId
    }
}

export const updateProductSipTrunkToValidatedAndSetUuid = (optionUuid: any) => {
    return {
        type: ACTION_CONST.UPDATE_PRODUCT_SIP_TRUNK_TO_VALIDATED_AND_SET_UUID,
        data: optionUuid
    }
}

export const setProductOptionToInvalidated = (optionId: any) => {
    return {
        type: ACTION_CONST.SET_PRODUCT_OPTION_TO_INVALIDATED,
        data: optionId
    }
}
export const setSipTrunkProductOptionToInvalidated = (optionUuid: any) => {
    return {
        type: ACTION_CONST.SET_SPI_TRUNK_PRODUCT_OPTION_TO_INVALIDATED,
        data: optionUuid
    }
}

type AppDispatch = ThunkDispatch<any, any, AnyAction>;

export const selectProductOption = (option: any, basket: Basket, setState: any, language: string, parentEntryUuid: string, parentOptionUuid?: any) => async (dispatch: AppDispatch) => {
    const optionId = option.product.id;
    let newBasket: Basket;
    const parentEntry = Object.values(basket?.entries).find(entry => entry?.uuid === parentEntryUuid);
    if (optionId ===  parentEntry?.options?.find((o: BasketEntry) => o.product.id === optionId)?.product?.id) {
        //remove from basket
        newBasket = await dispatch(removeOptionsFromBasket(option, basket));
        dispatch(saveOEOptionsOfOption({[parentOptionUuid]: []}))
    } else {
        //add to basket
        newBasket = await dispatch(addOptionsToBasket(option, basket));
        let newParentEntry = Object.values(newBasket?.entries)?.find((entry: BasketEntry) => entry?.uuid === parentEntryUuid);
        let optionUuid = newParentEntry?.options?.find((o: BasketEntry) => o.product.id === optionId)?.uuid;
        let res: any = await dispatch(apiCallAction(ACTION_CONST.API_GET_OE_OPTIONS_OF_OPTION,
            {
                language,
                basketId: basket?.id,
                uuid: newParentEntry?.options?.find((o: any) => o.product.id === optionId)?.uuid
            },
            true))
        parentOptionUuid && dispatch(saveOEOptionsOfOption({[parentOptionUuid]: res?.optionGroups}))
        optionUuid && dispatch(saveOEOptionsOfOption({[optionUuid]: res?.optionGroups}))


    }

    setState((prevState: any) => prevState.map((p: any) => {
        if (p.product.id === optionId) {
            return {...p, selected: !p.selected};
        } else {
            return {...p, selected: false}
        }
    }));

    return newBasket
}

export const selectProductOptionSipTrunk = (option: any, basket: Basket, setState: any, language: string, optionIndex: number, parentIndex: string, cancel?: any, optionalAction?: any, isMigration?: boolean) => async (dispatch: AppDispatch) => {
    // const optionId = option.product.id;
    let newBasket: Basket;
    let uuidOfAddedOption: string;
    let parentEntry = basket?.entries[parentIndex];
    if(!parentEntry) {
        return;
    }

    let indexOfOption = -1;
    if(parentEntry?.options?.findIndex((o: any) => o.uuid === option.uuid) !== -1) {
        let tempIndex = parentEntry?.options?.findIndex((o: any) => o.uuid === option.uuid);
        indexOfOption = tempIndex === undefined ? -1 : tempIndex;
    } else if(cancel === true){
        indexOfOption = parentEntry?.options?.length - 1;
    }

    if (indexOfOption !== -1) {
        //remove from basket
        const optionsOfBasketLength = parentEntry?.options?.length ? (parentEntry?.options?.length - 1) : -1;
        uuidOfAddedOption = parentEntry?.options && parentEntry?.options[optionsOfBasketLength]?.uuid || '';

        newBasket = await dispatch(apiCallAction(ACTION_CONST.API_BASKET_DELETE_OPTION_INDEX,
            {basketId: basket?.id, entryIndex: parentIndex, optionIndex: indexOfOption}
        ));
        dispatch(saveOEOptionsOfOption({[option.uuid]: []}))

        dispatch(deleteOESipTrunkOption(option.uuid))

        setState((prevState: any) => prevState.filter((p: any, index: number) => p.uuid !== option.uuid))
    } else {
        //add to basket
        newBasket = await dispatch(addOptionsToBasket(option, basket));
        const newParentEntry = newBasket?.entries[parentIndex];
        const optionsOfBasketLength = (newParentEntry?.options?.length || 0) - 1;
        uuidOfAddedOption = newParentEntry?.options && newParentEntry?.options[optionsOfBasketLength]?.uuid || '';

        let res: any = await dispatch(apiCallAction(ACTION_CONST.API_GET_OE_OPTIONS_OF_OPTION,
            {language, basketId: basket?.id, uuid: uuidOfAddedOption},
            true));

        let optionsLength = newBasket?.entries[parentIndex]?.options?.length;

        if (isMigration && optionsLength !== undefined && optionsLength <= 1) {
            let optionGroupChannel = res?.optionGroups.find((op: OptionGroup) => op.serviceGroup.id === 2);
            optionGroupChannel.basketOptions =
              optionGroupChannel?.basketOptions?.filter((bo: SelectableBasketOption) => bo.product?.productCategory?.id === 100);
        }

        dispatch(saveOEOptionsOfOption({[uuidOfAddedOption]: res?.optionGroups}))


    }

    await setState((prevState: any) => prevState?.map((p: any, stateIndex: number) => {
        if (optionIndex === stateIndex) {
            return {...p, selected: !p.selected, uuid: uuidOfAddedOption};
        } else {
            return {...p, selected: false}
        }
    }));

    if(optionalAction){
        optionalAction(uuidOfAddedOption, optionIndex);
    }

    return newBasket
}


export const onValidateProductOption = (o: any, basket: Basket, stepOneData: any, entryIndex: number) => async (dispatch: AppDispatch) => {
    const optionIndex = basket?.entries[entryIndex]?.options?.findIndex((option: BasketEntry) => option?.product?.id === o?.product?.id)
    const finalValues = {
        ...stepOneData,
        stepper: "VALIDATE_BASKET_OPTION",
        basketId: basket?.id,
        validateBasketEntryIndex: entryIndex,
        validateBasketEntryOptionIndex: optionIndex
    }
    dispatch(apiCallAction(ACTION_CONST.API_VALIDATE_CUSTOMER_DATA, finalValues)).then(() => {
        dispatch(setProductOptionToValidated(o?.product?.id))
        dispatch(clearError())
    }).catch(() => window.scrollTo({top: 0, behavior: 'smooth'}))
}

export const onValidateProductOptionSipTrunk = (basket: Basket, stepOneData: any, uuid: string, entryIndex: number) => async (dispatch: AppDispatch) => {

    const optionIndex = basket?.entries[entryIndex]?.options?.findIndex((option: any) => option?.uuid === uuid);
    const finalValues = {
        ...stepOneData,
        stepper: "VALIDATE_BASKET_OPTION",
        basketId: basket?.id,
        validateBasketEntryIndex: entryIndex,
        validateBasketEntryOptionIndex: optionIndex
    }
    dispatch(apiCallAction(ACTION_CONST.API_VALIDATE_CUSTOMER_DATA, finalValues)).then(() => {
        dispatch(updateProductSipTrunkToValidatedAndSetUuid(uuid))
        dispatch(clearError())
    }).catch(() => window.scrollTo({top: 0, behavior: 'smooth'}))
}