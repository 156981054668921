import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelectContracts, useSelectContractsChargesSum, useSelectedMainContract } from "../../../redux/store";
import { TRANSLATIONS } from "../../../constants/transitions/uiTranslations";
import { useTranslations } from "../../../utils/helper/utils";
import { NfoMonthlyCharges } from "../../../components/customer/monthlyCharges/monthlyCharges.component";
import { NfoMainContract } from "../../../components/customer/mainContractDetails/mainContractDetails.component";
import { CpxTitle } from "../../../../core/components/title/title.component";
import commonStyles from "../../../sass/base/common.module.scss";
import { apiCallAction } from "../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../constants/action.constants";
import { IContracts } from "../../../constants/types/types.constants";
import cs from 'classnames';
import { useTheme } from '../../../../core/utility/themeContext';
import { PortalContractChargesSum, PortalContractData } from "compax-api";
import { ID_MAIN_CONTRACT, ID_STATUS_SERVICES } from "../../../constants/configs/config.constants";
import "./contractDetails.scss";

export const NfoContractDetailsPage = () => {
  const translations = useTranslations(TRANSLATIONS.contractDetails);
  const dispatch = useDispatch();
  const internalClassName = 'contractDetails';
  const theme = useTheme();
  const selectedMainContract = useSelectedMainContract();

  useEffect(() => {
    dispatch(
      apiCallAction(ACTION_CONST.API_GET_CONTRACTS, { mainContractId : selectedMainContract?.contractId}, true)
    );
  }, [dispatch, selectedMainContract]);

  const contracts: IContracts = useSelectContracts();
  const chargesSum: PortalContractChargesSum = useSelectContractsChargesSum();
  const mainContract: PortalContractData = contracts && Array.isArray(contracts) ?
    contracts.filter(contract => contract.serviceType?.id + '' === ID_MAIN_CONTRACT)[0] : {}

  const contractsWithAmount: IContracts =
    contracts && Array.isArray(contracts) ? contracts.filter(contract => (contract.amountNetTotal || 0) > 0
      && contract.status?.id !== ID_STATUS_SERVICES.CANCELLATION_REQUESTED
      && contract.status?.id !== ID_STATUS_SERVICES.CANCELLED
      && contract.status?.id !== ID_STATUS_SERVICES.TERMINATION_REQUESTED
      && contract.status?.id !== ID_STATUS_SERVICES.TERMINATED) : [];

  const contractsWithAmountForCancellation: IContracts =
    contracts && Array.isArray(contracts) ? contracts.filter(contract => (contract.amountNetTotal || 0) > 0
      && contract.status?.id === ID_STATUS_SERVICES.CANCELLATION_REQUESTED) : [];

  return (
    <>
      <CpxTitle
        title={translations.title()}
        internalClassName={internalClassName}
      />
      <div className={'subTitleBox'}>
        <p
          className={cs(
            commonStyles.subheading,
            'subText',
            `subText-le--${theme}`,
            `${internalClassName}-subText-le--${theme}`
          )}
        >
          {translations.subheading()}
        </p>
      </div>

      <div className={commonStyles.columnContainer}>
        <div className={commonStyles.column}>
          {!!mainContract &&
            <NfoMainContract mainContract={mainContract}/>
          }
        </div>
        <div className={commonStyles.column}>
          {contractsWithAmount.length > 0 &&
            <NfoMonthlyCharges
              title={translations.monthlyCharges()}
              contracts={contractsWithAmount}
              totalSumNet={chargesSum.totalSumNetOthers}
              useInactiveFlag={true}
            />
          }
          {contractsWithAmountForCancellation.length > 0 &&
            <NfoMonthlyCharges
              title={translations.monthlyChargesToCancel()}
              contracts={contractsWithAmountForCancellation}
              totalSumNet={chargesSum.totalSumNetCancellationRequest}
              useInactiveFlag={false}
            />
          }
        </div>
      </div>
    </>
  );
};
