import cs from 'classnames';
import { PropsWithoutRef } from 'react';
import './button.scss';
import { useTheme } from '../utility/themeContext';
import { Link } from "react-router-dom";

type Props = PropsWithoutRef<JSX.IntrinsicElements['button']> & {
  isSmall?: boolean;
  buttonType?: 'default' | 'secondary' | 'error' | 'white' | 'moreLink';
  isLinkStyle?: boolean;
  withIcon?: boolean;
  to?: string;
};

export const CpxButton = ({
  buttonType,
  isLinkStyle,
  className,
  children,
  withIcon,
  to,
  ...props
}: Props) => {
  const internalClassname = 'cpxButton';
  const theme = useTheme();

  return (
    <button
      {...props}
      className={cs(
        internalClassname,
        `${internalClassname}-le--${theme}`,
        className,
        {
          secondary: buttonType === 'secondary',
          [`secondary-le--${theme}`]: buttonType === 'secondary',
          error: buttonType === 'error',
          white: buttonType === 'white',
          [`white-le--${theme}`]: buttonType === 'white',
          moreLink: buttonType === 'moreLink',
          [`moreLink-le--${theme}`]: buttonType === 'moreLink',
          linkStyle: isLinkStyle,
          withIcon: withIcon,
        }
      )}
    >
      {to ? (
        <Link to={to}>
          {children}
        </Link>
      ) : (
        <>
          {children}
        </>
      )}
    </button>
  );
};
