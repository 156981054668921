import { NavLink } from "react-router-dom";
import cs from "classnames";
import "./figureBox.scss";
import commonStyles from "../../../../sass/base/common.module.scss";
import { useTranslations } from "../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";
import { useTheme } from "../../../../../core/utility/themeContext";

type Props = {
  heading: string;
  figure: string;
  moreLinkTo: string;
};

export const NfoFigureBox = ({ heading, figure, moreLinkTo }: Props) => {
  const translations = useTranslations(TRANSLATIONS.settings);
  const theme = useTheme();
  const internalClassName = "figureBox";

  return (
    <section
      className={cs(internalClassName, `${internalClassName}-le--${theme}`)}
    >
      <h4 className={`${internalClassName}-heading-le--${theme}`}>{heading}</h4>
      <p className={commonStyles.bigFigure}>{figure}</p>
      <NavLink
        to={moreLinkTo}
        className={cs(commonStyles.moreLink, `moreLink moreLink-le--${theme}`)}
      >
        {translations.moreLink()}
      </NavLink>
    </section>
  );
};
