import cs from "classnames";
import { CpxModal } from "../../../../../../../core/components/modal.component";
import React from "react";
import { useTheme } from "../../../../../../../core/utility/themeContext";
import { useTranslations } from "../../../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../../../constants/transitions/uiTranslations";
import { SelectableBasketOption } from "compax-api";
import { CpxInputWithLabel } from "../../../../../../../core/components/inputWithLabel.component";
import "./textFieldForm.scss";
import { useFormik } from "formik";
import { createValidatorSchema, orderMaxLengthCheck, orderRequiredQuantityCheck, requiredCheck } from "../../../../../../utils/validation";
import { ProductOptionModalType } from "../../../../../../constants/types/types.constants";
import { CpxFormikForm } from "../../../../../../../core/components/formikForm.component";
import { useSelectError } from "../../../../../../redux/store";
import { Alert } from "../../../../../common/Alert/Alert.component";
import { clearError } from "../../../../../../redux/actions/error.action";
import { useDispatch } from "react-redux";

type Props = {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  option: SelectableBasketOption;
  selectOptionOfOption: Function;
  textFieldLabel?: string;
  type?: ProductOptionModalType.QUANTITY | ProductOptionModalType.CUSTOM_DATA;
}

export const TextFieldForm = ({ setShowModal, option, selectOptionOfOption, textFieldLabel, type }: Props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const translation = useTranslations(TRANSLATIONS.stepper.newCustomer);
  const translationsCommon = useTranslations(TRANSLATIONS.common);
  const internalClassName = "textFieldForm";

  const defaultQuantity = option?.servicePropertySip?.defaultQuantity;
  const orderMin = option?.servicePropertySip?.orderMin;
  const orderMax = option?.servicePropertySip?.orderMax;
  const ErrorsOfBE = useSelectError();

  const requiredMsg = translation.fieldRequired();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      textFieldValue: defaultQuantity
    },
    validationSchema: createValidatorSchema({
      textFieldValue: (orderMin && orderMax) ? orderRequiredQuantityCheck(requiredMsg, translation.quantityErrorMessage(orderMin.toString(), orderMax.toString()), orderMin, orderMax) : orderMaxLengthCheck(requiredMsg, translation.maxLengthErrorMessage(255+''), 255),
    }),
    onSubmit: async (values: any) => {
      dispatch(clearError());
      selectOptionOfOption({...option, stepper: "TEXT_FORM"}, type, values.textFieldValue);
    }
  });

  return (
    <CpxFormikForm handleSubmit={formik.handleSubmit}
                   initialValues={formik.initialValues}
                   id={'textField-modal-data-form'}>
      <CpxModal
        confirmText={translationsCommon.add()}
        onCancel={() => {
          setShowModal(false);
          dispatch(clearError());
        }
      }
        formId={'textField-modal-data-form'}
      >
        <h4 className={cs('modalTitle', `modalTitle-le--${theme}`)}>
          {option?.product.product}
        </h4>
        <div className={`${internalClassName}-content`}>
          {type === ProductOptionModalType.CUSTOM_DATA && (
            <CpxInputWithLabel
              id="textFieldValue"
              className={`${internalClassName}-input`}
              min={orderMin}
              max={orderMax}
              onChange={formik.handleChange}
              value={formik.values.textFieldValue}
              error={formik.touched?.textFieldValue && formik.errors?.textFieldValue}
            >
              {textFieldLabel && <p className='activation-date-content--text'>{textFieldLabel}</p>}
            </CpxInputWithLabel>
          )}
          {type === ProductOptionModalType.QUANTITY && (
            <CpxInputWithLabel
              id="textFieldValue"
              className={`${internalClassName}-input`}
              type="number"
              min={orderMin}
              max={orderMax}
              onChange={formik.handleChange}
              value={formik.values.textFieldValue}
              error={formik.touched?.textFieldValue && formik.errors?.textFieldValue}
            >
              <p className='activation-date-content--text'>{translation.quantityText()} *</p>
            </CpxInputWithLabel>
          )}
        </div>
        {ErrorsOfBE?.errorData && ErrorsOfBE?.errorData?.length > 0 &&
          ErrorsOfBE?.requestData?.currentData?.stepper === "TEXT_FORM" && <Alert errors={ErrorsOfBE?.errorData}/>}
      </CpxModal>
    </CpxFormikForm>
  )
}
