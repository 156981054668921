import { useSelectPartnerCustomer, useSelectPartners } from '../../../../redux/store';
import { TRANSLATIONS } from '../../../../constants/transitions/uiTranslations';
import { useTranslations } from '../../../../utils/helper/utils';
import { CpxTitle } from '../../../../../core/components/title/title.component';
import cs from 'classnames';
import { NfoPartnerOrderStatus } from '../../../../components/partner/partnerOrderStatus/partnerOrderStatus.component';
import { NfoPartnerContactHistory } from '../../../../components/partner/customer/overview/partnerContactHistory/partnerContactHistory.component';
import { NfoPartnerCustomerContractOverview } from '../../../../components/partner/customer/overview/partnerCustomerContractOverview/partnerCustomerContractOverview.component';
import { NfoPartnerCustomerDataOverview } from '../../../../components/partner/customer/overview/partnerCustomerDataOverview/partnerCustomerDataOverview.component';
import { IPartnerCustomer, IPartners } from '../../../../constants/types/types.constants';
import commonStyles from '../../../../sass/base/common.module.scss';
import './overview.scss';
import { CpxSubtitle } from "../../../../../core/components/title/subtitle.component";
import React, { useEffect } from "react";
import { apiCallAction } from "../../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../../constants/action.constants";
import { useDispatch } from "react-redux";
import { HIDE_COMPONENT_FOR_WHOLESALE_MODEL } from "../../../../constants/configs/config.constants";
import { useHistory } from "react-router-dom";
import { deleteSelectedPartnerCustomer } from "../../../../redux/actions/partnerCustomers.action";
import { routes } from "../../../../constants/routes/routes";
import { unsetSelectedContract } from "../../../../redux/actions/config.action";

export const NfoCustomersOverviewPartnerPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const translationsPartnerCustomer = useTranslations(TRANSLATIONS.partnerCustomer);
  const internalClassName = 'partner-customer-overview';
  const selectedPartnerCustomer: IPartnerCustomer | undefined = useSelectPartnerCustomer();
  const partners: IPartners =  useSelectPartners() as IPartners;
  const partnerWholesaleModelID: number | undefined = partners && partners[0]?.wholesaleModel?.id;
  const isSelectedPartnerCustomer = (customer: any): customer is IPartnerCustomer => {
    return customer.id !== null;
  }
  const customerId = selectedPartnerCustomer?.id;
  // last commit from the king 👑
  useEffect(() => {
    setTimeout(() => {
      if (customerId !== undefined) {
        dispatch(
          apiCallAction(
            ACTION_CONST.API_GET_PARTNER_CUSTOMERS_DATA,
            { customerId },
            true
          )
        );
      } else {
        dispatch(unsetSelectedContract());
        dispatch(deleteSelectedPartnerCustomer());
        history.push(routes.partnerCustomers.path);
      }
    }, 200);

  }, [customerId]);

  const isHiddenWholesaleModel = partnerWholesaleModelID && HIDE_COMPONENT_FOR_WHOLESALE_MODEL.includes(partnerWholesaleModelID);

  return (
    <>
      {
        isSelectedPartnerCustomer(selectedPartnerCustomer) &&
        <>
          <CpxTitle
            title={''}
            name={
              selectedPartnerCustomer?.companyName
            }
            internalClassName={internalClassName}
          />
          <>
            <CpxSubtitle
              subtitle={translationsPartnerCustomer.overviewCustomerData()}
              internalClassName={'partnerCustomerOverview'}
            />
            <div className={cs(commonStyles.columnContainer, 'customerOverview')}>
              <div className={commonStyles.column}>
                <NfoPartnerCustomerDataOverview/>
                <NfoPartnerCustomerContractOverview/>
              </div>

              <div className={commonStyles.column}>
                { !isHiddenWholesaleModel && <NfoPartnerContactHistory customerId={selectedPartnerCustomer.id}/>}
                <NfoPartnerOrderStatus customerId={selectedPartnerCustomer.id}/>
              </div>
            </div>
          </>
        </>
      }
    </>
  );
};
