import { useTheme } from '../../utility/themeContext';
import './title.scss';
import cs from 'classnames';
import { useLocation } from "react-router-dom";

type Props = {
  title: string;
  internalClassName: string;
  name?: string;
  tag?: string;
};

export const CpxTitle = (props: Props) => {
  const { title, internalClassName, name, tag } = props;
  let tagName;
  const theme = useTheme();
  const location = useLocation();
  const ORDER_ENTRY_NEW_CUSTOMER_PATHNAME = "/partner/customer/";

  const content = (
    <span className={`customer-container-le`}>
      {name && (
        <span className={cs(`${internalClassName}-customer-name`, `customer-name customer-name-le--${theme}`)}>{name + ''}</span>
      )}
      {location.pathname.startsWith(ORDER_ENTRY_NEW_CUSTOMER_PATHNAME) && title && <br/>}
      <span
         className={cs(
             `${internalClassName}-title-le--${theme}`,
             `customer-title`,`customer-title-le--${theme}`,
             ((theme === 'dts' && name) ? `dts-dark-color dts-dark-color-le--${theme}` : ''))}
      >
        {title}
      </span>
    </span>
  );
  const compClass = cs('title', `${internalClassName}-title-le--${theme}`);

  switch (tag) {
    case 'h1':
      tagName = <h1 className={compClass}>{content}</h1>;
      break;
    case 'h2':
      tagName = <h2 className={compClass}>{content}</h2>;
      break;
    case 'h3':
      tagName = <h3 className={compClass}>{content}</h3>;
      break;
    case 'h4':
      tagName = <h4 className={compClass}>{content}</h4>;
      break;
    case 'h5':
      tagName = <h5 className={compClass}>{content}</h5>;
      break;
    default:
      tagName = <h2 className={compClass}>{content}</h2>;
      break;
  }

  return <>{tagName}</>;
};
