import React, { useEffect, useState } from 'react';
import { TRANSLATIONS } from '../../../constants/transitions/uiTranslations';
import { useTranslations } from '../../../utils/helper/utils';
import { CpxButton } from '../../../../core/components/button.component';
import { useSelectedMainContract, useSelectExtensions, useSelectFeatures, useSelectPartnerCustomer } from '../../../redux/store';
import { NfoExtensionsTable } from '../../../components/customer/extensions/extensionsTable/extensionsTable.component';
import { useTheme } from '../../../../core/utility/themeContext';
import { preventDefault } from '../../../../core/utils';
import { NfoEditExtensions } from '../../../components/customer/extensions/extensionsModal/editExtensions/editExtensions.component';
import { TerminationWarningBox } from '../../../components/customer/extensions/terminationWarningBox/terminationWarningBox.component';
import { ExtensionsProgressBar } from '../../../components/customer/extensions/extensionsProgressBar/extensionsProgressBar.component';
import { CpxTitle } from '../../../../core/components/title/title.component';
import cs from 'classnames';
import './extensions.scss';
import { apiCallAction } from "../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../constants/action.constants";
import { useDispatch } from "react-redux";
import { FEATURES_FLAG } from "../../../constants/configs/config.constants";
import { IPartnerCustomer } from "../../../constants/types/types.constants";

export const NfoExtensionsPage = () => {
  const translations = useTranslations(TRANSLATIONS.extensions);
  const extensions = useSelectExtensions();
  const theme = useTheme();
  const dispatch = useDispatch();
  const internalClassName = 'extensions-headerBox';
  const features = useSelectFeatures();
  const selectedPartnerCustomer: IPartnerCustomer = useSelectPartnerCustomer() as IPartnerCustomer;
  const selectedMainContract = useSelectedMainContract();

  const [confirmModal, setConfirmModal] = useState(false);
  const handleModalClick = preventDefault(() => {
    setConfirmModal(true);
  });

  useEffect(() => {
    loadExtensions();
  }, [dispatch, selectedMainContract]);

  const loadExtensions = () => {
    dispatch(
      apiCallAction(ACTION_CONST.API_GET_EXTENSIONS,
        { contractId: selectedMainContract?.contractId, selectedPartnerCustomerId: selectedPartnerCustomer?.id }, true)
    );
  }

  useEffect(() => {
    dispatch(apiCallAction(ACTION_CONST.API_PORTAL_FEATURE, { featureName: FEATURES_FLAG.PORTAL_CUSTOMER_EXTENSIONS_ORDER }));
    dispatch(apiCallAction(ACTION_CONST.API_PORTAL_FEATURE, { featureName: FEATURES_FLAG.PORTAL_PARTNER_EXTENSIONS_ORDER }));
  }, [dispatch])

  const orderFlag = ((features.PORTAL_CUSTOMER_EXTENSIONS_ORDER && selectedPartnerCustomer?.id === undefined) ||
    (features.PORTAL_PARTNER_EXTENSIONS_ORDER && selectedPartnerCustomer?.id !== undefined));

  return (
    <>
      {confirmModal && (
        <NfoEditExtensions
          confirmModal={setConfirmModal}
          extensions={extensions}
          parentId={extensions.extensionsParentId + ''}
          childProductId={extensions?.extensionsChildProductId}
          loadExtensions={loadExtensions}
        />
      )}
      <>
        <div className={internalClassName}>

          <CpxTitle
            title={translations.title()}
            internalClassName={internalClassName}
          />

          <div className={cs('infoBox', `infoBox-le--${theme}`)}>
            <ExtensionsProgressBar extensions={extensions} showIcon={true}/>
            {orderFlag && selectedMainContract?.status?.id === 1 && <CpxButton
              id={'extensionsPageCustomizeButton'}
              onClick={handleModalClick}
            >
              {translations.customize()}
            </CpxButton>
            }
          </div>
        </div>
        {!!extensions && (
          <TerminationWarningBox
            terminatedExtensions={
              extensions?.terminatedExtensions
            }
            orderedExtensionsAmount={extensions?.extensionsOrderedTotal}
          />
        )}
      </>
      <NfoExtensionsTable extensions={extensions} title={translations.title()}/>
    </>
  );
};
