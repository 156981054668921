import cs from 'classnames';
import './infrastructureBox.scss';
import { TRANSLATIONS } from '../../../../../../constants/transitions/uiTranslations';
import { useTranslations } from '../../../../../../utils/helper/utils';
import { ReactComponent as World } from '../../../../../../assets/icons/world.svg';
import { ReactComponent as Phone } from '../../../../../../assets/icons/phone_blue.svg';
import { ReactComponent as Building } from '../../../../../../assets/icons/building.svg';
import { useTheme } from '../../../../../../../core/utility/themeContext';


type Props = {
  label: string;
  amount: number;
};

export const NfoInfrastructureBox = (props: Props) => {
    const { label, amount } = props;
    const internalClassName = 'infrastructureBox';
    const theme = useTheme();
    const translations = useTranslations(TRANSLATIONS.myContract);
    let symbol;

    switch (label) {
      case translations.overviewConnections():
        symbol = <World/>;
        break;
      case translations.overviewNumbers():
        symbol = <Phone/>;
        break;
      case translations.overviewLocations():
        symbol = <Building/>;
        break;
      default:
        return null;
    }

    return (
      <div className={internalClassName}>
        <h1 className={cs(`${internalClassName}__amount`, `${internalClassName}__amount-le--${theme}`)}>
          {amount}
        </h1>
        <h5 className={cs(`${internalClassName}__label`, `${internalClassName}__label-le--${theme}`)}>
          {symbol}
          <div
            className={cs(`${internalClassName}__label-text`, `${internalClassName}__label-text-le--${theme}`)}
          >
            {label}
          </div>
        </h5>

      </div>
    );
  }
;
