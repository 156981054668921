import { NavLink } from 'react-router-dom';
import { ProjectRoute } from '../../../../core/types';
import { selectCurrentLanguage } from '../../../../core/uiLanguage/lang.slice';
import { GenericLanguages } from '../../../../core/uiLanguage/types';
import { useSelectLang, useSelectMainProduct, useSelectUserRole, } from '../../../redux/store';
import './navigation.scss';
import cs from 'classnames';
import { useTheme } from '../../../../core/utility/themeContext';

export const NfoNavLink = <TLanguages extends GenericLanguages>({
  id,
  path,
  navTitle,
  visibleForRoles,
  visibleForProducts,
  children,
  activeClass,
  target = '_self',
  isExternalUrl = false,
  internalClassname,
}: ProjectRoute<TLanguages>) => {
  const lang = selectCurrentLanguage(useSelectLang());
  const theme = useTheme();
  const role = useSelectUserRole();
  const mainProductId = useSelectMainProduct();

  const rolesMatch = () => {
    if (typeof visibleForRoles !== 'undefined' && visibleForRoles !== []) {
      if (typeof role !== 'undefined' && role !== null) {
        return (
          typeof visibleForRoles.find(requiredRole => {
            return requiredRole === role;
          }) !== 'undefined'
        );
      }
      return false;
    }
    return true;
  };

  const productsMatch = () => {
    if (
      typeof visibleForProducts !== 'undefined' &&
      visibleForProducts !== []
    ) {
      return (
        typeof visibleForProducts.find(requiredProduct => {
          return requiredProduct === mainProductId;
        }) !== 'undefined'
      );
    }
    return true;
  };

  if (
    (typeof visibleForRoles === 'undefined' || rolesMatch()) && !isExternalUrl
  ) {
    return (
      <>
        <NavLink
          id={id}
          to={path}
          target={target}
          activeClassName={cs({ activeClass }, `${activeClass}-le--${theme}`)}
          className={cs(`nav-link nav-link-le--${theme}`, internalClassname)}
        >
          {navTitle &&
            (typeof navTitle === 'function' ? navTitle()[lang] : navTitle)}
          {children}
        </NavLink>
      </>
    );
  } else if (isExternalUrl) {
    return (
      <>
        <a href={path} target={target} id={id} className={internalClassname}>
          {navTitle &&
            (typeof navTitle === 'function' ? navTitle()[lang] : navTitle)}
          {children}
        </a>
      </>
    );
  } else {
    return <></>;
  }
};
