import cs from "classnames";
import { useTheme } from "../utility/themeContext";
import './error.scss';

type Props = {
  errorMessage: any;
}
export const InputError = ({ errorMessage, ...props }: Props) => {
  const theme = useTheme();

  return (
    <span className={cs('error-msg', `error-msg-le--${theme}`)} {...props}>
      {errorMessage}
    </span>
  )
}