import {LEGAL_ENTITIES} from "../../portal/constants/configs/config.constants";
import {useTheme} from "../utility/themeContext";
import {ReactComponent as StartWhite} from "../../portal/assets/icons/start_white.svg";
import {ReactComponent as StartDts} from "../../portal/assets/icons/start_dts.svg";

type Props = {
  className?: string;
  whiteOverride?: boolean;
};

export const CpxStart = ({ className, whiteOverride }: Props) => {
  const le = useTheme();

  if (le === LEGAL_ENTITIES.DTS) {
    return <StartDts className={className}/>
  } else {
    if (whiteOverride) {
      return <StartWhite className={className} />
    } else {
      return <StartWhite className={className} />
    }
  }
};
