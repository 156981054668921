import React, { useEffect, useState } from "react";
import { TRANSLATIONS } from "../../../constants/transitions/uiTranslations";
import { useTranslations } from "../../../utils/helper/utils";
import { NfoNumbersTable } from "../../../components/customer/numbers/numbersTable/numbersTable.component";
import { CpxTitle } from '../../../../core/components/title/title.component';
import { CpxSubtitle } from "../../../../core/components/title/subtitle.component";
import { useDispatch } from "react-redux";
import { apiCallAction } from "../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../constants/action.constants";
import { CpxButton } from "../../../../core/components/button.component";
import { ReactComponent as Add } from '../../../assets/icons/add.svg';
import { preventDefault } from "../../../../core/utils";
import { NfoNumbersOrder } from "./numbersModal/numberOrder/numberOrder.component";
import "./numbers.scss";
import { IPartnerCustomer } from "../../../constants/types/types.constants";
import { useSelectedMainContract, useSelectPartnerCustomer } from "../../../redux/store";
import { ID_STATUS_SERVICES } from "../../../constants/configs/config.constants";


export const NfoNumbersPage = () => {
  const internalClassName = "numbers";
  const translations = useTranslations(TRANSLATIONS.numbers);
  const dispatch = useDispatch();
  const [confirmModal, setConfirmModal] = useState(false);
  const selectedPartnerCustomer: IPartnerCustomer = useSelectPartnerCustomer() as IPartnerCustomer;
  const selectedMainContract = useSelectedMainContract();


  useEffect(() => {
    loadNumbers();
  }, [dispatch, selectedMainContract]);


  const loadNumbers = () => {
    dispatch(apiCallAction(ACTION_CONST.API_GET_CLIS, { contractId: selectedMainContract?.contractId, selectedPartnerCustomerId: selectedPartnerCustomer?.id }));
  }

  const handleModalClick = preventDefault(() => {
    setConfirmModal(true);
  });

  return (
    <>
      {confirmModal && (
        <NfoNumbersOrder
          confirmModal={setConfirmModal}
          customerId={selectedPartnerCustomer.id}
          loadNumbers={loadNumbers}
        />
      )}
      <>
        <div className={internalClassName}>
          <CpxTitle
            title={translations.title()}
            internalClassName={internalClassName}
          />
          { selectedMainContract?.status?.id === 1 && <NumbersAddButton handleModalClick={handleModalClick} isDisabled={selectedMainContract?.status?.id !== ID_STATUS_SERVICES.ACTIVE && selectedMainContract?.status?.id !== ID_STATUS_SERVICES.ORDERED}/>}
        </div>
        <CpxSubtitle
          subtitle={translations.subheading()}
          internalClassName={internalClassName}
        />
        <NfoNumbersTable title={translations.title()} handleModalClick={handleModalClick}/>
      </>
    </>
  );
};

export const NumbersAddButton = ({ handleModalClick, isDisabled }: any) => {
  const translationsButton = useTranslations(TRANSLATIONS.common);

  return (
    <CpxButton className={"buttonIcons"} onClick={handleModalClick} disabled={isDisabled}>
      <Add/>
      {translationsButton.add()}
    </CpxButton>
  )
}
