import './personInChargeList.scss';
import cs from 'classnames';
import { useTranslations } from '../../../../../utils/helper/utils';
import { TRANSLATIONS } from '../../../../../constants/transitions/uiTranslations';
import { useTheme } from '../../../../../../core/utility/themeContext';
import { IPartnerCustomer, } from '../../../../../constants/types/types.constants';
import { CUSTOMER_CONTACT_PERSON_ROLE_IDS } from "../../../../../constants/configs/config.constants";
import { NfoContactPerson } from "../../../../customer/myData/personInCharge/contactPerson.component";

type Props = {
  selectedCustomer: IPartnerCustomer;
  className?: string;
};

export const NfoPartnerCustomerPersonInChargeList = ({
                                                       selectedCustomer,
                                                       className,
                                                       ...props
                                                     }: Props) => {
  const translations = useTranslations(TRANSLATIONS.myData);
  const internalClassName = 'personInChargeList';
  const theme = useTheme();


  const businessContactPerson = selectedCustomer?.contactPersons?.find((person) => person?.role?.id === CUSTOMER_CONTACT_PERSON_ROLE_IDS.BUSINESS);
  const technicalContactPerson = selectedCustomer?.contactPersons?.find((person) => person?.role?.id === CUSTOMER_CONTACT_PERSON_ROLE_IDS.TECHNICAL);
  const commercialContactPerson = selectedCustomer?.contactPersons?.find((person) => person?.role?.id === CUSTOMER_CONTACT_PERSON_ROLE_IDS.COMMERCIAL);


  return (
    <article className={cs(internalClassName, `${internalClassName}-le--${theme}`, className)} {...props}>
      <h4 className={`componentTitle componentTitle-le--${theme}`}>
        {translations.personsInChargeTitle()}
      </h4>
      {businessContactPerson && <NfoContactPerson contactPerson={businessContactPerson}/>}
      {technicalContactPerson && <NfoContactPerson contactPerson={technicalContactPerson}/>}
      {commercialContactPerson && <NfoContactPerson contactPerson={commercialContactPerson}/>}
    </article>
  );
};
