import React, { useEffect } from 'react';
import { Redirect, Route, useHistory, useLocation } from 'react-router-dom';
import { useSelectContracts, useSelectUserRole } from '../redux/store';
import { useDispatch } from "react-redux";
import { setMainProduct } from "../redux/actions/config.action";
import { apiCallAction } from "../redux/actions/apiCall.action";
import { ACTION_CONST } from "../constants/action.constants";
import { clearError } from "../redux/actions/error.action";
import { NfoContainer } from "../container/container.component";
import { USER_CUSTOMER, USER_PARTNER, USER_WHOLESALER } from "../constants/configs/config.constants";

const PrivateRoute = ({ children, loggedIn, legalEntity, ...rest }) => {
  const role = useSelectUserRole();
  const history = useHistory();
  const dispatch = useDispatch();
  const contracts = useSelectContracts();
  const location = useLocation()
  const mainProduct = Array.isArray(contracts) && contracts.filter(entry => entry.componentOf === undefined)[0];

  const saveProduct = () => {
    if (typeof mainProduct !== 'undefined' && mainProduct !== null) {
      if (typeof mainProduct.product !== 'undefined' && mainProduct.product !== null) {
        dispatch(setMainProduct(mainProduct.product.id));
      }
    }
  };

  useEffect(() => {
    saveProduct();
  }, [mainProduct, saveProduct])


  useEffect(() => {
    dispatch(clearError())
  }, [dispatch])

  useEffect(() => {
    if (location.pathname === '/' || location.pathname === '/callback/oauth' || location.pathname.includes(legalEntity)) {
      if (role === USER_CUSTOMER) {
        history.push('/customer/my-contracts');
      } else if (role === USER_WHOLESALER || role === USER_PARTNER) {
        history.push('/partner/customers');
      }
    }
  }, [history, role, location, legalEntity]);

  useEffect(() => void dispatch(apiCallAction(ACTION_CONST.API_GET_CUSTOMER)), [dispatch]);
  return (
    <Route {...rest}>{loggedIn ? <NfoContainer>{children}</NfoContainer> : <Redirect to={`/login`}/>}</Route>
  );
};

PrivateRoute.propTypes = {};

export default PrivateRoute;
