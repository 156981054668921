import React, { useContext } from 'react'

const ThemeContext = React.createContext('')

export function useTheme () {
  return useContext(ThemeContext)
}

export function ThemeProvider ({children, legalEntity}) {
  return(
    <ThemeContext.Provider value={legalEntity}>
      {children}
    </ThemeContext.Provider>
  )
}