import React from 'react';
import './confirmationModal.scss'
import { useTheme } from "../../../../../core/utility/themeContext";
import { useTranslations } from "../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";
import { CpxModal } from "../../../../../core/components/modal.component";

type Props = {
  onConfirm: any;
  onCancel: any;
  confirmMessage: string;
}

export const NfoRemoveConfigurationConfirmationModal = ({
                                       onConfirm, onCancel, confirmMessage
                                     }: Props) => {
  const theme = useTheme();
  const translations = useTranslations(TRANSLATIONS.confirmationModal);
  const internalClassName = "removeConfigurationModal"

  const handleConfirmation = (e:any) => {
    onConfirm(e);
  }

  return (
    <>
      <CpxModal
        onConfirm={(e:any) => handleConfirmation(e)}
        onCancel={() => onCancel()}
        className={internalClassName}
      >
        <div className={"confirmationText"}>{confirmMessage}</div>
      </CpxModal>

    </>
  );
};
