import cs from 'classnames';
import { PropsWithoutRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CpxAddress } from '../../../../../../core/components/address.component';
import { ReactComponent as Phone } from '../../../../../assets/icons/phone_white.svg';
import { ReactComponent as Place } from '../../../../../assets/icons/place_white.svg';
import { useSelectPartnerCustomer } from '../../../../../redux/store';
import { TRANSLATIONS } from '../../../../../constants/transitions/uiTranslations';
import { useTranslations } from '../../../../../utils/helper/utils';
import './partnerCustomerDataOverview.scss';
import { useTheme } from '../../../../../../core/utility/themeContext';
import { ID_DATA_ENTITY, LEGAL_ENTITIES } from '../../../../../constants/configs/config.constants';
import { routes } from '../../../../../constants/routes/routes';
import { apiCallAction } from '../../../../../redux/actions/apiCall.action';
import { ACTION_CONST } from '../../../../../constants/action.constants';
import { IPartnerCustomer } from '../../../../../constants/types/types.constants';
import { NfoStatus } from '../../../../../../core/components/status.component';
import { ReactComponent as StatusActiveDTS } from "../../../../../assets/icons/status-active_dark.svg";
import { ReactComponent as StatusActive } from "../../../../../assets/icons/status-active_white.svg";
import { ReactComponent as StatusOrderDTS } from "../../../../../assets/icons/status-order-dark.svg";
import { ReactComponent as StatusOrder } from "../../../../../assets/icons/status-order-white.svg";
import { CpxButton } from "../../../../../../core/components/button.component";

type Props = PropsWithoutRef<JSX.IntrinsicElements['select']>;

export const NfoPartnerCustomerDataOverview = ({
  className,
  ...props
}: Props) => {
  const dispatch = useDispatch();
  const translations = useTranslations(TRANSLATIONS.myData);
  const translationsLinks = useTranslations(TRANSLATIONS.myContract);
  const theme = useTheme();
  const internalClassName = 'customerDataOverview';
  const selectedPartnerCustomer: IPartnerCustomer =
    useSelectPartnerCustomer() as IPartnerCustomer;
  const customerId = selectedPartnerCustomer.id;
  const iconActive =
      theme === LEGAL_ENTITIES.DTS ? <StatusActiveDTS/> : <StatusActive/>;
  const iconOrdered =
      theme === LEGAL_ENTITIES.DTS ? <StatusOrderDTS/> : <StatusOrder/>;

  useEffect(() => {
    if (customerId !== undefined) {
      dispatch(
        apiCallAction(
          ACTION_CONST.API_GET_PARTNER_CUSTOMERS_DATA,
          { customerId },
          true
        )
      );
    }
  }, [customerId]);

  return (
    <article
      className={cs(
        internalClassName,
        `${internalClassName}-boxStyling-le--${theme}`,
        className
      )}
      {...props}
    >
      <div className={cs(`column-container`, `column-container-le--${theme}`)}>
        <div className={'column'}>
          <h4
            className={cs(
              'customer-data-heading',
              `customer-data-heading-le--${theme}`
            )}
          >
            {translationsLinks.customerDataHeading()}
          </h4>

          <div className={'inner-column-container'}>
            <div className={'inner-column'}>
              <div>
                <h5>
                  <span className={cs(`${internalClassName}-companyName`,`${internalClassName}-companyName-le--${theme}`)}>{selectedPartnerCustomer.companyName}</span>
                </h5>
                <p className={`subheading-content-le--${theme}`}>
                  {translations.customerId()}:{' '}
                  {selectedPartnerCustomer?.customerId}
                </p>
              </div>
              <div>
                <h5>
                  <Place />
                  <span className={'symbol-text-spacing'}>
                    {translations.address()}
                  </span>
                </h5>

                <CpxAddress
                  address={selectedPartnerCustomer?.mainAddress}
                  className={`content content-le--${theme}`}
                />
              </div>
            </div>

            <div className={'inner-column'}>
              <div>
                <h5>
                  <span>{translationsLinks.customerStatus()}</span>
                </h5>
                <NfoStatus
                  entity={{ id: ID_DATA_ENTITY.SERVICE }}
                  status={selectedPartnerCustomer.status}
                  statusClassName={cs('status', `status-le--${theme}`)}
                  activeIconOverride={iconActive}
                  orderedIconOverride={iconOrdered}
                />
              </div>
              <div>
                <h5>
                  <Phone />
                  <span className={'symbol-text-spacing'}>
                    {translations.contact()}
                  </span>
                </h5>
                <p className={cs('mail', `content content-le--${theme}`)}>
                  {selectedPartnerCustomer?.mainContact?.email?.email}
                </p>
                <p className={`content content-le--${theme}`}>
                  {selectedPartnerCustomer?.mainContact?.phone?.phone}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={'bottom-row'}>
        <CpxButton
          buttonType={'moreLink'}
          className={cs(((theme !== 'dts') ? `white-override` : ''))}
          to={routes.partnerCustomersData.path}
        >
          {translationsLinks.moreLink()}
        </CpxButton>
      </div>
    </article>
  );
};
