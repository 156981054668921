import {ACTION_CONST} from '../../constants/action.constants'

export const showLoaderAction = () => {
	return {
		type: ACTION_CONST.SHOW_LOADER
	}
}

export const hideLoaderAction = (uuid) => {
	return {
		type: ACTION_CONST.HIDE_LOADER
	}
}