import { useEffect, useMemo, useState } from "react";
import { TRANSLATIONS } from "../../../constants/transitions/uiTranslations";
import { useTranslations } from "../../../utils/helper/utils";
import "./footer.scss";
import { useTheme } from "../../../../core/utility/themeContext";
import cs from "classnames";
import { useHistory } from "react-router-dom";
import { routes } from "../../../constants/routes/routes";
import { FooterLegalNoticeComponents } from "./footerLegalNotice.components";

export const NfoFooter = () => {
  const theme = useTheme();
  const translations = useTranslations(TRANSLATIONS.footer);
  const translationsDts = useTranslations(TRANSLATIONS.footer_dts);
  const history = useHistory();
  const [bottomSpacing, setBottomSpacing] = useState(false);

  useEffect(() => {
    if (history.location.pathname === routes.partnerCustomersNewCustomer.path) {
      setBottomSpacing(true);
    } else {
      setBottomSpacing(false);
    }
  });

  const year = useMemo(() => {
    return new Date().getFullYear();
  }, []);
  return (
    <footer className={cs('footer', `footer-le--${theme}`, bottomSpacing && `bottom-spacing`)}>
      <div className={cs('footer-legal-notice', `footer-legal-notice-le--${theme}`)}>
        <FooterLegalNoticeComponents />
      </div>
      <div>
        {theme === "dts"
          ? translationsDts.copyNote("" + year)
          : translations.copyNote("" + year)}
      </div>
    </footer>
  );
};
