import React, { useState } from 'react';
import { CpxModal } from '../../../../../core/components/modal.component';
import { useDispatch } from 'react-redux';
import { useTheme } from '../../../../../core/utility/themeContext';
import { useTranslations } from '../../../../utils/helper/utils';
import { TRANSLATIONS } from '../../../../constants/transitions/uiTranslations';
import { useSelectEditSipTrunk } from '../../../../redux/store';
import { apiCallAction } from '../../../../redux/actions/apiCall.action';
import { ACTION_CONST } from '../../../../constants/action.constants';
import { ID_CHARGE_TYPES, ID_SERVICE_GROUPS } from "../../../../constants/configs/config.constants";
import { currency } from "../../../../../core/utils";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { CpxPaginatedTable } from "../../../../../core/components/paginatedTable.component";
import { Charge, OptionGroup, SelectableBasketOption } from 'compax-api';
import cs from 'classnames';
import './summaryModal.scss';
import { NfoActivationDate } from "../../../../components/partner/newCustomer/configureProduct/activationDate/activationDate.component";

type AppDispatch = ThunkDispatch<any, any, AnyAction>;

export const NfoOstSummaryModal = ({
                                     onConfirm,
                                     backToPreviousPage,
                                     cancelModal,
                                     activeProduct,
                                     sipTrunkName,
                                     customerId
                                   }: any) => {
  const internalClassName = 'ostSummaryModal';
  const theme = useTheme();
  const dispatch: AppDispatch = useDispatch();
  const translations = useTranslations(TRANSLATIONS.editSipTrunk);
  const editSipTrunk: any = useSelectEditSipTrunk();
  const basket: any = editSipTrunk.basket;
  const possibleProducts: OptionGroup | undefined = editSipTrunk.possibleOptions.find((option: OptionGroup) => option.serviceGroup.id === ID_SERVICE_GROUPS.VOICE_CHANNELS);
  const activeBasketOption: SelectableBasketOption | undefined = possibleProducts?.basketOptions.find((option: any) => option.product.id === activeProduct);
  const selectedProduct = editSipTrunk.basket.entries[0].options[0];
  const chargesMonthly: Charge | undefined = activeBasketOption?.product.charges?.find((charge: Charge) => charge.chargeType?.id === ID_CHARGE_TYPES.BASIC_FEE);
  const [activationDate, setActivationDate] = useState();
  const [activationDateErrors, setActivationDateErrors] = useState();

  const confirmForm = () => {
    if (typeof editSipTrunk.basket !== "undefined" && typeof editSipTrunk.basket.id !== "undefined") {
      dispatch(
        apiCallAction(ACTION_CONST.API_OST_POST_SUBMIT_CHANGE, { basketId: editSipTrunk.basket.id, selectedPartnerCustomerId: customerId, changeRequestDate: activationDate }, true)
      ).then(() => {
        onConfirm(true);
      }).catch(() => {
        onConfirm(false);
      });
    }
  }

  const deleteOption = () => {
    dispatch(
      apiCallAction(ACTION_CONST.API_BASKET_DELETE_OPTION,
        {
          basketId: editSipTrunk.basket.id,
          uuid: activeBasketOption?.parentUuid,
          optionId: activeBasketOption?.product.id,
        }, true, null, true)
    );
  }

  const backToQuantitySelect = () => {
    deleteOption();
    backToPreviousPage();
  }

  const cancelModalHandler = () => {
    deleteOption();
    cancelModal(false);
  }

  return (
    <>
      <CpxModal
        onCancel={() => cancelModalHandler()}
        onBack={() => backToQuantitySelect()}
        onConfirm={() => confirmForm()}
        disableButton={!activationDateErrors}
      >
        <h3 className={cs('modalTitle', `modalTitle-le--${theme}`)}>
          {translations.titleSummary()}
        </h3>
        <p className={'modalInfoText'}>
          {translations.subTextSummary()}
        </p>
        <NfoActivationDate setActivationDate={setActivationDate} className={'basketView'} startDtRequested={activeBasketOption?.startDtRequested?.toString()} isValid={setActivationDateErrors}/>
        <h4 className={cs(`${internalClassName}__name-le--${theme}`)}>{sipTrunkName}</h4>
        <article className={'modalTable'}>
          <CpxPaginatedTable
            id={'ostSummaryTable'}
            tableHeader={[
              translations.amount(),
              translations.product(),
              translations.pricePerMonth() + '*',
            ]}
            tableData={[
              {
                id: 'test',
                cellData: [
                  selectedProduct && selectedProduct?.quantity,
                  activeBasketOption && activeBasketOption?.product?.displayValue,
                  selectedProduct && chargesMonthly?.currency && currency(selectedProduct.recurringCharges[0].amountNet, chargesMonthly.currency.description)
                ],
              }
            ]}
          />
        </article>
        <div className={cs(`${internalClassName}-total-sum`, `${internalClassName}-total-sum-le--${theme}`)}>
          <p>
            {translations.totalNet()}
          </p>
          <p>
            {basket && chargesMonthly?.currency && currency(basket.total.recurring.amountNet, chargesMonthly.currency.description)}
          </p>
        </div>
      </CpxModal>
    </>
  );
};
