import cs from 'classnames';
import React, { ForwardedRef, forwardRef, PropsWithoutRef, ReactNode } from 'react';
import './dateInputWithLabel.scss';
import { useTheme } from "../utility/themeContext";
import { CpxIcon } from "./icon.component";
import { ICONS } from "../../portal/constants/configs/config.constants";
import { InputError } from "./inputError.component";
import { useSelectLang } from "../../portal/redux/store";

type Props = PropsWithoutRef<JSX.IntrinsicElements['input']> & {
  id?: string;
  children?: ReactNode;
  suffix?: ReactNode;
  labelClassName?: string;
  inputClassName?: string;
  buttonClassName?: string;
  onChange?: any;
  onPaste?: any;
  error?: any;
  disableErrors?: boolean;
  maxLength?:number;
};

export const CpxDateInputWithLabel = forwardRef(
  (
    {
      children,
      suffix,
      labelClassName,
      inputClassName,
      className,
      onChange,
      onPaste,
      error,
      disableErrors,
      buttonClassName,
      ...props
    }: Props,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const theme = useTheme();
    const language = useSelectLang().selected;

    return (
      <div
        className={cs('dateInputWithLabel', className, {
          ['isDisabled']: props.disabled,
        })}
      >
        {children &&
          <label
          htmlFor={props.id}
          className={cs(labelClassName, { ['required']: !!props.required }, `label label-le--${theme}`)}
          >
            {children}
          </label>
        }
        <div className='dateInput-wrapper'>
          <CpxIcon className={cs(`dateInput-icon`, `dateInput-icon-le--${theme}`)} icon={ICONS.CALENDAR}/>
          <input
            type={props.type ? props.type : 'text'}
            {...props}
            className={cs('input', inputClassName, { ['isNotEmpty']: !!props.value }, `input-le--${theme}`)}
            onChange={onChange}
            onPaste={onPaste}
            ref={ref}
            maxLength={props?.maxLength}
          />
        </div>
        <div className={buttonClassName}>{suffix}</div>
        {(!disableErrors && error) && <InputError errorMessage={error}/>}
      </div>
    )
  }
);
