import { useSelectPartnerCustomer, useSelectPartnerCustomerAccounts, useSelectPartners } from '../../../../redux/store';
import { TRANSLATIONS } from '../../../../constants/transitions/uiTranslations';
import { useTranslations } from '../../../../utils/helper/utils';
import { CpxTitle } from '../../../../../core/components/title/title.component';
import { IAccounts, IPartnerCustomer, IPartners } from '../../../../constants/types/types.constants';
import { CpxSubtitle } from '../../../../../core/components/title/subtitle.component';
import { NfoPartnerCustomerDataOverview } from '../../../../components/partner/customer/data/overview/dataOverview.component';
import { NfoPartnerCustomerPersonInChargeList } from '../../../../components/partner/customer/data/personInChargeList/personInChargeList.component';
import cs from 'classnames';
import { useTheme } from '../../../../../core/utility/themeContext';
import { NfoPartnerContractAccount } from '../../../../components/partner/customer/data/contractAccount/contractAccount.component';
import { NfoPartnerCustomerPreferredContact } from '../../../../components/partner/customer/data/preferredContact/preferredContact.component';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { apiCallAction } from '../../../../redux/actions/apiCall.action';
import { ACTION_CONST } from '../../../../constants/action.constants';
import commonStyles from '../../../../sass/base/common.module.scss';
import './data.scss';
import { ContactPerson } from "compax-api";
import { CUSTOMER_CONTACT_PERSON_ROLE_IDS, HIDE_COMPONENT_FOR_WHOLESALE_MODEL } from "../../../../constants/configs/config.constants";


export const NfoCustomersDataPartnerPage = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const translations = useTranslations(TRANSLATIONS.partnerPages.data);
  const internalClassName = 'partner-customer-data';
  const selectedPartnerCustomer: IPartnerCustomer = useSelectPartnerCustomer() as IPartnerCustomer;
  const selectedPartnerCustomerContractAccounts = useSelectPartnerCustomerAccounts() as IAccounts;
  const customerId = selectedPartnerCustomer.id;

  const partners: IPartners =  useSelectPartners() as IPartners;
  const partnerWholesaleModelID = partners && partners[0]?.wholesaleModel?.id;

  useEffect(() => {
    if (customerId !== undefined) {
      dispatch(
        apiCallAction(
          ACTION_CONST.API_GET_PARTNER_CUSTOMERS_ACCOUNTS,
          { customerId },
          true
        )
      );
    }
  }, []);


  const isPartnerCustomerAccount = selectedPartnerCustomer.contactPersons?.filter(
    (contactPerson: ContactPerson) =>
      contactPerson.role.id === CUSTOMER_CONTACT_PERSON_ROLE_IDS.TECHNICAL ||
      contactPerson.role.id === CUSTOMER_CONTACT_PERSON_ROLE_IDS.BUSINESS
  ).length !== 0;

  const isHiddenWholesaleModel = partnerWholesaleModelID && HIDE_COMPONENT_FOR_WHOLESALE_MODEL.includes(partnerWholesaleModelID);

  return (
    <>
      <CpxTitle
        title={translations.title()}
        name={
          selectedPartnerCustomer.companyName &&
          selectedPartnerCustomer.companyName
        }
        internalClassName={internalClassName}
      />
      <CpxSubtitle
          subtitle={translations.subheading()}
          internalClassName={cs(
              commonStyles.subheading,
              'subText',
              `subText-le--${theme}`,
              `${internalClassName}-subText-le--${theme}`
          )}
      />
      <div className={commonStyles.columnContainer}>
        <NfoPartnerCustomerDataOverview
          className={commonStyles.column}
          selectedCustomer={selectedPartnerCustomer}
        />
        {isPartnerCustomerAccount && <NfoPartnerCustomerPersonInChargeList
          className={commonStyles.column}
          selectedCustomer={selectedPartnerCustomer}
        />}
      </div>
      <NfoPartnerCustomerPreferredContact
        className={commonStyles.column}
        selectedCustomer={selectedPartnerCustomer}
      />
      { !isHiddenWholesaleModel && (
        <NfoPartnerContractAccount
          className={commonStyles.column}
          selectedPartnerCustomerContractAccount={
            selectedPartnerCustomerContractAccounts &&
            selectedPartnerCustomerContractAccounts[0]
          }
        />
      )}
    </>
  );
};
