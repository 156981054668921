import {CpxModal} from "../../../../../core/components/modal.component";
import cs from "classnames";
import React, {useState} from "react";
import {useTheme} from "../../../../../core/utility/themeContext";
import {apiCallAction} from "../../../../redux/actions/apiCall.action";
import {ACTION_CONST} from "../../../../constants/action.constants";
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {IPartnerCustomer, ToastTypes} from "../../../../constants/types/types.constants";
import {
    useSelectEditSipTrunk,
    useSelectError,
    useSelectPartnerCustomer,
    useSelectSipTrunks
} from "../../../../redux/store";
import {useToast} from "../../../../components/common/header/toast/toast.component";
import {useTranslations} from "../../../../utils/helper/utils";
import {TRANSLATIONS} from "../../../../constants/transitions/uiTranslations";
import {CpxPaginatedTable} from "../../../../../core/components/paginatedTable.component";
import {ID_CHARGE_TYPES, ID_SERVICE_GROUPS} from "../../../../constants/configs/config.constants";
import {Charge} from "compax-api";
import {currency} from "../../../../../core/utils";
import {Alert} from "../../../../components/common/Alert/Alert.component";
import {
    NfoActivationDate
} from "../../../../components/partner/newCustomer/configureProduct/activationDate/activationDate.component";


type Props = {
    setShowVoiceChannelModal: any;
    setShowVoiceChannelConfirmationModal: any;
    loadSipTrunks: any;
    sipTrunkName: string;
    sipTrunkOptionId: number;
    selectedSipTrunkId: number;
}

type AppDispatch = ThunkDispatch<any, any, AnyAction>;

export const EditVoiceChannelConfirmationModalComponent = ({ setShowVoiceChannelModal, setShowVoiceChannelConfirmationModal, loadSipTrunks, sipTrunkName, sipTrunkOptionId, selectedSipTrunkId}: Props) => {
    const internalClassName = "ostSummaryModal";
    const dispatch: AppDispatch = useDispatch();
    const theme = useTheme();
    const selectedPartnerCustomer: IPartnerCustomer = useSelectPartnerCustomer() as IPartnerCustomer;
    const sipTrunks: any = useSelectSipTrunks();
    const sipTrunkOptions: any = useSelectEditSipTrunk();
    const basket: any = sipTrunkOptions.basket;
    const selectedProduct = sipTrunkOptions.basket.entries[0].options[0];
    const currentChannel: any = sipTrunkOptions.possibleOptions.find((option: any) => option.serviceGroup.id === ID_SERVICE_GROUPS.VOICE_CHANNELS)?.basketOptions.find((basketOption: any) => basketOption.product.id === sipTrunkOptionId);
    const chargesMonthly: Charge | undefined = currentChannel?.product.charges?.find((charge: Charge) => charge.chargeType?.id === ID_CHARGE_TYPES.BASIC_FEE);
    const { showToast } = useToast();
    const error = useSelectError();
    const oldQuantity = sipTrunks.find((item: any) => item.id === selectedSipTrunkId)?.sipTrunkOptions?.find((item: any) => item.serviceGroup.id === ID_SERVICE_GROUPS.VOICE_CHANNELS).quantity
    const oldAmountNet = currentChannel.product.charges.find((charge: any) => charge.chargeType.id === ID_CHARGE_TYPES.BASIC_FEE).amountNet
    const [activationDate, setActivationDate] = useState();
    const [activationDateErrors, setActivationDateErrors] = useState();

    const translationsConfirm = useTranslations(TRANSLATIONS.confirmationModal);
    const translations = useTranslations(TRANSLATIONS.editSipTrunk);

    const goBack = () => {
        deleteOption();
        setShowVoiceChannelConfirmationModal(false);
        setShowVoiceChannelModal(true);
    }

    const deleteOption = () => {
        dispatch(
            apiCallAction(ACTION_CONST.API_BASKET_DELETE_OPTION,
                {
                    basketId: sipTrunkOptions.basket.id,
                    uuid: currentChannel?.parentUuid,
                    optionId: currentChannel?.product.id,
                }, true, null, true)
        );
    }

    const submitOrder = () => {
        dispatch(
            apiCallAction(ACTION_CONST.API_OST_POST_SUBMIT_CHANGE, { basketId: sipTrunkOptions.basket.id, selectedPartnerCustomerId: selectedPartnerCustomer.id, changeRequestDate: activationDate}, true)
        ).then(() => {
            loadSipTrunks(); // reload sip-tunk table
            setShowVoiceChannelConfirmationModal(false)
            showToast(ToastTypes.SUCCESS, translationsConfirm.reduceSuccess());
        })
        .catch(() => {
            setShowVoiceChannelConfirmationModal(false)
            showToast(ToastTypes.ERROR, translationsConfirm.orderError(), translationsConfirm.tryAgain());
        })
    }

    return (
        <CpxModal
            className={`${internalClassName}-voiceChannelModal`}
            onConfirm={submitOrder}
            onCancel={() => setShowVoiceChannelConfirmationModal(false)}
            onBack={goBack}
            disableButton={!activationDateErrors}
        >
            <h3 className={cs('modalTitle', `modalTitle-le--${theme}`)}>
                {translations.titleSummary()}
            </h3>
            <p className={cs('modalInfoText', `modalInfoText-le--${theme}`)}>{translations.subTextSummary()}</p>
            <NfoActivationDate setActivationDate={setActivationDate} className={'basketView'} startDtRequested={currentChannel.startDtRequested} isValid={setActivationDateErrors}/>
            <h4 className={cs(`${internalClassName}__name-le--${theme}`)}>{currentChannel && currentChannel?.product?.displayValue}</h4>
            <article className={'modalTable'}>
                <CpxPaginatedTable
                    id={'ostSummaryTable'}
                    tableHeader={[
                        translations.amount(),
                        translations.product(),
                        translations.pricePerMonth() + '*',
                    ]}
                    tableData={[
                        {
                            id: '1',
                            cellData: [
                                <p><span className={'line-through'}>{oldQuantity}</span> {selectedProduct && selectedProduct?.quantity}</p>,
                                sipTrunkOptions.possibleOptions.find((option: any) => option.serviceGroup.id === ID_SERVICE_GROUPS.VOICE_CHANNELS).serviceGroup.description,
                                selectedProduct && chargesMonthly?.currency && <p><span className={'line-through'}>{currency(oldAmountNet*oldQuantity, chargesMonthly.currency.description)}</span> {currency(selectedProduct.recurringCharges[0].amountNet, chargesMonthly.currency.description)}</p>,
                            ],
                        }
                    ]}
                />
            </article>
            <div className={cs(`${internalClassName}-total-sum`, `${internalClassName}-total-sum-le--${theme}`)}>
                <p>
                    {translations.totalNet()}
                </p>
                <p><span className={'line-through'}>{currency(oldAmountNet*oldQuantity, chargesMonthly?.currency?.description)}</span> {basket && chargesMonthly?.currency && currency(basket.total.recurring.amountNet, chargesMonthly.currency.description)}</p>
            </div>
            {error?.errorData && error?.errorData?.length > 0 && <Alert errors={error?.errorData}/> }
        </CpxModal>
    )
}