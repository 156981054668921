import { CpxPaginatedTable } from "../../../../../core/components/paginatedTable.component";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";
import { useGetSites, useTranslations } from "../../../../utils/helper/utils";
import "../../../../pages/customer/myData/myData.scss";
import React, { useEffect, useState } from "react";
import cs from "classnames";
import { useTheme } from "../../../../../core/utility/themeContext";
import { Address } from "compax-api";
import { NfoSitesHelpBox } from "../../sites/sitesHelpBox/sitesHelpBox.component";
import { useDispatch } from "react-redux";

export const NfoSites = () => {
    const translations = useTranslations(TRANSLATIONS.myData);
    const sites = useGetSites();
    const internalClassName = "tableBox";
    const theme = useTheme();
    const [data, setData] = useState(sites);
    const dispatch = useDispatch();

  useEffect(() => {
    setData(sites);
  }, [sites]);

    const sitesTableRowData = (address: Address) => ({
      id: "" + address.id,
      cellData: [
        <div className={"name"}>
          <p>{address.companyName}</p>
          <p>
            {address.firstName} {address.lastName}
          </p>
        </div>,
        `${address.street} ${address.houseNumber}`,
        address.zip,
        address.city,
        address.country.description,
        address.role?.description,
        <NfoSitesHelpBox addressTypeId={address.role?.id}/>
      ],
    });

    return (
        <article className={cs(internalClassName, `${internalClassName}-le--${theme}`)}>
            <CpxPaginatedTable
                id="mydataSitesTable"
                className='sitesTable'
                caption={translations.subheadingLocations()}
                itemsPerPage={3}
                tableHeader={[
                  { label: translations.tableHeadingName(), sort: true, sortBy: 'companyName', sortMethod: 'string' },
                  { label: translations.tableHeadingStreet(), sort: true, sortBy: 'street', sortMethod: 'string' },
                  { label: translations.tableHeadingPostalCode(), sort: true, sortBy: 'zip', sortMethod: 'string' },
                  { label: translations.tableHeadingCity(), sort: true, sortBy: 'city', sortMethod: 'string' },
                  { label: translations.tableHeadingCountry(), sort: true, sortBy: 'country.description', sortMethod: 'string' },
                  { label: translations.tableHeadingType(), sort: true, sortBy: 'role.description', sortMethod: 'string' },
                ]}
                tableData={data?.map(sitesTableRowData)}
                rowData={data}
                getSortedRowsBack={(sortedRows: any) => setData(sortedRows?.slice())}
            />
        </article>
    );
};

