import React from "react";
import "./sdrTable.scss"
import cs from "classnames";
import { CpxDownloadTable } from "../../../../core/components/downloadTable.component";
import { CpxDownload } from "../../../../core/components/download.component";
import { useTranslations } from "../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../constants/transitions/uiTranslations";
import { useTheme } from "../../../../core/utility/themeContext";
import { apiCallAction } from "../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../constants/action.constants";
import { useDispatch } from "react-redux";
import { useSDRTable } from "../../../utils/bills/utils";
import { validate } from "../../../../core/utils";

export const NfoSDRTable = () => {
  const dispatch = useDispatch();
  const translations = useTranslations(TRANSLATIONS.sdr);
  const theme = useTheme();

  const getSDRCSVFile = (id: string) => {
    return async () => {
      // @ts-ignore
      const response: {
        billFile: { file: string }
      } = await dispatch(apiCallAction(ACTION_CONST.API_GET_WHOLESALER_SDR_CSV,
        { sdrDocumentId: id }
      ))
      return `${response.billFile?.file}`;
    }
  }

  const tableData = useSDRTable([
    {
      getterCreator: getSDRCSVFile,
      filenameGetter: sdr => validate(sdr.billName + '.csv'),
      downloadType: "application/csv",
    }
  ]);

  return (
    <>
      {tableData && tableData.length !== 0 &&
        <article className={cs('commissionTable sdrTable', `commissionTable-le--${theme}`)}>
          <CpxDownloadTable
            title={translations.title()}
            headers={[
              { label: translations.tableHeadingDate(), sort: true, sortBy: 'cols.0', sortMethod: 'date' },
              { label: translations.tableHeadingName(), sort: true, sortBy: 'cols.1', sortMethod: 'string' },
            ]}
            tableData={tableData ? tableData : []}
            downloadsProps={[
              {
                linkName: (
                  <>
                    <CpxDownload/>
                    {translations.csvLinkName()}
                  </>
                ),
                zipLinkName: translations.allCsvsName(),
                zipFileName: translations.zipFileNameCsv(),
              }
            ]}
          />
        </article>
      }
    </>
  )
}
