import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { PortalBill } from "compax-api";
import { selectCurrentLanguage } from "../../../../../core/uiLanguage/lang.slice";
import { validate } from "../../../../../core/utils";
import { routes } from "../../../../constants/routes/routes";
import { useSelectBills, useSelectLang, } from "../../../../redux/store";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";
import { useTranslations } from "../../../../utils/helper/utils";
import styles from "./downloads.module.scss";
import { NfoDownloadSection } from "../downloadSection/downloadSection.component";
import { apiCallAction } from "../../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../../constants/action.constants";
import { IBills } from "../../../../constants/types/types.constants";

export const NfoDownloads = () => {
  const dispatch = useDispatch();
  const translations = useTranslations(TRANSLATIONS.myContract);
  const currentLang = selectCurrentLanguage(useSelectLang());
  const bills = useSelectBills() as IBills
  const latestBill: PortalBill = bills && Array.isArray(bills) ? bills
    .sort((a, b) =>
      !!a.invoiceDate && !!b.invoiceDate
        ? b.invoiceDate.valueOf() - a.invoiceDate.valueOf()
        : 0
    )[0] : {}


  useEffect(() => {
    dispatch(apiCallAction(ACTION_CONST.API_GET_BILLS, { language: currentLang }));
  }, [dispatch, currentLang]);

  if (!latestBill) {
    return null;
  }

  const getBillFile = (id: string) => {
    return async () => {
      // @ts-ignore
      const response: { billFile: { file?: string } } = await dispatch(apiCallAction(ACTION_CONST.API_GET_BILL_FILE, { billId: id }))
      return `${response.billFile?.file}`;
    }
  }


  const getBillFileEnv = (id: string) => {
    return async () => {
      // @ts-ignore
      const response: { itemizedBillFile: { file?: string } } = await dispatch(apiCallAction(ACTION_CONST.API_GET_BILL_FILE_ENV, { billId: id }))
      return `${response.itemizedBillFile?.file}`;
    }
  }

  return (
    <article className={styles.downloads}>
      <NfoDownloadSection
        heading={translations.lastBill()}
        downloadTitle={validate(latestBill?.billFileName)}
        downloadUrl="./"
        filename={validate(latestBill?.billFileName)}
        getFile={getBillFile(validate(latestBill?.id))}
        moreLinkTo={routes.customerBills.path}
      />
      <NfoDownloadSection
        heading={translations.lastItemized()}
        downloadTitle={validate(latestBill?.itemizedBillFileName)}
        downloadUrl="./"
        filename={validate(latestBill?.itemizedBillFileName)}
        getFile={getBillFileEnv(validate(latestBill?.id))}
        moreLinkTo={routes.customerItemized.path}
      />
    </article>
  );
};
