import { ReactComponent as Warning } from '../../portal/assets/icons/attention_white.svg';
import { useTheme } from '../../core/utility/themeContext';
import './terminationWarningBox.scss';
import cs from "classnames";

type Props = {
  warnings: string[];
};

export const CpxWarningBox = ({ warnings }: Props) => {
  const theme = useTheme();

  const generateWarningEntry = (content: string, index: number) => {
    return (
      <div key={index} className={'warning-entry'}>
        <Warning className={'warning-icon'}/>
        {content}
      </div>
    );
  };

  const warningEntries = warnings.map((warning, index) => {
    return generateWarningEntry(warning, index);
  });

  return (
    <div className={cs(`warning-box`, `warning-box-le--${theme}`)}>
      {warningEntries}
    </div>
  )
}

