import { IPartnerCustomerContracts } from "../../../../../constants/types/types.constants";
import { PortalContractData } from "compax-api";

type Props = {
    parentId: string;
    contracts: IPartnerCustomerContracts
    className?: string;
};

export const NfoPartnerSubContracts = ({ parentId, contracts, className }: Props) => {
    const childContracts = contracts.filter((contract: PortalContractData) => contract.componentOf === parseInt(parentId, 10));
    return (
        <>
            {childContracts && childContracts.map(contract => (
                <div key={contract.id} className={className}>
                    <p>{contract.contractName}</p>
                    {contract.contractDescription && (
                        <p>{contract.contractDescription}</p>
                    )}
                    <NfoPartnerSubContracts contracts={contracts} parentId={contract.id + ""}/>
                </div>
            ))}
        </>
    );
};
