import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import '../../components/auth/auth.scss';
import '../../components/auth/authForm.scss';
import '../../../App.scss';
import { useTheme } from "../../../core/utility/themeContext";
import MainConfigConstants from "../../constants/configs/main.config.constants";
import { routes } from "../../constants/routes/routes";
import { selectCurrentLanguage } from "../../../core/uiLanguage/lang.slice";
import { useSelectLang } from "../../redux/store";


export const NfoSetNewPasswordPage = () => {
  const query = new URLSearchParams(useLocation().search);
  const userId = query.get('userId');
  const token: string = query.get('token')!;
  const theme = useTheme();
  const currentURL = window.location.href.split(routes.setNewPassword.path)[0];
  const lang = selectCurrentLanguage(useSelectLang());


  useEffect(() => {
    const redirectUrl = encodeURIComponent(`${currentURL}${routes.login.path}`)

    window.location.href = process.env.REACT_APP_AUTH_URL + MainConfigConstants.API.OAUTH2.REQUESTS.NEW_PASSWORD.URL_APPENDIX +
      "?client_id=" + process.env.REACT_APP_CLIENT_ID +
      "&userId=" + userId +
      "&token=" + token +
      "&redirect_uri=" + redirectUrl +
      "&user_language=" + lang.toLowerCase() +
      "&client_style=" + theme;
  }, [])


  return (
    <></>
  );
};
