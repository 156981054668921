import React, { useEffect, useState } from 'react';
import { useTranslations } from '../../portal/utils/helper/utils';
import { TRANSLATIONS } from '../../portal/constants/transitions/uiTranslations';
import { Link } from 'react-router-dom';

import { ID_PASSWORD, ID_PASSWORD_CONFIRM, } from '../../portal/constants/configs/config.constants';
import commonStyles from '../../portal/sass/base/common.module.scss';
import { routes } from '../../portal/constants/routes/routes';
import cs from 'classnames';
import { CpxPasswordInput } from './passwordInput.component';
import styles from '../../portal/pages/setNewPassword/setNewPassword.module.scss';
import { CpxPasswordStrenght } from './passwordStrength.component';
import { CpxInputWithLabel } from './inputWithLabel.component';
import { CpxButton } from './button.component';
import './changePassword.scss';
import { useTheme } from "../utility/themeContext";
import { useToast } from "../../portal/components/common/header/toast/toast.component";
import { ToastTypes } from "../../portal/constants/types/types.constants";

export const CpxChangePassword = ({
                                    formSubmit,
                                    setNewPasswordPage,
                                    formClassName,
                                    currentPwInput,
                                    onCurrentPwdInput,
                                    onNewPwdInput,
                                    onNewPwdConfirmationInput,
                                    buttonClassName,
                                    passwordCriteriaClass,
                                    successPassword,
                                    hasError,
                                  }: any) => {
  const isLoading = false;
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [isValid, setIsValid] = useState(false);
  const translationsAuth = useTranslations(TRANSLATIONS.auth);
  const translations = useTranslations(TRANSLATIONS.profile);
  const passwordInput = password ? 'passwordInput' : '';
  const currentPasswordInput = hasError ? 'currentPw' : '';
  const internalClassName = "changePassword";
  const theme = useTheme();
  const { showToast } = useToast();

  useEffect(() => {
    if (successPassword) {
      showToast(ToastTypes.SUCCESS, translationsAuth.newPasswordSuccess());
      setPassword('');
      setPasswordConfirmation('');
      setCurrentPassword('');
    }
  }, [successPassword])

  const handleNewPasswordChange = (e: any) => {
    setPassword(e.currentTarget.value);
    if (onNewPwdInput) {
      onNewPwdInput(e.currentTarget.value);
    }
  };

  const handleNewPasswordConfirmationChange = (e: any) => {
    setPasswordConfirmation(e.currentTarget.value);
    if (onNewPwdConfirmationInput) {
      onNewPwdConfirmationInput(e.currentTarget.value);
    }
  };

  const handleOldPasswordChange = (e: any) => {
    setCurrentPassword(e.currentTarget.value);
    if (onCurrentPwdInput) {
      onCurrentPwdInput(e.currentTarget.value);
    }
  };

  return (
    <form onSubmit={formSubmit} className={cs(formClassName, internalClassName)}>
      {setNewPasswordPage && (
        <>
          <h3 className={cs(`${internalClassName}-title-le--${theme}`)}>{translationsAuth.newPasswordTitle()}</h3>
          <p className={cs(`${internalClassName}-context ${internalClassName}-context-le--${theme}`)}>{translationsAuth.newPasswordDescription()}</p>
          <h5 className={cs(`${internalClassName}-subTitle-le--${theme}`)}>{translationsAuth.newPasswordFormTitle()}</h5>
        </>
      )}

      <div className={cs({ [commonStyles.isLoading]: isLoading })}>
        {currentPwInput && (
          <CpxInputWithLabel
            id="currentPassword"
            type="password"
            value={currentPassword}
            onChange={handleOldPasswordChange}
            className={currentPasswordInput}
            autoComplete={'off'}
            required
          >
            {translations.currentPassword()}
          </CpxInputWithLabel>
        )}
        {hasError && <p className={cs(`currentPwError currentPwError-le--${theme}`)}>{translationsAuth.errorPasswordSubmit()}</p>}
        <CpxPasswordInput
          id={ID_PASSWORD}
          value={password}
          autoComplete={'off'}
          required
          onChange={handleNewPasswordChange}
          className={cs(styles.noMargin)}
          buttonClassName={buttonClassName}
        >
          {translationsAuth.newPasswordNewPassword()}
        </CpxPasswordInput>

        <div className={passwordCriteriaClass}>
          <CpxPasswordStrenght
            password={password}
            textMinChars={translationsAuth.pwStrengthMinChars()}
            textPasswordCriteria={translationsAuth.pwStrengthPasswordCriteria()}
            textPasswordStrength={translationsAuth.pwStrengthPasswordStrength()}
            textSpecial={translationsAuth.pwStrengthSpecial()}
            textUpperLower={translationsAuth.pwStrengthUpperLower()}
            minChars={8}
            onChangeValid={setIsValid}
          />
        </div>

        <CpxInputWithLabel
          id={ID_PASSWORD_CONFIRM}
          type="password"
          value={passwordConfirmation}
          className={passwordInput}
          inputClassName={passwordInput}
          autoComplete={'off'}
          required
          onChange={handleNewPasswordConfirmationChange}
        >
          {translationsAuth.newPasswordConfirm()}
        </CpxInputWithLabel>
        {passwordConfirmation &&
          (passwordConfirmation !== password ? (
            <p className={cs(`repeatNewPwError repeatNewPwError-le--${theme}`)}>
              {translationsAuth.errorPasswordConfirm()}
            </p>
          ) : (
            ''
          ))}
        <div className={cs(`${internalClassName}-actions`)}>
          <CpxButton
            type="submit"
            disabled={password !== passwordConfirmation || !isValid}
          >
            {translationsAuth.newPasswordSubmit()}
          </CpxButton>
        </div>
      </div>
    </form>
  );
};
