import { PortalContractData } from 'compax-api';
import { CpxStart } from '../../../../core/components/start.component';
import { CpxEnd } from '../../../../core/components/end.component';
import { NfoStatus } from '../../../../core/components/status.component';
import '../../../../core/components/paginatedTable.scss';
import { useSelectContracts, useSelectLang } from '../../../redux/store';
import { ID_CONTRACT_CHANNEL_OPTION, ID_CONTRACT_CLIP_OPTION, ID_CONTRACT_MS_TEAMS, ID_DATA_ENTITY, } from '../../../constants/configs/config.constants';
import { useTranslations } from '../../../utils/helper/utils';
import { TRANSLATIONS } from '../../../constants/transitions/uiTranslations';
import { IContracts } from '../../../constants/types/types.constants';
import './sipTrunk.scss';
import cs from 'classnames';
import { useTheme } from '../../../../core/utility/themeContext';
import { CpxPaginatedTable } from "../../../../core/components/paginatedTable.component";
import { NfoSwitchAllProducts } from "../../switchActiveProducts/switchActiveProducts.component";
import React, { useState } from "react";
import { formatDate } from "../../../../core/utils";

type Props = {
  contract: PortalContractData;
  countSip: number;
};

export const NfoSipTrunkBox = ({ contract, countSip }: Props) => {
  const contracts = useSelectContracts() as IContracts;
  const theme = useTheme();
  const translations = useTranslations(TRANSLATIONS.contractDetails);
  const internalClassName = "sipTrunkTable";
  const [filteredItems, setFilteredItems] = useState([])
  const language = useSelectLang().selected;


  const channelOptions = contracts.filter(
    entry =>
      entry.componentOf === contract.id &&
      entry?.serviceType?.id + '' === ID_CONTRACT_CHANNEL_OPTION
  );

  const subOptions = contracts.filter(
    entry =>
      entry.componentOf === contract.id &&
      (entry?.serviceType?.id + '' === ID_CONTRACT_CLIP_OPTION || entry?.serviceType?.id + '' === ID_CONTRACT_MS_TEAMS)
  );

  let allOptions = []
  if (!!channelOptions && channelOptions.length > 0) {
    allOptions.push(...channelOptions)
  }
  if (!!subOptions && subOptions.length > 0) {
    allOptions.push(...subOptions)
  }

  const sipTrunkRowData = (channelOption: any) => {
    return {
      id: channelOption.id + "",
      cellData: [
        <div className={cs(`line-le--${theme}`)}>
          <p className={cs("component-title", `component-title-le--${theme}`)}>{channelOption.contractName}</p>
        </div>,
        <div className={cs("startdate", `startdate-le--${theme}`, `line-le--${theme}`)}>
          {channelOption.startDateBilling && (
            <>
              <CpxStart/>
              {formatDate(new Date(channelOption.startDateBilling as Date), language)}{' '}
            </>
          )}
        </div>,
        <div className={cs('enddate', `enddate-le--${theme}`, `line-le--${theme}`)}>
          {channelOption.endDateBilling && (
            <>
              <CpxEnd/>
              {formatDate(new Date(channelOption.endDateBilling as Date), language)}
            </>
          )}
        </div>,
        <div className={cs(`line-le--${theme}`)}>
          <NfoStatus
            entity={{ id: ID_DATA_ENTITY.SERVICE }}
            status={channelOption.status}
            statusClassName={cs('status', `status-le--${theme}`)}
          />
        </div>
      ]
    }
  }

  return (
    <article className={cs(internalClassName, `${internalClassName}-le--${theme}`)}>
      <div className={'sipTrunk'}>
        <div>
          <h4 className={cs('title', `title-le--${theme}`)}>
            {contract.contractName}
          </h4>
          <p>Service ID: {contract.id}</p>
        </div>
      </div>
      <NfoSwitchAllProducts data={allOptions} getFilteredDataBack={(data: any) => setFilteredItems(data)}/>
      <CpxPaginatedTable
        id="sipTrunk"
        tableHeader={[
          { label: translations.languagePackOption(), sort: true, sortBy: 'contractName', sortMethod: 'string' },
          { label: translations.contractStart(), sort: true, sortBy: 'startDateBilling', sortMethod: 'date' },
          { label: translations.contractEnd(), sort: true, sortBy: 'endDateBilling', sortMethod: 'date' },
          { label: translations.status(), sort: true, sortBy: 'status.id', sortMethod: 'number' },
        ]}
        tableData={filteredItems.map(sipTrunkRowData)}
        rowData={filteredItems}
        getSortedRowsBack={(sortedRows: any) => setFilteredItems(sortedRows?.slice())}
      />

    </article>
  );
};
