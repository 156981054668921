import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import { clearError } from "../redux/actions/error.action";

const PublicRoute = ({children, loggedIn, ...rest }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(clearError())
  }, [dispatch])

  return (
    <Route {...rest}>
      {loggedIn ? <Redirect to={`/`}/> : children}
    </Route>
  )
}

PublicRoute.propTypes = {}

export default PublicRoute
