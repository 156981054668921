import { useTranslations } from "../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { saveCurrentStep, saveStep3Data } from "../../../../redux/actions/step.action";
import { NfoCustomerWrapperComponent } from "../wrapper/newCustomerWrapper.component";
import { useTheme } from "../../../../../core/utility/themeContext";
import cs from "classnames";
import { ID_CONTRACT_TELEPHONE_CONNECTION } from "../../../../constants/configs/config.constants";
import { useCurrentStep, useOEProductOptions, useOrderEntryBasket, useSelectLang, useStep1Data, useStep3Data, useStepperActivationDate, } from "../../../../redux/store";
import { useFormik } from "formik";
import { CpxFormikForm } from "../../../../../core/components/formikForm.component";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { apiCallAction } from "../../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../../constants/action.constants";
import { clearError } from "../../../../redux/actions/error.action";
import { Basket, OptionGroup } from "compax-api";
import { NfoOrderEntryTelephoneConnections } from "../configureProduct/orderEntryTelephoneConnections";
import { selectCurrentLanguage } from "../../../../../core/uiLanguage/lang.slice";

type AppDispatch = ThunkDispatch<any, any, AnyAction>;

export const NfoCustomerConfigureTelephoneConnectionsStep = () => {
    const translationsStepper = useTranslations(TRANSLATIONS.stepper.newCustomer);
    const dispatch: AppDispatch = useDispatch();
    const theme = useTheme();
    const currentStep = useCurrentStep();
    const basket: Basket = useOrderEntryBasket();
    const stepOneData = useStep1Data();
    const oEProductOptions = useOEProductOptions();
    const [telephoneConnections, setTelephoneConnections] = useState<Array<OptionGroup> & any>([]);
    const currentLang = selectCurrentLanguage(useSelectLang());
    const activationDate = useStepperActivationDate();
    const step3Data = useStep3Data();


    useEffect(() => {
        setTelephoneConnections(filteredTelephoneConnections);
    }, [oEProductOptions])

    useEffect(() => {
        dispatch(apiCallAction(ACTION_CONST.API_GET_OE_PRODUCT_OPTIONS,
            {
                basketId: basket?.id,
                uuid: step3Data?.activeEntryUuid,
                language: currentLang
            }, true))
    }, [currentLang]);


    const filteredTelephoneConnections = Array.isArray(oEProductOptions) && Object.values(
        oEProductOptions?.reduce((basketOptions: OptionGroup[], item: any) =>
            basketOptions?.concat(item.basketOptions?.filter((basketOption: any) => basketOption?.product?.productType?.id === +ID_CONTRACT_TELEPHONE_CONNECTION)), [])
    );


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {},
        onSubmit: async (values) => {

            const validateValues = {
                ...stepOneData,
                stepper: "VALIDATE_BASKET_OPTION",
                basketId: basket?.id,
                validateBasketEntryIndex: step3Data?.activeIndex
            }



            dispatch(apiCallAction(ACTION_CONST.API_VALIDATE_CUSTOMER_DATA, validateValues)).then(() => {
                dispatch(apiCallAction(ACTION_CONST.API_BASKET_PUT_ACTIVATION_DATE,
                  {uuid:  step3Data?.activeEntryUuid, basketId: basket?.id, activationDate}
                ))
                dispatch(saveCurrentStep(stepOneData?.migrationCustomerId ? 8 : 5));
                if (!stepOneData?.migrationCustomerId) {
                    dispatch(saveStep3Data([]))
                }
                dispatch(clearError());
            }).catch(() => window.scrollTo({top: 0, behavior: 'smooth'}))
        }
    })


    return (
        <>
            <NfoCustomerWrapperComponent
                title={translationsStepper.telephoneConnectionConfiguration()}
                subtitle={translationsStepper.productConfigurationSubTitle()}
            >
                <>
                    <CpxFormikForm handleSubmit={formik.handleSubmit}
                                   initialValues={formik.initialValues}
                                   id={'new-customer-form'}
                    >
                    </CpxFormikForm>
                    <div className={cs(`options`, `options-le--${theme}`)}>
                        {telephoneConnections && <NfoOrderEntryTelephoneConnections
                            telephoneConnectionsObject={telephoneConnections}
                        />}
                    </div>
                </>
            </NfoCustomerWrapperComponent>
        </>
    );
};
