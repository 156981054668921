import React, { useEffect } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { useSelectApiCallStatus, useSelectPkceCodeChallenge } from "../../redux/store";
import { useDispatch } from "react-redux";
import { loginViaAuthServer } from "../../redux/actions/apiCall.action";
import OAUTH2_GRANT_TYPES from '../../constants/main.oauth2-grant-types';
import { LOCALSTORAGE_REFRESHTOKEN } from "../../constants/configs/config.constants";
import { deleteSelectedPartnerCustomer } from "../../redux/actions/partnerCustomers.action";


export const NfoCallbackPage = () => {
  const query = new URLSearchParams(useLocation().search);
  const code = query.get('code');
  const { code_verifier } = useSelectPkceCodeChallenge();
  const dispatch = useDispatch();
  const currentURL = window.location.href.split('?')[0];
  const isLoggedIn = useSelectApiCallStatus().loggedIn && localStorage.getItem(LOCALSTORAGE_REFRESHTOKEN);

  useEffect(() => {
    if (!isLoggedIn && code) {
      let formObject = {
        grant_type: OAUTH2_GRANT_TYPES.AUTHORIZATION_CODE,
        code: code,
        redirect_uri: currentURL,
        code_verifier: code_verifier,
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_NOT_SO_SECRET,
      }
      dispatch(loginViaAuthServer(formObject))

    }
    dispatch(deleteSelectedPartnerCustomer());
  }, [code, code_verifier, currentURL, dispatch, isLoggedIn])

  return (
      <>
      {!code && <Redirect to={`/`}/>}
      </>
  );
};
