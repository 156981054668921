import { useTheme } from '../../../../../../core/utility/themeContext';
import { TRANSLATIONS } from '../../../../../constants/transitions/uiTranslations';
import { ReactComponent as World } from '../../../../../assets/icons/world_nfon.svg';
import { ReactComponent as Mail } from '../../../../../assets/icons/mail.svg';
import { IPartnerCustomer, } from '../../../../../constants/types/types.constants';
import cs from 'classnames';
import { useTranslations } from '../../../../../utils/helper/utils';
import { NfoInfoBlockWithIcon } from '../../../../../../core/components/infoBlock.component';
import './preferredContact.scss';

type Props = {
  selectedCustomer: IPartnerCustomer;
  className?: string;
};

export const NfoPartnerCustomerPreferredContact = ({
                                                     selectedCustomer,
                                                     className,
                                                     ...props
                                                   }: Props) => {
  const translations = useTranslations(TRANSLATIONS.partnerPages.data);
  const internalClassName = 'preferredContact';
  const theme = useTheme();


  return (
    <article className={cs(internalClassName, `${internalClassName}-le--${theme}`, className)} {...props}>
      <h4 className={`componentTitle componentTitle-le--${theme}`}>
        {translations.preferredContact()}
      </h4>
      <div className={'preferredContactBox'}>
        <NfoInfoBlockWithIcon
          title={
            <>
              <World/>
              {translations.language()}
            </>
          }
          className={`componentTitle-le--${theme}`}
        >
          <p>{translations.german()}</p>
        </NfoInfoBlockWithIcon>
        <NfoInfoBlockWithIcon
          title={
            <>
              <Mail/>
              {translations.preferredMethodOfContact()}
            </>
          }
          className={`componentTitle-le--${theme}`}
        >
          <p>E-Mail</p>
        </NfoInfoBlockWithIcon>
        <NfoInfoBlockWithIcon
          title={
            <>
              <Mail/>
              {translations.preferredMethodOfContact()}
            </>
          }
          classNameBox={'hidden'}
        />
      </div>
      {/* )
        )} */}
    </article>
  );
};
