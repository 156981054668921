import { TRANSLATIONS } from "../../../constants/transitions/uiTranslations";
import { useTranslations } from "../../../utils/helper/utils";
import { NfoChangePasswordForm } from "../../../components/common/profile/changePasswordForm/changePasswordForm.component";
import { NfoUserDataForm } from "../../../components/common/profile/userDataForm/userDataForm.component";
import { useTheme } from "../../../../core/utility/themeContext";
import { CpxTitle } from "../../../../core/components/title/title.component";
import commonStyles from "../../../sass/base/common.module.scss";
import "./profile.scss";
import { CpxSubtitle } from "../../../../core/components/title/subtitle.component";
import React, { useEffect } from "react";
import { FEATURES_FLAG } from "../../../constants/configs/config.constants";
import { apiCallAction } from "../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../constants/action.constants";
import { useDispatch } from "react-redux";

export const NfoProfilePage = () => {
  const translations = useTranslations(TRANSLATIONS.profile);
  const theme = useTheme();
  const dispatch = useDispatch();
  const internalClassName = "profileBox";

  useEffect(() => {
    dispatch(apiCallAction(ACTION_CONST.API_PORTAL_FEATURE, { featureName: FEATURES_FLAG.PORTAL_PARTNER_CUSTOM_INFO }))
  }, [])


  return (
    <>
      <CpxTitle
        title={translations.title()}
        internalClassName={internalClassName}
      />
      <CpxSubtitle
        subtitle={translations.subheading()}
        internalClassName={internalClassName}
      />
      <div className={commonStyles.columnContainer}>
        <div className={commonStyles.column}>
          <NfoUserDataForm/>
          <NfoChangePasswordForm/>
        </div>
      </div>
    </>
  );
};
