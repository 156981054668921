import { TRANSLATIONS } from "../../../constants/transitions/uiTranslations";
import { useTranslations } from "../../../utils/helper/utils";
import { NfoLicenseTable } from "../../../components/customer/licenses/licensesTable/licensesTable.component";
import { CpxTitle } from '../../../../core/components/title/title.component';
import { CpxSubtitle } from "../../../../core/components/title/subtitle.component";
import "./licenses.scss";
import { useEffect } from "react";
import { apiCallAction } from "../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../constants/action.constants";
import { useDispatch } from "react-redux";
import { useSelectedMainContract, useSelectLang, useSelectPartnerCustomer } from "../../../redux/store";
import { IPartnerCustomer } from "../../../constants/types/types.constants";
import { selectCurrentLanguage } from "../../../../core/uiLanguage/lang.slice";

export const NfoLicensesPage = () => {
  const translations = useTranslations(TRANSLATIONS.licenses);
  const internalClassName = "licenses-headerBox";
  const dispatch = useDispatch();
  const selectedPartnerCustomer: IPartnerCustomer = useSelectPartnerCustomer() as IPartnerCustomer;
  const selectedMainContract = useSelectedMainContract();
  const currentLanguage = selectCurrentLanguage(useSelectLang());

  useEffect(() => {
    loadLicenses();
  }, [currentLanguage, selectedMainContract]);

  const loadLicenses = () => {
    dispatch(
      apiCallAction(ACTION_CONST.API_GET_LICENSES_BY_SERVICE_GROUP, { contractId: selectedMainContract?.contractId, selectedPartnerCustomerId: selectedPartnerCustomer?.id, includeNotOrderedOptions: true }, true)
    );
  }

  return (
    <>
      <>
        <div className={internalClassName}>
          <CpxTitle
            title={translations.title()}
            internalClassName={internalClassName}
          />
        </div>
        <CpxSubtitle
          subtitle={translations.subheading()}
          internalClassName={internalClassName}
        />
      </>
      <NfoLicenseTable title={translations.title()} loadLicenses={loadLicenses}/>
    </>
  );
};

