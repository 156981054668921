import { ACTION_CONST } from "../../constants/action.constants";
import { PortalContractData } from "compax-api";

export const setUserRole = (data: any) => {
  return {
    type: ACTION_CONST.SET_USER_ROLE,
    data,
  };
};

export const setWholesaleModel = (data: any) => {
  return {
    type: ACTION_CONST.SET_WHOLESALE_MODEL,
    data,
  };
};

export const setMainProduct = (data: number) => {
  return {
    type: ACTION_CONST.SET_MAIN_PRODUCT,
    data,
  };
};

export const setSelectedContract = (data: PortalContractData) => {
  return {
    type: ACTION_CONST.SET_SELECTED_CONTRACT,
    data,
  };
};

export const unsetSelectedContract = () => {
  return {
    type: ACTION_CONST.UNSET_SELECTED_CONTRACT,
  }
}

export const setPkceCodeChallenge = (data: any) => {
  return {
    type: ACTION_CONST.SET_PKCE_CODE_CHALLENGE,
    data
  }
}

export const setPartnerCollapsibleStatus = (data: any) => {
  return {
    type: ACTION_CONST.PARTNER_COLLAPSIBLE_STATUS,
    data,
  }
}