import { CpxHelpBox } from "../../../../../core/components/helpBox.component";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";
import { useTranslations } from "../../../../utils/helper/utils";
import styles from "./itemizedHelpBox.module.scss";

export const NfoItemizedHelpBox = () => {
  const translations = useTranslations(TRANSLATIONS.helpBox);

  return (
    <CpxHelpBox className={styles.helpBox}>
      {translations
        .itemized()
        .split(/\r?\n/)
        .map((paragraph, key) => (
          <p key={key}>{paragraph}</p>
        ))}
    </CpxHelpBox>
  );
};
