import styles from "./inboundOutbound.module.scss";
import { ReactComponent as Check } from "../../../../assets/icons/check.svg";
import { ReactComponent as CheckDTS } from "../../../../assets/icons/check_dts.svg";
import { useTheme } from "../../../../../core/utility/themeContext";

type Props = {
  inbound: boolean;
  outbound: boolean;
};

export const NfoInbountOutbound = ({ inbound, outbound }: Props) => {
  const theme = useTheme();

  return (
    <div className={styles.inboundOutbound}>
      <>
        {theme === "dts" ? (
          <div>
              {inbound && <CheckDTS />}
              {outbound && <CheckDTS />}
          </div>
        ) : (
          <div>
              {inbound && <Check />}
              {outbound && <Check />}
          </div>
        )}
      </>
    </div>
  );
};
