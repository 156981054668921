import { useTheme } from "../utility/themeContext";
import { ReactComponent as Download } from "../../portal/assets/icons/download.svg";
import "./download.scss";
import cs from "classnames";

type Props = {
  className?: string;
};

export const CpxDownload = ({ className }: Props) => {
  const internalClassname = "cpxDownload";
  const theme = useTheme();
  return <Download className={cs(internalClassname, `${internalClassname}-le--${theme}`, className)} />
};
