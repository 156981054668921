import { ACTION_CONST } from "../../constants/action.constants";
import { LOCALSTORAGE_REFRESHTOKEN } from "../../constants/configs/config.constants";
import { IAction } from "../../constants/types/types.constants";


const initialState = {
	loading: false,
	error: false,
	loggedIn: !!localStorage.getItem(LOCALSTORAGE_REFRESHTOKEN)

}

function apiCallStatusReducer (state = initialState, action: IAction) {
	switch (action.type) {
		case ACTION_CONST.API_CALL_START:
			state = {
				...state,
				loading: true,
				error: false,
			}
			break
		case ACTION_CONST.API_CALL_DONE:
			state = {
				...state,
				loading: false,
				error: false,
			}
			break
		case ACTION_CONST.API_CALL_ERROR:
			state = {
				...state,
				loading: false,
				error: true,
			}
			break
		case ACTION_CONST.CLEAR_ERROR:
			state = {
				...state,
				error: false,
			}
			break
		case ACTION_CONST.API_LOGIN_CALL:
			state = {
				...state,
				loggedIn: true
			}
			break
		case ACTION_CONST.LOGOUT_CALL:
			state = {
				...state,
				loggedIn: false
			}
			break
	}

	return state
}

export default apiCallStatusReducer