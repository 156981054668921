import { ACTION_CONST } from "../../constants/action.constants";
import { IAction } from "../../constants/types/types.constants";

const initialState: never[] = [];

function partnerCommissionBillReducer(state = initialState, action: IAction) {
  switch (action.type) {
    case ACTION_CONST.API_GET_PARTNER_COMMISSION_DATA:
      return action.data;

    default:
      return state;
  }
}

export default partnerCommissionBillReducer;
