import { useEffect, useState } from 'react';
import { CpxSwitch } from '../../../core/components/switch.component';
import { useTranslations } from "../../utils/helper/utils";
import { TRANSLATIONS } from "../../constants/transitions/uiTranslations";
import "./switchActiveProducts.scss";
import { ID_STATUS_SERVICES } from "../../constants/configs/config.constants";

type Props = {
  onChange?: any;
  data?: any;
  getFilteredDataBack?: (data: any) => void;
};

export const NfoSwitchAllProducts = ({ data, getFilteredDataBack }: Props) => {
  const [showAll, setShowAll] = useState(false);
  const commonTranslations = useTranslations(TRANSLATIONS.common);
  const internalClassName = "switchActiveProducts";


  useEffect(() => {
    const filteredData = data?.filter((p: any) => p?.status?.id === ID_STATUS_SERVICES.ACTIVE || p?.status?.id === ID_STATUS_SERVICES.ORDERED);
    if (getFilteredDataBack) {
      if (!showAll) {
        getFilteredDataBack(filteredData);
      } else {
        getFilteredDataBack(data);
      }
    }
  }, [showAll, data])

  return (
    <span className={internalClassName}>
        <CpxSwitch
          className={'switch switch--right'}
          onChange={() => {
            setShowAll(!showAll);
          }}
          checked={showAll}
        />
      {commonTranslations.showAll()}
      </span>
  );
};
