import { useSelectPartnerCustomer } from '../../../../redux/store';
import { TRANSLATIONS } from '../../../../constants/transitions/uiTranslations';
import { useTranslations } from '../../../../utils/helper/utils';
import { CpxTitle } from '../../../../../core/components/title/title.component';
import { IPartnerCustomer } from '../../../../constants/types/types.constants';

export const NfoCustomersTasksPartnerPage = () => {
  const translations = useTranslations(TRANSLATIONS.partnerNav);
  const internalClassName = 'partner-customer-tasks';
  const selectedPartnerCustomer: IPartnerCustomer | undefined = useSelectPartnerCustomer();
  const isSelectedPartnerCustomer = (customer: any): customer is IPartnerCustomer => {
    return customer.id !== null;
  }

  return (
    <>
      {
        isSelectedPartnerCustomer(selectedPartnerCustomer) &&
        <CpxTitle
          title={translations.tasks()}
          name={
            selectedPartnerCustomer.companyName &&
            selectedPartnerCustomer.companyName
          }
          internalClassName={internalClassName}
        />
      }
    </>
  );
};
