import { CpxStart } from '../../../../../../core/components/start.component';
import { CpxEnd } from '../../../../../../core/components/end.component';
import { NfoStatus } from '../../../../../../core/components/status.component';
import './partnerTelephoneConnection.scss';
import { useTheme } from '../../../../../../core/utility/themeContext';
import cs from 'classnames';
import { ID_DATA_ENTITY } from '../../../../../constants/configs/config.constants';
import { IPartnerCustomerContracts, } from '../../../../../constants/types/types.constants';
import { useTranslations } from '../../../../../utils/helper/utils';
import { TRANSLATIONS } from '../../../../../constants/transitions/uiTranslations';
import { NfoPartnerSubContracts } from '../partnerSubContracts/partnerSubContracts.component';
import { PortalContractData } from "compax-api";
import { CpxPaginatedTable } from "../../../../../../core/components/paginatedTable.component";
import { NfoSwitchAllProducts } from "../../../../switchActiveProducts/switchActiveProducts.component";
import React, { useState } from "react";
import { useSelectLang } from "../../../../../redux/store";
import { formatDate } from "../../../../../../core/utils";

type Props = {
  telephoneConnection: PortalContractData;
  contracts: IPartnerCustomerContracts;
};

export const NfoPartnerTelephoneConnection = ({
                                                telephoneConnection,
                                                contracts,
                                              }: Props) => {
  const theme = useTheme();
  const internalClassName = 'telephoneConnection';
  const translations = useTranslations(TRANSLATIONS.telephoneDetails);
  const language = useSelectLang().selected;


  const childContracts = contracts.filter(
    entry => entry.componentOf === telephoneConnection.id
  );
  const [filteredItems, setFilteredItems] = useState(childContracts)

  const partnerTelephoneConnectionRowData = (childContract: any) => {
    return {
      id: childContract.id + "",
      cellData: [
        <div className={cs(`line-le--${theme}`)}>
          <p className={`telcon-row-title-le--${theme}`}>
            {childContract.contractName}
          </p>
          {childContract.contractDescription && (
            <p className={cs('desc', `desc-le--${theme}`)}>
              {childContract.contractDescription}
            </p>
          )}
          <NfoPartnerSubContracts
            parentId={childContract.id + ''}
            contracts={contracts}
            className={cs('subContract', `subContract-le--${theme}`, `desc-le--${theme}`)}
          />
        </div>,
        <div className={cs('startdate', `startdate-le--${theme}`, `line-le--${theme}`)}>
          {childContract.startDateBilling && (
            <>
              <CpxStart className={'startdate'}/>
              {formatDate(new Date(childContract.startDateBilling), language)}
            </>
          )}
        </div>,
        <div className={cs('startdate', `startdate-le--${theme}`, `line-le--${theme}`)}>
          {childContract.endDateBilling && (
            <>
              <CpxEnd className={'enddate'}/>
              {formatDate(new Date(childContract.endDateBilling), language)}
            </>
          )}
        </div>,
        <div className={cs(`line-le--${theme}`)}>
          <NfoStatus
            entity={{ id: ID_DATA_ENTITY.SERVICE }}
            status={childContract.status}
            statusClassName={cs('status', `status-le--${theme}`)}
          />
        </div>
      ]
    }
  }

  return (
    <article className={cs(internalClassName, `${internalClassName}-le--${theme}`)}>
      <div
        className={cs(
          'telephoneConnectionHeader',
          `telephoneConnectionHeader-le--${theme}`
        )}
      >
        <h4 className={cs('title', `title-le--${theme}`)}>
          {telephoneConnection.contractName}
        </h4>
      </div>
      <NfoSwitchAllProducts data={childContracts} getFilteredDataBack={(data: any) => setFilteredItems(data)}/>
      <CpxPaginatedTable
        id="partnerTelephoneConnection"
        tableHeader={[
          { label: translations.configurationOptions(), sort: true, sortBy: 'contractName', sortMethod: 'string' },
          { label: translations.contractStart(), sort: true, sortBy: 'startDateBilling', sortMethod: 'date' },
          { label: translations.contractEnd(), sort: true, sortBy: 'endDateBilling', sortMethod: 'date' },
          { label: translations.status(), sort: true, sortBy: 'status.id', sortMethod: 'number' },
        ]}
        rowData={filteredItems}
        tableData={filteredItems.map(partnerTelephoneConnectionRowData)}
        getSortedRowsBack={(sortedRows: any) => setFilteredItems(sortedRows?.slice())}
      />
    </article>
  );
};
