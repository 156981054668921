import './infoWarningBox.scss'
import { ReactComponent as Warning } from '../../../../../assets/icons/attention_white.svg'
import cs from "classnames";
import { useTheme } from "../../../../../../core/utility/themeContext";

type Props = {
   text: string;
};

export const InfoWarningBox = (
  {
    text,
  }: Props) => {
  const theme = useTheme();

    return (
      <>
        <div className={cs('infoWarningBox', `infoWarningBox-le--${theme}`)}>
            <div className={'warningEntry'}>
              <Warning className={'warningIcon'}/>
              <div>{text}</div>
            </div>
        </div>
      </>
    )
}
