import { CpxInputWithLabel } from "./inputWithLabel.component";
import { CpxPhoneInputWithLabel } from "./phoneInputWithLabel.component";
import React from "react";
import { useTranslations } from "../../portal/utils/helper/utils";
import { TRANSLATIONS } from "../../portal/constants/transitions/uiTranslations";
import './portalUserInputGroup.scss';
import { useTheme } from "../utility/themeContext";
import cs from "classnames";


export const AdminPortalUserInputGroup = ({ values, handleChange, touched, errors, }: any) => {
  const translation = useTranslations(TRANSLATIONS.stepper.newCustomer);
  const theme = useTheme();

  values = values?.contactPersons?.technicalContactPerson
  touched = touched?.contactPersons?.technicalContactPerson && touched?.contactPersons?.technicalContactPerson
  errors = errors?.contactPersons?.technicalContactPerson && errors?.contactPersons?.technicalContactPerson

  return (
    <div className="admin-portal-user">
      <div className="line-grey"/>
      <h5 className={cs(`sub-title sub-title-le--${theme}`)}>{translation.technicalContactPersonUser()} *</h5>
      <CpxInputWithLabel
        id="contactPersons.technicalContactPerson.email"
        type="text"
        onChange={handleChange}
        value={values.email.trimStart()}
        error={touched?.email && errors?.email}
      >
        {translation.email() + " *"}
      </CpxInputWithLabel>
      <CpxInputWithLabel
        id="contactPersons.technicalContactPerson.firstName"
        type="text"
        onChange={handleChange}
        value={values.firstName.trimStart()}
        error={touched?.firstName && errors?.firstName}
      >
        {translation.firstName() + " *"}
      </CpxInputWithLabel>
      <CpxInputWithLabel
        id="contactPersons.technicalContactPerson.lastName"
        type="text"
        onChange={handleChange}
        value={values.lastName.trimStart()}
        error={touched?.lastName && errors?.lastName}
      >
        {translation.lastName() + " *"}
      </CpxInputWithLabel>
      <CpxPhoneInputWithLabel
        fields={[{ name: 'contactPersons.technicalContactPerson.mobile.countryCode', value: values?.mobile?.countryCode, id: 'countryCode', placeholder: translation.countryCode() },
          { name: 'contactPersons.technicalContactPerson.mobile.areaCode', value: values?.mobile?.areaCode, id: 'areaCode', placeholder: translation.areaCode() },
          { name: 'contactPersons.technicalContactPerson.mobile.cli', value: values?.mobile?.cli, id: 'cli', placeholder: translation.cli() }
        ]}
        onChange={handleChange}
        errors={errors}
        touched={touched}
        label={translation.mobileNumber() + " *"}
        phoneType={values.mobile.phoneType.id}
      >
      </CpxPhoneInputWithLabel>
      <CpxPhoneInputWithLabel
        fields={[{ name: 'contactPersons.technicalContactPerson.phone.countryCode', value: values?.phone?.countryCode, id: 'countryCode', placeholder: translation.countryCode() },
          { name: 'contactPersons.technicalContactPerson.phone.areaCode', value: values?.phone?.areaCode, id: 'areaCode', placeholder: translation.areaCode() },
          { name: 'contactPersons.technicalContactPerson.phone.cli', value: values?.phone?.cli, id: 'cli', placeholder: translation.cli() }
        ]}
        onChange={handleChange}
        errors={errors}
        touched={touched}
        label={translation.phoneNumber()}
        phoneType={values.phone?.phoneType?.id}
      >
      </CpxPhoneInputWithLabel>
    </div>
  )
};
