import cs from 'classnames';
import { CpxProgressBar } from '../../../../../core/components/progressBar.component';
import { useTheme } from '../../../../../core/utility/themeContext';
import { useTranslations } from '../../../../utils/helper/utils';
import { TRANSLATIONS } from '../../../../constants/transitions/uiTranslations';
import { ReactComponent as Limit } from '../../../../assets/icons/limit.svg';
import './extensionsProgressBar.scss';

type Props = {
  extensions: any;
  showIcon: boolean;
};
export const ExtensionsProgressBar = ({ extensions, showIcon }: Props) => {
  const theme = useTheme();
  const translations = useTranslations(TRANSLATIONS.extensions);
  const unusedExtensionsAmount =
    typeof extensions?.extensionsTotal !== 'undefined'
      ? extensions?.extensionsTotal - extensions?.extensionsUsedTotal
      : '';

  const maxValue = extensions?.extensionsTotal;
  const currentValue = extensions?.extensionsUsedTotal;

  return (
    <>
      <div
        className={cs(
          'extensionsProgressBar',
          `extensionsProgressBar-le--${theme}`
        )}
      >
        <div className={cs('infoField', `infoField-le--${theme}`)}>
          <div className={'iconWithText'}>
            {showIcon && <Limit className={'progressBarIcon'}/>}
            <p className={cs('extensionsSum', `extensionsSum-le--${theme}`)}>
              {translations.totalExtensions()}
              <span>{extensions?.extensionsTotal}</span>
            </p>
          </div>
        </div>
        <CpxProgressBar
          max={maxValue}
          current={currentValue}
        />
        <div className={cs('modalExtensionsLegend', `modalExtensionsLegend-le--${theme}`)}>
          <div
            className={cs(
              'usedExtensionsLegend',
              `usedExtensionsLegend-le--${theme}`
            )}
          />
          <p>{translations.used()}</p>
          <span>{extensions?.extensionsUsedTotal}</span> <p>/</p>
          <div className={cs('freeExtensionsLegend',
            `freeExtensionsLegend-le--${theme}`)}/>
          <p>{translations.free()}</p> <span>{unusedExtensionsAmount}</span>
        </div>
      </div>
    </>
  );
};
