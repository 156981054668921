import { ACTION_CONST } from "../../constants/action.constants";
import { IAction } from "../../constants/types/types.constants";

const initialState: never[] = [];

function basketReducer(state = initialState, action: IAction) {
  switch (action.type) {
    case ACTION_CONST.API_POST_PARTNER_ADD_OPTIONS:
    case ACTION_CONST.API_POST_ADD_OPTIONS:
      return action.data;

    default:
      return state;
  }
}

export default basketReducer;
