import { CpxIcon } from "./components/icon.component";
import { ICONS } from "../portal/constants/configs/config.constants";
import React, { PropsWithoutRef, ReactNode } from "react";
import './emptyState.scss'
import cs from "classnames";
import { useTheme } from "./utility/themeContext";
import { useTranslations } from "../portal/utils/helper/utils";
import { TRANSLATIONS } from "../portal/constants/transitions/uiTranslations";

type Props = {
  icon?: string;
  children?: ReactNode;
  data: Array<any>;
  pageTitle: string;
  subtitle?: string;
  button?: PropsWithoutRef<JSX.IntrinsicElements['button']>;
  boxStyling?: boolean;
}

export const NfoEmptyState = ({ icon, children, data, pageTitle, subtitle, button, boxStyling }: Props) => {
  const translations = useTranslations(TRANSLATIONS.common);
  const internalClassName = "empty-state";
  const theme = useTheme();

  return (
    <>
      { (data !== undefined && data.length > 0) ? (
        children
      ) : (
        <div className={cs(
          `${internalClassName} ${internalClassName}-le--${theme}`,
          {
            boxStyling: boxStyling,
            [`boxStyling-le--${theme}`]: boxStyling
          }
        )}>
          <CpxIcon icon={icon ? icon : ICONS.NOTE} className={cs(`${internalClassName}-icon ${internalClassName}-icon-le--${theme}`)}/>
          <h4 className={cs(`${internalClassName}-title ${internalClassName}-title-le--${theme}`)}>{translations.emptyStateTitle(pageTitle)}</h4>
          <p className={cs(`${internalClassName}-sub-title ${internalClassName}-sub-title-le--${theme}`)}>{subtitle ? subtitle : translations.emptyStateSubTitle()}</p>
          <div className={cs(`${internalClassName}-button ${internalClassName}-button-le--${theme}`)}>{ button }</div>
        </div>
      )}
    </>
  )
}