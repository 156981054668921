import './personInChargeList.scss';
import { ReactComponent as Tech } from '../../../../assets/icons/tech.svg';
import { ReactComponent as User } from '../../../../assets/icons/user.svg';
import cs from 'classnames';
import { ContactPerson } from "compax-api";
import { useTranslations } from "../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";
import { useTheme } from "../../../../../core/utility/themeContext";
import { ID_PARTNER_CONTACTPERSON_PORTAL_USER, ID_PARTNER_CONTACTPERSON_TECHNICAL, ID_PARTNER_CONTACTPERSON_ACTIVE } from "../../../../constants/configs/config.constants";
import { NfoInfoBlockWithIcon } from "../../../../../core/components/infoBlock.component";


type Props = {
  partner: any;
  className?: string;
};

export const NfoPartnerPersonInChargeList = ({
                                               partner,
                                               className,
                                               ...props
                                             }: Props) => {
  const translations = useTranslations(TRANSLATIONS.myData);
  const internalClassName = 'personInChargeList';
  const theme = useTheme();

  return (
    <article className={cs(internalClassName, `${internalClassName}-le--${theme}`, className)} {...props}>
      <h4 className={`componentTitle-le--${theme}`}>
        {translations.personsInChargeTitle()}
      </h4>
      {partner[0]?.contactPersons &&
        partner[0]?.contactPersons
          .filter(
            (contactPerson: ContactPerson) =>
              contactPerson.role.id + '' === ID_PARTNER_CONTACTPERSON_TECHNICAL ||
              contactPerson.role.id + '' === ID_PARTNER_CONTACTPERSON_PORTAL_USER
          )
          .map((contactPerson: ContactPerson) => (
            <NfoInfoBlockWithIcon
              key={contactPerson.id}
              title={
                <>
                  {contactPerson.role.id + '' ===
                    ID_PARTNER_CONTACTPERSON_TECHNICAL && contactPerson?.status?.id + '' === ID_PARTNER_CONTACTPERSON_ACTIVE &&
                    (
                      <>
                        <Tech/>
                        {translations.technicalContactPerson()}
                      </>
                    )}
                  {contactPerson.role.id + '' === ID_PARTNER_CONTACTPERSON_PORTAL_USER
                    && contactPerson?.status?.id + '' === ID_PARTNER_CONTACTPERSON_ACTIVE && (
                      <>
                        <User className={'user'}/>
                        {translations.PartnerPortalUser()}
                      </>
                    )}
                </>
              }
            >

              {contactPerson?.status?.id + '' === ID_PARTNER_CONTACTPERSON_ACTIVE && (
                <>
                  <p>
                    {contactPerson.firstName} {contactPerson.lastName}
                  </p>
                  <p>{contactPerson.mobile?.phone}</p>
                  <p className={cs('aColor', 'mail', `aColor-le--${theme}`)}>
                    {contactPerson.email + ''}
                  </p>
                </>
              )}

            </NfoInfoBlockWithIcon>
          ))}
    </article>
  );
};
