import { ACTION_CONST } from "../../constants/action.constants";
import { IAction } from "../../constants/types/types.constants";

const initialState = {
  languages: [],
  salutations: [],
  legalForms: [],
  country: [],
  billMedia: [],
  paymentMode: [],
  carrier: [],
};

function partnersReducer(state = initialState, action: IAction) {
  switch (action.type) {
    case ACTION_CONST.API_GET_LANGUAGES:
      return {
        ...state,
        languages: action.data.extendedValues,
      };
     case ACTION_CONST.API_GET_SALUTATIONS:
      return {
        ...state,
        salutations: action.data.extendedValues,
      };
     case ACTION_CONST.API_GET_LEGAL_FORMS:
      return {
        ...state,
        legalForms: action.data.extendedValues,
      };
    case ACTION_CONST.API_GET_COUNTRY:
      return {
        ...state,
        country: action.data.extendedValues,
      };
    case ACTION_CONST.API_GET_BILL_MEDIA:
      return {
        ...state,
        billMedia: action.data.extendedValues,
      };
    case ACTION_CONST.API_GET_PAYMENT_MODE:
      return {
        ...state,
        paymentMode: action.data.extendedValues,
      };
    case ACTION_CONST.API_GET_CARRIER:
      return {
        ...state,
        carrier: action.data.extendedValues,
      };
    default:
      return state;
  }
}

export default partnersReducer;
