import { groupBy } from '../../../../core/utils';
import { TRANSLATIONS } from '../../../constants/transitions/uiTranslations';
import { useTranslations } from '../../../utils/helper/utils';
import cs from 'classnames';
import { ID_STATUS_SERVICES } from '../../../constants/configs/config.constants';
import { PortalContractData } from 'compax-api';
import { useTheme } from '../../../../core/utility/themeContext';
import { IContracts } from "../../../constants/types/types.constants";
import commonStyles from '../../../sass/base/common.module.scss';
import './monthlyCharges.scss';

type Props = {
  title: string,
  contracts: IContracts,
  totalSumNet?: number,
  useInactiveFlag: boolean,
}

const isActive = (status: number) =>
  status === ID_STATUS_SERVICES.ACTIVE;

const extractContractData = (contracts: IContracts, useInactiveFlag: boolean) => {
  return contracts.reduce((result: any[], currentValue: PortalContractData) => {
    if (currentValue.product !== undefined) {
      if (currentValue?.charges) {
        result.push({
          id: currentValue.id,
          productId: currentValue.product.id,
          status: currentValue.status?.id,
          inactiveFlag: (currentValue.status?.id !== ID_STATUS_SERVICES.ACTIVE && useInactiveFlag) ? '*' : '',
          contractName: currentValue.contractName,
          amountNetTotal: currentValue.amountNetTotal,
          currency: currentValue?.charges[0]?.currency?.description,
        });
      }
    }
    return result;
  }, []);
};

export const NfoMonthlyCharges = ({ title, contracts, totalSumNet, useInactiveFlag }: Props) => {
  const translations = useTranslations(TRANSLATIONS.contractDetails);
  const internalClassName = 'monthlyCharges';
  const theme = useTheme();

  const contractData = extractContractData(contracts, useInactiveFlag);
  const groupByProduct = groupBy(['productId', 'amountNetTotal', 'inactiveFlag']);
  const groupedContracts = groupByProduct(contractData);
  const groupKeys = Object.keys(groupedContracts);

  return (
    <aside className={cs(internalClassName, `${internalClassName}-le--${theme}`)}>
      <h4 className={`${internalClassName}-heading-le--${theme}`}>{title}</h4>
      {/*<CpxHelpBox className={"helpBox"}>
        {translations.infoBox()}
      </CpxHelpBox>*/}
      <table className={`table-le--${theme}`}>
        <tbody>
        {groupKeys &&
          groupKeys.map((groupKey, id) => (
              <tr
                key={groupedContracts[groupKey][0].contractName + id}
                className={cs(
                  {
                    inactive: (!isActive(groupedContracts[groupKey][0].status) && useInactiveFlag),
                    [`inactive-le--${theme}`]: (!isActive(groupedContracts[groupKey][0].status) && useInactiveFlag)
                  }
                )}
              >
                <td style={{ width: "10%" }}>{groupedContracts[groupKey].length}</td>
                <td style={{ width: "10%" }}>&nbsp;x</td>
                <td style={{ width: "75%" }}>{groupedContracts[groupKey][0].contractName + groupedContracts[groupKey][0].inactiveFlag}</td>
              </tr>
            )
          )}
        </tbody>
      </table>
      {contractData &&
        useInactiveFlag &&
        contractData.find(
          contract => !isActive(contract.status)
        ) && <p className={cs(commonStyles.footnote, `${internalClassName}-footnote-le--${theme}`)}>{translations.inactive()}</p>}
    </aside>
  );
};
