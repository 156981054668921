import cs from "classnames";
import React, { useEffect, useState } from "react";
import { useTheme } from "../../../../../../core/utility/themeContext";
import './activationDate.scss'
import { CpxSwitchWithText } from "../../../../../../core/components/switchWithText.component";
import { useFormik } from "formik";
import { CpxDateInputWithLabel } from "../../../../../../core/components/dateInputWithLabel.component";
import { useTranslations } from "../../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../../constants/transitions/uiTranslations";
import { saveActivationDateSelection } from "../../../../../redux/actions/step.action";
import { useSelectFeatures, useSelectLang, useStepperActivationDate, useStepperActivationSelection } from "../../../../../redux/store";
import { useDispatch } from "react-redux";
import { apiCallAction } from "../../../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../../../constants/action.constants";
import { FEATURES_FLAG } from "../../../../../constants/configs/config.constants";
import { createValidatorSchema, dateCheck } from "../../../../../utils/validation";
import { formatDate } from "../../../../../../core/utils";
import { Alert } from "../../../../common/Alert/Alert.component";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

type Props = {
  setActivationDate: any;
  className?: string;
  startDtRequested?: string;
  isValid?: any;
  isPortingDate?: boolean;
  portingDate?: string | undefined;
}

type AppDispatch = ThunkDispatch<any, any, AnyAction>;

export const NfoActivationDate = ({ setActivationDate, className, startDtRequested, isValid, isPortingDate = false, portingDate = undefined, ...props }: Props) => {
  const theme = useTheme();
  const dispatch: AppDispatch = useDispatch();
  const translationsStepper = useTranslations(TRANSLATIONS.stepper.newCustomer);
  const activationDateSelection = useStepperActivationSelection();
  const activationDate = useStepperActivationDate();
  const requiredMsg = translationsStepper.fieldRequired();
  const [activationTypeIsScheduled, setActivationTypeIsScheduled] = useState(activationDateSelection?.isPlanned);
  const [isStartDateRequestedMonthly, setIsStartDateRequestedMonthly] = useState<boolean>(false);
  const language = useSelectLang().selected;
  const features = useSelectFeatures();


  const handleSwitchChange = () => {
    setActivationTypeIsScheduled(!activationTypeIsScheduled);
    dispatch(saveActivationDateSelection({ isPlanned: !activationTypeIsScheduled }));
  }

  const handleDateInputChange = (e: React.ChangeEvent<any>) => {
    formik.handleChange(e);
    formik.submitForm();
  }

  const changeAnytimeAllowed = startDtRequested ? startDtRequested?.split('T')[0] === new Date().toISOString().split('T')[0] : true;

  useEffect(() => {
    dispatch(apiCallAction(ACTION_CONST.API_PORTAL_FEATURE, { featureName: FEATURES_FLAG.ENABLE_REQUEST_DATE }))

    if (isPortingDate && portingDate) {
      setActivationTypeIsScheduled(true);
      dispatch(saveActivationDateSelection({ isPlanned: true }));
    } else if (activationDate && activationDate?.split('T')[0] !== new Date().toISOString().split('T')[0]) {
      setActivationTypeIsScheduled(true);
      dispatch(saveActivationDateSelection({ isPlanned: true }));
    } else if (changeAnytimeAllowed) {
      setActivationTypeIsScheduled(false);
      dispatch(saveActivationDateSelection({ isPlanned: false }));
    } else {
      setActivationTypeIsScheduled(true);
      dispatch(saveActivationDateSelection({ isPlanned: true }));
    }
  }, [])

  const selectableMinDate: any = () => {
    if(isPortingDate) {
      return calculateFutureDate(20).toISOString().split('T')[0];
    }
    if (!startDtRequested || changeAnytimeAllowed) {
      return new Date().toISOString().split('T')[0]
    } else {
      const today = new Date();

      let nextMonth = today.getMonth() + 1;
      let nextYear = today.getFullYear();

      if (nextMonth > 11) {
        nextMonth = 0;
        nextYear++;
      }

      const firstOfNextMonth = new Date(nextYear, nextMonth, 1+1);
      return firstOfNextMonth.toISOString().split('T')[0];
    }
  }

  const calculateFutureDate = (numDays: number) => {
    const currentDate = new Date();
    let count = 0;

    while (count < numDays) {
      currentDate.setDate(currentDate.getDate() + 1);
      const dayOfWeek = currentDate.getDay();

      // Check if the current day is a weekend (Saturday or Sunday)
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        count++;
      }
    }

    return currentDate;
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      activationDate: (isPortingDate && portingDate) ? portingDate.split('T')[0] : (activationDate ? activationDate.split('T')[0] :selectableMinDate()),
    },
    validationSchema: createValidatorSchema({
      activationDate: activationDateSelection?.isPlanned && dateCheck(requiredMsg, isStartDateRequestedMonthly ? translationsStepper.dateValidationMonthly(formatDate(new Date(selectableMinDate()), language)) : translationsStepper.dateValidationText(), isStartDateRequestedMonthly, isPortingDate ? selectableMinDate() : null),
    }),
    onSubmit: async (values) => {

      const createCurrentDate = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      }

      let activationDate: string
      if (!activationDateSelection?.isPlanned && !isPortingDate) {
        activationDate = createCurrentDate();
      } else if(!activationDateSelection?.isPlanned && isPortingDate) {
        activationDate = selectableMinDate();
      } else {
        activationDate = values.activationDate;
      }

      setActivationDate(new Date(activationDate).toISOString());
    }
  });

  useEffect(() => {
    if(isValid) {
      if (Object.keys(formik.errors).length === 0) {
        isValid(true)
      } else {
        isValid(false)
      }
    }
  }, [formik.errors])

  useEffect(() => {
    if ((selectableMinDate() !== new Date().toISOString().split('T')[0]) && !isPortingDate) {
      setIsStartDateRequestedMonthly(true)
    } else {
      setIsStartDateRequestedMonthly(false);
    }

    formik.submitForm();
  }, [formik.values.activationDate, activationDateSelection])

  return (
    <>
      {features.ENABLE_REQUEST_DATE &&
        <div className={cs(`activation-date`, `activation-date-le--${theme}`, className)}>
          <h4 className={cs(`activation-date-title`, `activation-date-title-le--${theme}`)}>{translationsStepper.activationHeading()}</h4>
          <div className={cs(`activation-date-content`, `activation-date-content-le--${theme}`)}>
            <p className='activation-date-content--text'>{translationsStepper.activationDateText()}</p>
            <CpxSwitchWithText
              className={'activation-date-content--switch'}
              text={{ left: isPortingDate ? translationsStepper.plus20Days() : translationsStepper.activationDateImmediatelyText(), right: translationsStepper.activationDatePlannedText() }}
              checked={activationTypeIsScheduled}
              onChange={handleSwitchChange}
              disableOption={{ left: !changeAnytimeAllowed }}
            />
            {activationTypeIsScheduled &&
              <CpxDateInputWithLabel
                id='activationDate'
                type="date"
                value={formik.values?.activationDate}
                error={formik.touched?.activationDate && formik.errors?.activationDate}
                onChange={(e: React.ChangeEvent<any>) => handleDateInputChange(e)}
                min={selectableMinDate()}
                disableErrors={true}
              ></CpxDateInputWithLabel>
            }
          </div>
          {(formik.touched?.activationDate && formik.errors?.activationDate) && <Alert errors={formik.errors?.activationDate}/>}
        </div>
      }
    </>
  )
}