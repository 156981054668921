import cs from 'classnames';
import commonStyles from '../../../sass/base/common.module.scss';
import { ReactNode } from 'react';

type Props = {
  showLoader: ReactNode;
  children: ReactNode;
};

export const NfoPageLoader = ({ children, showLoader }: Props) => {
  return (
    <>
      {
        <div className={cs(showLoader && commonStyles.isLoading)}>
          {children}
        </div>
      }
    </>
  );
};
