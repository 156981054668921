import cs from "classnames";
import { CpxDropdownWithLabel } from "../../../../../core/components/dropdown.component";
import { CpxModal } from "../../../../../core/components/modal.component";
import React from "react";
import { getCountryById, useTranslations } from "../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";
import { useTheme } from "../../../../../core/utility/themeContext";
import { SelectOption } from "../../../../constants/types/types.constants";
import { useFormik } from "formik";
import { createValidatorSchema, requiredCheck } from "../../../../utils/validation";
import { CpxFormikForm } from "../../../../../core/components/formikForm.component";

type Props = {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  addNewDocument: (option: any) => void;
  siteAddresses: any
}

export const ProofOfSiteModal = ({ setShowModal, addNewDocument, siteAddresses }: Props) => {
  const internalClassName = "proofOfLocationModal";
  const translation = useTranslations(TRANSLATIONS.stepper.newCustomer);
  const translationsCommon = useTranslations(TRANSLATIONS.common);
  const requiredMsg = translation.fieldRequired();
  const theme = useTheme();

  const DEFAULT_SELECT_OPTION: SelectOption = {
    id: '',
    name: translationsCommon.defaultSelect(),
  }


  const getAddressById = (key: string) => {
    return siteAddresses[key];
  }

  const buildOptions = () : Array<any> => {
    const optionArray = Array<any>();
    let values = Array.from(Object.values(siteAddresses));
    for (const group of values) {

      if (Array.isArray(group) && group[0]){
        optionArray.push({

          id: group[0].areaCountryCode,
          name: `(${group[0].areaCountryCode})  ${group[0].siteAddress?.street} ${group[0].siteAddress?.houseNumber}, ${group[0].siteAddress?.zip} ${group[0].siteAddress?.city}, ${getCountryById(+group[0].siteAddress?.country?.id)}`,
          data: group[0].siteAddress
        })
      }
    }

    return optionArray;
  }

  const siteAddressOptions =  buildOptions();


  const proofTypeOptions: SelectOption[] = [
    { id: 1, name: translation.proofTypeBusinessRegistration() },
    { id: 2, name: translation.proofTypeCommercialRegister() },
    { id: 3, name: translation.proofTypeSalesTaxId() },
    { id: 4, name: translation.proofTypeIdentityCard() },
    { id: 5, name: translation.proofTypePowerBill() },
    { id: 6, name: translation.proofTypeAssociationRegister() },
  ];

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      selectedAddress: {
        id: siteAddressOptions[-1]?.id,
        data: '',
      },
      selectedProofType: {
        id: proofTypeOptions[-1]?.id,
        data: '',
      }
    },
    validationSchema: createValidatorSchema({
      selectedAddress: createValidatorSchema({
        id: requiredCheck(requiredMsg),
      }),
      selectedProofType: createValidatorSchema({
        id: requiredCheck(requiredMsg),
      }),
    }),
    onSubmit: async (values: any) => {
      const selectedAddress = getAddressById(values.selectedAddress.id);
      let entries = selectedAddress.map((a:any) => a.uuid);
      const selectedProofType = proofTypeOptions[values.selectedProofType.id - 1].name;
      const option = {
        entries: entries,
        name: 'site',
        label: translation.documentsTypeSite() + ' *',
        subLabel: `${selectedProofType} - (${selectedAddress[0]?.areaCountryCode})`,
        fileName: null,
        documentTypeId: proofTypeOptions[+values.selectedProofType.id - 1].id
      }
      addNewDocument(option);
      setShowModal(false);
    }
  });

  return (
    <CpxFormikForm handleSubmit={formik.handleSubmit}
                   initialValues={formik.initialValues}
                   id={'proofOfLocation-form'}
    >
      <CpxModal
        confirmText={translationsCommon.add()}
        onCancel={() => setShowModal(false)}
        formId={'proofOfLocation-form'}
      >
        <h3 className={cs('modalTitle', `modalTitle-le--${theme}`)}>
          {translation.proofOfSiteModalTitle()}
        </h3>
        <p>
          {translation.proofOfSiteModalSubtitle()}
        </p>
        <div className={cs(`${internalClassName}-wrapper`, `${internalClassName}-wrapper-le--${theme}`)}>
          <CpxDropdownWithLabel
            id="selectedAddress.id"
            name="selectedAddress.id"
            required={true}
            options={siteAddressOptions}
            defaultOption={DEFAULT_SELECT_OPTION}
            value={formik.values?.selectedAddress?.id}
            onChange={formik.handleChange}
            error={formik.errors?.selectedAddress?.id}
          >
            {translation.site()}
          </CpxDropdownWithLabel>
          <CpxDropdownWithLabel
            id={`selectedProofType.id`}
            name={`selectedProofType.id`}
            required={true}
            options={proofTypeOptions}
            defaultOption={DEFAULT_SELECT_OPTION}
            value={formik.values?.selectedProofType?.id}
            onChange={formik.handleChange}
            error={formik.errors?.selectedProofType?.id}
          >
            {translation.proofType()}
          </CpxDropdownWithLabel>
        </div>
      </CpxModal>
    </CpxFormikForm>
  );
}