import React from "react";
import { NfoEmptyState } from "../../../core/emptyState.component";
import cs from "classnames";
import { CpxDownloadTable } from "../../../core/components/downloadTable.component";
import { CpxDownload } from "../../../core/components/download.component";
import { useDispatch } from "react-redux";
import { useTranslations } from "../../utils/helper/utils";
import { TRANSLATIONS } from "../../constants/transitions/uiTranslations";
import { useTheme } from "../../../core/utility/themeContext";
import { apiCallAction } from "../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../constants/action.constants";
import { useBillsTable } from "../../utils/bills/utils";
import { validate } from "../../../core/utils";
import { selectCurrentLanguage } from "../../../core/uiLanguage/lang.slice";
import { useSelectItemizedBillsTelefonica, useSelectLang } from "../../redux/store";
import { CpxTitle } from "../../../core/components/title/title.component";
import { CpxSubtitle } from "../../../core/components/title/subtitle.component";


export const wholesalersEvnPage = () => {
  const dispatch = useDispatch();
  const translations = useTranslations(TRANSLATIONS.itemized);
  const internalClassName = "itemized";
  const theme = useTheme();
  const currentLanguage = selectCurrentLanguage(useSelectLang());
  const itemizedBillsTelefonica = useSelectItemizedBillsTelefonica();

  const getItemizedEnvFile = (id: string) => {
    return async () => {
      // @ts-ignore
      const response: {
        itemizedBillFile: { file: string }
      } = await dispatch(apiCallAction(ACTION_CONST.API_GET_BILL_FILE_ENV_TELEFONICA, { billId: id }))
      return `${response.itemizedBillFile?.file}`;
    }
  }

  const tableData: any = useBillsTable(
    [{
      getterCreator: getItemizedEnvFile,
      filenameGetter: bill => validate(bill.itemizedBillFileName),
      downloadType: "application/csv",
    }], true, true
  );


  return (
    <>
      <CpxTitle
        title={translations.title()}
        internalClassName={internalClassName}
      />
      <CpxSubtitle
        subtitle={translations.subheading()}
        internalClassName={internalClassName}
      />
      <NfoEmptyState
        data={tableData}
        pageTitle={translations.title()}
        boxStyling={true}
      >
        {Array.isArray(tableData) && tableData.length !== 0 &&
          <article className={cs("itemizedTable", `itemizedTable-le--${theme}`)}>
            <CpxDownloadTable
              headers={[
                { label: translations.tableHeadingDate(), sort: true, sortBy: 'cols.0', sortMethod: 'date' },
                { label: translations.tableHeadingName(), sort: true, sortBy: 'cols.1', sortMethod: 'string' },
                { label: translations.tableHeadingBillNumber(), sort: true, sortBy: 'cols.2', sortMethod: 'string' },
              ]}
              tableData={tableData ? tableData : []}
              downloadsProps={[
                {
                  linkName: (
                    <>
                      <CpxDownload/>
                      {translations.csvLinkName()}
                    </>
                  ),
                  zipLinkName: translations.allCsvsName(),
                  zipFileName: translations.zipFileNameCsv(),
                },
              ]}
              downloadColHeader={translations.tableHeadingDownload()}
            />
          </article>
        }
      </NfoEmptyState>
    </>
  )
}