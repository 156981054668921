import { TRANSLATIONS } from "../../../constants/transitions/uiTranslations";
import { useTranslations } from "../../../utils/helper/utils";
import { NfoBillAccount } from "../../../components/customer/myData/billAccount/billAccount.component";
import { NfoMyDataOverview } from "../../../components/customer/myData/myDataOverview/myDataOverview.component";
import { NfoPersonInChargeList } from "../../../components/customer/myData/personInChargeList/personInChargeList.component";
import { NfoSites } from "../../../components/customer/myData/sites/sites.component";
import { CpxTitle } from '../../../../core/components/title/title.component';
import { CpxSubtitle } from "../../../../core/components/title/subtitle.component";
import commonStyles from "../../../sass/base/common.module.scss";
import React, { useEffect } from "react";
import { apiCallAction } from "../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../constants/action.constants";
import { useDispatch } from "react-redux";
import {selectCurrentLanguage} from "../../../../core/uiLanguage/lang.slice";
import { useSelectLang, useSelectPartnerCustomer } from "../../../redux/store";
import { IPartnerCustomer } from "../../../constants/types/types.constants";

export const NfoMyDataPage = () => {
  const translations = useTranslations(TRANSLATIONS.myData);
  const internalClassName = "myData";
  const dispatch = useDispatch()
  const currentLanguage = selectCurrentLanguage(useSelectLang());
  const selectedPartnerCustomer: IPartnerCustomer = useSelectPartnerCustomer() as IPartnerCustomer;


  useEffect(() => {
    dispatch(apiCallAction(ACTION_CONST.API_GET_ACCOUNTS))
    dispatch(apiCallAction(ACTION_CONST.API_GET_ADDRESSES,  { language: currentLanguage, selectedPartnerCustomerId: selectedPartnerCustomer?.id }));
  }, [dispatch, currentLanguage]);

  return (
    <>
      <CpxTitle
        title={translations.title()}
        internalClassName={internalClassName}
      />
      <CpxSubtitle
        subtitle={translations.subheading()}
        internalClassName={internalClassName}
      />
      <div className={commonStyles.columnContainer}>
        <NfoMyDataOverview className={commonStyles.column}/>
        <NfoPersonInChargeList className={commonStyles.column}/>
      </div>
      <NfoBillAccount/>
      <NfoSites/>
    </>
  );
};
