import { useHistory } from "react-router-dom";
import { routes } from "../../../../constants/routes/routes";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";
import { useGetLocked, useTranslations } from "../../../../utils/helper/utils";
import "./toast.scss";
import React, { useEffect } from "react";
import { useTheme } from "../../../../../core/utility/themeContext";
import { useSelectLang, useSelectPartnerCustomer, useSelectUserRole } from "../../../../redux/store";
import { setSelectedContract } from "../../../../redux/actions/config.action";
import { useDispatch } from "react-redux";
import { PortalContractData } from "compax-api";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import toast, { Toast, ToastBar, Toaster } from 'react-hot-toast';
import { CpxIcon } from "../../../../../core/components/icon.component";
import { ICONS, USER_CUSTOMER } from "../../../../constants/configs/config.constants";
import { IPartnerCustomer, ToastTypes } from "../../../../constants/types/types.constants";
import cs from "classnames";
import { CpxButton } from "../../../../../core/components/button.component";
import { apiCallAction } from "../../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../../constants/action.constants";

type AppDispatch = ThunkDispatch<any, any, AnyAction>;

export const useToast = () => {
  const translations = useTranslations(TRANSLATIONS.common);

  const Toast = ({ title, body, onClick }: any) => {
    return <div className={cs({ ['toast-with-button']: onClick })}>
      <div>
        <h5>{title}</h5>
        <p>{body}</p>
      </div>
      {onClick && <CpxButton buttonType={'white'} onClick={onClick}>{translations.unlock()}</CpxButton>}
    </div>
  }

  return {
    showToast: (type: ToastTypes, title: string, body?: string, duration?: number, onClick?: any) => {
      switch (type) {
        case ToastTypes.SUCCESS:
          toast.success(() => <Toast title={title} body={body} onClick={onClick}/>, { duration: duration })
          break;
        case ToastTypes.ERROR:
          toast.error(() => <Toast title={title} body={body} onClick={onClick}/>, { duration: duration })
          break;
        default:
          toast.success(() => <Toast title={title} body={body} onClick={onClick}/>, { duration: duration });
      }
    },
  }
}

export const NfoToast = () => {
  const internalClassName = "toast";
  const translations = useTranslations(TRANSLATIONS.common);
  const theme = useTheme();
  const dispatch : AppDispatch = useDispatch();
  const history = useHistory();
  const { showToast } = useToast();
  const selectedPartnerCustomer: IPartnerCustomer = useSelectPartnerCustomer() as IPartnerCustomer;
  const role = useSelectUserRole();
  const apiFraudLock = useGetLocked();
  const language = useSelectLang();

  const selectMainContractAndGoToSecuritySettings = () => {
    dispatch(apiCallAction(ACTION_CONST.API_GET_MAIN_CONTRACTS, { selectedPartnerCustomerId: selectedPartnerCustomer?.id }, true))
      .then((contracts: any) => {
        const mainContract: any = Array.isArray(contracts) && contracts?.find((mainContract: PortalContractData) => mainContract?.id === apiFraudLock.lockedContracts[0]?.componentOf);

        if(mainContract?.contractId){
          dispatch(setSelectedContract(mainContract));
          history.push(routes.customerSecuritySettings.path);
        }
      })
  }

  useEffect(() => {
    toast.remove();
    if (role === USER_CUSTOMER) {
      if (apiFraudLock && apiFraudLock?.lockedContracts?.length !== 0) {
        showToast(ToastTypes.ERROR,
          apiFraudLock?.lockedContracts > 1 ? translations.lockedWarningHeading2() : translations.lockedWarningHeading(apiFraudLock?.lockedContracts && apiFraudLock?.lockedContracts[0]?.contractName),
          translations.lockedWarning(),
          Infinity,
          selectMainContractAndGoToSecuritySettings
        );
      }
    }
  }, [apiFraudLock, language])

  return (
    <>
      <Toaster
        containerClassName={internalClassName}
        toastOptions={{
          success: {
           className: `toast-notification toast-notification-le--${theme} toast-success toast-success-le--${theme}`,
           icon: <CpxIcon icon={ICONS.CHECK} />,
           duration: 5000,
          },
          error: {
           className: `toast-notification toast-notification-le--${theme} toast-error toast-error-le--${theme}`,
           icon: <CpxIcon icon={ICONS.ATTENTION} />,
           duration: 5000,
          }
        }}
        reverseOrder={true}
      >
        {(t: Toast) => (
          <div onClick={() => toast.dismiss(t.id)}>
            <ToastBar
              toast={t}
              style={{
                ...t.style,
                animation: t.visible ? 'customIn .5s ease forwards' : 'customOut .5s ease forwards',
              }}
            />
          </div>
        )}
      </Toaster>
    </>
  );
};
