import React, { useState } from 'react';
import { CpxModal } from '../../../../../core/components/modal.component';
import { useTheme } from '../../../../../core/utility/themeContext';
import { useTranslations } from '../../../../utils/helper/utils';
import { TRANSLATIONS } from '../../../../constants/transitions/uiTranslations';
import { useSelectEditSipTrunk } from '../../../../redux/store';
import { CpxInputWithLabel } from "../../../../../core/components/inputWithLabel.component";
import { CpxTextWithLabel } from "../../../../../core/components/textWithLabel.component";
import { Charge, OptionGroup, SelectableBasketOption } from 'compax-api';
import { ID_CHARGE_TYPES, ID_SERVICE_GROUPS } from "../../../../constants/configs/config.constants";
import { ReactComponent as Info } from '../../../../assets/icons/info_green.svg';
import { currency } from "../../../../../core/utils";
import { useDispatch } from "react-redux";
import { apiCallAction } from "../../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../../constants/action.constants";
import cs from 'classnames';
import './selectQuantityModal.scss';
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

type AppDispatch = ThunkDispatch<any, any, AnyAction>;
export const NfoOstSelectQuantityModal = ({
                                            onConfirm,
                                            backToPreviousPage,
                                            cancelModal,
                                            activeProduct,
                                            savedQty,
                                          }: any) => {
  const internalClassName = 'selectQuantityModal';
  const theme = useTheme();
  const dispatch: AppDispatch = useDispatch();
  const translations = useTranslations(TRANSLATIONS.editSipTrunk);
  const translationsCommon = useTranslations(TRANSLATIONS.common);
  const editSipTrunk: any = useSelectEditSipTrunk();
  const DEFAULT_QTY: number = 1;
  const possibleProducts: OptionGroup | undefined = editSipTrunk.possibleOptions.find((option: OptionGroup) => option.serviceGroup.id === ID_SERVICE_GROUPS.VOICE_CHANNELS);
  const activeBasketOption: SelectableBasketOption | undefined = possibleProducts?.basketOptions.find((option: any) => option.product.id === activeProduct);
  const chargesMonthly: Charge | undefined = activeBasketOption?.product.charges?.find((charge: Charge) => charge.chargeType?.id === ID_CHARGE_TYPES.BASIC_FEE);
  const orderMin: number = activeBasketOption?.servicePropertySip?.orderMin || DEFAULT_QTY;
  const orderMax: number = activeBasketOption?.servicePropertySip?.orderMax || DEFAULT_QTY;
  const minMaxText: string = (orderMin && orderMax && (orderMin - orderMax !== 0)) ? `(${orderMin}-${orderMax})` : "";
  const [disabled, setDisabled] = useState(false);
  let initialQty = DEFAULT_QTY;
  if (typeof savedQty !== "undefined" && savedQty !== -1) {
    initialQty = savedQty;
  } else if (typeof activeBasketOption?.servicePropertySip?.defaultQuantity !== "undefined") {
    initialQty = activeBasketOption?.servicePropertySip?.defaultQuantity;
  }
  const [setQuantity, setSetQuantity] = useState(initialQty);
  let charge = activeBasketOption?.product?.charges?.find((charge: Charge) => charge.chargeType?.id === ID_CHARGE_TYPES.BASIC_FEE);
  let isDiscounted;
  let discountDifference = charge?.amountNetDiscounts && charge?.amountNet && (charge?.amountNet - charge?.amountNetDiscounts) * setQuantity;

  if (charge?.amountNetDiscounts !== undefined && charge?.amountNetDiscounts > 0) {
    isDiscounted = true;
  } else {
    isDiscounted = false;
  }

  const backToPageTwo = () => {
    backToPreviousPage();
  }

  const cancelModalHandler = () => {
    cancelModal(false);
  }

  const confirmToNextPage = () => {
    dispatch(
      apiCallAction(ACTION_CONST.API_BASKET_PUT_ADD_OPTION,
        {
          basketId: editSipTrunk.basket.id,
          uuid: activeBasketOption?.parentUuid,
          optionId: activeBasketOption?.product.id,
          quantity: setQuantity,
        },
        true, null, true)
    ).then((res: any) => {
        onConfirm(setQuantity);
    }
    );

  }

  const onChangeQuantity = (e: any) => {
    let input = e.target.value;
    if (input === '' || (input < orderMin && input > 0)) {
      setSetQuantity(input);
      setDisabled(true);
    } else {
      if (input > orderMax) {
        setSetQuantity(orderMax)
      } else if (input < orderMin) {
        setSetQuantity(orderMin);
      } else {
        setSetQuantity(input);
      }
      setDisabled(false);
    }
  }

  return (
    <>
      <CpxModal
        onCancel={() => cancelModalHandler()}
        onBack={() => backToPageTwo()}
        onConfirm={() => confirmToNextPage()}
        confirmText={translations.next()}
        disableButton={disabled}
      >
        <h3 className={cs('modalTitle', `modalTitle-le--${theme}`)}>
          {translations.selectQuantity()}
        </h3>
        <p className={'modalInfoText'}>
          {translations.selectQuantitySubheading()}
        </p>
        {isDiscounted && <div className={cs(`discountInfo`, `discountInfo-le--${theme}`)}>
          <Info/>
          <div>
            <p className={cs('infoTitle', `infoTitle-le--${theme}`)}>
              {translationsCommon.discountedInfoText(currency(discountDifference, chargesMonthly?.currency?.description) + "")}</p>
          </div>
        </div>}

        <div className={internalClassName}>
          <CpxInputWithLabel
            id={`${internalClassName}-quantity`}
            type={"number"}
            min={orderMin}
            max={orderMax}
            value={setQuantity}
            onChange={onChangeQuantity}
          >
            {`${translations.amount()} ${minMaxText}`}
          </CpxInputWithLabel>
          <CpxTextWithLabel label={translations.singlePrice() + "*"}>
            <p className={cs({ ['line-through']: isDiscounted })}>{currency(chargesMonthly?.amountNet, chargesMonthly?.currency?.description)}</p> {isDiscounted && currency(chargesMonthly?.amountNetDiscounts, chargesMonthly?.currency?.description)} / {translations.month()}
          </CpxTextWithLabel>
          <CpxTextWithLabel label={translations.total() + "*"}>
            <p className={cs({ ['line-through']: isDiscounted })}>{currency(chargesMonthly && chargesMonthly.amountNet && chargesMonthly.amountNet * setQuantity, chargesMonthly?.currency?.description)}</p> {isDiscounted && currency(chargesMonthly && chargesMonthly.amountNetDiscounts && chargesMonthly.amountNetDiscounts * setQuantity, chargesMonthly?.currency?.description)} / {translations.month()}
          </CpxTextWithLabel>
        </div>
      </CpxModal>
    </>
  );
};
