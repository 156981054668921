export const ACTION_CONST: any = {
  SET_USER_ROLE: 'SET_USER_ROLE',
  SET_MAIN_PRODUCT: 'SET_MAIN_PRODUCT',
  SET_SELECTED_CONTRACT: 'SET_SELECTED_CONTRACT',
  UNSET_SELECTED_CONTRACT: 'UNSET_SELECTED_CONTRACT',
  SET_WHOLESALE_MODEL: 'SET_WHOLESALE_MODEL',

  API_CALL_START: 'LOADING_START',
  API_CALL_DONE: 'LOADING_DONE',
  API_TOKEN: 'API_TOKEN',
  API_CALL_ERROR: 'API_ERROR',
  API_LOGIN_CALL: 'API_LOGIN_CALL',
  LOGOUT_CALL: 'LOGOUT_CALL',
  CLEAR_ERROR: 'CLEAR_ERROR',
  SET_ERROR: 'SET_ERROR',

  SHOW_LOADER: 'SHOW_LOADER',
  HIDE_LOADER: 'HIDE_LOADER',
  SET_USER_DATA: 'SET_USER_DATA',

  // Stepper
  STORE_STEP_1_DATA: 'STORE_STEP_1_DATA',
  STORE_STEP_2_DATA: 'STORE_STEP_2_DATA',
  STORE_STEP_3_DATA: 'STORE_STEP_3_DATA',
  STORE_STEP_4_DATA: 'STORE_STEP_4_DATA',
  STORE_STEP_5_DATA: 'STORE_STEP_5_DATA',
  STORE_STEP_6_DATA: 'STORE_STEP_6_DATA',
  SAVE_CURRENT_STEP: 'SAVE_CURRENT_STEP',
  SAVE_CURRENT_ORDER_TYPE: 'SAVE_CURRENT_ORDER_TYPE',
  API_VALIDATE_CUSTOMER_DATA: 'API_VALIDATE_CUSTOMER_DATA',
  API_BASKET_PREPARE_ORDER_ENTRY: 'API_BASKET_PREPARE_ORDER_ENTRY',
  API_BASKET_SET_LANGUAGE:'API_BASKET_SET_LANGUAGE',
  API_VALIDATE_AREA_CODE: 'API_VALIDATE_AREA_CODE',
  API_VALIDATE_LOCAL_AREA_CODE: 'API_VALIDATE_LOCAL_AREA_CODE',
  API_CREATE_CUSTOMER: 'API_CREATE_CUSTOMER',
  API_CREATE_CUSTOMER_CONTRACT: 'API_CREATE_CUSTOMER_CONTRACT',
  API_MIGRATE_CUSTOMER: 'API_MIGRATE_CUSTOMER',
  API_MIGRATE_CUSTOMER_CONTRACT: 'API_MIGRATE_CUSTOMER_CONTRACT',
  DELETE_STEP_DATA: 'DELETE_STEP_DATA',
  DELETE_STEP_4: 'DELETE_STEP_4',
  ALL_REQ_DOCUMENTS_UPLOADED: 'ALL_REQ_DOCUMENTS_UPLOADED',
  IS_PRODUCT_SELECTED: 'IS_PRODUCT_SELECTED',
  REACHED_CONFIRM_PAGE: 'REACHED_CONFIRM_PAGE',
  BILLING_INFORMATION_SELECTION: 'BILLING_INFORMATION_SELECTION',
  ACTIVATION_DATE_SELECTION: 'ACTIVATION_DATE_SELECTION',
  STORE_STEP_2_FORM_DATA: 'STORE_STEP_2_FORM_DATA',
  STORE_ACTIVATION_DATE: 'STORE_ACTIVATION_DATE',
  SAVE_OE_OPTIONS_OF_OPTION: 'SAVE_OE_OPTIONS_OF_OPTION',
  DELETE_OE_OPTIONS_OF_OPTION_WITH_PRODUCT_ID: 'DELETE_OE_OPTIONS_OF_OPTION_WITH_PRODUCT_ID',
  SAVE_OE_CONFIGURATIONS_OF_OPTION: 'SAVE_OE_CONFIGURATIONS_OF_OPTION',
  SAVE_OE_NEW_SIP_TRUNK_OPTION: 'SAVE_OE_NEW_SIP_TRUNK_OPTION',
  DELETE_OE_SIP_TRUNK_OPTION: 'DELETE_OE_SIP_TRUNK_OPTION',
  SET_PRODUCT_OPTION_TO_VALIDATED: 'SET_PRODUCT_OPTION_TO_VALIDATED',
  UPDATE_PRODUCT_SIP_TRUNK_TO_VALIDATED_AND_SET_UUID: 'UPDATE_PRODUCT_SIP_TRUNK_TO_VALIDATED_AND_SET_UUID',
  SET_PRODUCT_OPTION_TO_INVALIDATED: 'SET_PRODUCT_OPTION_TO_INVALIDATED',
  SET_SPI_TRUNK_PRODUCT_OPTION_TO_INVALIDATED: 'SET_SPI_TRUNK_PRODUCT_OPTION_TO_INVALIDATED',

  //Order-Entry calls
  API_GET_OE_PRODUCTS: 'API_GET_OE_PRODUCTS',
  API_GET_OE_PRODUCT_OPTIONS: 'API_GET_OE_PRODUCT_OPTIONS',
  API_GET_OE_PRODUCT_OPTIONS_FOR_SIP_TRUNK: 'API_GET_OE_PRODUCT_OPTIONS_FOR_SIP_TRUNK',
  API_PUT_OE_PRODUCT_OPTIONS: 'API_PUT_OE_PRODUCT_OPTIONS',
  API_DELETE_OE_PRODUCT_OPTIONS: 'API_DELETE_OE_PRODUCT_OPTIONS',
  API_GET_OE_OPTIONS_OF_OPTION: 'API_GET_OE_OPTIONS_OF_OPTION',

  //migration calls order entry
  API_GET_OE_MIGRATION_CUSTOMER: 'API_GET_OE_MIGRATION_CUSTOMER',
  API_GET_OE_MIGRATION_CUSTOMER_PRODUCT: 'API_GET_OE_MIGRATION_CUSTOMER_PRODUCT',
  API_GET_OE_MIGRATION_CUSTOMER_SERVICE_DATA: 'API_GET_OE_MIGRATION_CUSTOMER_SERVICE_DATA',
  API_POST_OE_MIGRATION_CUSTOMER_ADD_CHANNEL_TO_BASKET: 'API_POST_OE_MIGRATION_CUSTOMER_ADD_CHANNEL_TO_BASKET',
  API_POST_OE_MIGRATION_CUSTOMER_ADD_CALLNUMBERS_TO_BASKET: 'API_POST_OE_MIGRATION_CUSTOMER_ADD_CALLNUMBERS_TO_BASKET',
  API_GET_OE_OPTIONS_OF_OPTION_FOR_MIGRATION: 'API_GET_OE_OPTIONS_OF_OPTION_FOR_MIGRATION',

  API_GET_USER: 'API_GET_USER',
  API_GET_CUSTOMER: 'API_GET_CUSTOMER',
  API_GET_ACCOUNTS: 'API_GET_ACCOUNTS',
  API_POST_CUSTOMER_BANK_ACCOUNT: 'API_POST_CUSTOMER_BANK_ACCOUNT',
  API_GET_ENV_TYPE: 'API_GET_ENV_TYPE',
  API_POST_ENV_TYPE: 'API_POST_ENV_TYPE',
  API_GET_PACKAGES: 'API_GET_PACKAGES',
  API_GET_PARTNERS: 'API_GET_PARTNERS',
  API_GET_CONTRACTS: 'API_GET_CONTRACTS',
  API_GET_MAIN_CONTRACTS: 'API_GET_MAIN_CONTRACTS',
  API_GET_FRAUDLOCK_OF_CONTRACT: 'API_GET_FRAUDLOCK_OF_CONTRACT',
  API_POST_FRAUD_UNLOCK_OF_CONTRACT: 'API_POST_FRAUD_UNLOCK_OF_CONTRACT',
  API_GET_FRAUD_LIMIT: 'API_GET_FRAUD_LIMIT',
  API_POST_FRAUD_LIMIT: 'API_POST_FRAUD_LIMIT',
  API_GET_CONTRACTS_CHARGE_SUM: 'API_GET_CONTRACTS_CHARGE_SUM',
  API_GET_ADDRESSES: 'API_GET_ADDRESSES',
  API_GET_CLIS: 'API_GET_CLIS',
  API_PUT_PROFILE: 'API_PUT_PROFILE',
  API_GET_BILLS: 'API_GET_BILLS',
  API_GET_ITEMIZED_BILLS: 'API_GET_ITEMIZED_BILLS',
  API_GET_ITEMIZED_BILLS_FOR_TELEFONICA_CUSTOMERS: 'API_GET_ITEMIZED_BILLS_FOR_TELEFONICA_CUSTOMERS',
  API_GET_BILL_FILE: 'API_GET_BILL_FILE',
  API_GET_BILL_FILE_ENV: 'API_GET_BILL_FILE_ENV',
  API_GET_BILL_FILE_ENV_TELEFONICA: 'API_GET_BILL_FILE_ENV_TELEFONICA',
  API_GET_LICENSES: 'API_GET_LICENSES',
  API_GET_LICENSES_BY_SERVICE_GROUP: 'API_GET_LICENSES_BY_SERVICE_GROUP',
  API_POST_LICENSES_PREPARE_ORDER: 'API_POST_LICENSES_PREPARE_ORDER',
  API_GET_EXTENSIONS: 'API_GET_EXTENSIONS',
  API_SET_PASSWORD: 'API_SET_PASSWORD',
  API_CHANGE_PASSWORD: 'API_CHANGE_PASSWORD',
  API_POST_ADD_OPTIONS: 'API_POST_ADD_OPTIONS',
  API_SEND_ORDER: 'API_SEND_ORDER',
  API_SEND_TERMINATION: 'API_SEND_TERMINATION',
  API_GET_OPTIONS: 'API_GET_OPTIONS',
  API_POST_RESET_PASSWORD: 'API_POST_RESET_PASSWORD',
  API_GET_PARTNER_COMMISSION_BILLS_CSV: 'API_GET_PARTNER_COMMISSION_BILLS_CSV',
  API_GET_PARTNER_COMMISSION_BILLS_PDF: 'API_GET_PARTNER_COMMISSION_BILLS_PDF',
  API_GET_PARTNER_COMMISSION_DATA: 'API_GET_PARTNER_COMMISSION_DATA',
  API_GET_SIPTRUNK: 'API_GET_SIPTRUNK',
  API_POST_CUSTOMER_SERVICE_PIN: 'API_POST_CUSTOMER_SERVICE_PIN',
  API_PUT_CONTRACT_NOTES: 'API_PUT_CONTRACT_NOTES',
  API_POST_CUSTOMER_MSTEAMS_CNAME: 'API_POST_CUSTOMER_MSTEAMS_CNAME',

  // Wholesaler
  API_GET_WHOLESALER_SDR_CSV: 'API_GET_WHOLESALER_SDR_CSV',
  API_GET_WHOLESALER_SDR_DATA: 'API_GET_WHOLESALER_SDR_DATA',
  API_GET_WHOLESALER_CDR_CSV: 'API_GET_WHOLESALER_CDR_CSV',
  API_GET_WHOLESALER_CDR_DATA: 'API_GET_WHOLESALER_CDR_DATA',

  // Basket
  API_BASKET_CREATE_ORDER_ENTRY: 'API_BASKET_CREATE_ORDER_ENTRY',
  API_BASKET_PUT_ADD_OPTION: 'API_BASKET_PUT_ADD_OPTION',
  API_BASKET_PUT_PRODUCT_OPTIONS_CUSTOM_DATA: 'API_BASKET_PUT_PRODUCT_OPTIONS_CUSTOM_DATA',
  API_BASKET_PUT_PRODUCT_OPTIONS_TELCO_DATA: 'API_BASKET_PUT_PRODUCT_OPTIONS_TELCO_DATA',
  API_BASKET_ADD_TELCO_DATA: 'API_BASKET_ADD_TELCO_DATA',
  API_BASKET_DELETE_OPTION: 'API_BASKET_DELETE_OPTION',
  API_BASKET_DELETE_OPTION_BY_UUID: 'API_BASKET_DELETE_OPTION_BY_UUID',
  API_BASKET_PUT_PRODUCT: 'API_BASKET_PUT_PRODUCT',
  API_BASKET_DELETE_PRODUCT: 'API_BASKET_DELETE_PRODUCT',
  API_BASKET_DELETE_OPTION_INDEX: 'API_BASKET_DELETE_OPTION_INDEX',
  API_BASKET_PUT_ACTIVATION_DATE: 'API_BASKET_PUT_ACTIVATION_DATE',
  API_BASKET_PUT_DOCUMENT: 'API_BASKET_PUT_DOCUMENT',
  API_BASKET_DELETE_DOCUMENT: 'API_BASKET_DELETE_DOCUMENT',

  //documents
  API_DOCUMENTS_POST_DOCUMENT: 'API_DOCUMENTS_POST_DOCUMENT',
  API_DOCUMENTS_DELETE_DOCUMENT: 'API_DOCUMENTS_DELETE_DOCUMENT',
  API_DOCUMENTS_DOWNLOAD_DOCUMENT: 'API_DOCUMENTS_DOWNLOAD_DOCUMENT',

  //nfon-OrderCallNumber
  API_OCN_GET_CUSTOMER_LEGAL_ENTITIES: 'API_OCN_GET_CUSTOMER_LEGAL_ENTITIES',
  API_OCN_GET_PREPARE_ORDER: 'API_OCN_PREPARE_ORDER',
  API_OCN_PUT_BASKET_ADD_OPTION: 'API_OCN_PUT_BASKET_ADD_OPTION',
  API_OCN_POST_COMPLETE: 'API_OCN_POST_COMPLETE',
  API_OCN_DELETE_BASKET_REMOVE_OPTION: 'API_OCN_DELETE_BASKET_REMOVE_OPTION',
  SAVE_OCN_STEP_DATA: 'SAVE_OCN_STEP_DATA',
  DELETE_OCN_STEP_DATA: 'DELETE_OCN_STEP_DATA',
  API_BASKET_PUT_PRODUCT_OPTIONS_TELCO_DATA_ORDER: 'API_BASKET_PUT_PRODUCT_OPTIONS_TELCO_DATA_ORDER',

  //nfon-OrderSipTrunk
  API_OST_GET_PREPARE_CHANGE: 'API_OST_GET_PREPARE_CHANGE',
  API_OST_POST_SUBMIT_CHANGE: 'API_OST_POST_SUBMIT_CHANGE',

  // Partner
  SAVE_SELECTED_PARTNER_CUSTOMER: 'SAVE_SELECTED_PARTNER_CUSTOMER',
  DELETE_SELECTED_PARTNER_CUSTOMER: 'DELETE_SELECTED_PARTNER_CUSTOMER',

  API_GET_PARTNER_CUSTOMERS: 'API_GET_PARTNER_CUSTOMERS',
  API_GET_PARTNER_CUSTOMERS_DATA: 'API_GET_PARTNER_CUSTOMERS_DATA',
  API_GET_PARTNER_CUSTOMERS_EXTENSIONS_DATA: 'API_GET_PARTNER_CUSTOMERS_EXTENSIONS_DATA',
  API_GET_CUSTOMERS_MENU_ITEMS: 'API_GET_CUSTOMERS_MENU_ITEMS',
  API_GET_PARTNER_CUSTOMERS_ADDRESSES_DATA: 'API_GET_PARTNER_CUSTOMERS_ADDRESSES_DATA',
  API_GET_PARTNER_CUSTOMERS_CLI_DATA: 'API_GET_PARTNER_CUSTOMERS_CLI_DATA',
  API_GET_PARTNER_CUSTOMERS_CHARGE_SUM: 'API_GET_PARTNER_CUSTOMERS_CHARGE_SUM',
  API_GET_PARTNER_CUSTOMERS_ACCOUNTS: 'API_GET_PARTNER_CUSTOMERS_ACCOUNTS',
  API_GET_PARTNER_CUSTOMERS_COMMUNICATIONS: 'API_GET_PARTNER_CUSTOMERS_COMMUNICATIONS',
  API_GET_PARTNER_CUSTOMERS_WORKFLOWS: 'API_GET_PARTNER_CUSTOMERS_WORKFLOWS',
  API_GET_PARTNER_CUSTOM_CONTACT_PERSON: 'API_GET_PARTNER_CUSTOM_CONTACT_PERSON',
  API_POST_PARTNER_CUSTOM_CONTACT_PERSON: 'API_POST_PARTNER_CUSTOM_CONTACT_PERSON',
  UPDATE_PARTNER_CUSTOM_CONTACT_PERSON: 'UPDATE_PARTNER_CUSTOM_CONTACT_PERSON',

  //lookups for some data
  API_GET_LANGUAGES: 'API_GET_LANGUAGES',
  API_GET_SALUTATIONS: 'API_GET_SALUTATIONS',
  API_GET_LEGAL_FORMS: 'API_GET_LEGAL_FORMS',
  API_GET_COUNTRY: 'API_GET_COUNTRY',
  API_GET_BILL_MEDIA: 'API_GET_BILL_MEDIA',
  API_GET_PAYMENT_MODE: 'API_GET_PAYMENT_MODE',
  API_GET_CARRIER:'API_GET_CARRIER',
  SET_PKCE_CODE_CHALLENGE: 'SET_PKCE_CODE_CHALLENGE',
  PARTNER_COLLAPSIBLE_STATUS: 'PARTNER_COLLAPSIBLE_STATUS',

  // Features
  API_PORTAL_FEATURE: 'API_PORTAL_FEATURE',

  // Licenses
  SAVE_LICENSES_BASKET: 'SAVE_LICENSES_BASKET',
  DELETE_LICENSES_BASKET: 'DELETE_LICENSES_BASKET',

  //change-product calls
  API_PREPARE_CHANGE_PRODUCT: 'API_PREPARE_CHANGE_PRODUCT',
  SAVE_CHANGE_PRODUCT_BASKET: 'SAVE_CHANGE_PRODUCT_BASKET',
  API_CHANGE_PRODUCT_SEND_ORDER: 'API_CHANGE_PRODUCT_SEND_ORDER',
};
