import { useTheme } from "../../utility/themeContext";
import cs from "classnames";
import commonStyles from "../../../portal/sass/base/common.module.scss";

type Props = {
  subtitle: string;
  internalClassName: string;
};

export const CpxSubtitle = (props: Props) => {
  const { subtitle, internalClassName } = props;
  const theme = useTheme();

  return (
    <div className={'subTitleBox'}>
      <p
        className={cs(
          commonStyles.subheading,
          'subText',
          `subText-le--${theme}`,
          `${internalClassName}-subText-le--${theme}`
        )}
      >
        {subtitle}
      </p>
    </div>
  )
}

