import { ACTION_CONST } from "../../constants/action.constants";

export const setError = (data) => {
	return {
		type: ACTION_CONST.SET_ERROR,
		data: data
	}
}

export const clearError = () => {
	return {
		type: ACTION_CONST.CLEAR_ERROR,
	}
}