import * as Yup from 'yup'
import { useTranslations } from "./helper/utils";
import { TRANSLATIONS } from "../constants/transitions/uiTranslations";
import { PRODUCT_CATEGORY, PRODUCT_CATEGORY_CALL_NUMBER_BLOCKS_MIGRATION, PRODUCT_CATEGORY_MIGRATION_DEFINED_BLOCK, SERVICE_PROPERTIES } from "../constants/configs/config.constants";

export const createValidatorSchema = object => Yup.object().shape(object)
export const createValidatorSchemaArray = object => Yup.array().of(createValidatorSchema(object))

export const emailCheck = (customMsg) => Yup.string().email(customMsg).
matches(/^(?:[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[A-Za-z0-9-]*[A-Za-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])$/g, customMsg);

export const emailCheckReq = (errorMsg, customMsg) => Yup.string().email(customMsg).required(errorMsg).
matches(/^([A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[A-Za-z0-9-]*[A-Za-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])$/g, customMsg);

export const requiredCheck = errorMsg => Yup.string().trim().required(errorMsg);

export const countryCodeRequiredCheck = (reqMsg, customMsg) => Yup.string()
	.required(reqMsg)
	.matches(/(^[+]\d{1,2}$)|(^00\d{1,2}$)/g, customMsg);

export const areaCodeRequiredCheck = (reqMsg, customMsg) => Yup.string()
	.required(reqMsg)
	.matches(/^\d{1,6}$/g, customMsg);

export const cliRequiredCheck = (reqMsg, customMsg) => Yup.string()
	.required(reqMsg)
	.matches(/^\d{1,50}$/g, customMsg);

export const countryCodeCheck = (customMsg) => Yup.string()
	.matches(/(^[+]\d{1,2}$)|(^00\d{1,2}$)/g, customMsg);

export const areaCodeCheck = (customMsg) => Yup.string()
	.matches(/^\d{1,6}$/g, customMsg);

export const cliCheck = (customMsg) => Yup.string()
	.matches(/^\d{1,50}$/g, customMsg);

export const numberCheck = (reqMsg, customMsg) => Yup.string()
	.required(reqMsg)
	.matches(/^[0-9]+$/g, customMsg);

export const ibanCheck = (reqMsg, customMsg, validEUSepaMsg) => Yup.string()
	.transform(currentValue => currentValue.replaceAll(' ', ''))
	.required(reqMsg)
	.matches(/^[A-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}$/g, customMsg)
	.matches(/^(BE|BG|DK|DE|EE|FO|FI|FR|GI|GR|GL|GB|IE|IS|IT|HR|LV|LI|LT|LU|MT|MC|NL|NO|AT|PL|PT|RO|SM|SE|CH|SK|SI|ES|CZ|HU|CY)[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}$/g, validEUSepaMsg);

export const bicCheck = (reqMsg, customMsg) => Yup.string()
	.required(reqMsg)
	.matches(/^[a-zA-Z]{4}[a-zA-Z]{2}[a-zA-Z0-9]{2}([a-zA-Z0-9]{3})?$/g, customMsg);

export const dateCheck = (reqMsg, customMsg, startDtRequested,minDate) => {
	if(startDtRequested) {
		return Yup.string()
			.matches(/^\d{4}-\d{2}-01$/, customMsg)
			.required(reqMsg)
	}
	if(minDate) {
		return Yup.date()
			.nullable()
			.required(reqMsg)
			.min(minDate, customMsg);
	}
	return Yup.date()
		.nullable()
		.required(reqMsg)
		.min(new Date().toJSON().slice(0, 10), customMsg);
}



export const orderRequiredQuantityCheck = (reqMsg, customMsg, min, max) => Yup.number()
	.required(reqMsg)
	.min(min, customMsg)
	.max(max, customMsg);

export const orderQuantityCheck = (customMsg, min, max) => Yup.number()
	.min(min, customMsg)
	.max(max, customMsg);

export const orderMaxLengthCheck = (reqMsg, customMsg, max) => Yup.string()
	.required(reqMsg)
	.max(max, customMsg);

export const orderMaxLengthNumberCheck = (reqMsg, customMsg, max) => Yup.string()
	.required(reqMsg)
	.matches(/^[0-9]+$/g, customMsg)
	.max(max, customMsg);

export const cliMigrationCheck = (reqMsg, matchMsg, option) => {
	if(option.product.productCategory.id === PRODUCT_CATEGORY.phoneNumberMigration) {
		return Yup.string()
			.required(reqMsg)
			.matches(/^\d{0,10}$/g, matchMsg);
	}
	return Yup.string();
}

export const extensionNumberCheck = (reqMsg, matchMsg, option) => {
	if(PRODUCT_CATEGORY_CALL_NUMBER_BLOCKS_MIGRATION.includes(option.product.productCategory.id)) {
		return Yup.string()
			.required(reqMsg)
			.matches(/^\d{0,10}$/g, matchMsg);
	}
	return Yup.string();

}


export const blockFromCheck = (reqMsg, matchMsg) => Yup.string()
	.required(reqMsg)
	.matches(/^\d{0,9}0$/g, matchMsg)
export const blockToCheck = (reqMsg, regexMsg, customMsg, option) => {
	if(option?.product?.productCategory?.id === PRODUCT_CATEGORY_MIGRATION_DEFINED_BLOCK) {
		return Yup.string()
			.required(reqMsg)
			.matches(/^\d{0,9}9$/g, regexMsg)
			.when(['blockFrom'], (blockFrom, schema) => {
				return schema.test({
					test: blockTo => !!blockFrom && blockTo - blockFrom + 1 === +JSON.parse(JSON.stringify(option?.serviceProperties))[SERVICE_PROPERTIES.ANZAHL_RUFNUMMERN],
					message: !!blockFrom && customMsg(+blockFrom + +JSON.parse(JSON.stringify(option?.serviceProperties))[SERVICE_PROPERTIES.ANZAHL_RUFNUMMERN] - 1)
				})
			})
	}
	return Yup.string()
		.required(reqMsg)
		.matches(/^\d{0,9}9$/g, regexMsg);

}


export const MaxLengthCheck = (maxLength, validationFunction) => {
	const translations = useTranslations(TRANSLATIONS.common);

	if (validationFunction) return validationFunction.max(maxLength, translations.inputMaxLengthText(maxLength + ''));
	return Yup.string().max(maxLength, translations.inputMaxLengthText(maxLength + ''));
}

export const servicePinCheck = (reqMsg, customMsg) => Yup.string()
	.required(reqMsg)
	.matches(/^(?!.*(\d)\1{5})(?!012345|123456|234567|345678|456789|987654|876543|765432|654321|543210)\d{6}$/g, customMsg);

export const requiredOptionCheck = (reqMsg, customMsg, invalidNumberMsg, min, max) => Yup.number()
	.when("isQtyOption", {
		is: true,
		then: Yup.number()
			.required(reqMsg)
			.min(min, customMsg)
			.max(max, customMsg)
			//.matches(/^[0-9]+$/g, invalidNumberMsg) // TODO
	})

