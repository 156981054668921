import { routes } from '../../../../constants/routes/routes';
import './customerData.scss';
import { ReactComponent as Phone } from '../../../../assets/icons/phone_blue.svg';
import { ReactComponent as Place } from '../../../../assets/icons/place.svg';
import { useTranslations } from '../../../../utils/helper/utils';
import { TRANSLATIONS } from '../../../../constants/transitions/uiTranslations';
import { useSelectCustomer } from '../../../../redux/store';
import { CpxAddress } from '../../../../../core/components/address.component';
import { useTheme } from '../../../../../core/utility/themeContext';
import cs from 'classnames';
import { PortalCustomerData } from 'compax-api';
import { CpxButton } from "../../../../../core/components/button.component";
import React from "react";

export const NfoCustomerData = () => {
  const translations = useTranslations(TRANSLATIONS.myContract);
  const customer = useSelectCustomer() as PortalCustomerData;
  const theme = useTheme();
  const internalClassName = 'customerData';

  const mail: any = customer?.mainContact?.email?.email?.length;
  const mailClass = mail > 25 ? 'longEmail' : null;

  return (
    <article className={cs(internalClassName,
      `${internalClassName}-le--${theme}`)}
    >
      <div className={'column'}>
        <h4 className={`${internalClassName}-title-le--${theme}`}>
          {translations.customerDataHeading()}
        </h4>
        <section>
          <h5 className={`${internalClassName}-label-le--${theme}`}>
            {customer?.customerName}
          </h5>
          <p className={`${internalClassName}-content-le--${theme}`}>
            {translations.customerId()}: {customer?.customerNumber}
          </p>
        </section>
        <section>
          <h5 className={`${internalClassName}-label-le--${theme}`}>
            <Phone/>
            {translations.contact()}
          </h5>
          <p
            className={cs(
              mailClass,
              `${internalClassName}-content-le--${theme}`,
              "longEmail"
            )}
          >
            {customer?.mainContact?.email?.email}
          </p>
          <p className={`${internalClassName}-content-le--${theme}`}>
            {customer?.mainContact?.phone?.phone}
          </p>
        </section>
        <section>
          <h5 className={`${internalClassName}-label-le--${theme}`}>
            <Place/>
            {translations.address()}
          </h5>
          <CpxAddress
            className={`${internalClassName}-content-le--${theme}`}
            address={customer?.mainAddress}
          />
        </section>
      </div>
      <div className={'column'}>
       <div className={'logo'}></div>
        <CpxButton
          buttonType={'moreLink'}
          to={routes.customerMyData.path}
        >
          {translations.moreLink()}
        </CpxButton>
      </div>
    </article>
  );
};
