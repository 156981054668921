import { CpxInputWithLabel } from "../../../../../core/components/inputWithLabel.component";
import React, { useEffect } from "react";
import { CpxSwitch } from "../../../../../core/components/switch.component";
import './contactDataItem.scss'
import { CpxPhoneInputWithLabel } from "../../../../../core/components/phoneInputWithLabel.component";

type Props = {
  key: number;
  formik: any;
  handleChange: any;
  label: string;
  name: string;
  switches: any;
  activeBillSwitchCounter: number;
  setActiveBillSwitchCounter: any;
  isPhone?: boolean;
}

export const ContactDataItem = ({ key, formik, handleChange, label, name, switches, activeBillSwitchCounter, setActiveBillSwitchCounter, isPhone = false }: Props) => {
  const internalClassName = 'contactDataItem';

  const handlePortalSwitchChange = () => {
    formik.setFieldValue(`${name}Portal`, !formik.values[`${name}Portal`])
  }

  const handlePortalBillChange = () => {
    formik.setFieldValue(`${name}Bill`, !formik.values[`${name}Bill`])
  }

  return (
    <tr className={`${internalClassName}`}>
      <td>
        {isPhone ? (
          <CpxPhoneInputWithLabel
            fields={[{ name: `${name}.countryCode`, value: formik.values[`${name}`]?.countryCode, id: 'countryCode', placeholder: '' },
              { name: `${name}.areaCode`, value: formik.values[`${name}`]?.areaCode, id: 'areaCode', placeholder: '' },
              { name: `${name}.cli`, value: formik.values[`${name}`]?.cli, id: 'cli', placeholder: '' }
            ]}
            onChange={handleChange}
            errors={formik.errors}
            touched={formik.touched}
            label={label}
            disabled={!(formik.values[`${name}Portal`] || formik.values[`${name}Bill`])}
            customItemName={name}
          />

        ) : (
          <CpxInputWithLabel
            id={name}
            type="text"
            onChange={handleChange}
            value={formik.values[`${name}`]}
            error={formik.touched[`${name}`] && formik.errors[`${name}`]}
            disabled={!(formik.values[`${name}Portal`] || formik.values[`${name}Bill`])}
          >
            {label}
          </CpxInputWithLabel>)}

      </td>
      <td>
        {switches.portal && (
          <CpxSwitch
            onChange={handlePortalSwitchChange}
            checked={formik.values[`${name}Portal`]}
            disabled={name === "name"}
          />
        )}
      </td>
      <td>
        {switches.bill && (
          <CpxSwitch
            onChange={handlePortalBillChange}
            checked={formik.values[`${name}Bill`]}
            disabled={name === "name"}
          />
        )}
      </td>
    </tr>
  )
}