import { ACTION_CONST } from "../../constants/action.constants";
import { IAction } from "../../constants/types/types.constants";

const initialState = {}

function errorReducer (state = initialState, action: IAction) {
	switch (action.type) {
		case ACTION_CONST.SET_ERROR:
		case ACTION_CONST.API_CALL_ERROR:
			return state = {
				...state,
				...action.data,
			}
		case ACTION_CONST.CLEAR_ERROR:
			return state = {}

		default:
			return state
	}

}

export default errorReducer