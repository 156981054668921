import { TRANSLATIONS } from "../../../constants/transitions/uiTranslations";
import { useTranslations } from "../../../utils/helper/utils";
import { NfoSitesTable } from "../../../components/customer/sites/sitesTable/sitesTable.component";
import { CpxTitle } from "../../../../core/components/title/title.component";
import { CpxSubtitle } from "../../../../core/components/title/subtitle.component";
import "./sites.scss";
import React, { useEffect } from "react";
import { apiCallAction } from "../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../constants/action.constants";
import { useDispatch } from "react-redux";
import {selectCurrentLanguage} from "../../../../core/uiLanguage/lang.slice";
import { useSelectLang, useSelectPartnerCustomer } from "../../../redux/store";
import { IPartnerCustomer } from "../../../constants/types/types.constants";

export const NfoSitesPage = () => {
  const translations = useTranslations(TRANSLATIONS.sites);
  const internalClassName = "sites";
  const dispatch = useDispatch();
  const currentLanguage = selectCurrentLanguage(useSelectLang());
  const selectedPartnerCustomer: IPartnerCustomer = useSelectPartnerCustomer() as IPartnerCustomer;



  useEffect(() => {
      dispatch(apiCallAction(ACTION_CONST.API_GET_ADDRESSES,  { language: currentLanguage, selectedPartnerCustomerId: selectedPartnerCustomer?.id}));
  }, [dispatch, currentLanguage]);

  return (
    <div className={internalClassName}>
      <CpxTitle
        title={translations.title()}
        name={
          selectedPartnerCustomer.companyName &&
          selectedPartnerCustomer.companyName
        }
        internalClassName={internalClassName}
      />
      <CpxSubtitle
        subtitle={translations.subheading()}
        internalClassName={internalClassName}
      />
      <NfoSitesTable/>
    </div>
  );
};
