import {ACTION_CONST} from '../../constants/action.constants'
import { IAction } from "../../constants/types/types.constants";

const initialState = false

function loaderReducer (state = initialState, action: IAction) {
	switch (action.type) {
		case ACTION_CONST.SHOW_LOADER:
			return true
		case ACTION_CONST.HIDE_LOADER:
			return false
		default:
			return state
	}
}

export default loaderReducer