import { createLangStore } from "./lang.slice";
import { SwitchLanguageActionCreator } from "./types";
import { LANGUAGES } from "../../portal/constants/configs/config.constants";

const store = createLangStore<typeof LANGUAGES>(
  navigator.languages[0].slice(0, 2) === "de" ? "DE" : "EN"
);

export const langReducer = store.slice.reducer;

export const { switchLanguage } = (store.slice.actions as unknown) as {
  switchLanguage: SwitchLanguageActionCreator<typeof LANGUAGES | never>;
};
