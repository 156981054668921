import {ACTION_CONST} from "../../constants/action.constants";
import {Basket, CustomerLegalEntity, SelectableBasketOption, SignUpResponse} from 'compax-api';
import {IAction} from "../../constants/types/types.constants";

const initialState = {
  customerLegalEntities: [] as Array<CustomerLegalEntity>,
  basket: {} as Basket,
  possibleCallNumberServices: [] as Array<SelectableBasketOption>,
  possiblePortingServices: [] as Array<SelectableBasketOption>,
  result: {} as SignUpResponse,
  stepData: {} as any
};

function numberOrderReducer(state = initialState, action: IAction) {
  switch (action.type) {
    case ACTION_CONST.API_OCN_GET_CUSTOMER_LEGAL_ENTITIES:
      state = {
        ...state,
        customerLegalEntities: action.data,
      };
      break;
    case ACTION_CONST.API_OCN_GET_PREPARE_ORDER:
      state = {
        ...state,
        ...action.data,
      };
      break;
    case ACTION_CONST.API_OCN_PUT_BASKET_ADD_OPTION:
    case ACTION_CONST.API_BASKET_PUT_PRODUCT_OPTIONS_TELCO_DATA_ORDER:
      state = {
        ...state,
        basket: action.data,
      }
      break;
    case ACTION_CONST.API_OCN_DELETE_BASKET_REMOVE_OPTION:
      state = {
        ...state,
        basket: action.data,
      }
      break;
    case ACTION_CONST.API_OCN_POST_COMPLETE:
      state = {
        ...state,
        result: action.data,
      }
      break;
    case ACTION_CONST.SAVE_OCN_STEP_DATA:
      state = {
        ...state,
        stepData: action.data,
      }
      break;
    case ACTION_CONST.DELETE_OCN_STEP_DATA:
      state = {
        ...state,
        stepData: {}
      }
      break;
  }
  return state;
}

export default numberOrderReducer;
