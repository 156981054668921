import { PropsWithoutRef } from 'react';
import './personInChargeList.scss';
import cs from 'classnames';
import { useTranslations } from '../../../../utils/helper/utils';
import { TRANSLATIONS } from '../../../../constants/transitions/uiTranslations';
import { useSelectCustomer } from '../../../../redux/store';
import { CUSTOMER_CONTACT_PERSON_ROLE_IDS } from '../../../../constants/configs/config.constants';
import { useTheme } from '../../../../../core/utility/themeContext';
import { PortalCustomerData } from 'compax-api';
import { NfoContactPerson } from "../personInCharge/contactPerson.component";

type Props = PropsWithoutRef<JSX.IntrinsicElements['select']>;

export const NfoPersonInChargeList = ({ className, ...props }: Props) => {
  const translations = useTranslations(TRANSLATIONS.myData);
  const customer = useSelectCustomer() as PortalCustomerData;
  const internalClassName = 'personInChargeList';
  const theme = useTheme();


  const businessContactPerson = customer?.contactPersons?.find((person) => person?.role?.id === CUSTOMER_CONTACT_PERSON_ROLE_IDS.BUSINESS);
  const technicalContactPerson = customer?.contactPersons?.find((person) => person?.role?.id === CUSTOMER_CONTACT_PERSON_ROLE_IDS.TECHNICAL);
  const commercialContactPerson = customer?.contactPersons?.find((person) => person?.role?.id === CUSTOMER_CONTACT_PERSON_ROLE_IDS.COMMERCIAL);


  return (
    <article className={cs(internalClassName, `${internalClassName}-le--${theme}`, className)}  {...props}>
      <h4 className={`componentTitle-le--${theme}`}>
        {translations.personsInChargeTitle()}
      </h4>
      {businessContactPerson && <NfoContactPerson contactPerson={businessContactPerson}/>}
      {technicalContactPerson && <NfoContactPerson contactPerson={technicalContactPerson}/>}
      {commercialContactPerson && <NfoContactPerson contactPerson={commercialContactPerson}/>}
    </article>
  );
};
