import { Formik, Form } from 'formik'

type Props = {
  initialValues?: any;
  handleSubmit?: any;
  children?: any;
  id?: string;
};

export const CpxFormikForm = (props: Props) => {
  let className = 'FormikFormComponent';
  const { initialValues, handleSubmit, children, id } = props;

  return (
    <div className={className}>
      <Formik initialValues={initialValues}
              onSubmit={handleSubmit}>
        {formikProps =>
          <Form onSubmit={formikProps.handleSubmit} noValidate={true} id={id}>
            {children}
          </Form>
        }
      </Formik>
    </div>
  )
}