import React, { useEffect, useState } from 'react';
import { CpxModal } from '../../../../../core/components/modal.component';
import { useTheme } from '../../../../../core/utility/themeContext';
import { useTranslations } from '../../../../utils/helper/utils';
import { TRANSLATIONS } from '../../../../constants/transitions/uiTranslations';
import { CpxSelectBox } from '../../../../../core/components/selectBox.component';
import { apiCallAction } from "../../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../../constants/action.constants";
import { useDispatch } from "react-redux";
import { useSelectEditSipTrunk, useSelectError, useSelectSipTrunks } from "../../../../redux/store";
import { Charge, OptionGroup, SelectableBasketOption } from 'compax-api';
import { NfoOstSelectQuantityModal } from "./selectQuantityModal.component";
import { NfoOstSummaryModal } from "./summaryModal.component";
import { currency } from "../../../../../core/utils";
import cs from 'classnames';
import './editSipTrunk.scss';
import { ICONS, ID_CHARGE_MODES, ID_CHARGE_TYPES, ID_SERVICE_GROUPS } from "../../../../constants/configs/config.constants";
import { useToast } from "../../../../components/common/header/toast/toast.component";
import { ToastTypes } from "../../../../constants/types/types.constants";
import { CpxIcon } from '../../../../../core/components/icon.component';
import { CpxButton } from "../../../../../core/components/button.component";
import parse from "html-react-parser";

export const NfoEditSipTrunk = ({ showModal, sipTrunk, productId, sipTrunkOptionId, name, customerId, loadSipTrunks, changeVoiceChannel }: any) => {
  const internalClassName = "editSipTrunk";
  const translations = useTranslations(TRANSLATIONS.editSipTrunk);
  const translationStatus = useTranslations(TRANSLATIONS.orderCallNumberModal);
  const translationCharges = useTranslations(TRANSLATIONS.charges);
  const theme = useTheme();
  const dispatch = useDispatch();
  const sipTrunkOptions = useSelectEditSipTrunk();
  const sipTrunks = useSelectSipTrunks();
  const apiError = useSelectError();
  const [disabled, setDisabled] = useState(true);
  const [editModal, setEditModal] = useState(true);
  const [selectProducts, setSelectProducts] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);
  const [activeProduct, setActiveProduct] = useState<number>();
  const [activeOption, setActiveOption] = useState();
  const [selectQuantityModal, setSelectQuantityModal] = useState(false);
  const [setQuantity, setSetQuantity] = useState(-1);
  const [summaryModal, setSummaryModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState('');
  const { showToast } = useToast();

  const currentSipTrunk: any = sipTrunks.find((sip: any) => sip.id === sipTrunk);
  const optionGroup: OptionGroup | undefined = sipTrunkOptions.possibleOptions.find((option: OptionGroup) => option.serviceGroup.id === ID_SERVICE_GROUPS.VOICE_CHANNELS);

  useEffect(() => {
    dispatch(apiCallAction(ACTION_CONST.API_OST_GET_PREPARE_CHANGE,
      { contractId: sipTrunk, selectedPartnerCustomerId: customerId },
      true)
    );
  }, [dispatch]);

  const handleProductClick = (id: any, productId: number) => {
    const els = document.getElementsByClassName("selectProduct");
    if (typeof els !== "undefined" && els.length > 0) {
      for (let i = 0; i < els.length; i++) {
        els[i].classList.remove("active-le--" + theme);
      }
      els[id].classList.add("active-le--" + theme);
    }
    setActiveProduct(productId);
    setDisabled(false);
  }

  const handleOptionClick = (id: any) => {
    const els = document.getElementsByClassName("selectOption");
    if (typeof els !== "undefined" && els.length > 0) {
      for (let i = 0; i < els.length; i++) {
        els[i].classList.remove("active-le--" + theme);
      }
      els[id].classList.add("active-le--" + theme);
    }
    setActiveOption(id);
  }

  const generateProducts: any = () => {
    let selectBoxElements: any[] = [];

    if (typeof optionGroup !== "undefined") {
      selectBoxElements = optionGroup.basketOptions.filter((option: SelectableBasketOption) => option.product.id !== sipTrunkOptionId).map((basketOption: SelectableBasketOption, i: number) => {
        if (typeof basketOption.product.displayValue !== "undefined") {
          let charge = basketOption.product.charges?.find((charge: Charge) => charge.chargeType?.id === ID_CHARGE_TYPES.BASIC_FEE);
          let oneTimeCharge = basketOption.product.charges?.find((charge: Charge) => charge.chargeMode?.id === ID_CHARGE_MODES.ONE_TIME);
          let isDiscounted = (charge?.amountNetDiscounts !== undefined && charge?.amountNetDiscounts > 0);
          let isOneTimeDiscounted = (oneTimeCharge?.amountNetDiscounts !== undefined && oneTimeCharge?.amountNetDiscounts > 0);

          return (
            <CpxSelectBox
              className={"selectProduct"}
              key={basketOption.product.id}
              tabIndex={i + 1}
              title={basketOption.product.displayValue}
              onClick={() => handleProductClick(i, Number(basketOption.product.id))}
              active={false}
              current={sipTrunkOptionId === basketOption.product.id}
            >
              {basketOption.product.description && (
                <span
                  dangerouslySetInnerHTML={{ __html: basketOption.product.description }}
                  className={cs('sub-text', `sub-text-le--${theme}`)}>
                </span>
              )}
              {((charge?.amountNet && charge?.currency) || (oneTimeCharge?.amountNet && oneTimeCharge?.currency)) && (
                <>
                  <p
                    className={cs("sub-text-price", `sub-text-price-le--${theme}`)}>
                    { charge?.amountNet && charge?.currency &&
                      <p>
                        <span className={cs({ ['line-through']: isDiscounted })}>{isDiscounted && currency(charge.amountNet, charge.currency.description)}</span>
                        <span>{isDiscounted ? `${currency(charge.amountNetDiscounts, charge.currency.description)} / ${translations.month()}` : `${currency(charge.amountNet, charge.currency.description)} / ${translations.month()}`}</span>
                      </p>
                    }
                    { oneTimeCharge?.amountNet && oneTimeCharge?.currency &&
                      <p>
                        <span className={cs({ ['line-through']: isOneTimeDiscounted })}>{isOneTimeDiscounted && currency(oneTimeCharge.amountNet, oneTimeCharge.currency.description)}</span>
                        <span>{isOneTimeDiscounted ? `${currency(oneTimeCharge.amountNetDiscounts, oneTimeCharge.currency.description)} ${translationCharges.oneTime()}` : `${currency(oneTimeCharge.amountNet, oneTimeCharge.currency.description)} ${translationCharges.oneTime()}`}</span>
                      </p>
                    }
                  </p>
                </>
              )}
            </CpxSelectBox>
          );
        }
      });
    }
    return selectBoxElements;
  }

  const generateOptions: any = () => {
    let selectBoxElements: any[] = [];
    const optionGroup: OptionGroup | undefined = sipTrunkOptions.possibleOptions.find((option: any) => option.serviceGroup.id === ID_SERVICE_GROUPS.OPTIONS);
    if (typeof optionGroup !== "undefined") {
      selectBoxElements = optionGroup.basketOptions.map((basketOption: SelectableBasketOption, i: number) => {
        if (typeof basketOption.product.displayValue !== "undefined") {
          let charge = basketOption.product.charges?.find((charge: Charge) => charge.chargeType?.id === ID_CHARGE_TYPES.BASIC_FEE);
          return (
            <CpxSelectBox
              className={"selectOption"}
              key={basketOption.product.id}
              tabIndex={i + 1}
              title={basketOption.product.displayValue}
              onClick={() => handleOptionClick(i)}
              active={false}
              disabled={true}
            >
              {basketOption.product.description && (
                <span
                  dangerouslySetInnerHTML={{ __html: basketOption.product.description }}
                  className={cs('sub-text', `sub-text-le--${theme}`)}>
              </span>
              )}
              {charge?.amountNet && charge?.currency && (
                <>
                  <p
                    className={`sub-text-price-le--${theme}`}>
                    {`${currency(charge.amountNet, charge.currency.description)} / ${translations.month()}*`}
                  </p>
                </>
              )}
            </CpxSelectBox>
          );
        }
      });
    }
    return selectBoxElements;
  }

  useEffect(() => {
    setSelectProducts(generateProducts());
    setSelectOptions(generateOptions());
  }, [sipTrunkOptions]);

  const confirmToPageTwo = () => {
    setSelectQuantityModal(true);
    setEditModal(false);
  };

  const backFromQuantitySelection = () => {
    setEditModal(true);
    setDisabled(true);
    setSetQuantity(-1);
    setSelectQuantityModal(false);
  };

  const confirmToPageThree = (quantity: number) => {
    setSetQuantity(quantity);
    setSummaryModal(true);
    setSelectQuantityModal(false);
  };

  const backFromSummaryPage = () => {
    setSelectQuantityModal(true);
    setSummaryModal(false);
  }

  const confirmToConfirmationPage = (isSuccess: boolean) => {
    setIsSuccess(isSuccess);
    setConfirmationModal(true);
    setSummaryModal(false);
  }

  useEffect(() => {
    if (confirmationModal) {
      if (isSuccess) {
        showToast(ToastTypes.SUCCESS, translationStatus.orderSuccess());
        loadSipTrunks();
      } else {
        showToast(ToastTypes.ERROR, translationStatus.orderError(), (apiError?.errorData && apiError?.errorData?.length > 0) ? apiError?.errorData : translationStatus.tryAgain());
      }
      showModal(false);
      setEditModal(true);
    }
  }, [confirmationModal, isSuccess])

  return (
    <>
      <>
        {summaryModal && (
          <NfoOstSummaryModal
            onConfirm={(isSuccess: boolean) => confirmToConfirmationPage(isSuccess)}
            backToPreviousPage={() => backFromSummaryPage()}
            cancelModal={showModal}
            activeProduct={activeProduct}
            sipTrunkName={name}
            customerId={customerId}
          />
        )}
        {selectQuantityModal && (
          <NfoOstSelectQuantityModal
            onConfirm={confirmToPageThree}
            backToPreviousPage={() => backFromQuantitySelection()}
            cancelModal={showModal}
            activeProduct={activeProduct}
            savedQty={setQuantity}
          />
        )}
      </>
      <>
        {editModal && (
          <CpxModal
            onConfirm={() => confirmToPageTwo()}
            confirmText={translations.next()}
            onCancel={() => showModal(false)}
            disableButton={disabled}
            className={internalClassName}
          >
            <h3 className={cs('modalTitle', `modalTitle-le--${theme}`)}>
              {translations.channelsAndOptions()}
            </h3>
            <p className={cs('modalInfoText', `modalInfoText-le--${theme}`)}>{translations.subheading()}</p>

            {!!optionGroup?.basketOptions.find((option: SelectableBasketOption) => option.product.id === sipTrunkOptionId) && <div>
              <h4 className={`font-medium-le--${theme}`}>{translations.currentChannelText()}</h4>
              <div
                  className={`${internalClassName}-currentVoiceChannel ${internalClassName}-currentVoiceChannel-le--${theme}`}>
                <p className={'current'}><CpxIcon icon={ICONS.STATUS.ACTIVE}/>{translations.current()}</p>
                <h4>{currentSipTrunk?.voicePackage}</h4>
                <p>{parse(optionGroup?.basketOptions.find((option: SelectableBasketOption) => option.product.id === sipTrunkOptionId)?.product?.description + "")}</p>
                <p></p>
                <div className={`${internalClassName}-header`}>
                  <p>{`${currentSipTrunk?.sipTrunkOptions?.find((item: any) => item.serviceGroup.id === 2).quantity} ${translations.voiceChannels()}`}</p>
                  <CpxButton
                      onClick={changeVoiceChannel}
                  >
                    {translations.changeQuantityOfChannels()}
                  </CpxButton>
                </div>
              </div>
            </div>}

            <h4 className={`font-medium-le--${theme}`}>{translations.voiceChannelPackages()}</h4>
            <div className={`${internalClassName} modalBoxes`}>
              {selectProducts && selectProducts}
            </div>
            {selectOptions && selectOptions.length > 0 && (
              <>
                <h4>{translations.options()}</h4>
                <div className={`${internalClassName} modalBoxes`}>
                  {selectOptions}
                </div>
              </>
            )}
            {error && (
              <div className={'errorField'}>{error}</div>
            )}
          </CpxModal>
        )}
      </>
    </>
  );
};
