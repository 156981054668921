import { Contract, PortalContractData } from "compax-api";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTheme } from "../../../../../core/utility/themeContext";
import { getCurrencySymbol, preventDefault, setStateFromEvent } from "../../../../../core/utils";
import { CpxButton } from "../../../../../core/components/button.component";
import { useSelectFraudLimits, useSelectLang } from "../../../../redux/store";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";
import { useGetLocked, useTranslations, } from "../../../../utils/helper/utils";
import "./connection.scss";
import { ICONS } from "../../../../constants/configs/config.constants";
import cs from "classnames";
import { apiCallAction } from "../../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../../constants/action.constants";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { NfoConfirmationModal } from "../../../../../core/components/confirmationModal.component";
import { CpxIcon } from "../../../../../core/components/icon.component";
import { CpxModal } from "../../../../../core/components/modal.component";
import { CpxInputWithLabel } from "../../../../../core/components/inputWithLabel.component";
import { CpxHelpBox } from "../../../../../core/components/helpBox.component";
import { CpxSwitchWithText } from "../../../../../core/components/switchWithText.component";
import { InputError } from "../../../../../core/components/inputError.component";
import { selectCurrentLanguage } from "../../../../../core/uiLanguage/lang.slice";
import { useToast } from "../../header/toast/toast.component";
import { ToastTypes } from "../../../../constants/types/types.constants";

type Props = { connection: PortalContractData, index: number };
type AppDispatch = ThunkDispatch<any, any, AnyAction>;

export const NfoConnection = ({connection, index}: Props) => {
        const dispatch: AppDispatch = useDispatch();
        const translations = useTranslations(TRANSLATIONS.securitySettings);
        const theme = useTheme();
        const internalClassname = "connection";
        const [confirmLimitModal, setConfirmLimitModal] = useState(false);
        const [confirmFraudUnluckModal, setConfirmFraudUnluckModal] = useState(false);
        const [isSuccess, setIsSuccess] = useState(true);
        const [error, setError]: any = useState(false);
        const language = selectCurrentLanguage(useSelectLang());
        const apiFraudLimits = useSelectFraudLimits();
        const apiFraudLock = useGetLocked();
        const { showToast } = useToast();


        const sipTrunkWithFraudLimit: any = {
            ...connection,
            fraudLimit: Array.isArray(apiFraudLimits) && apiFraudLimits?.find((limit: any) => limit?.contractId + "" === connection?.contractId)
        }
        const [formFraudLimitStatus, setFormFraudLimitStatus] = useState<boolean>(sipTrunkWithFraudLimit?.fraudLimit?.status?.id === 1);
        const [formFraudLimitAmount, setFormFraudLimitAmount] = useState<string>(sipTrunkWithFraudLimit?.fraudLimit?.amount + "");

        const limitId = 101168598;


        useEffect(() => {
            dispatch(apiCallAction(ACTION_CONST.API_GET_FRAUD_LIMIT));
        }, []);

        useEffect(() => {
            setFormFraudLimitAmount(sipTrunkWithFraudLimit?.fraudLimit?.amount + "")
            setFormFraudLimitStatus(sipTrunkWithFraudLimit?.fraudLimit?.status?.id === 1)
        }, [apiFraudLimits]);


        const handleConfirmFraudUnlockModal = () => {
            setConfirmFraudUnluckModal(false);
            if (isSuccess) {
                window.location.reload();
            }
        }

        const handleFraudUnlock = () => {
            apiFraudLock.lockedContracts.map((contract: Contract) => {
                dispatch(apiCallAction(
                    ACTION_CONST.API_POST_FRAUD_UNLOCK_OF_CONTRACT, {lockedContractId: contract.id}
                ))
                    .then(() => {
                        setIsSuccess(true);
                        setConfirmFraudUnluckModal(true);
                    })
                    .catch(() => {
                        setConfirmFraudUnluckModal(true);
                        setIsSuccess(false);
                    });
            });
        };

        const handleFraudLimitConfirm = () => {

            if (sipTrunkWithFraudLimit.fraudLimit === undefined) {
                sipTrunkWithFraudLimit.fraudLimit = {
                    amount: null,
                    contractId: null,
                    slimitId: null,
                    status: {id: null}

                }
            }

            sipTrunkWithFraudLimit.fraudLimit.amount = +formFraudLimitAmount;
            sipTrunkWithFraudLimit.fraudLimit.status.id = formFraudLimitStatus ? 1 : 9;


            if (!sipTrunkWithFraudLimit.fraudLimit.slimitId) {
                sipTrunkWithFraudLimit.fraudLimit.slimitId = limitId;
            }
            if (!sipTrunkWithFraudLimit.fraudLimit.contractId) {
                sipTrunkWithFraudLimit.fraudLimit.contractId = sipTrunkWithFraudLimit.contractId
            }

            dispatch(apiCallAction(
                ACTION_CONST.API_POST_FRAUD_LIMIT, {
                    ...sipTrunkWithFraudLimit.fraudLimit,
                    language
                })).then(() => {
                setConfirmLimitModal(false);
                setError(false)
                showToast(ToastTypes.SUCCESS, translations.success());
            }).catch((res) => {
                setError(res.errorData[0])
            })
        };

        const handleFraudLimitFormSubmit = preventDefault(() => {
            setConfirmLimitModal(true);
        });

        return (
            <>
                {confirmFraudUnluckModal && (
                    <NfoConfirmationModal
                        onConfirm={handleConfirmFraudUnlockModal}
                        successful={isSuccess}
                        successTitle={translations.unlockButton()}
                        successSubTitle={translations.confirmUnlockPopupBody()}
                    />
                )}
                {confirmLimitModal && (
                    <CpxModal
                        onConfirm={handleFraudLimitConfirm}
                        onCancel={() => {
                            setConfirmLimitModal(false)
                            setError(false)
                        }}
                    >
                        <h3>{translations.popupHeading()}</h3>
                        <p>{translations.popupBody1()}</p>
                        <br/>
                        {formFraudLimitAmount !== sipTrunkWithFraudLimit?.fraudLimit?.amount + "" && <p>
                            {translations.popupBody2() + " "}
                            <b>{formFraudLimitAmount} {getCurrencySymbol(sipTrunkWithFraudLimit?.fraudLimit?.currency?.description)}</b>
                        </p>}
                        {!formFraudLimitStatus && <p>
                            {translations.popupBody3()}
                        </p>}
                        {error && <InputError errorMessage={error}/>}
                    </CpxModal>
                )}
                {/*set fraud limit*/}
                {(apiFraudLock?.lockedContracts && Array.isArray(apiFraudLock?.lockedContracts) && apiFraudLock?.lockedContracts.length === 0) &&
                    (apiFraudLock?.progressLockedContracts && Array.isArray(apiFraudLock?.progressLockedContracts) && apiFraudLock?.progressLockedContracts.length === 0) &&
                    <article className={cs(internalClassname, `${internalClassname}-le--${theme}`,)}>
                        <h4 className={`${internalClassname}-componentTitle-le--${theme}`}>
                            {sipTrunkWithFraudLimit.contractName}
                        </h4>
                        <p>Service ID: {sipTrunkWithFraudLimit.id}</p>
                        <div className={"fraudDetection"}>
                            {<><h5 className={`${internalClassname}-componentTitle-le--${theme}`}>
                                {translations.fraudDetection()}
                                <CpxHelpBox className={"helpBox"}>
                                    {translations.infoBoxText()}
                                </CpxHelpBox>
                            </h5>
                                <form onSubmit={handleFraudLimitFormSubmit}>
                                    <div className={"formColumn"}>
                                        <CpxInputWithLabel
                                            id={`setfraudlimit-connection-${connection.id}`}
                                            className={cs(
                                                "limitInput",
                                                `${internalClassname}-inputLabel-le--${theme}`
                                            )}
                                            value={formFraudLimitAmount}
                                            defaultValue={formFraudLimitAmount}
                                            onChange={setStateFromEvent(setFormFraudLimitAmount)}
                                            type="number"
                                            readOnly={!formFraudLimitStatus}
                                            min={sipTrunkWithFraudLimit?.fraudLimit?.defaultAmount}
                                            max={999999}
                                            required
                                        >
                                            {translations.limitPerInterval()}
                                        </CpxInputWithLabel>
                                        &nbsp;{getCurrencySymbol(sipTrunkWithFraudLimit?.fraudLimit?.currency?.description)}
                                    </div>
                                    <div className={"formColumn"}>
                                        <CpxButton
                                            type="submit"
                                            disabled={(formFraudLimitAmount === sipTrunkWithFraudLimit?.fraudLimit?.amount + "") && (formFraudLimitStatus === (sipTrunkWithFraudLimit?.fraudLimit?.status?.id === 1))}
                                        >
                                            {translations.save()}
                                        </CpxButton>
                                    </div>
                                </form>
                                <div style={{width: '120px', position: 'absolute', right: '30px', top: '30px'}}>
                                    <CpxSwitchWithText
                                        className={'switch switch--right'}
                                        onChange={() => {
                                            setFormFraudLimitStatus(!formFraudLimitStatus);
                                        }}
                                        text={{left: translations.inactive(), right: translations.active()}}
                                        checked={formFraudLimitStatus}
                                    />
                                </div>
                            </>}
                        </div>
                    </article>}
                {/*fraud lock*/}
                {((apiFraudLock?.lockedContracts && Array.isArray(apiFraudLock?.lockedContracts) && apiFraudLock?.lockedContracts?.find((item: Contract) => item?.id === sipTrunkWithFraudLimit?.id)) ||
                    (apiFraudLock?.progressLockedContracts && Array.isArray(apiFraudLock?.progressLockedContracts) && apiFraudLock?.progressLockedContracts?.find((item: Contract) => item?.id === sipTrunkWithFraudLimit?.id)))
                    &&
                    <article className={cs(internalClassname, `${internalClassname}-le--${theme}`,)}>
                        <h4 className={`${internalClassname}-componentTitle-le--${theme}`}>
                            {sipTrunkWithFraudLimit.contractName}
                        </h4>
                        <p>Service ID: {sipTrunkWithFraudLimit.id}</p>
                        <div className={"fraudDetection"}>
                            {apiFraudLock?.lockedContracts && Array.isArray(apiFraudLock?.lockedContracts) && apiFraudLock?.lockedContracts?.map((contract: Contract) => {
                                if (connection.id === contract.id) {
                                    return (
                                        <form className={`warning warning-le--${theme}`}>
                                            <div className={"formColumn"}>
                                                <h5>
                                                    <CpxIcon icon={ICONS.ATTENTION}/>
                                                    {translations.lockedHeading()}
                                                </h5>
                                                <p>{translations.lockedBody()}</p>
                                            </div>
                                            <div className={"formColumn"}>
                                                <CpxButton type="button" onClick={handleFraudUnlock}>
                                                    {translations.unlockButton()}
                                                </CpxButton>
                                            </div>
                                        </form>
                                    )
                                }
                            })}
                            {apiFraudLock?.progressLockedContracts && Array.isArray(apiFraudLock?.progressLockedContracts) && apiFraudLock.progressLockedContracts.map((contract: Contract) => {
                                if (connection.id === contract.id) {
                                    return (
                                        <form className={`success success-le--${theme}`}>
                                            <div className={"formColumn"}>
                                                <h5>
                                                    <CpxIcon icon={ICONS.STATUS.ACTIVE}/>
                                                    {translations.unlockInProgressHeading()}
                                                </h5>
                                                <p>{translations.unlockInProgressBody()}</p>
                                            </div>
                                            <div className={"formColumn"}>
                                                <CpxButton type="button" disabled>
                                                    {translations.unlockButton()}
                                                </CpxButton>
                                            </div>
                                        </form>
                                    )
                                }
                            })}

                        </div>
                    </article>}
            </>
        );
    };
