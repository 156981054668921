import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { selectCurrentLanguage } from "../../../core/uiLanguage/lang.slice";
import { formatDate, validate } from "../../../core/utils";
import { DownloadType, FileDownload, FileGetter, } from "../../../core/components/downloadTable.component";
import { useSelectBills, useSelectItemizedBills, useSelectItemizedBillsTelefonica, useSelectLang, useSelectPartnerCommissionBill, useSelectWholesalerCDR, useSelectWholesalerSDR, } from "../../redux/store";
import { apiCallAction } from "../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../constants/action.constants";
import { IBills } from "../../constants/types/types.constants";

export type FileGetterCreator = (id: string) => FileGetter;

export type FilenameGetter = (bill: any) => string;

type Download = {
  getterCreator: FileGetterCreator;
  filenameGetter: FilenameGetter;
  downloadType: DownloadType;
};

export const useBillsTable = (downloads: [Download, ...Download[]], isItemized?: boolean, isTelefonica?: boolean) => {
  const dispatch = useDispatch();
  const currentLanguage = selectCurrentLanguage(useSelectLang());
  const billsData: IBills = isTelefonica ? useSelectItemizedBillsTelefonica() : (isItemized ? useSelectItemizedBills() : useSelectBills());

  if(isTelefonica){
    useEffect(() => {
      dispatch(apiCallAction(ACTION_CONST.API_GET_ITEMIZED_BILLS_FOR_TELEFONICA_CUSTOMERS, { language: currentLanguage}));
    }, [dispatch, currentLanguage]);
  }else{
    useEffect(() => {
      isItemized ?
        dispatch(apiCallAction(ACTION_CONST.API_GET_ITEMIZED_BILLS, { language: currentLanguage})) :
        dispatch(apiCallAction(ACTION_CONST.API_GET_BILLS, { language: currentLanguage}));
    }, [dispatch, currentLanguage]);

  }

  return useMemo(
    () =>
      !!billsData
        ? Array.isArray(billsData) && billsData
        .map((bill: any) => ({
          id: "" + validate(bill.id),
          cols: [
            formatDate(new Date(bill.invoiceDate as Date), currentLanguage),
            bill.displayName,
            validate(bill.billNumber),
          ],
          fileDownloads: downloads.map(download => ({
            fileGetter: download.getterCreator(validate(bill.id)),
            fileName: download.filenameGetter(bill),
            downloadType: download.downloadType,
          })) as [FileDownload, ...FileDownload[]],
        }))
        : [],
    [billsData, downloads]
  );
};

export const useCommissionBillTable = (downloads: [Download, ...Download[]]) => {
  const dispatch = useDispatch();
  const commissionData = useSelectPartnerCommissionBill();
  const currentLang = selectCurrentLanguage(useSelectLang());

  useEffect(() => void dispatch(
    apiCallAction(ACTION_CONST.API_GET_PARTNER_COMMISSION_DATA, { language: currentLang })
  ), [dispatch, currentLang]);

  return useMemo(
    () =>
      !!commissionData
        ? Array.isArray(commissionData) && commissionData
        .map(bill => (
          {
            id: "" + validate(bill.id),
            cols: [
              formatDate(new Date(bill.billDate as Date), currentLang),
              downloads[0].filenameGetter(bill).split(".")[0],
              validate(bill.billNumber),
            ],
            fileDownloads: downloads.map(download => ({
              fileGetter: download.getterCreator(validate(bill.id)),
              fileName: download.filenameGetter(bill),
              downloadType: download.downloadType,
            })) as [FileDownload, ...FileDownload[]],
          }))
        : [],
    [commissionData, downloads]
  );
};

export const useSDRTable = (downloads: [Download, ...Download[]]) => {
  const dispatch = useDispatch();
  const sdrData = useSelectWholesalerSDR();
  const currentLang = selectCurrentLanguage(useSelectLang());

  useEffect(() => void dispatch(
    apiCallAction(ACTION_CONST.API_GET_WHOLESALER_SDR_DATA, { language: currentLang })
  ), [dispatch, currentLang]);

  return useMemo(
    () =>
      !!sdrData
        ? Array.isArray(sdrData) && sdrData
        .map(sdr => (
          {
            id: "" + validate(sdr.billId),
            cols: [
              new Date(sdr.billDate as Date)?.toLocaleDateString(),
              downloads[0].filenameGetter(sdr).split(".")[0],
              // validate(sdr.billNumber),
            ],
            fileDownloads: downloads.map(download => ({
              fileGetter: download.getterCreator(validate(sdr.billId)),
              fileName: download.filenameGetter(sdr),
              downloadType: download.downloadType,
            })) as [FileDownload, ...FileDownload[]],
          }))
        : [],
    [sdrData, downloads]
  );
};

export const useCDRTable = (downloads: [Download, ...Download[]]) => {
  const dispatch = useDispatch();
  const cdrData = useSelectWholesalerCDR();
  const currentLang = selectCurrentLanguage(useSelectLang());

  useEffect(() => void dispatch(
    apiCallAction(ACTION_CONST.API_GET_WHOLESALER_CDR_DATA, { language: currentLang })
  ), [dispatch, currentLang]);

  return useMemo(
    () =>
      !!cdrData
        ? Array.isArray(cdrData) && cdrData
        .map(cdr => (
          {
            id: "" + validate(cdr.billId),
            cols: [
              new Date(cdr.billDate as Date)?.toLocaleDateString(),
              downloads[0].filenameGetter(cdr).split(".")[0],
              // validate(sdr.billNumber),
            ],
            fileDownloads: downloads.map(download => ({
              fileGetter: download.getterCreator(validate(cdr.billId)),
              fileName: download.filenameGetter(cdr),
              downloadType: download.downloadType,
            })) as [FileDownload, ...FileDownload[]],
          }))
        : [],
    [cdrData, downloads]
  );
};
