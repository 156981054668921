import cs from 'classnames';
import React, { PropsWithoutRef } from 'react';
import './switchWithText.scss';
import { useTheme } from '../utility/themeContext';
import { selectCurrentLanguage } from "../uiLanguage/lang.slice";
import { useSelectLang } from "../../portal/redux/store";

type Props = Omit<
  PropsWithoutRef<JSX.IntrinsicElements['input']>,
  'onChange'
> & {
  onChange: (checked: boolean) => void;
  text: { left: string, right: string};
  disableOption?: { left?: boolean, right?: boolean }
};

export const CpxSwitchWithText = ({
  text,
  onChange,
  checked,
  className,
  disableOption,
  ...props
}: Props) => {
  const theme = useTheme();
  const currentLanguage = selectCurrentLanguage(useSelectLang());
  const internalClassName = 'switchTextWrapper';

  const handleClick = () => {
    if (!disableOption?.left && !disableOption?.right) {
      onChange(!checked);
    }
  };

  return (
    <span className={cs(className, internalClassName)} onClick={handleClick}>
      <p className={cs(
        'switch-text-left',
        `switch-text-left-le--${theme}`,
        { active: !checked },
        { en: currentLanguage === "EN"},
        { disabled: disableOption?.left === true }
      )}>
          {text.left}
      </p>
      <p className={cs(
        'switch-text-right',
        `switch-text-right-le--${theme}`,
        { active: !!checked },
        { en: currentLanguage === "EN"},
        { disabled: disableOption?.right }
      )}>
        {text.right}
      </p>
      <span
        className={cs(
          'switch-text',
          `switch-text-le--${theme} `,
          `switch-text-le`,
          { active: !!checked },
          { [`switch-text-le--${theme}-active`]: !!checked },
          { en: currentLanguage === "EN"},
          { [`switch-text-le--${theme}-en`]: currentLanguage === "EN"}
        )}
      />
      <input
        type="checkbox"
        checked={!!checked}
        {...props}
        onChange={handleClick}
      />
    </span>
  );
};
