import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useTheme } from "../../../../../../core/utility/themeContext";
import { useDispatch } from "react-redux";
import { useTranslations } from "../../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../../constants/transitions/uiTranslations";
import { useSelectNumberOrder } from "../../../../../redux/store";
import { apiCallAction } from "../../../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../../../constants/action.constants";
import { deleteOCNStepData } from "../../../../../redux/actions/numberOrder.action";
import { currency, removeFirstOptionFromBasket } from "../../../../../../core/utils";
import { ReactComponent as Info } from '../../../../../assets/icons/info_green.svg';
import cs from "classnames";
import { CpxModal } from "../../../../../../core/components/modal.component";
import React from "react";
import "./orderPortingSummary.scss"

type AppDispatch = ThunkDispatch<any, any, AnyAction>;

type Props = {
    onConfirm:any;
    backToPreviousPage:any;
    cancelModal:any;
    connection:any;
    address:any;
    product:any;
    customerId:any;
    numbers: string[];
}

export const NfoOrderPortingSummary = ({
                                              onConfirm,
                                              backToPreviousPage,
                                              cancelModal,
                                              connection,
                                              address,
                                              product,
                                              customerId,
                                              numbers
                                          }: Props) => {
    const internalClassName = 'orderPortingSummary';
    const theme = useTheme();
    const dispatch: AppDispatch = useDispatch();
    const translations = useTranslations(TRANSLATIONS.orderCallNumberModal);
    const translationsCommon = useTranslations(TRANSLATIONS.common);
    const numberOrder = useSelectNumberOrder();

    const charge = product.charges?.find((charge: any) => charge.chargeType.id === 4);
    let option = numberOrder.basket.entries[0].options?.find(opt => opt.product.id === product.id);
    let basketCharge = option?.oneTimeCharges?.find((charge: any) => charge.chargeMode.id === 1);


    const confirmForm = () => {
        if (typeof numberOrder.basket !== "undefined" && typeof numberOrder.basket.id !== "undefined") {
            dispatch(
                apiCallAction(ACTION_CONST.API_OCN_POST_COMPLETE, { basketId: numberOrder.basket.id, selectedPartnerCustomerId: customerId }, true)
            ).then(() => {
                onConfirm(true);
            }).catch(() => {
                onConfirm(false);
            });
            dispatch(deleteOCNStepData())
        }
    }

    const backToPageTwo = () => {
        removeFirstOptionFromBasket(numberOrder.basket, dispatch);
        backToPreviousPage();
    }

    const cancelModalHandler = () => {
        dispatch(deleteOCNStepData())
        removeFirstOptionFromBasket(numberOrder.basket, dispatch);
        cancelModal(false);
    }

    const handleDiscount = () => {
        if (isDiscountGreaterThanZero()) {
            return <p>1x {product.displayValue} (<span className={cs('line-through')}>{currency(basketCharge?.amountNet, basketCharge?.currency?.description, true)}</span> {currency((getBasketChargeAmountDiscounted()), basketCharge?.currency?.description)})</p>
        } else {
            return <p>1x {product.displayValue} ({currency(basketCharge?.amountNet, basketCharge?.currency?.description, true)})</p>
        }
    }

    const isDiscountGreaterThanZero = () => {
        return basketCharge !== undefined && basketCharge?.amountNetDiscounts !== undefined && basketCharge.amountNetDiscounts > 0;
    }

    const getBasketChargeAmountDiscounted = () => {
        let amountNet = basketCharge?.amountNet !== undefined ? basketCharge.amountNet : 0;
        let amountNetDiscounts = basketCharge?.amountNetDiscounts !== undefined ? basketCharge.amountNetDiscounts : 0;
        return amountNet - amountNetDiscounts;
    }

    return (
        <>
            <CpxModal
              onCancel={() => cancelModalHandler()}
              onBack={() => backToPageTwo()}
              onConfirm={() => confirmForm()}
              className={internalClassName}
            >
                <h3 className={cs('modalTitle', `modalTitle-le--${theme}`)}>
                    {translations.titleSummary()}
                </h3>
                <p className={cs(`modalInfoText modalInfoText-le--${theme}`)}>
                    {translations.subTextSummary()}
                </p>
                <hr className={cs(`${internalClassName}-hr`, `${internalClassName}-hr-le--${theme}`)}/>
                <h5 className={`${internalClassName}-section-title-le--${theme}`}>{translations.connection()}</h5>
                <p className={`${internalClassName}-section-subTitle-le--${theme}`}>{connection && connection}</p>
                <br/>
                <h5 className={`${internalClassName}-section-title-le--${theme}`}>{translations.location()}</h5>
                <p className={`${internalClassName}-section-subTitle-le--${theme}`}>{address && address}</p>
                <div>
                    <hr className={cs(`${internalClassName}-hr`, `${internalClassName}-hr-le--${theme}`)}/>
                    <h5 className={`${internalClassName}-section-title-le--${theme}`}>Aktivierungsdatum</h5>
                    <p className={`${internalClassName}-section-subTitle-le--${theme}`}>{numberOrder?.stepData?.scheduledDate?.split('T')[0]}</p>
                </div>
                <hr className={cs(`${internalClassName}-hr`, `${internalClassName}-hr-le--${theme}`)}/>
                <h5 className={`${internalClassName}-section-title-le--${theme}`}>{translations.order()}</h5>
                {product && <p className={`${internalClassName}-section-subTitle-le--${theme}`}>
                    {handleDiscount()}
                </p>}
                {(isDiscountGreaterThanZero()) && <div className={cs(`discountInfo ${internalClassName}-discountInfo`, `discountInfo-le--${theme}`)}>
                    <Info/>
                    <div>
                        <p className={cs('infoTitle', `infoTitle-le--${theme}`)}>
                            {translationsCommon.discountedInfoText(currency(charge.amountNetDiscounts, charge?.currency?.description, true) + "")}</p>
                    </div>
                </div>}
                {(isDiscountGreaterThanZero()) && <div className={cs(`discountInfo ${internalClassName}-discountInfo`, `discountInfo-le--${theme}`)}>
                    <Info/>
                    <div>
                        <p className={cs('infoTitle', `infoTitle-le--${theme}`)}>
                            {translationsCommon.discountedInfoText(currency(basketCharge?.amountNetDiscounts, basketCharge?.currency?.description, true) + "")}</p>
                    </div>
                </div>}
                <ul>
                    {numbers.map((num: string) => (
                      <li>{num}</li>
                    ))}
                </ul>
                <br/>

            </CpxModal>
        </>
    );
};