import cs from "classnames";
import { useTheme } from "../../../../../../core/utility/themeContext";
import { Address, TelcoData } from "compax-api";
import { CpxIcon } from "../../../../../../core/components/icon.component";
import { ICONS } from "../../../../../constants/configs/config.constants";
import { useSelectCountries } from "../../../../../redux/store";
import { useTranslations } from "../../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../../constants/transitions/uiTranslations";

type Props = {
  site: Address,
  areaCode: string,
  telcoData: TelcoData,
}

export const CallNumberBlockConfigurationItem = ({ site, areaCode, telcoData }: Props) => {
  const theme = useTheme();
  const countries = useSelectCountries();
  const translations = useTranslations(TRANSLATIONS.stepper.newCustomer);

  const getCountryById = (id: number) => {
    return countries?.filter((country: any) => country.id === id)[0]?.description || '';
  }


  const getPhoneNumberString = () => {
    if(telcoData?.newLnpPhoneNumberBlock){
      return ' ' + telcoData.newLnpPhoneNumberBlock.extensionNumber + ' (' + telcoData.newLnpPhoneNumberBlock.blockFrom + '-' + telcoData.newLnpPhoneNumberBlock.blockTo + ')';
    }
    if(telcoData?.newPhoneNumber?.cli){
      return ' ' + telcoData?.newPhoneNumber?.cli;
    }
    if(telcoData?.lnpData) {
      if(telcoData.lnpData.portPhoneNumbers){
        if(telcoData.lnpData.portPhoneNumbers.length > 0){
          return ' ' + telcoData.lnpData.portPhoneNumbers[0].cli + (telcoData.lnpData.portPhoneNumbers.length > 1 ? ',...' : '');
        }else {
          return '';
        }

      }else if(telcoData.lnpData?.portPhoneNumberBlock) {
        return ' ' + telcoData.lnpData?.portPhoneNumberBlock.extensionNumber + ' (' + telcoData.lnpData?.portPhoneNumberBlock.blockFrom + '-' + telcoData.lnpData?.portPhoneNumberBlock.blockTo + ')';
      }
    }
    return '';
  }

  const getCountryCode = () => {
    if(telcoData.lnpData) {
      if(telcoData.lnpData.portPhoneNumbers && telcoData.lnpData.portPhoneNumbers.length > 0) {
        return telcoData.lnpData.portPhoneNumbers[0].countryCode
      }
      if(telcoData.lnpData.portPhoneNumberBlock) {
        return telcoData.lnpData.portPhoneNumberBlock.countryCode;
      }
    }
    if(telcoData?.newLnpPhoneNumberBlock){
      return telcoData.newLnpPhoneNumberBlock.countryCode || '';
    }
    if(telcoData?.newPhoneNumber){
      return telcoData?.newPhoneNumber?.countryCode || '';
    }
    if(telcoData?.newPhoneNumberBlock){
      return telcoData?.newPhoneNumberBlock?.countryCode;
    }
    return '';
  }


  return (
    <div className={cs('selectBox_sites__item', `selectBox_sites__item-le--${theme}`)}>
      <CpxIcon icon={ICONS.PLACE}/>
      <div>
        <h5 className={cs('selectBox_sites__item-title', `selectBox_sites__item-title-le--${theme}`)}>{`${translations.site()}: ${getCountryById(site?.country?.id)} | ${getCountryCode()} ${areaCode}${getPhoneNumberString()}`}</h5>
        {site ? <p>{`${site?.companyName ? site?.companyName : site?.firstName}, ${site?.street} ${site?.houseNumber}, ${site?.zip} ${site?.city}, ${getCountryById(site?.country?.id)}`}</p> : <p>Bitte ordnen Sie diese Rufnummer einem Standort zu!</p>}
      </div>
    </div>
  );
}