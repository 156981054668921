import {LEGAL_ENTITIES} from '../../portal/constants/configs/config.constants';
import {useTheme} from '../utility/themeContext';
import {ReactComponent as EndWhite} from '../../portal/assets/icons/end_white.svg';
import {ReactComponent as EndDts} from '../../portal/assets/icons/end_dts.svg';

type Props = {
  className?: string;
  whiteOverride?: boolean;
};

export const CpxEnd = ({ className, whiteOverride }: Props) => {
  const le = useTheme();

  if (le === LEGAL_ENTITIES.DTS) {
    return <EndDts className={className} />;
  } else {
    if (whiteOverride) {
      return <EndWhite className={className} />;
    } else {
      return <EndWhite className={className} />;
    }
  }
};
