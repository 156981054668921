import { PortalContractData } from 'compax-api';
import { ReactComponent as StatusActive } from '../../../assets/icons/status-active_white.svg';
import { ReactComponent as StatusActiveDTS } from '../../../assets/icons/status-active_dark.svg';
import { CpxStart } from '../../../../core/components/start.component';
import { CpxEnd } from '../../../../core/components/end.component';
import { NfoStatus } from '../../../../core/components/status.component';
import { TRANSLATIONS } from '../../../constants/transitions/uiTranslations';
import { useTranslations } from '../../../utils/helper/utils';
import cs from 'classnames';
import { useTheme } from '../../../../core/utility/themeContext';
import {
  ID_DATA_ENTITY,
  LEGAL_ENTITIES,
} from '../../../constants/configs/config.constants';
import React from 'react';
import { useSelectLang } from "../../../redux/store";
import { formatDate } from "../../../../core/utils";

type Props = {
  contract: PortalContractData;
};

export const NfoMainContractInfo = ({ contract }: Props) => {
  const translations = useTranslations(TRANSLATIONS.contractDetails);
  const le = useTheme();
  const theme = useTheme();
  const icon =
    le === LEGAL_ENTITIES.DTS ? <StatusActiveDTS /> : <StatusActive />;
  const language = useSelectLang().selected;


  return (
    <div className={cs('columnContainer', `columnContainer-le--${theme}`)}>
      <div className={'column'}>
        <h5 className={cs(`column-title-le--${theme}`)}>{translations.contractStart()}</h5>
        {contract.startDateBilling && (
          <p className={cs('startdate', `startdate-le--${theme}`)}>
            <CpxStart whiteOverride={true} />
            {formatDate(new Date(contract.startDateBilling as Date), language)}
          </p>
        )}
      </div>
      <div className={'column'}>
        <h5 className={cs(`column-title-le--${theme}`)}>{translations.contractEnd()}</h5>
        {contract.endDateBilling && (
          <p className={cs('enddate', `enddate-le--${theme}`)}>
            <CpxEnd whiteOverride={true} />
            {formatDate(new Date(contract.endDateBilling as Date), language)}
          </p>
        )}
      </div>
      <div className={'column'}>
        <h5 className={cs(`column-title-le--${theme}`)}>{translations.contractNumber()}</h5>
        <p>
          {contract.knumber}
        </p>
      </div>
      <div className={'column'}>
        <h5 className={cs(`column-title-le--${theme}`)}>{translations.status()}</h5>
        <p className={cs('status', `status-le--${theme}`)}>
          <NfoStatus
            entity={{ id: ID_DATA_ENTITY.SERVICE }}
            status={contract.status}
            activeIconOverride={icon}
          />
        </p>
      </div>
    </div>
  );
};
