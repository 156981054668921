import React from 'react';
import { Helmet } from 'react-helmet';
import { useTheme } from '../../utility/themeContext';
// @ts-ignore
import FavDTS from '../../../portal/assets/favicons/favicon_dts.svg';
// @ts-ignore
import FavNFON from '../../../portal/assets/favicons/favicon_nfon.ico';
// @ts-ignore
import FavTEF from '../../../portal/assets/favicons/favicon_tef.ico';
import { useGetTitle } from "../../../portal/utils/helper/utils";

export const SeoComponent = () => {
  const theme = useTheme();
  const userValueTyp = useGetTitle();

  let favicon;

  switch (theme) {
    case 'nfon':
      favicon = FavNFON;
      break;
    case 'dts':
      favicon = FavDTS;
      break;
    case 'tef':
      favicon = FavTEF;
      break;
    default:
      favicon = FavNFON;
  }
  return (
    <>
      <Helmet>
        <link rel="icon" href={favicon} />
        <title>{userValueTyp}</title>
      </Helmet>
    </>
  );
};
