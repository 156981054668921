import { CpxSelectBox } from "../../../../../../core/components/selectBox.component";
import React, { useEffect, useState } from "react";
import { CurrencyWithChargeModeWithAmountNet } from "../../../../../../core/utils";
import './optionGroups.scss'
import cs from "classnames";
import { useTheme } from "../../../../../../core/utility/themeContext";
import { Basket, BasketEntry, OptionGroup, SelectableBasketOption } from "compax-api";
import { useTranslations } from "../../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../../constants/transitions/uiTranslations";
import { addOptionsCustomDataToBasket, addOptionsTelcoDataToBasket, addOptionsToBasket, editOptionFromBasket, removeOptionsFromBasket, saveOEOptionsOfOption } from "../../../../../redux/actions/step.action";
import { useDispatch } from "react-redux";
import { useOrderEntryBasket, useSelectFeatures, useSelectLang, useStep1Data, useStep3Data } from "../../../../../redux/store";
import { apiCallAction } from "../../../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../../../constants/action.constants";
import {
    FEATURES_FLAG,
    ID_PRODUCT_STATUS,
    ID_SERVICE_GROUPS,
    NUMBERS_MODAL_PRODUCT_CATEGORIES,
    NUMBERS_PORTING_PRODUCT_CATEGORIES,
    QUANTITY_MODAL_PRODUCT_CATEGORIES,
    SERVICE_TYPE_PORTING,
    TELEPHONE_CONNECTIONS_IDS,
} from "../../../../../constants/configs/config.constants";
import { selectCurrentLanguage } from "../../../../../../core/uiLanguage/lang.slice";
import { ProductOptionModal } from "../productOptionModal/productOptionModal.component";
import { ProductOptionModalType } from "../../../../../constants/types/types.constants";


type Props = { optionGroups: any, parentOptionId: number, parentOptionUuid: string }

export const NfoOptionGroups = ({optionGroups, parentOptionId, parentOptionUuid}: Props) => {
    const theme = useTheme();
    const internalClassName = 'optionGroup';
    const translations = useTranslations(TRANSLATIONS.charges);
    const dispatch = useDispatch();
    const basket: Basket = useOrderEntryBasket();
    const currentLang = selectCurrentLanguage(useSelectLang());
    const features = useSelectFeatures();
    const [selectedOption, setSelectedOption] = useState<SelectableBasketOption>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const step1Data = useStep1Data();
    const step3Data = useStep3Data();
    const isMigration = !!step1Data?.migrationCustomerId;

    // Disable filter for NOT_RELEASED products on INT environment
    useEffect(() => {
        dispatch(apiCallAction(ACTION_CONST.API_PORTAL_FEATURE, {featureName: FEATURES_FLAG.PORTAL_ORDER_ENTRY_GENERATOR}))
    }, [])

    const isModalOption = (option: SelectableBasketOption) => {
        return (option?.product?.productCategory && (
                /*TEXT_FIELD_MODAL_PRODUCT_CATEGORIES.includes(option?.product?.productCategory?.id) ||*/
                QUANTITY_MODAL_PRODUCT_CATEGORIES.includes(option?.product?.productCategory?.id) ||
                NUMBERS_MODAL_PRODUCT_CATEGORIES.includes(option?.product?.productCategory?.id) ||
                NUMBERS_PORTING_PRODUCT_CATEGORIES.includes(option?.product?.productCategory?.id)
            )
        )
    }

    const handleOptionModal = (option: SelectableBasketOption) => {

        if (option.selected) {
            selectOptionOfOption(option);
            return;
        }

        if (isModalOption(option)) {
            setSelectedOption(option);
            setShowModal(true);
        } else {
            selectOptionOfOption(option);
        }
    }


    const getNewBasket = async (option: any, type?: ProductOptionModalType, customData?: any) => {
        if (type === ProductOptionModalType.CUSTOM_DATA) {
            return dispatch(addOptionsCustomDataToBasket(option, basket, customData));
        } else if (type === ProductOptionModalType.TELCO_DATA) {
            return dispatch(addOptionsTelcoDataToBasket(option, basket, customData));
        } else {
            return dispatch(addOptionsToBasket(option, basket, customData));
        }
    }

    const selectOptionOfOption = async (option: any, type?: ProductOptionModalType, customData?: any) => {

        const optionId = option?.product?.id;
        const optionIdInBasket = basket?.entries[step3Data?.activeIndex]?.options?.find((o: any) =>
            o?.uuid === parentOptionUuid)?.options?.find((ofo: any) => ofo?.product?.id === optionId)?.product?.id;
        let tempBasket: any;
        if(option.product.productType.id === SERVICE_TYPE_PORTING && !type){
            tempBasket = await dispatch(removeOptionsFromBasket(option, basket));
        }else if(option.product.productType.id === SERVICE_TYPE_PORTING){
            tempBasket = await getNewBasket(option, type, customData);
        }else if (optionId === optionIdInBasket && type === ProductOptionModalType.EDIT_TELCO_DATA) {
            tempBasket = await dispatch(editOptionFromBasket(option, basket, customData))
        } else if (optionId === optionIdInBasket) {
            tempBasket = await dispatch(removeOptionsFromBasket(option, basket));
        } else {
            tempBasket = await getNewBasket(option, type, customData);
        }

        let newBasket: Basket = tempBasket;

        const action: string = (step1Data?.migrationCustomerId && step1Data?.partnerVoNumber && parentOptionId === 7706200) ? ACTION_CONST.API_GET_OE_OPTIONS_OF_OPTION_FOR_MIGRATION : ACTION_CONST.API_GET_OE_OPTIONS_OF_OPTION;

        const res: any = await dispatch(apiCallAction(action,
            {
                basketId: basket?.id,
                uuid: Object.values(newBasket?.entries)?.find(entry => entry.uuid === step3Data?.activeEntryUuid)?.options?.find((o: any) => o.uuid === parentOptionUuid)?.uuid,
                language: currentLang
            },
            true));

        let optionsLength = newBasket?.entries[step3Data?.activeIndex]?.options?.length;

        if (isMigration && optionsLength !== undefined && optionsLength <= 1) {
            let optionGroupChannel = res?.optionGroups.find((op: OptionGroup) => op.serviceGroup.id === 2);
            if (optionGroupChannel) {
                optionGroupChannel.basketOptions =
                  optionGroupChannel?.basketOptions?.filter((bo: SelectableBasketOption) => bo.product?.productCategory?.id === 100);

            }
        }

        dispatch(saveOEOptionsOfOption({[parentOptionUuid]: res?.optionGroups}));
        setShowModal(false);
        return newBasket;
    }
    const listOfTelephoneConnections = basket?.entries[step3Data?.activeIndex]?.options?.filter((option: BasketEntry) => TELEPHONE_CONNECTIONS_IDS.includes(option?.product?.id));
    // Sort Call Number Objects based on Selection
    const selectedTelephoneConnectionId = listOfTelephoneConnections && listOfTelephoneConnections.length && listOfTelephoneConnections.length > 0 && listOfTelephoneConnections[0].product.id;

    let uuid = basket?.entries[step3Data?.activeIndex]?.options?.find((option: BasketEntry) => option.product.id === selectedTelephoneConnectionId)?.uuid;

    if (optionGroups && uuid && optionGroups[uuid]) {
        [...optionGroups[uuid]]?.filter((optionGroup: OptionGroup) => optionGroup.serviceGroup.id === ID_SERVICE_GROUPS.CALL_NUMBERS)[0]?.basketOptions.sort((a: any, b: any) => b.selected - a.selected);
    }

    const showMigrationPrice = (option: SelectableBasketOption) => {
        if (isMigration && option.selected) {
            let currentSipTrunk = basket?.entries[step3Data.activeIndex]?.options?.find(o => o.uuid === option.parentUuid);
            return currentSipTrunk && !!currentSipTrunk.options?.find(o => o.product?.id === option.product.id)?.discounts?.find(d => d.id === 5);
        }
        return false;
    }

    return (
        <>
            {showModal && (
                <ProductOptionModal
                    option={selectedOption}
                    setShowModal={setShowModal}
                    selectOptionOfOption={selectOptionOfOption}
                />
            )}

            {(optionGroups && Array.isArray(optionGroups[parentOptionUuid])) && optionGroups[parentOptionUuid].map((optionGroup: OptionGroup, index: number) => {

                return (
                    <div className={internalClassName}>
                        {optionGroup?.basketOptions?.length > 0 &&
                            optionGroup?.basketOptions.find((o: SelectableBasketOption) => o?.product?.status?.id !== 0)
                            &&
                            <h4 className={cs(`${internalClassName}-title`, `${internalClassName}-title-le--${theme}`)}>{optionGroup?.serviceGroup?.description}</h4>}
                        <div className='boxes'>
                            {optionGroup?.basketOptions.filter((o: SelectableBasketOption) => {
                                // Disable filter on INT environment
                                if (features.PORTAL_ORDER_ENTRY_GENERATOR) return true;
                                return o?.product?.status?.id !== ID_PRODUCT_STATUS.NOT_RELEASED
                            }).map((o: any) => {
                                return (
                                    <CpxSelectBox
                                        key={index}
                                        tabIndex={index}
                                        option={o && o}
                                        product={o?.product}
                                        title={o?.product?.displayValue}
                                        subtitle={o?.product?.description}
                                        active={o.selected}
                                        selectOptionOfOption={selectOptionOfOption}
                                        isIncluded={o?.role?.id === 8 || (o?.selected && o?.role?.id === 666)}
                                        onClick={() => handleOptionModal(o)}
                                    >
                                        <div
                                            className={cs(`${internalClassName}-charges`, `${internalClassName}-charges-le--${theme}`)}>
                                            {
                                                o?.product?.charges?.length !== 0
                                                  ? CurrencyWithChargeModeWithAmountNet(o?.product?.charges, parentOptionId, translations, showMigrationPrice(o))?.map((charge: any, index: number) =>
                                                    (<p key={index}>{charge}</p>)
                                                  )
                                                  : ''
                                            }
                                        </div>
                                    </CpxSelectBox>)
                            })}
                        </div>
                    </div>
                )
            })}
        </>
    )
}