import { TRANSLATIONS } from '../../../../constants/transitions/uiTranslations';
import { useTranslations } from '../../../../utils/helper/utils';
import { CpxTitle } from '../../../../../core/components/title/title.component';
import { useSelectContracts, useSelectedMainContract, useSelectPartnerCustomer, useSelectPartnerCustomerChargeSum, } from '../../../../redux/store';
import commonStyles from '../../../../sass/base/common.module.scss';
import './contract.scss';
import { IPartnerCustomer, } from '../../../../constants/types/types.constants';
import { NfoMainPartnerContract } from '../../../../components/partner/customer/contract/mainPartnerContractDetails/mainPartnerContractDetails.component';
import { NfoMonthlyCharges } from "../../../../components/customer/monthlyCharges/monthlyCharges.component";
import { ID_STATUS_SERVICES } from "../../../../constants/configs/config.constants";
import { useEffect } from "react";
import { apiCallAction } from "../../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../../constants/action.constants";
import { useDispatch } from "react-redux";
import { PortalContractChargesSum, PortalContractData } from "compax-api";
import { CpxSubtitle } from "../../../../../core/components/title/subtitle.component";

export const NfoCustomersContractPartnerPage = () => {
    const translations = useTranslations(TRANSLATIONS.partnerNav);
    const translationsPartnerCustomer = useTranslations(TRANSLATIONS.partnerCustomer);
    const dispatch = useDispatch();

    const internalClassName = 'partner-customer-contract';
    const selectedPartnerCustomer: IPartnerCustomer =
        useSelectPartnerCustomer() as IPartnerCustomer;
    const selectedPartnerCustomerChargeSum: PortalContractChargesSum =
        useSelectPartnerCustomerChargeSum() as PortalContractChargesSum;
    const selectedPartnerCustomerContract: PortalContractData[] =
        useSelectContracts() as unknown as PortalContractData[];
    const selectedMainContract = useSelectedMainContract();

    const contractsWithAmount = Array.isArray(selectedPartnerCustomerContract) &&
        selectedPartnerCustomerContract.filter(contract => (contract.amountNetTotal || 0) > 0
            && contract.status?.id !== ID_STATUS_SERVICES.CANCELLATION_REQUESTED
            && contract.status?.id !== ID_STATUS_SERVICES.CANCELLED
            && contract.status?.id !== ID_STATUS_SERVICES.TERMINATION_REQUESTED
            && contract.status?.id !== ID_STATUS_SERVICES.TERMINATED);

    const contractsWithAmountForCancellation = Array.isArray(selectedPartnerCustomerContract) &&
        selectedPartnerCustomerContract.filter(contract => (contract.amountNetTotal || 0) > 0
            && contract.status?.id === ID_STATUS_SERVICES.CANCELLATION_REQUESTED);

    const mainProduct =
        Array.isArray(selectedPartnerCustomerContract) &&
        selectedPartnerCustomerContract !== undefined &&
        selectedPartnerCustomerContract.find(
            (entry: any) => entry.componentOf === undefined
        );

    useEffect(() => {
        dispatch(
            apiCallAction(ACTION_CONST.API_GET_PARTNER_CUSTOMERS_CHARGE_SUM, {
                customerId: selectedPartnerCustomer.id,
            })
        );
      dispatch(
        apiCallAction(
          ACTION_CONST.API_GET_CONTRACTS,
          { customerId: selectedPartnerCustomer.id, mainContractId: selectedMainContract?.contractId },
          true
        )
      );
    }, [selectedMainContract?.contractId]);

    return (
        <>
            <CpxTitle
                title={translations.contract()}
                name={
                    selectedPartnerCustomer.companyName &&
                    selectedPartnerCustomer.companyName
                }
                internalClassName={internalClassName}
            />
            <CpxSubtitle
              subtitle={translationsPartnerCustomer.overviewCustomerContractDetails()}
              internalClassName={'internalClassName'}
            />
            <div className={commonStyles.columnContainer}>
                <div className={commonStyles.column}>
                    {!!mainProduct && mainProduct && (
                      <NfoMainPartnerContract
                        key={mainProduct.id}
                        mainContract={mainProduct}
                        contracts={selectedPartnerCustomerContract}
                      />
                    )}
                </div>
                <div className={commonStyles.column}>
                    {contractsWithAmount &&
                        contractsWithAmount.length > 0 &&
                      <NfoMonthlyCharges
                        title={translations.monthlyCharges()}
                        contracts={contractsWithAmount}
                        useInactiveFlag={true}
                        totalSumNet={selectedPartnerCustomerChargeSum && selectedPartnerCustomerChargeSum.totalSumNetOthers}
                      />
                    }
                    {contractsWithAmountForCancellation &&
                        contractsWithAmountForCancellation.length > 0 &&
                      <NfoMonthlyCharges
                        title={translations.monthlyChargesToCancel()}
                        contracts={contractsWithAmountForCancellation}
                        useInactiveFlag={false}
                        totalSumNet={selectedPartnerCustomerChargeSum && selectedPartnerCustomerChargeSum.totalSumNetCancellationRequest}
                      />
                    }
                </div>
            </div>
        </>
    );
};
