import { useSelectPartnerCustomer } from '../../../../redux/store';
import { TRANSLATIONS } from '../../../../constants/transitions/uiTranslations';
import { useTranslations } from '../../../../utils/helper/utils';
import { CpxTitle } from '../../../../../core/components/title/title.component';
import { IPartnerCustomer } from '../../../../constants/types/types.constants';

export const NfoCustomersOrdersPartnerPage = () => {
  const translations = useTranslations(TRANSLATIONS.partnerNav);
  const internalClassName = 'partner-customer-orders';

  const selectedPartnerCustomer: IPartnerCustomer = useSelectPartnerCustomer() as IPartnerCustomer;

  return (
    <>
      <CpxTitle
        title={translations.orders()}
        name={
          selectedPartnerCustomer.companyName &&
          selectedPartnerCustomer.companyName
        }
        internalClassName={internalClassName}
      />
    </>
  );
};
