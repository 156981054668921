import cs from 'classnames';
import { PropsWithoutRef, ReactNode, useRef, useState } from 'react';
import { preventDefault } from '../utils';
import { ReactComponent as VisibilityOff } from '../../portal/assets/icons/visibility-off.svg';
import { ReactComponent as VisibilityOn } from '../../portal/assets/icons/visibility-on.svg';
import { CpxInputWithLabel } from './inputWithLabel.component';
import styles from './passwordInput.module.scss';
import { useTheme } from "../utility/themeContext";

type Props = Omit<PropsWithoutRef<JSX.IntrinsicElements['input']>, 'type'> & {
  id: string;
  children: ReactNode;
  buttonClassName?: string;
  labelClassName?: string;
};

export const CpxPasswordInput = (props: Props) => {
  const [hidden, setHidden] = useState(true);
  const inputRef = useRef<HTMLInputElement>(null);
  const theme = useTheme();

  const hideIcon = theme === 'dts' ? styles.hideButton : styles.hideButtonNfon;

  const handleHideButtonClick = preventDefault(() => {
    setHidden(x => !x);
    if (!!inputRef && !!inputRef.current) {
      inputRef.current.focus();
    }
  });

  const hideButton = (
    <button
      onClick={handleHideButtonClick}
      className={cs(props.buttonClassName, hideIcon)}
      type={'button'}
    >
      {hidden ? <VisibilityOn/> : <VisibilityOff/>}
    </button>
  );

  return (
    <CpxInputWithLabel
      {...props}
      ref={inputRef}
      className={cs(props.className, styles.passwordInput)}
      suffix={hideButton}
      type={hidden ? 'password' : 'text'}
    />
  );
};
