import styles from "./sitesDetails.module.scss";
import { ReactComponent as Limit } from "../../../../assets/icons/limit.svg";
import { ReactComponent as Place } from "../../../../assets/icons/place.svg";
import { ReactComponent as Phone } from "../../../../assets/icons/phone_blue.svg";
import { ReactComponent as Vertrag } from "../../../../assets/icons/contract.svg";
import { ReactComponent as Arrow } from "../../../../assets/icons/arrow.svg";
import { CpxProgressBar } from "../../../../../core/components/progressBar.component";
import { CpxButton } from "../../../../../core/components/button.component";
import { useTranslations } from "../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";

type Props = {
  id: string;
  onClose: () => void;
};

export const NfoSiteDetails = ({ onClose }: Props) => {
  const translations = useTranslations(TRANSLATIONS.sites);

  return (
    <article className={styles.siteDetails}>
      <header>
        <p>{translations.siteDetailsLabel()}</p>
        <button onClick={onClose}>
          <Arrow/>
        </button>
      </header>
      <h3>Digital Media Sales</h3>
      <h4>
        <Place/>
        {translations.site()}
      </h4>
      <p>Digital Media GmbH,</p>
      <p>Ziegelstraße 16</p>
      <p>D-10117 Berlin</p>
      <h4>
        <Phone/>
        {translations.numberBlock()}
      </h4>
      <p>+49 89 465464 00 - 99</p>
      <h4>
        <Vertrag/>
        {translations.options()}
      </h4>
      <p>Mobilfunk Option</p>
      <h4>
        <Limit/>
        {translations.package()}
      </h4>
      <p>800 / 10.000 min</p>
      <CpxProgressBar
        current={800}
        max={10000}
        isWarningColor={800 / 10000 > 0.8}
        className={styles.progressBar}
        useSecurityBehaviour={true}
      />
      <footer>
        <CpxButton onClick={onClose}>
          {translations.closeSiteDetails()}
        </CpxButton>
      </footer>
    </article>
  );
};
