import './alert.scss'
import { ReactComponent as Error } from '../../../assets/icons/attention_white.svg'

type Props = {
  errors?: any;
  hideIcon?: boolean;
};

export const Alert = (
  {
    errors,
    hideIcon,
  }: Props) => {
  return (

    <>
      {Array.isArray(errors) && errors?.length > 0 ?
        <div className={'alert'}>
          <div className={'entry'}>
            {!hideIcon && <Error className={'icon'}/>}
            <div className={'messages'}>

              {Array.isArray(errors) && errors?.length > 0 && errors?.map((error, index) => {
                return (<div key={index}>{error}</div>)
              })}
            </div>
          </div>
        </div>
        :
        <div className={'alert'}>
          <div className={'entry'}>
            {!hideIcon && <Error className={'icon'}/>}
            <div className={'messages'}>
              <div>{errors}</div>
            </div>
          </div>
        </div>}
    </>
  )
}
