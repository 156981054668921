import React, { useEffect } from 'react';
import { CpxCollapsible } from '../../../../core/components/collapsible.component';
import { ReactComponent as Partner } from '../../../assets/icons/partner_white.svg';
import { useTranslations } from '../../../utils/helper/utils';
import { TRANSLATIONS } from '../../../constants/transitions/uiTranslations';
import { useDispatch } from 'react-redux';
import { useSelectPartners } from '../../../redux/store';
import { ChannelMaintainer } from 'compax-api';
import './myContactPerson.scss';
import { apiCallAction } from "../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../constants/action.constants";
import { IPartners } from "../../../constants/types/types.constants";

export const NfoMyContactPerson = () => {
  const dispatch = useDispatch();
  const translations = useTranslations(TRANSLATIONS.myContactPerson);
  const partners = useSelectPartners() as IPartners;


  useEffect(() => {
    dispatch(apiCallAction(ACTION_CONST.API_GET_PARTNERS));
  }, [dispatch]);

  return (
    <CpxCollapsible
      title={
        <>
          <Partner/>
          {translations.contactPersonHeading()}
        </>
      }
    >
      {partners.length > 0 && partners.map(partner => (
        <div key={partner.id}>
          <h5></h5>
          {partner.channelMaintainers &&
          partner.channelMaintainers && partner.channelMaintainers.map(
            (channelMaintainer: ChannelMaintainer) => (
              <div key={channelMaintainer.fullName}>
                <p>{channelMaintainer.fullName}</p>
                <p className={'mail'}>{channelMaintainer.email}</p>
                <p>{channelMaintainer.phoneNumber}</p>
              </div>
            )
          )}
        </div>
      ))}
    </CpxCollapsible>
  );
};
