import { ACTION_CONST } from "../../constants/action.constants";
import { IAction } from "../../constants/types/types.constants";
import { PortalContractData } from "compax-api";

const initialState = {
  isAnonymized: false,
  myAccounts: [],
  myAddresses: [],
  mySipTrunks: [],
  myBills: [],
  myItemizedBills: [],
  myContracts: [],
  myMainContracts: [],
  myContractsChargeSum: {},
  myData: [],
  myExtensions: {},
  myFraudLimits: [],
  myFraudLock: [],
  myLicenses: [],
  myLicensesByServiceGroup: [],
  myNumbers: [],
  myPackages: [],
  myServicePin: [],
  myItemizedBillsTelefonica: [],
  mySelectedMainContract: {} as PortalContractData,
  myMenuItems: [],
}

function customerReducer(state = initialState, action: IAction) {
  switch (action.type) {
    case ACTION_CONST.API_GET_CUSTOMER:
      state = {
        ...state,
        myData: action.data,
      }
      break;
    case ACTION_CONST.API_GET_ACCOUNTS:
      state = {
        ...state,
        myAccounts: action.data,
      }
      break;
    case ACTION_CONST.API_GET_ENV_TYPE:
      state = {
        ...state,
        isAnonymized: action.data,
      }
      break;
    case ACTION_CONST.API_POST_ENV_TYPE:
      state = {
        ...state,
        isAnonymized: !state.isAnonymized,
      }
      break;
    case ACTION_CONST.API_GET_PACKAGES:
      state = {
        ...state,
        myPackages: action.data,
      }
      break;
    case ACTION_CONST.API_GET_CONTRACTS:
      state = {
        ...state,
        myContracts: action.data,
      }
      break;
    case ACTION_CONST.API_GET_MAIN_CONTRACTS:
      state = {
        ...state,
        myMainContracts: action.data,
      }
      break;
    case ACTION_CONST.API_GET_CONTRACTS_CHARGE_SUM:
      state = {
        ...state,
        myContractsChargeSum: action.data,
      }
      break;
    case ACTION_CONST.API_GET_FRAUDLOCK_OF_CONTRACT:
      state = {
        ...state,
        myFraudLock: action.data,
      }
      break;
    case ACTION_CONST.API_GET_FRAUD_LIMIT:
      state = {
        ...state,
        myFraudLimits: action.data,
      }
      break;
    case ACTION_CONST.API_GET_BILLS:
      state = {
        ...state,
        myBills: action.data,
      }
      break;
    case ACTION_CONST.API_GET_ITEMIZED_BILLS:
      state = {
        ...state,
        myItemizedBills: action.data,
      }
      break;
    case ACTION_CONST.API_GET_ITEMIZED_BILLS_FOR_TELEFONICA_CUSTOMERS:
      state = {
        ...state,
        myItemizedBillsTelefonica: action.data,
      }
      break;
    case ACTION_CONST.API_GET_CLIS:
      state = {
        ...state,
        myNumbers: action.data,
      }
      break;
    case ACTION_CONST.API_GET_LICENSES:
      state = {
        ...state,
        myLicenses: action.data,
      }
      break;
    case ACTION_CONST.API_GET_LICENSES_BY_SERVICE_GROUP:
      state = {
        ...state,
        myLicensesByServiceGroup: action.data,
      }
      break;
    case ACTION_CONST.API_GET_EXTENSIONS:
      state = {
        ...state,
        myExtensions: action.data,
      }
      break;
    case ACTION_CONST.API_GET_ADDRESSES:
      state = {
        ...state,
        myAddresses: action.data,
      }
      break;
    case ACTION_CONST.API_GET_SIPTRUNK:
      state = {
        ...state,
        mySipTrunks: action.data,
      }
      break;
    case ACTION_CONST.SET_SELECTED_CONTRACT:
      state = {
        ...state,
        mySelectedMainContract: action.data,
      }
      break;
    case ACTION_CONST.UNSET_SELECTED_CONTRACT:
      state = {
        ...state,
        mySelectedMainContract: {} as PortalContractData,
      }
      break;
    case ACTION_CONST.API_GET_CUSTOMERS_MENU_ITEMS:
      state = {
        ...state,
        myMenuItems: action.data,
      }
      break;
  }
  return state;
}

export default customerReducer;
