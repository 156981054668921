import { useDispatch } from "react-redux";
import { CpxInputWithLabel } from "../../../../../core/components/inputWithLabel.component";
import { useSelectError, useSelectLanguage, useSelectUser } from "../../../../redux/store";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";
import { useTranslations } from "../../../../utils/helper/utils";
import cs from "classnames";
import { CpxButton } from "../../../../../core/components/button.component";
import { CpxIcon } from "../../../../../core/components/icon.component";
import { ICONS, INPUT_MAX_LENGTHS, LANGUAGES_ID } from "../../../../constants/configs/config.constants";
import React, { useEffect, useState } from "react";
import { useTheme } from "../../../../../core/utility/themeContext";
import { useFormik } from "formik";
import { areaCodeRequiredCheck, cliRequiredCheck, countryCodeRequiredCheck, createValidatorSchema, emailCheckReq, MaxLengthCheck, requiredCheck } from "../../../../utils/validation";
import { CpxFormikForm } from "../../../../../core/components/formikForm.component";
import { CpxDropdownWithLabel } from "../../../../../core/components/dropdown.component";
import { apiCallAction } from "../../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../../constants/action.constants";
import { CpxPhoneInputWithLabel } from "../../../../../core/components/phoneInputWithLabel.component";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { setUserData } from "../../../../redux/actions/user.actions";
import { Alert } from "../../Alert/Alert.component";
import { clearError } from "../../../../redux/actions/error.action";
import { useToast } from "../../header/toast/toast.component";
import { ToastTypes } from "../../../../constants/types/types.constants";
import { switchLanguage } from "../../../../../core/uiLanguage/langStore";

type AppDispatch = ThunkDispatch<any, any, AnyAction>;

export const NfoUserDataForm = () => {
  const dispatch: AppDispatch = useDispatch();
  const translation = useTranslations(TRANSLATIONS.profile);
  const translations = useTranslations(TRANSLATIONS.stepper.newCustomer);
  const user = useSelectUser();
  const theme = useTheme();
  const internalClassName = 'myProfileBox'
  const languages = useSelectLanguage();
  const requiredMsg = translations.fieldRequired();
  const error = useSelectError();
  const { showToast } = useToast();

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  useEffect(() => {
    dispatch(apiCallAction(ACTION_CONST.API_GET_USER));
    dispatch(apiCallAction(ACTION_CONST.API_GET_LANGUAGES));
  }, [])


  let contactLanguageOptions = Array.isArray(languages) ? languages.map(language => {
    return { name: language.description, id: +language.id }
  }).filter((language) => language.id === 1 || language.id === 2) : []; // filter for german and english only

  const getLanguageLookupById = (id: number) => languages.find((language: any) => language.id === id)

  const handleStatusModal = () => {
    setShowModal(false);
  }

  const handleCancel = () => {
    formik.resetForm();
    dispatch(clearError());
    setIsEditMode(false);
  }

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      fullName: `${user?.given_name || ""} ${user?.family_name || ""}`,
      userName: user?.preferred_username || "",
      email: user?.email || "",
      phone: {
        countryCode: user?.phone?.countryCode || "",
        areaCode: user?.phone?.areaCode || "",
        cli: user?.phone?.cli || "",
        phoneType: user?.phone?.phoneType || { id: 1 }
      },
      language: {
        id: Number(user?.language?.id)
      }
    },
    validationSchema: createValidatorSchema({
      userName: MaxLengthCheck(INPUT_MAX_LENGTHS.contactPersons.login, requiredCheck(requiredMsg)),
      email: MaxLengthCheck(INPUT_MAX_LENGTHS.contact.email, emailCheckReq(requiredMsg, translations.emailSyntax())),
      phone: createValidatorSchema({
        countryCode: countryCodeRequiredCheck(translations.countryCodeRequired(), translations.countryCodeSyntax()),
        areaCode: areaCodeRequiredCheck(translations.areaCodeRequired(), translations.areaCodeSyntax()),
        cli: cliRequiredCheck(translations.cliRequired(), translations.cliSyntax()),
      }),
      language: createValidatorSchema({
        id: requiredCheck(requiredMsg),
      }),
    }),
    onSubmit: (values) => {

      let userDataObj: any = {
        login: values?.userName,
        phone: values?.phone,
        contactLanguage: getLanguageLookupById(Number(values?.language?.id)),
      }

      // Check if email changed
      if (user?.email !== values?.email) {
        userDataObj = {...userDataObj,
          email: {
            email: values?.email,
            emailType: {
              id: 4
            }
          }}
      }



      dispatch(apiCallAction(ACTION_CONST.API_PUT_PROFILE,  userDataObj))
        .then((res) => {
          dispatch(setUserData(res))

          dispatch(clearError());
          setIsEditMode(false)
          showToast(ToastTypes.SUCCESS, translation.successTitle())
        })
    }
  });

  useEffect(() => {
    console.log("formik", formik.errors)
  }, [formik.errors])

  const changesMade = () => {
    const currentValues = formik.values;
    const stateValues = {
      fullName: `${user?.given_name} ${user?.family_name}`,
      userName: user?.preferred_username,
      email: user?.email,
      phone: {
        countryCode: user?.phone?.countryCode,
        areaCode: user?.phone?.areaCode,
        cli: user?.phone?.cli,
        phoneType: user?.phone?.phoneType
      },
      language: {
        id: user?.language?.id
      }
    }

    return JSON.stringify(currentValues) === JSON.stringify(stateValues);
  }

  return (
    <>
      <div className={cs(`${internalClassName}`, `${internalClassName}-le--${theme}`)}>
        <div className={cs(`${internalClassName}-header`, `${internalClassName}-header-le--${theme}`)}>
          <h4 className={`${internalClassName}-subtitle-le--${theme}`}>
            {translation.userDataHeading()}
          </h4>
          {!isEditMode && <CpxButton className={'iconOnly'} onClick={() => setIsEditMode(true)}><CpxIcon icon={ICONS.EDIT}/></CpxButton>}
        </div>
        <CpxFormikForm handleSubmit={formik.handleSubmit}
                       initialValues={formik.initialValues}
                       id={'myProfile-userData-form'}
        >
          <CpxInputWithLabel
            id={'fullName'}
            name={'fullName'}
            value={formik.values.fullName}
            error={formik.errors?.fullName && formik?.touched?.fullName && formik?.errors?.fullName}
            onChange={formik.handleChange}
            disabled
          >
            {translation.fullName()}
          </CpxInputWithLabel>
          <CpxInputWithLabel
            id={'userName'}
            name={'userName'}
            value={formik.values.userName}
            error={formik.errors?.userName && formik?.touched?.userName && formik?.errors?.userName}
            onChange={formik.handleChange}
            disabled={!isEditMode}
            helpBoxText={isEditMode ? <span dangerouslySetInnerHTML={{__html: translation.userNameInfoText()}}></span> : ""}
          >
            {translation.userName()}
          </CpxInputWithLabel>
          <CpxInputWithLabel
            type={'email'}
            id={'email'}
            name={'email'}
            value={formik.values.email}
            error={formik.errors?.email && formik?.touched?.email && formik?.errors?.email}
            onChange={formik.handleChange}
            disabled={!isEditMode}
          >
            {translation.email()}
          </CpxInputWithLabel>
          <CpxPhoneInputWithLabel
            fields={[
              { name: 'phone.countryCode', value: formik.values?.phone?.countryCode, id: 'countryCode', placeholder: translations.countryCode() },
              { name: 'phone.areaCode', value: formik.values?.phone?.areaCode, id: 'areaCode', placeholder: translations.areaCode() },
              { name: 'phone.cli', value: formik.values?.phone?.cli, id: 'cli', placeholder: translations.cli() }
            ]}
            onChange={formik.handleChange}
            errors={formik.errors}
            touched={formik.touched}
            label={translation.phoneNumber()}
            disabled={!isEditMode}
          >
          </CpxPhoneInputWithLabel>
          <CpxDropdownWithLabel
            id={'language.id'}
            name={'language.id'}
            options={contactLanguageOptions}
            disabled={!isEditMode}
            value={formik.values.language.id}
            onChange={formik.handleChange}
            error={formik.errors?.language?.id && formik?.touched?.language?.id && formik?.errors?.language?.id}
          >
            {translation.language()}
          </CpxDropdownWithLabel>
        </CpxFormikForm>
        {error?.errorData && error?.errorData?.length > 0 && <Alert errors={error?.errorData}/>}
        {isEditMode && <div className={cs(`${internalClassName}-actions`)}>
          <CpxButton buttonType={'secondary'} onClick={() => handleCancel()}>{translation.contactDataCancelButton()}</CpxButton>
          <CpxButton form={'myProfile-userData-form'} disabled={!isEditMode || changesMade()}>{translation.contactDataSaveButton()}</CpxButton>
        </div>}
      </div>
    </>
  );
};
