import commonStyles from "../../../sass/base/common.module.scss";
import { TRANSLATIONS } from "../../../constants/transitions/uiTranslations";
import { useTranslations } from "../../../utils/helper/utils";
import { NfoCustomerData } from "../../../components/customer/myContract/customerData/customerData.component";
import { NfoDownloads } from "../../../components/customer/myContract/downloads/downloads.component";
import { NfoOverview } from "../../../components/customer/myContract/overview/overview.component";
import { CpxTitle } from '../../../../core/components/title/title.component';
import { CpxSubtitle } from "../../../../core/components/title/subtitle.component";
import "./myContract.scss";
import { apiCallAction } from "../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../constants/action.constants";
import { useDispatch } from "react-redux";
import React, { useEffect } from "react";
import { useSelectContracts, useSelectedMainContract } from "../../../redux/store";

export const NfoMyContractPage = () => {
  const translations = useTranslations(TRANSLATIONS.myContract);
  const internalClassName = "myContract";
  const dispatch = useDispatch();
  const myContracts = useSelectContracts();
  const selectedMainContract = useSelectedMainContract();

  useEffect(() => {
    if(selectedMainContract?.contractId){
      dispatch(apiCallAction(ACTION_CONST.API_GET_CONTRACTS, {mainContractId : selectedMainContract?.contractId}, true))
    }
  }, [dispatch, selectedMainContract])

  useEffect(() => {
    if(selectedMainContract?.contractId){
      dispatch(apiCallAction(ACTION_CONST.API_GET_LICENSES, { contractId: selectedMainContract.contractId, selectedPartnerCustomerId: undefined}, true));
    }
  }, [dispatch, selectedMainContract]);


  return (
    <>
      <CpxTitle
        title={translations.title()}
        internalClassName={internalClassName}
      />
      <CpxSubtitle
        subtitle={translations.subheading()}
        internalClassName={internalClassName}
      />

      <div className={commonStyles.columnContainer}>
        <div className={commonStyles.column}>
          <NfoOverview contracts={myContracts}/>
          <NfoCustomerData/>
          <NfoDownloads/>
        </div>
        <div className={commonStyles.column}>{/* <NfoOrderStatus /> */}</div>
      </div>
    </>
  );
};
