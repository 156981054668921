import { useTheme } from '../../../../../../core/utility/themeContext';
import './partnerContactHistory.scss';
import cs from 'classnames';
import {
  getCommunicationIcon,
  getCommunicationStatusIcon,
  useTranslations,
} from '../../../../../utils/helper/utils';
import { TRANSLATIONS } from '../../../../../constants/transitions/uiTranslations';
import { NfoDetailBox } from '../partnerCustomerContractOverview/components/detailBox.component';
import { useSelectLang, useSelectPartnerCustomerCommunications } from '../../../../../redux/store';
import { useEffect } from 'react';
import { apiCallAction } from '../../../../../redux/actions/apiCall.action';
import { ACTION_CONST } from '../../../../../constants/action.constants';
import { useDispatch } from 'react-redux';
import { CommunicationHistory } from 'compax-api';
import { formatDate } from "../../../../../../core/utils";

type Props = {
  customerId: any;
};

export const NfoPartnerContactHistory = ({ customerId }: Props) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const translations = useTranslations(TRANSLATIONS.partnerNav);
  // const translation = useTranslations(TRANSLATIONS.common);
  const partnerContactHistory = useSelectPartnerCustomerCommunications() as CommunicationHistory[];
  const language = useSelectLang().selected;


  useEffect(() => {
    dispatch(
      apiCallAction(
        ACTION_CONST.API_GET_PARTNER_CUSTOMERS_COMMUNICATIONS,
        { customerId },
        true
      )
    );
  }, []);

  return (
    <div className={cs(`contactHistory`, `contactHistory-le--${theme}`)}>
      <h4 className={cs('title', `title-le--${theme}`)}>
        {translations.contactHistory()}
      </h4>
      {partnerContactHistory &&
      Array.isArray(partnerContactHistory) &&
      partnerContactHistory.slice(0, 4).map((contactHistory, index) => {
        return (
          <div key={index} className={'componentStyle'}>
            <NfoDetailBox
              leftIcon={getCommunicationIcon(
                contactHistory.communicationChannel,
                theme
              )}
              label={contactHistory.ticket}
              labelClassName={cs('labelClass', `labelClass-le--${theme}`)}
              boxClassName={'boxClass'}
              rightIcon={getCommunicationStatusIcon(
                contactHistory.status,
                theme
              )}
            >
              {contactHistory && contactHistory.dtSent
                ? formatDate(contactHistory.dtSent, language)
                : ''}
            </NfoDetailBox>
          </div>
        );
      })}
    </div>
  );
};
