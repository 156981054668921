import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelectedMainContract, useSelectPackages, useSelectPartnerCustomer } from '../../../redux/store';
import { TRANSLATIONS } from '../../../constants/transitions/uiTranslations';
import { useTranslations } from '../../../utils/helper/utils';
import { NfoPackageListing } from '../../../components/customer/packages/packageListing/packageListing.component';
import { CpxTitle } from '../../../../core/components/title/title.component';
import { CpxSubtitle } from "../../../../core/components/title/subtitle.component";
import './flatrate.scss';
import { apiCallAction } from "../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../constants/action.constants";
import { NfoEmptyState } from "../../../../core/emptyState.component";
import { IPartnerCustomer } from "../../../constants/types/types.constants";


export const NfoFlatratePage = () => {
  const translations = useTranslations(TRANSLATIONS.packages);
  const dispatch = useDispatch();
  const minutePackages = useSelectPackages();
  const internalClassName = 'flatrate';
  const selectedPartnerCustomer: IPartnerCustomer = useSelectPartnerCustomer() as IPartnerCustomer;
  const selectedMainContract = useSelectedMainContract();

  useEffect(() => {
    if(selectedMainContract?.contractId){
      dispatch(apiCallAction(ACTION_CONST.API_GET_PACKAGES, { contractId: selectedMainContract?.contractId, selectedPartnerCustomerId: selectedPartnerCustomer?.id }));
    }
  }, [dispatch, selectedMainContract]);

  return (
    <>
      <CpxTitle
        title={translations.titleDTS()}
        internalClassName={internalClassName}
      />
      <CpxSubtitle
        subtitle={translations.subheading()}
        internalClassName={internalClassName}
      />
      <NfoEmptyState
        data={minutePackages}
        pageTitle={translations.titleDTS()}
        boxStyling={true}
      >
        <div className={'columnContainer'}>
          {!!minutePackages &&
            minutePackages
              .map((minutePackage) => (
                <NfoPackageListing
                  minutePackage={minutePackage}
                />
              ))}
        </div>
      </NfoEmptyState>
    </>
  );
};
