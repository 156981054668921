import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CpxSwitch } from '../../../../../core/components/switch.component';
import { useSelectIsAnonymized } from '../../../../redux/store';
import { Account } from 'compax-api';
import { apiCallAction } from '../../../../redux/actions/apiCall.action';
import { ACTION_CONST } from '../../../../constants/action.constants';

type Props = {
  className?: string;
  account?: Account;
};

export const NfoAnonymousSwitch = ({ className, account }: Props) => {
  const dispatch = useDispatch();

  const selectAccountAnonymized = useSelectIsAnonymized();

  const handleChange = (anonymized: boolean) => {
    if (account && account.id) {
      dispatch(
        apiCallAction(ACTION_CONST.API_POST_ENV_TYPE, {
          accountId: account.id,
          type: anonymized,
        })
      );
    }
  };

  useEffect(() => {
    if (account && account.id) {
      dispatch(
        apiCallAction(ACTION_CONST.API_GET_ENV_TYPE, { accountId: account.id })
      );
    }
  }, [dispatch, account]);

  return (
    <CpxSwitch
      className={className}
      onChange={handleChange}
      checked={selectAccountAnonymized}
    />
  );
};
