import cs from 'classnames';
import './pagination.scss';
import { useTheme } from '../utility/themeContext';

type Props = {
  pages: number;
  currentPage: number;
  onChange: React.Dispatch<React.SetStateAction<number>>;
};

const limitedIncrement = (
  limitFn: (x: number, y: number) => number,
  incFn: (x: number) => number,
  limit: number
) => (current: number) => limitFn(limit, incFn(current));

const add = (x: number) => (y: number) => x + y;

export const CpxPagination = ({ pages, currentPage, onChange }: Props) => {
  const pageUp = limitedIncrement(Math.min, add(1), pages);
  const pageDown = limitedIncrement(Math.max, add(-1), 1);
  const theme = useTheme();

  return (
    <>
      <div className={'pagination'}>
        {currentPage !== 1 && (
          <button
            className={cs('pageDown', 'button', `button-le--${theme}`)}
            onClick={() => onChange(pageDown)}
          >
            {'<'}
          </button>
        )}
        {[...Array(pages)].map(
          (_, key) =>
            (key + 1 === currentPage ||
              key + 2 === currentPage ||
              key + 3 === currentPage ||
              key - 1 === currentPage ||
              key === currentPage) && (
              <button
                disabled={key + 1 === currentPage}
                className={cs(
                  {
                    [`active-le--${theme}`]: key + 1 === currentPage,
                  },
                  'button',
                  `button-le--${theme}`
                )}
                key={key + 1}
                onClick={() => onChange(key + 1)}
              >
                {key + 1}
              </button>
            )
        )}
        {currentPage !== pages && (
          <button
            className={cs('pageUp', 'button', `button-le--${theme}`)}
            onClick={() => onChange(pageUp)}
          >
            {'>'}
          </button>
        )}
      </div>
    </>
  );
};
