import "./downloadSection.scss";
import cs from "classnames";
import { ReactComponent as Pdf } from "../../../../assets/icons/pdf.svg";
import { useTranslations } from "../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";
import { directDownload } from "../../../../../core/components/utils";
import { preventDefault } from "../../../../../core/utils";
import { useTheme } from "../../../../../core/utility/themeContext";
import { CpxButton } from "../../../../../core/components/button.component";
import React from "react";

type Props = {
  heading: string;
  downloadTitle: string;
  downloadUrl: string;
  moreLinkTo: string;
  getFile: () => Promise<string>;
  filename: string;
};

export const NfoDownloadSection = ({
                                     heading,
                                     downloadTitle,
                                     downloadUrl,
                                     moreLinkTo,
                                     getFile,
                                     filename,
                                   }: Props) => {
  const translations = useTranslations(TRANSLATIONS.myContract);
  const theme = useTheme();
  const internalClassName = "downloadSection";

  return (
    <section className={cs(internalClassName, `${internalClassName}-le--${theme}`)}>
      <h4 className={`${internalClassName}-header-le--${theme}`}>{heading}</h4>
      <a
        href={downloadUrl}
        className={cs("downloadLink", `${internalClassName}-le--${theme}`)}
        onClick={preventDefault(() =>
          directDownload(getFile, filename, "application/pdf")
        )}
      >
        <Pdf className={`icons-le--${theme}`}/>
        {downloadTitle}
      </a>
      <CpxButton
        buttonType={'moreLink'}
        to={moreLinkTo}
      >
        {translations.moreLink()}
      </CpxButton>
    </section>
  );
};
