import React from "react";
import { useDispatch } from "react-redux";
import { saveCurrentStep, saveStep1Data } from "../../../../redux/actions/step.action";
import { useCurrentStep, useSelectError, useStep1Data, } from "../../../../redux/store";
import { trimStringProperties, useTranslations } from "../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";
import { NfoCustomerWrapperComponent } from "../wrapper/newCustomerWrapper.component";
import "./step.scss";
import { ContactPersonForm } from "./forms/ContactPersonForm";
import { useFormik } from "formik";
import { areaCodeCheck, areaCodeRequiredCheck, cliCheck, cliRequiredCheck, countryCodeCheck, countryCodeRequiredCheck, createValidatorSchema, emailCheckReq, MaxLengthCheck, requiredCheck } from "../../../../utils/validation";
import { CpxFormikForm } from "../../../../../core/components/formikForm.component";
import { Alert } from "../../../common/Alert/Alert.component";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { clearError } from "../../../../redux/actions/error.action";
import { INPUT_MAX_LENGTHS } from "../../../../constants/configs/config.constants";
import { ACTION_CONST } from "../../../../constants/action.constants";
import { apiCallAction } from "../../../../redux/actions/apiCall.action";
import { NfoGenerateFakeDataComponent } from "../generateFakeData/generateFakeData.component";

type AppDispatch = ThunkDispatch<any, any, AnyAction>;


export const NfoContactPersonsStep = () => {
  const translation = useTranslations(TRANSLATIONS.stepper.newCustomer);
  const dispatch: AppDispatch = useDispatch();
  const currentStep = useCurrentStep();
  const dataOfStep1 = useStep1Data();
  const ErrorsOfBE = useSelectError();

  const technicalContactPersonTemp = dataOfStep1?.contactPersons?.find((cp:any) => +cp.role.id === 113);
  const commercialContactPersonTemp = dataOfStep1?.contactPersons?.find((cp:any) => +cp.role.id === 134);



  const goForward = () => {
    dispatch(saveCurrentStep(currentStep + 1));
  }

  const requiredMsg = translation.fieldRequired();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {

      // contact person
      contactPersons: {
        technicalContactPerson: {
          firstName: (dataOfStep1?.contactPersons && technicalContactPersonTemp?.firstName) || '',
          lastName: (dataOfStep1?.contactPersons && technicalContactPersonTemp?.lastName) || '',
          email: (dataOfStep1?.contactPersons && technicalContactPersonTemp?.email) || '',
          mobile: {
            countryCode: (dataOfStep1?.contactPersons && technicalContactPersonTemp?.mobile?.countryCode) || '',
            areaCode: (dataOfStep1?.contactPersons && technicalContactPersonTemp?.mobile?.areaCode) || '',
            cli: (dataOfStep1?.contactPersons && technicalContactPersonTemp?.mobile?.cli) || '',
            phoneType: {
              id: 2
            },
          },
          phone: {
            countryCode: (dataOfStep1?.contactPersons && technicalContactPersonTemp?.phone?.countryCode) || '',
            areaCode: (dataOfStep1?.contactPersons && technicalContactPersonTemp?.phone?.areaCode) || '',
            cli: (dataOfStep1?.contactPersons && technicalContactPersonTemp?.phone?.cli) || '',
            phoneType: {
              id: 1
            },
          },
          role: {
            id: '113'
          }
        },
        commercialContactPerson: {
          firstName: (dataOfStep1?.contactPersons && commercialContactPersonTemp?.firstName) || '',
          lastName: (dataOfStep1?.contactPersons && commercialContactPersonTemp?.lastName) || '',
          email: (dataOfStep1?.contactPersons && commercialContactPersonTemp?.email) || '',
          login: (dataOfStep1?.contactPersons && commercialContactPersonTemp?.email) || '',
          mobile: {
            countryCode: (dataOfStep1?.contactPersons && commercialContactPersonTemp?.mobile?.countryCode) || '',
            areaCode: (dataOfStep1?.contactPersons && commercialContactPersonTemp?.mobile?.areaCode) || '',
            cli: (dataOfStep1?.contactPersons && commercialContactPersonTemp?.mobile?.cli) || '',
            phoneType: {
              id: 2
            },
          },
          phone: {
            countryCode: (dataOfStep1?.contactPersons && commercialContactPersonTemp?.phone?.countryCode) || '',
            areaCode: (dataOfStep1?.contactPersons && commercialContactPersonTemp?.phone?.areaCode) || '',
            cli: (dataOfStep1?.contactPersons && commercialContactPersonTemp?.phone?.cli) || '',
            phoneType: {
              id: 1
            },
          },
          role: {
            id: '134'
          }
        }
      },
    },
    validationSchema: createValidatorSchema({
      // contact person
      contactPersons: createValidatorSchema({
        technicalContactPerson: createValidatorSchema({
          firstName: MaxLengthCheck(INPUT_MAX_LENGTHS.contactPersons.firstName, requiredCheck(requiredMsg)),
          lastName: MaxLengthCheck(INPUT_MAX_LENGTHS.contactPersons.lastName, requiredCheck(requiredMsg)),
          email: MaxLengthCheck(INPUT_MAX_LENGTHS.contact.email, emailCheckReq(requiredMsg, translation.emailSyntax())),
          mobile: createValidatorSchema({
            countryCode: countryCodeRequiredCheck(translation.countryCodeRequired(), translation.countryCodeSyntax()),
            areaCode: areaCodeRequiredCheck(translation.areaCodeRequired(), translation.areaCodeSyntax()),
            cli: cliRequiredCheck(translation.cliRequired(), translation.cliSyntax()),
          }),
          phone: createValidatorSchema({
            countryCode: countryCodeCheck(translation.countryCodeSyntax()),
            areaCode: areaCodeCheck(translation.areaCodeSyntax()),
            cli: cliCheck(translation.cliSyntax()),
          })
        }),
        commercialContactPerson: createValidatorSchema({
          //login: MaxLengthCheck(INPUT_MAX_LENGTHS.contact.email, emailCheckReq(requiredMsg, translation.emailSyntax())),
          firstName: MaxLengthCheck(INPUT_MAX_LENGTHS.contactPersons.firstName, requiredCheck(requiredMsg)),
          lastName: MaxLengthCheck(INPUT_MAX_LENGTHS.contactPersons.lastName, requiredCheck(requiredMsg)),
          email: MaxLengthCheck(INPUT_MAX_LENGTHS.contact.email, emailCheckReq(requiredMsg, translation.emailSyntax())),
          mobile: createValidatorSchema({
            countryCode: countryCodeCheck(translation.countryCodeSyntax()),
            areaCode: areaCodeCheck(translation.areaCodeSyntax()),
            cli: cliCheck(translation.cliSyntax()),
          }),
          phone: createValidatorSchema({
            countryCode: countryCodeCheck(translation.countryCodeSyntax()),
            areaCode: areaCodeCheck(translation.areaCodeSyntax()),
            cli: cliCheck(translation.cliSyntax()),
          })
        })
      }),
    }),

    onSubmit: async (values: any) => {

      const commercialContactPerson = {...values.contactPersons.commercialContactPerson, login: values.contactPersons.commercialContactPerson.email}
      const contactPersonsArray = [commercialContactPerson, values.contactPersons.technicalContactPerson];

      contactPersonsArray.map((cp: any) => {
        if (cp.phone?.countryCode === '' && cp.phone.areaCode === '' && cp.phone?.cli === '') {
          cp.phone = undefined;
        }
        if (cp.mobile?.countryCode === '' && cp.mobile.areaCode === '' && cp.mobile?.cli === '') {
          cp.mobile = undefined;
        }
      })

      const finalValues = {
        ...dataOfStep1,
        contactPersons: contactPersonsArray,
        stepper: "CONTACT_PERSONS"
      }

      const tempValues = {
        customer: {...dataOfStep1.customer},
        mainAddress: {...dataOfStep1.mainAddress},
        contactPersons: contactPersonsArray,
        stepper: "CONTACT_PERSONS"
      }

      trimStringProperties(finalValues);
      trimStringProperties(tempValues);

     dispatch(apiCallAction(ACTION_CONST.API_VALIDATE_CUSTOMER_DATA, tempValues))
       .then(() => {
          dispatch(clearError())
          dispatch(saveStep1Data(finalValues));
          goForward();
       })
       .catch(() => window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' }))
    }
  });


  return (
    <>
      <NfoCustomerWrapperComponent
        title={translation.contactPersons()}
        subtitle={translation.customerDataSubTitle()}
      >
        <>
          <CpxFormikForm handleSubmit={formik.handleSubmit}
                         initialValues={formik.initialValues}
                         id={'new-customer-form'}
          >
            <NfoGenerateFakeDataComponent/>
            <ContactPersonForm values={formik.values} handleChange={formik.handleChange}
                               touched={formik.touched} errors={formik.errors}/>

          </CpxFormikForm>
          {ErrorsOfBE.errorData && ErrorsOfBE.errorData.length > 0 &&
            ErrorsOfBE?.requestData?.currentData?.stepper === "CONTACT_PERSONS" && <Alert errors={ErrorsOfBE.errorData}/>}
        </>
      </NfoCustomerWrapperComponent>
    </>
  );
};
