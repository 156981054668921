import { ReactNode, useEffect, useState } from 'react';
import './collapsible.scss';
import cs from 'classnames';
import { useTheme } from '../utility/themeContext';
import { CpxIcon } from "./icon.component";
import { ICONS } from "../../portal/constants/configs/config.constants";
import { useSelectPartnerCollapsibleStatus } from "../../portal/redux/store";
import { useDispatch } from "react-redux";
import { setPartnerCollapsibleStatus } from "../../portal/redux/actions/config.action";

type Props = {
  title: ReactNode;
  children: ReactNode;
};

export const CpxCollapsible = ({ title, children }: Props) => {
  const partnerCollapsibleStatus = useSelectPartnerCollapsibleStatus();
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(partnerCollapsibleStatus);
  const internalClassName = 'collapsible';
  const theme = useTheme();

  useEffect(() => {
    dispatch(setPartnerCollapsibleStatus(collapsed))
  }, [collapsed])

  return (
    <aside
      className={cs(
        internalClassName,
        { collapsed: collapsed },
        `${internalClassName}-le--${theme}`
      )}
    >
      <header
        onClick={() => setCollapsed(prev => !prev)}
      >
        <button disabled={!collapsed}>
          <h5>{title}</h5>
        </button>
        <button className={'closeButton'}>
          { collapsed ? <CpxIcon icon={ICONS.ARROW_DOWN}/> : <CpxIcon icon={ICONS.ARROW_UP} /> }
        </button>
      </header>
      {!collapsed && <div className={'collapsibleBody'}>{children}</div>}
    </aside>
  );
};
