import { ACTION_CONST } from "../../constants/action.constants";

export const saveOCNStepData = (data: any) => {
	return {
		type: ACTION_CONST.SAVE_OCN_STEP_DATA,
		data,
	};
};

export const deleteOCNStepData = () => {
	return {
		type: ACTION_CONST.DELETE_OCN_STEP_DATA,
	};
};
