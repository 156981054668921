import {
  NUMBERS_MODAL_PRODUCT_CATEGORIES,
  NUMBERS_PORTING_PRODUCT_CATEGORIES,
  QUANTITY_MODAL_PRODUCT_CATEGORIES,
  TEXT_FIELD_MODAL_PRODUCT_CATEGORIES
} from "../../../../../constants/configs/config.constants";
import React from "react";
import { NumbersForm } from "./numbersForm/numbersForm.component";
import { TextFieldForm } from "./textFieldForm/textFieldForm.component";
import { SelectableBasketOption } from "compax-api";
import { ProductOptionModalType } from "../../../../../constants/types/types.constants";
import { useTranslations } from "../../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../../constants/transitions/uiTranslations";
import {PortingNumbersForms} from "./portingFrom/portingNumbersForm.component";

type Props = {
  option: SelectableBasketOption | undefined;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectOptionOfOption: Function;
}

export const ProductOptionModal = ({ option, setShowModal, selectOptionOfOption }: Props) => {
  const translation = useTranslations(TRANSLATIONS.stepper.newCustomer);

  if (option?.product.productCategory) {
if (QUANTITY_MODAL_PRODUCT_CATEGORIES.includes(option?.product.productCategory.id)) {
      return (
        <TextFieldForm setShowModal={setShowModal} option={option} type={ProductOptionModalType.QUANTITY} selectOptionOfOption={selectOptionOfOption}/>
      );
    } else if (NUMBERS_MODAL_PRODUCT_CATEGORIES.includes(option?.product.productCategory.id)) {
      return (
        <NumbersForm setShowModal={setShowModal} option={option} type={ProductOptionModalType.TELCO_DATA} selectOptionOfOption={selectOptionOfOption}/>
      );
    } else if (NUMBERS_PORTING_PRODUCT_CATEGORIES.includes(option?.product.productCategory.id)) {
      return (
          <PortingNumbersForms setShowModal={setShowModal} option={option} type={ProductOptionModalType.TELCO_DATA} selectOptionOfOption={selectOptionOfOption}/>
      );
    /*} else if (TEXT_FIELD_MODAL_PRODUCT_CATEGORIES.includes(option?.product.productCategory.id)) {
      return (
        <TextFieldForm setShowModal={setShowModal} option={option} type={ProductOptionModalType.CUSTOM_DATA} textFieldLabel={translation.MSTeamsTXTRecordText() + ' *'} selectOptionOfOption={selectOptionOfOption}/>
      );*/
    } else {
      return <></>;
    }
  } else {
    return <></>;
  }

}
