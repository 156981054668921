import { useTranslations } from "../../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../../constants/transitions/uiTranslations";
import { useTheme } from "../../../../../../core/utility/themeContext";
import cs from "classnames";
import { CpxButton } from "../../../../../../core/components/button.component";
import { CpxIcon } from "../../../../../../core/components/icon.component";
import { ICONS, ID_CONTRACT_SIP_TRUNK } from "../../../../../constants/configs/config.constants";
import { NfoConfigurationBox } from "../configurationBox/configurationBox.component";
import React, { useEffect, useState } from "react";
import { useOEOptionsOfOptions, useOEProductOptions, useOEProductOptionsForSipTrunk, useOrderEntryBasket, useSelectError, useSelectLang, useStep1Data, useStep3Data } from "../../../../../redux/store";
import { Basket, OptionGroup, SelectableBasketOption } from "compax-api";
import { apiCallAction } from "../../../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../../../constants/action.constants";
import { useDispatch } from "react-redux";
import { onValidateProductOption, onValidateProductOptionSipTrunk, saveOENewSipTrunkOption, saveOEOptionsOfOption, selectProductOptionSipTrunk } from "../../../../../redux/actions/step.action";
import { NfoEditBox } from "../editBox/editBox.component";
import { selectCurrentLanguage } from "../../../../../../core/uiLanguage/lang.slice";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { Alert } from "../../../../common/Alert/Alert.component";

type Props = {setError: any;}
type AppDispatch = ThunkDispatch<any, any, AnyAction>;

export async function extracted(dispatch: ThunkDispatch<any, any, AnyAction>, newBasket: Basket, step3Data: any, uuid: string, currentLang: string, isMigration: boolean) {
  const res: any = await dispatch(apiCallAction(ACTION_CONST.API_GET_OE_OPTIONS_OF_OPTION,
    {
      basketId: newBasket?.id,
      uuid: newBasket?.entries[step3Data?.activeIndex]?.options?.find((o: any) => o.uuid === uuid)?.uuid,
      language: currentLang
    },
    true));

  if (isMigration) {
    let optionGroupChannel = res?.optionGroups.find((op: OptionGroup) => op.serviceGroup.id === 2);
    optionGroupChannel.basketOptions =
      optionGroupChannel?.basketOptions?.filter((bo: SelectableBasketOption) => bo.product?.productCategory?.id === 100);
  }

  dispatch(saveOEOptionsOfOption({ [uuid]: res?.optionGroups }));
  return newBasket;
}

export const NfoSipTrunkOption = ({setError}: Props) => {
    const theme = useTheme();
    const dispatch: AppDispatch = useDispatch();
    const basket: Basket = useOrderEntryBasket();
    const oEProductOptions = useOEProductOptions();
    const oEOptionsOfOptions = useOEOptionsOfOptions();
    const oEProductOptionsForSipTrunk = useOEProductOptionsForSipTrunk();
    const [sipTrunksArray, setSipTrunksArray] = useState<Array<OptionGroup> & any>([]);
    const currentLang = selectCurrentLanguage(useSelectLang());
    const stepOneData = useStep1Data();
    const step3Data = useStep3Data();
    const error = useSelectError();
    const translationsStepper = useTranslations(TRANSLATIONS.stepper.newCustomer);
    const isMigration = !!stepOneData?.migrationCustomerId;

    let sipTrunkOption: any = Array.isArray(oEProductOptions) && Object.values(
        oEProductOptions?.reduce((basketOptions: OptionGroup[], item: any) => basketOptions.concat(item.basketOptions?.filter((basketOption: any) => basketOption?.product?.productType?.id === +ID_CONTRACT_SIP_TRUNK)[0]), [])
    );

    sipTrunkOption = Array.isArray(sipTrunkOption) && sipTrunkOption.find((item: any) => item !== undefined) || [];


    useEffect(() => {
        dispatch(apiCallAction(ACTION_CONST.API_GET_OE_PRODUCT_OPTIONS,
            {
                basketId: basket?.id,
                uuid: step3Data?.activeEntryUuid,
                language: currentLang
            }, true))
    }, [currentLang]);


    useEffect(() => {
        if (((Array.isArray(oEProductOptionsForSipTrunk) && oEProductOptionsForSipTrunk.length === 0) ||
            (Array.isArray(oEProductOptionsForSipTrunk) && oEProductOptionsForSipTrunk[0]?.sipTrunkOpt?.length === 0)) &&
          (basket.entries[step3Data?.activeIndex]?.options?.length === 0)) {
            sipTrunkOption && setSipTrunksArray([sipTrunkOption]);
            dispatch(saveOENewSipTrunkOption([{sipTrunkOpt: sipTrunkOption, uuid: null}]))
        } else {
            setSipTrunksArray(Array.isArray(oEProductOptionsForSipTrunk) && oEProductOptionsForSipTrunk?.map((item: any) => {
                item.sipTrunkOpt.uuid = item.uuid;
                return item.sipTrunkOpt
            }));
        }

    }, [sipTrunkOption])

    const toSipTrunkOption = (uuid: string, sipTrunk: any) => {
        return {uuid: uuid, sipTrunkOpt: {...sipTrunk, validated: true}};
    }



    useEffect(() => {
        const options = basket?.entries[step3Data?.activeIndex]?.options
        if (basket && Array.isArray(options) && options.length > 0) {
            options?.map((option) => dispatch(onValidateProductOption(option, basket, stepOneData, step3Data?.activeIndex)))
        }


        // oEProductOptionsForSipTrunk
        if((basket?.entries[step3Data?.activeIndex]?.options?.length || 0) > 0){
            dispatch(apiCallAction(ACTION_CONST.API_GET_OE_PRODUCT_OPTIONS_FOR_SIP_TRUNK,
              {
                  basketId: basket?.id,
                  uuid: step3Data?.activeEntryUuid,
                  language: currentLang
              }, true)).then(newSipTrunk => {
                newSipTrunk = Array.isArray(newSipTrunk.optionGroups) && Object.values(
                  newSipTrunk.optionGroups?.reduce((basketOptions: OptionGroup[], item: any) => basketOptions.concat(item.basketOptions?.filter((basketOption: any) => basketOption?.product?.productType?.id === +ID_CONTRACT_SIP_TRUNK)[0]), [])
                );

                newSipTrunk = Array.isArray(newSipTrunk) && newSipTrunk.find((item: any) => item !== undefined);
                setSipTrunksArray((prev: any) => {
                    return [...prev, newSipTrunk];
                })
              setSipTrunksArray(basket?.entries[step3Data?.activeIndex]?.options?.filter(option => option.product.id === newSipTrunk?.product?.id ).map(option => {return { ...newSipTrunk, uuid: option.uuid }}))
              dispatch(saveOENewSipTrunkOption(basket?.entries[step3Data?.activeIndex]?.options?.filter(option => option.product.id === newSipTrunk?.product?.id ).map(option => toSipTrunkOption(option.uuid || '', newSipTrunk))))
            })


        }

    }, [])
    const addSipTrunk = async () => {
      setError('');
        //  setIndexOfSipTrunk(indexOfSipTrunk + 1);
        let newSipTrunk = await dispatch(apiCallAction(ACTION_CONST.API_GET_OE_PRODUCT_OPTIONS_FOR_SIP_TRUNK,
            {
                basketId: basket?.id,
                uuid: step3Data?.activeEntryUuid,
                language: currentLang
            }, true))
        newSipTrunk = Array.isArray(newSipTrunk.optionGroups) && Object.values(
            newSipTrunk.optionGroups?.reduce((basketOptions: OptionGroup[], item: any) => basketOptions.concat(item.basketOptions?.filter((basketOption: any) => basketOption?.product?.productType?.id === +ID_CONTRACT_SIP_TRUNK)[0]), [])
        );

        newSipTrunk = Array.isArray(newSipTrunk) && newSipTrunk.find((item: any) => item !== undefined);
        setSipTrunksArray((prev: any) => {
            return [...prev, newSipTrunk];
        })
        dispatch(saveOENewSipTrunkOption([...oEProductOptionsForSipTrunk, {sipTrunkOpt: newSipTrunk, uuid: null}]))

    }

    const selectOptionOfOption = async (uuid:string, index: number) => {
        if(index !== 0 || !uuid || !stepOneData?.migrationCustomerId || !stepOneData?.partnerVoNumber) {
            return;
        }

        let migrationCustomerId = stepOneData.migrationCustomerId;
        let partnerVoNumber = stepOneData?.partnerVoNumber;
        let migrationServiceId = stepOneData?.migrationServiceId;

        let newBasket:Basket = await dispatch(apiCallAction(ACTION_CONST.API_POST_OE_MIGRATION_CUSTOMER_ADD_CHANNEL_TO_BASKET, {basketId: basket.id, customerId: migrationCustomerId, entryUuid: uuid, partnerId: partnerVoNumber, serviceId: migrationServiceId}))
      return await extracted(dispatch, newBasket, step3Data, uuid, currentLang, isMigration);
    }


    return (
        <>
            {error?.errorData && error?.errorData?.length > 0 &&
                error?.requestData?.currentData?.stepper === "VALIDATE_BASKET_OPTION" &&
                <Alert errors={error?.errorData}/>}
            <div className={cs(`options-wrapper`, `options-wrapper-le--${theme}`)}>
                <h5 className={cs(`options-title`, `options-title-le--${theme}`)}>SIP Trunk(s)</h5>
                <CpxButton
                    className={'buttonIcons'}
                    type="button"
                    buttonType={'secondary'}
                    withIcon={true}
                    disabled={Array.isArray(oEProductOptionsForSipTrunk) && !!oEProductOptionsForSipTrunk?.find((p: any) => p?.uuid === null)}
                    onClick={() => addSipTrunk()}
                >
                    <CpxIcon icon={ICONS.ADD}/>
                    {translationsStepper.addSipTrunkText()}
                </CpxButton>
            </div>
            {Array.isArray(oEProductOptionsForSipTrunk) && oEProductOptionsForSipTrunk.map((p: any, index: number) => {
                    if (p?.sipTrunkOpt?.validated) {

                        return (<NfoEditBox
                                key={index}
                                index={index}
                                name={`${p?.sipTrunkOpt?.product?.displayValue} - ${index + 1}`}
                                parentUuid={p?.sipTrunkOpt.parentUuid}
                                optionUuid={p?.uuid}
                                basket={basket}
                                isSipTrunk={true}
                                onDelete={() => dispatch(selectProductOptionSipTrunk({...p?.sipTrunkOpt, uuid: p.uuid}, basket, setSipTrunksArray, currentLang, index, step3Data?.activeIndex,true, null, isMigration))}
                            />
                        )
                    }
                    return (
                        <NfoConfigurationBox
                            key={index}
                            index={index}
                            name={`${p?.sipTrunkOpt?.product?.displayValue} - ${index + 1}`}
                            configurable={true}
                            optionsOfOptions={oEOptionsOfOptions}
                            parentOptionUuid={sipTrunksArray[index]?.uuid}
                            parentOptionId={p?.sipTrunkOpt?.product?.id}
                            onClick={(cancel) => {
                                setError('');
                                dispatch(selectProductOptionSipTrunk({ ...p?.sipTrunkOpt, uuid: p.uuid }, basket, setSipTrunksArray, currentLang, index, step3Data?.activeIndex, cancel, (stepOneData?.migrationCustomerId && stepOneData?.partnerVoNumber) ? (uuid:string, index:number) => selectOptionOfOption(uuid, index) : null, isMigration));
                            }}
                            onApplyProductOption={() => dispatch(onValidateProductOptionSipTrunk(basket, stepOneData, sipTrunksArray[index]?.uuid, step3Data?.activeIndex))}
                        />)
                }
            )}

        </>
    )
}