import { useTranslations } from "../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";
import React from "react";
import { NfoCustomerWrapperComponent } from "../wrapper/newCustomerWrapper.component";
import { NfoDocumentsTable } from "../documentsTable/documentsTable.component";
import { Alert } from "../../../common/Alert/Alert.component";
import { useSelectError } from "../../../../redux/store";

export const NfoCustomerContractDocumentsStep = () => {
  const translationsStepper = useTranslations(TRANSLATIONS.stepper.newCustomer);
  const ErrorsOfBE = useSelectError();



  return (
    <>
      <NfoCustomerWrapperComponent
        title={translationsStepper.documents()}
        subtitle={translationsStepper.subtitleOfDocumentsStep()}
      >
        <>
          {ErrorsOfBE.errorData && ErrorsOfBE.errorData.length > 0 && <Alert errors={ErrorsOfBE.errorData}/>}
          <NfoDocumentsTable/>
        </>
      </NfoCustomerWrapperComponent>
    </>
  );
};
