import { CpxInputWithLabel } from "../../../../../../../core/components/inputWithLabel.component";
import React from "react";
import { CpxIcon } from "../../../../../../../core/components/icon.component";
import { ICONS } from "../../../../../../constants/configs/config.constants";
import { CpxButton } from "../../../../../../../core/components/button.component";
import { useTheme } from "../../../../../../../core/utility/themeContext";
import { CpxHelpBox } from "../../../../../../../core/components/helpBox.component";
import { PopupDirections } from "../../../../../../constants/types/types.constants";
import { useTranslations } from "../../../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../../../constants/transitions/uiTranslations";

type CallNumberConfiguration = {
  callNumber: number;
}

type Props = {
  formik: any;
  callNumberConfigurations: any;
  setCallNumberConfigurations: any;
}

export const PortingCallNumberConfigurationComponent = ({ formik, callNumberConfigurations, setCallNumberConfigurations }: Props) => {
  const translation = useTranslations(TRANSLATIONS.stepper.newCustomer);
  const theme = useTheme();
  const internalClassName = "portingCallNumberConfiguration";

  const MAX_CONFIGURATIONS = 10;

  const addConfiguration = () => {
    formik.setFieldValue(`multipleSubscriberNumbers.callNumberConfigurations[${callNumberConfigurations.length}]`, { callNumber: '' });
    setCallNumberConfigurations((prevState: any) => [...prevState, { callNumber: '' }]);
    formik.setTouched({});
  }

  const removeConfiguration = (atIndex: number) => {
    const filtered = formik.values.multipleSubscriberNumbers.callNumberConfigurations.filter((configuration: any, configurationIndex: number) => configurationIndex !== atIndex)
    formik.setFieldValue(`multipleSubscriberNumbers.callNumberConfigurations`, filtered);
    setCallNumberConfigurations((prevState: any) => prevState.filter((configuration: CallNumberConfiguration, i: number) => i !== atIndex));
  }

  return (
    <div className={`${internalClassName}`}>
      { callNumberConfigurations.map((configuration: any, index: number) => {
        return (<div className={`${internalClassName}-container`}>
          <CpxInputWithLabel
            className={`${internalClassName}-input`}
            id={`multipleSubscriberNumbers.callNumberConfigurations[${index}].callNumber`}
            type="text"
            onChange={formik.handleChange}
            value={formik.values?.multipleSubscriberNumbers.callNumberConfigurations[index].callNumber}
            error={formik?.touched?.multipleSubscriberNumbers?.callNumberConfigurations[index].callNumber && formik?.errors?.multipleSubscriberNumbers?.callNumberConfigurations && formik?.errors?.multipleSubscriberNumbers?.callNumberConfigurations[index]?.callNumber}
          >
            {index === 0 && translation.callNumber() + ' *'}
          </CpxInputWithLabel>
          <div className={`${internalClassName}-icons ${internalClassName}-icons-le--${theme}`}>
            {(callNumberConfigurations.length === index + 1 && index + 1 !== MAX_CONFIGURATIONS) && <CpxButton className={"iconOnly"} type={'button'} onClick={addConfiguration}><CpxIcon icon={ICONS.ADD}/></CpxButton>}
            {index > 0 && <CpxButton className={"iconOnly"} type={'button'} onClick={() => removeConfiguration(index)}><CpxIcon icon={ICONS.MINUS}/></CpxButton>}
          </div>
          {index + 1 === MAX_CONFIGURATIONS && (
            <CpxHelpBox className={"helpBox"} popupDirection={PopupDirections.RIGHT}>
              {translation.maxCallNumberText()}
            </CpxHelpBox>
          )}
        </div>)
      }) }
    </div>
  )
}