import React from "react";
import { useTranslations } from "../../../../../utils/helper/utils";
import { CpxInputWithLabel } from "../../../../../../core/components/inputWithLabel.component";
import { ID_SALUTATIONS } from "../../../../../constants/configs/config.constants";
import { TRANSLATIONS } from "../../../../../constants/transitions/uiTranslations";


type Props = {
    selectedLocation: any;
    getCountryById: (id: number) => {};
}
export const OrderPortingDisplayAddressForm = ({ selectedLocation, getCountryById }: Props) => {
    const translation = useTranslations(TRANSLATIONS.stepper.newCustomer);
    const inputNames = {
        salutation: "connectionAddress.salutation.id",
        companyName1: "connectionAddress.companyName1",
        companyName2: "connectionAddress.companyName2",
        street: "connectionAddress.street",
        houseNumber: "connectionAddress.houseNumber",
        zip: "connectionAddress.zip",
        city: "connectionAddress.city",
        country: "connectionAddress.country.id"
    }

    return (
        <>
            <div className={'addressInputGroup-container'}>

                <div className={'addressInputGroup'}>
                    <div className={'addressInputGroup'}>
                        <CpxInputWithLabel
                            id={inputNames.companyName1}
                            type="text"
                            value={selectedLocation?.salutation && (parseInt(String(selectedLocation.salutation.id)) === ID_SALUTATIONS.COMPANY) ?
                              selectedLocation?.companyName : selectedLocation?.firstName}
                            disabled={true}
                        >
                            {selectedLocation?.salutation && (parseInt(String(selectedLocation.salutation.id)) === ID_SALUTATIONS.COMPANY) ?
                                translation.companyName1() : translation.firstName()}
                        </CpxInputWithLabel>
                        <CpxInputWithLabel
                          id={inputNames.companyName2}
                          type="text"
                          value={selectedLocation?.salutation && (parseInt(String(selectedLocation.salutation.id)) === ID_SALUTATIONS.COMPANY) ?
                            selectedLocation?.companyName2 : selectedLocation?.lastName}
                          disabled={true}
                        >
                            {selectedLocation.salutation && (parseInt(String(selectedLocation.salutation.id)) === ID_SALUTATIONS.COMPANY) ?
                              translation.companyName2() : translation.lastName()}
                        </CpxInputWithLabel>


                    </div>
                </div>

                <div className={'addressInputGroup'}>
                    <div className={'addressInputGroup'}>
                        <CpxInputWithLabel
                            id={inputNames.street}
                            type="text"
                            value={selectedLocation?.street}
                            disabled={true}
                        >
                            {translation.street()}
                        </CpxInputWithLabel>

                        <CpxInputWithLabel
                            id={inputNames.houseNumber}
                            type="text"
                            value={selectedLocation?.houseNumber}
                            disabled={true}
                        >
                            {translation.houseNumber()}
                        </CpxInputWithLabel>

                    </div>
                </div>

                <div className={'addressInputGroup'}>
                    <div className={'addressInputGroup'}>

                        <div className={'addressInputGroup'}>
                            <div className={'addressInputGroup'}>
                                <CpxInputWithLabel
                                    id={inputNames.zip}
                                    type="text"
                                    value={selectedLocation?.zip}
                                    disabled={true}
                                >
                                    {translation.postcode()}
                                </CpxInputWithLabel>

                                <CpxInputWithLabel
                                    id={inputNames.city}
                                    type="text"
                                    value={selectedLocation?.city}
                                    disabled={true}
                                >
                                    {translation.city()}
                                </CpxInputWithLabel>

                            </div>
                        </div>
                        <CpxInputWithLabel
                            id={inputNames.country}
                            type="text"
                            value={getCountryById(+selectedLocation?.country.id)?.toString()}
                            disabled={true}
                        >
                            {translation.country()}
                        </CpxInputWithLabel>
                    </div>
                </div>
            </div>

        </>
    );

};