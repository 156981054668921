import cs from "classnames";
import { PropsWithoutRef } from "react";
import { CpxAddress } from "../../../../../core/components/address.component";
import { ReactComponent as Phone } from "../../../../assets/icons/phone_white.svg";
import { ReactComponent as Place } from "../../../../assets/icons/place_white.svg";
import { useSelectCustomer } from "../../../../redux/store";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";
import { useTranslations } from "../../../../utils/helper/utils";
import "./myDataOverview.scss";
import { useTheme } from "../../../../../core/utility/themeContext";
import { PortalCustomerData } from "compax-api";

type Props = PropsWithoutRef<JSX.IntrinsicElements["select"]>;

export const NfoMyDataOverview = ({ className, ...props }: Props) => {
  const translations = useTranslations(TRANSLATIONS.myData);
  const customer = useSelectCustomer() as PortalCustomerData;
  const theme = useTheme();
  const internalClassName = "myDataOverview";

  return (
    <article
      className={cs(
        internalClassName,
        `${internalClassName}-boxStyling-le--${theme}`,
        className
      )}
      {...props}
    >
      <h4 className={cs(`${internalClassName}-title-le--${theme}`)}>{customer?.customerName}</h4>
      <p className={cs("subheading", `subheading-content-le--${theme}`)}>
        {translations.customerId()}: {customer?.customerNumber}
      </p>
      <div className={"columnContainer"}>
        <div className={"column"}>
          <h5 className={cs(`column-title column-title-le--${theme}`)}>
            <Phone/>
            {translations.contact()}
          </h5>
          <p className={`content-le--${theme} email`}>
            {customer?.mainContact?.email?.email}
          </p>
          <p className={`content-le--${theme}`}>
            {customer?.mainContact?.phone?.phone}
          </p>
        </div>
        <div className={"column"}>
          <h5 className={cs(`column-title column-title-le--${theme}`)}>
            <Place/>
            {translations.address()}
          </h5>
          <CpxAddress
            address={customer?.mainAddress}
            className={`content-le--${theme}`}
          />
        </div>
      </div>
    </article>
  );
};
