import cs from "classnames";
import { NfoDetailBox } from "../../customer/overview/partnerCustomerContractOverview/components/detailBox.component";
import { ReactComponent as UserDocuments } from "../../../../assets/icons/user_documents.svg";
import { ReactComponent as Phone } from "../../../../assets/icons/phone_white.svg";
import { ReactComponent as PhoneCircle } from "../../../../assets/icons/phone_circle.svg";
import { ReactComponent as Card } from "../../../../assets/icons/card.svg";
import { ReactComponent as Building } from "../../../../assets/icons/building.svg";
import { ReactComponent as Tech } from "../../../../assets/icons/tech.svg";
import React, { useEffect } from "react";
import { useTranslations } from "../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";
import { useTheme } from "../../../../../core/utility/themeContext";
import { useSelectCountries, useSelectLang, useSelectLanguage, useSelectLegalForms, useSelectPaymentMode, useSelectSalutations, useStep1Data, useStep5Data } from "../../../../redux/store";
import { selectCurrentLanguage } from "../../../../../core/uiLanguage/lang.slice";
import { apiCallAction } from "../../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../../constants/action.constants";
import { useDispatch } from "react-redux";
import { ReactComponent as Contract } from '../../../../../portal/assets/icons/contract.svg';
import { ReactComponent as Check } from '../../../../../portal/assets/icons/check.svg';

type Props = {
  className: any;
};

export const NfoCustomerDetails = ({ className }: Props) => {
  const translationsStepper = useTranslations(TRANSLATIONS.stepper.newCustomer);
  const theme = useTheme();
  const dispatch = useDispatch();
  const dataOfStep1 = useStep1Data();
  const dataOfStep5 = useStep5Data();
  const salutations = useSelectSalutations();
  const legalForms = useSelectLegalForms();
  const countries = useSelectCountries();
  const languages = useSelectLanguage();
  const paymentModes = useSelectPaymentMode();
  const currentLang = selectCurrentLanguage(useSelectLang());

  const salutation = salutations.filter((salutation: any) => salutation.id === +dataOfStep1?.customer?.salutation?.id)[0]?.description;
  const legalForm = legalForms.filter((legalForm: any) => legalForm.id === +dataOfStep1?.customer?.legalForm?.id)[0]?.description;
  const country = countries.filter((country: any) => country.id === +dataOfStep1?.mainAddress?.country?.id)[0]?.description;
  const contactLanguage = languages.filter((language: any) => language.id === +dataOfStep1?.customer?.language?.id)[0]?.description;
  const paymentMode = paymentModes.filter((paymentMode: any) => paymentMode.id === +dataOfStep1?.account?.paymentMode?.id)[0]?.description;
  const billingSalutation = salutations.filter((salutation: any) => salutation.id === +dataOfStep1?.account?.billAddress?.salutation?.id)[0]?.description;
  const billingCountry = countries.filter((country: any) => country.id === +dataOfStep1?.account?.billAddress?.country?.id)[0]?.description;

  useEffect(() => {
    dispatch(apiCallAction(ACTION_CONST.API_GET_LEGAL_FORMS));
    dispatch(apiCallAction(ACTION_CONST.API_GET_SALUTATIONS));
    dispatch(apiCallAction(ACTION_CONST.API_GET_LANGUAGES));
    dispatch(apiCallAction(ACTION_CONST.API_GET_BILL_MEDIA));
    dispatch(apiCallAction(ACTION_CONST.API_GET_PAYMENT_MODE));
    dispatch(apiCallAction(ACTION_CONST.API_GET_COUNTRY));
    dispatch(apiCallAction(ACTION_CONST.API_GET_CARRIER));

  }, [currentLang]);

  return (
    <>
      <h4 className={`step-subtitle-le--${theme}`}>
        {translationsStepper.customerDetails()}
      </h4>
      <div className={cs(`${className}-row`, `${className}-row-le--${theme}`)}>
        <NfoDetailBox
          leftIcon={<UserDocuments/>}
          label={translationsStepper.customerDetails()}
        >
          {dataOfStep1?.migrationCustomerId && <p>{dataOfStep1?.migrationCustomerId}</p>}
          <p>
            {salutation}
          </p>
          <p>
            {dataOfStep1.customer.companyName1} {legalForm}
          </p>
          <p>
            {dataOfStep1.customer.companyName2}
          </p>
          <p>
            {dataOfStep1.customer.taxIdentificationNumber}
          </p>
          <p>
            {dataOfStep1.mainAddress.street} {dataOfStep1.mainAddress.houseNumber}
          </p>
          <p>
            {dataOfStep1.mainAddress.additionalAddress}
          </p>
          <p>
            {dataOfStep1.mainAddress.zip} {dataOfStep1.mainAddress.city}
          </p>
          <p>
            {country}
          </p>
        </NfoDetailBox>
        <NfoDetailBox
          leftIcon={<Phone/>}
          label={translationsStepper.contactDetails()}
        >
          <p className={cs(`email`, `email-le--${theme}`)}>
            {dataOfStep1.customer.email}
          </p>
          <p>
            {dataOfStep1.customer.phone.countryCode} {dataOfStep1.customer.phone.areaCode} {dataOfStep1.customer.phone.cli}
          </p>
          <p>
            {translationsStepper.contactLanguage()}: {contactLanguage}
          </p>
        </NfoDetailBox>
      </div>
      <div className={cs(`${className}-row`, `${className}-row-le--${theme}`)}>
        <NfoDetailBox
          leftIcon={<Tech/>}
          label={translationsStepper.technicalContactPerson()}
        >
          <p>
            {dataOfStep1.contactPersons[1].firstName} {dataOfStep1.contactPersons[1].lastName}
          </p>
          <p className={cs(`email`, `email-le--${theme}`)}>
            {dataOfStep1.contactPersons[1].email}
          </p>
          {dataOfStep1.contactPersons[1].mobile && <p>
            {dataOfStep1.contactPersons[1].mobile.countryCode} {dataOfStep1.contactPersons[1].mobile.areaCode} {dataOfStep1.contactPersons[1].mobile.cli}
          </p> }
          {dataOfStep1.contactPersons[1].phone && <p>
            {dataOfStep1.contactPersons[1].phone.countryCode} {dataOfStep1.contactPersons[1].phone.areaCode} {dataOfStep1.contactPersons[1].phone.cli}
          </p> }
        </NfoDetailBox>
        <NfoDetailBox
          leftIcon={<PhoneCircle/>}
          label={translationsStepper.businessContactPerson()}
        >
          <p>
            Login: <span className={cs(`email`, `email-le--${theme}`)}>{dataOfStep1.contactPersons[0].login}</span>
          </p>
          <p>
            {dataOfStep1.contactPersons[0].firstName} {dataOfStep1.contactPersons[0].lastName}
          </p>
          {dataOfStep1.contactPersons[0].phone && <p>
            {dataOfStep1.contactPersons[0].phone.countryCode} {dataOfStep1.contactPersons[0].phone.areaCode} {dataOfStep1.contactPersons[0].phone.cli}
          </p>}
          {dataOfStep1.contactPersons[0].mobile && <p>
            {dataOfStep1.contactPersons[0].mobile.countryCode} {dataOfStep1.contactPersons[0].mobile.areaCode} {dataOfStep1.contactPersons[0].mobile.cli}
          </p>}
        </NfoDetailBox>
      </div>
      <div className={cs(`${className}-row`, `${className}-row-le--${theme}`)}>
        <NfoDetailBox
            leftIcon={<Card/>}
            label={translationsStepper.payment()}
        >
          <p>
            {paymentMode}
          </p>
          <p>
            {dataOfStep1.account.accountName}
          </p>
          <p>
            {dataOfStep1.account.iban}
          </p>
          <p>
            {dataOfStep1.account.bic}
          </p>
        </NfoDetailBox>
        {dataOfStep1.account.billAddress ?
            (<NfoDetailBox
                leftIcon={<Building/>}
                label={translationsStepper.differentBillingAddress()}
            >
              <p>
                {billingSalutation}
              </p>
              <p>
                {dataOfStep1.account.billAddress?.companyName1}
              </p>
              <p>
                {dataOfStep1.account.billAddress?.companyName2}
              </p>
              <p>
                {dataOfStep1.account.billAddress?.street} {dataOfStep1.account.billAddress?.houseNumber}
              </p>
              <p>
                {dataOfStep1.account.billAddress?.additionalAddress}
              </p>
              <p>
                {dataOfStep1.account.billAddress?.zip} {dataOfStep1.account.billAddress?.city}
              </p>
              <p>
                {billingCountry}
              </p>
              <p>
                {translationsStepper.billingEmail()}: <span className={cs(`email`, `email-le--${theme}`)}>{dataOfStep1.account.billAddress?.email}</span>
              </p>
            </NfoDetailBox>)
            :    <NfoDetailBox
                leftIcon={<Contract/>}
                label={translationsStepper.documents()}
            >
              {dataOfStep5 && Array.isArray(dataOfStep5) && dataOfStep5.map(doc => (<p>
                <Check style={{marginRight: '0.5rem'}}/>{"" + (doc.type === "contract" ? translationsStepper.documentsTypeContract() : doc.label) + " - " + doc.subLabel}
              </p>))}
            </NfoDetailBox>
        }
      </div>
      {dataOfStep1.account.billAddress &&
          <div className={cs(`${className}-row`, `${className}-row-le--${theme}`)}>
        <NfoDetailBox
            leftIcon={<Contract/>}
            label={translationsStepper.documents()}
        >
          {dataOfStep5 && Array.isArray(dataOfStep5) && dataOfStep5.map(doc => (<p>
            <Check style={{marginRight: '0.5rem'}}/>{"" + (doc.type === "contract" ? translationsStepper.documentsTypeContract() : doc.label) + " - " + doc.subLabel}
          </p>))}
        </NfoDetailBox>
      </div>}
    </>
  );
};
