import { ForwardedRef, forwardRef, PropsWithoutRef, ReactNode } from 'react';
import { ReactComponent as ArrowTip } from '../../portal/assets/icons/arrows_Tip.svg';
import { SelectOption } from "../../portal/constants/types/types.constants";
import cs from 'classnames';
import './inputWithLabel.scss';
import './dropdown.scss';
import { useTheme } from "../utility/themeContext";
import { InputError } from "./inputError.component";

type Props = PropsWithoutRef<JSX.IntrinsicElements['input']> & {
  id: string;
  children?: ReactNode;
  suffix?: ReactNode;
  labelClassName?: string;
  inputClassName?: string;
  buttonClassName?: string;
  onChange?: any;
  onPaste?: any;
  defaultOption?: SelectOption;
  options?: Array<SelectOption>;
  value?: any;
  name?: string;
  error?: any;
};

export const CpxDropdownWithLabel = forwardRef(
  (
    {
      children,
      suffix,
      labelClassName,
      inputClassName,
      className,
      onChange,
      onPaste,
      buttonClassName,
      defaultOption,
      options,
      value,
      error,
      name = 'dropdown',
      id = 'dropdown',
      ...props
    }: Props,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const theme = useTheme();

    return (
      <div
        className={cs('dropdownWithLabel', className, {
          ['isDisabled']: props.disabled,
        })}
      >
        <label
          htmlFor={id}
          className={cs(labelClassName, { ['required']: !!props.required }, `label label-le--${theme}`)}
        >
          {children}
        </label>
        {/*<input*/}
        {/*  type="text"*/}
        {/*  {...props}*/}
        {/*  className={cs(inputClassName, { [styles.isNotEmpty]: !!props.value })}*/}
        {/*  onChange={onChange}*/}
        {/*  onPaste={onPaste}*/}
        {/*  ref={ref}*/}
        {/*/>*/}
        <div className={'dropdown'}>
          <select className={cs('select', `select-le--${theme}`, {['error']: error, [`error-le--${theme}`]: error})} name={name} id={id}
                  onChange={onChange} value={value} disabled={props.disabled}>
            {defaultOption &&
              <option key={defaultOption.id} value={defaultOption.id}>
                {defaultOption.name}
              </option>
            }
            {options && options.map((option: SelectOption) => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
          </select>
          <ArrowTip/>
        </div>
        <div className={buttonClassName}>{suffix}</div>
        <InputError errorMessage={error}/>
      </div>
    )
  }
);
