import { useTheme } from '../../../../core/utility/themeContext';
import { useTranslations } from '../../../utils/helper/utils';
import { TRANSLATIONS } from '../../../constants/transitions/uiTranslations';
import { NfoDetailBox } from '../customer/overview/partnerCustomerContractOverview/components/detailBox.component';
import { ReactComponent as ArrowDown } from '../../../assets/icons/arrows-down.svg';
import { ReactComponent as ArrowUp } from '../../../assets/icons/arrows-up.svg';
import React, { useEffect, useState } from 'react';
import {
  useSelectLang,
  useSelectPartnerCustomerWorkflowsinfo,
} from '../../../redux/store';
import { apiCallAction } from '../../../redux/actions/apiCall.action';
import { useDispatch } from 'react-redux';
import { ACTION_CONST } from '../../../constants/action.constants';
import { WorkFlowInfo, WorkflowItemInfo } from 'compax-api';
import { ICONS, LEGAL_ENTITIES, WORKFLOWITEMS } from '../../../constants/configs/config.constants';
import { ReactComponent as Terminated } from '../../../assets/icons/status-terminated.svg';
import { ReactComponent as Attention } from '../../../assets/icons/attention.svg';
import { ReactComponent as Refresh } from '../../../assets/icons/refresh.svg';
import { ReactComponent as RefreshDts } from '../../../assets/icons/refresh_dts.svg';
import { PROVISIONING_WORKFLOW_ID } from '../../../constants/configs/config.constants';
import { selectCurrentLanguage } from '../../../../core/uiLanguage/lang.slice';
import { CpxIcon } from "../../../../core/components/icon.component";
import cs from 'classnames';
import './partnerOrderStatus.scss';
import { formatDate } from "../../../../core/utils";

type Props = {
  customerId: any;
};

export const NfoPartnerOrderStatus = ({ customerId }: Props) => {
  const current = selectCurrentLanguage(useSelectLang());
  const dispatch = useDispatch();
  const theme = useTheme();
  const translations = useTranslations(TRANSLATIONS.myContract);
  const translation = useTranslations(TRANSLATIONS.common);
  const [collapsed, setCollapsed] = useState(true);
  const workflowsinfo = useSelectPartnerCustomerWorkflowsinfo() as [WorkFlowInfo];
  const language = useSelectLang().selected;

  const workflowsItemInfo: WorkflowItemInfo =
    workflowsinfo && (workflowsinfo[0]?.workflowItemInfos as WorkflowItemInfo);

  useEffect(() => {
    dispatch(
      apiCallAction(
        ACTION_CONST.API_GET_PARTNER_CUSTOMERS_WORKFLOWS,
        {
          customerId,
          workflowId: PROVISIONING_WORKFLOW_ID,
        },
        true
      )
    );
  }, [dispatch, current]);

  const workflows =
    Array.isArray(workflowsItemInfo) &&
    workflowsItemInfo.filter(
      item =>
        item.status.id === 0 ||
        item.status.id === 1 ||
        item.status.id === 8 ||
        item.status.id === 6
    );

  const getRefreshIcon = () => {
    if (theme === LEGAL_ENTITIES.NFON) {
      return <Refresh/>;
    }
    return <RefreshDts/>;
  }

  const getWorkflowStatusIcon = (
    returnCode: any,
    workFlowItemId: any,
    status: any
  ) => {
    let statusIcon: any;

    if (
      status === 0 ||
      status === 8 ||
      (status === 6 && workFlowItemId === WORKFLOWITEMS.WFI_1095)
    ) {
      statusIcon = getRefreshIcon();
    } else if (status === 1) {
      if (returnCode === 1) {
        statusIcon = <CpxIcon icon={ICONS.STATUS.ACTIVE} />;
      } else if (
        (returnCode === 2 && workFlowItemId === WORKFLOWITEMS.WFI_2500) ||
        (returnCode === 4 && workFlowItemId === WORKFLOWITEMS.WFI_2500)
      ) {
        statusIcon = (
          <div className="statusIcon">
            <CpxIcon icon={ICONS.STATUS.ORDERED}/>
          </div>
        );
      } else if (returnCode === 2) {
        statusIcon = <Attention/>;
      } else if (
        returnCode === 3 &&
        workFlowItemId === WORKFLOWITEMS.WFI_2500
      ) {
        statusIcon = <Terminated/>;
      } else if (
        returnCode === 3 &&
        workFlowItemId === WORKFLOWITEMS.WFI_1095
      ) {
        statusIcon = getRefreshIcon();
      }
    }

    return statusIcon;
  };

  return (
    <div className={cs(`orderStatus`, `orderStatus-le--${theme}`)}>
      <h4 className={cs('title', `title-le--${theme}`)}>
        {translations.orderStatus()}
      </h4>
      {workflows &&
      Array.isArray(workflows) &&
      workflows.slice(0, 4).map((workflow, index) => {
        // slice to show only 4 workflow items
        return (
          <div className={'componentStyle'} key={index}>
            <NfoDetailBox
              label={workflow.displayText}
              labelClassName={cs('labelClass', `labelClass-le--${theme}`)}
              boxClassName={'boxClass'}
              rightIcon={getWorkflowStatusIcon(
                workflow.returnCode?.id,
                workflow.workFlowItemId,
                workflow.status?.id
              )}
            >
              {workflow.compilationDate
                ? formatDate(workflow.compilationDate, language)
                : ''}
            </NfoDetailBox>
          </div>
        );
      })}
      {!collapsed &&
      workflows &&
      Array.isArray(workflows) &&
      workflows.slice(4).map((workflow, index) => {
        // slice to show only 4 workflow items
        return (
          <div key={index} className={'componentStyle'}>
            <NfoDetailBox
              label={workflow.displayText}
              labelClassName={cs('labelClass', `labelClass-le--${theme}`)}
              boxClassName={'boxClass'}
              rightIcon={getWorkflowStatusIcon(
                workflow.returnCode?.id,
                workflow.workFlowItemId,
                workflow.status?.id
              )}
            >
              {workflow.compilationDate
                ? formatDate(workflow.compilationDate, language)
                : ''}
            </NfoDetailBox>
          </div>
        );
      })}
      <div className={'bottom-row'}>
        <div
          onClick={() => {
            setCollapsed(!collapsed);
          }}
          className={
            cs('moreLink', ` moreLink-le--${theme} `) +
            cs('bottom-row__link', `bottom-row__link-le--${theme}`)
          }
        >
          <div className={cs(`moreButtonWithIcon`, `moreButtonWithIcon-le--${theme}`)}>
            {!collapsed ? (
              <>
                {translation.showLess()} <ArrowUp className={cs(`moreButtonWithIcon-icon-le--${theme}`)}/>
              </>
            ) : (
              <>
                {translation.showMore()} <ArrowDown className={cs(`moreButtonWithIcon-icon-le--${theme}`)}/>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
