import { CpxModal } from '../../../../../../core/components/modal.component';
import cs from 'classnames';
import './confirmExtensions.scss';
import { ReactComponent as Info } from '../../../../../assets/icons/info_green.svg';
import React, { useEffect, useState } from 'react';
import { useTheme } from '../../../../../../core/utility/themeContext';
import { endOfMonth, useTranslations } from '../../../../../utils/helper/utils';
import { TRANSLATIONS } from '../../../../../constants/transitions/uiTranslations';
import { useAddOptionToBasket, useSelectPartnerCustomer, useSelectUser } from '../../../../../redux/store';
import { CpxPaginatedTable } from '../../../../../../core/components/paginatedTable.component';
import { apiCallAction } from '../../../../../redux/actions/apiCall.action';
import { ACTION_CONST } from '../../../../../constants/action.constants';
import { terminateContractsBody } from '../../../../../utils/helper/requestBodyHelper';

import { INCREASE_INFO, REDUCE_INFO, } from '../../../../../constants/configs/config.constants';
import { useDispatch } from 'react-redux';
import { BasketEntry } from 'compax-api';
import { currency } from "../../../../../../core/utils";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { IPartnerCustomer, ToastTypes } from "../../../../../constants/types/types.constants";
import { useToast } from "../../../../common/header/toast/toast.component";
import {
    NfoActivationDate
} from "../../../../partner/newCustomer/configureProduct/activationDate/activationDate.component";

type AppDispatch = ThunkDispatch<any, any, AnyAction>;

export const NfoConfirmExtensions = ({
                                       showModal,
                                       backToEditModal,
                                       cancelModal,
                                       inputIncreaseValue,
                                       inputReduceValue,
                                       contractId,
                                       optionId,
                                       unusedExtensionIds,
                                       selected,
                                       setEditModal,
                                       loadExtensions
                                     }: any) => {
  const theme = useTheme();
  const translations = useTranslations(TRANSLATIONS.extensionsModal);
  const translationsConfirm = useTranslations(TRANSLATIONS.confirmationModal);
  const translationsCommon = useTranslations(TRANSLATIONS.common);
  const basket = useAddOptionToBasket();
  const dispatch: AppDispatch = useDispatch();
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [extensionsSummaryModal, setExtensionsSummaryModal] = useState(true);
  const inputValue = inputReduceValue ? REDUCE_INFO : INCREASE_INFO;
  let monthlyCharge = 0;
  let undiscountedMonthlyCharge;
  const userData = useSelectUser();
  const selectedPartnerCustomer: IPartnerCustomer = useSelectPartnerCustomer() as IPartnerCustomer
  const customerId = selectedPartnerCustomer.id;
  const { showToast } = useToast();

  const [activationDate, setActivationDate] = useState(new Date().toISOString());

  let basketId = 0;
  let basketTotal = 0;
  let undiscountedBasketTotal = 0;
  let discountDifference = 0;
  let isDiscounted = false;

  const confirmToConfirmationPage = () => {
    setConfirmationModal(true);
    onSubmit()
    setExtensionsSummaryModal(false);
  }

  useEffect(() => {
    if (inputReduceValue === '' && inputIncreaseValue !== '') {
      dispatch(
        apiCallAction(
          ACTION_CONST.API_POST_ADD_OPTIONS,
          {
            selectedPartnerCustomerId: customerId,
            contractId,
            optionId: optionId,
            quantity: inputIncreaseValue,
          },
          true
        )
      );

    }
  }, [inputIncreaseValue]);

  if (basket !== null && typeof basket.id !== 'undefined') {
    monthlyCharge = basket.entries[0].options
      .filter(
        (option: BasketEntry) => option.product.id === optionId
      )[0]
      ?.recurringCharges[0]?.unitAmountNet?.toFixed(2);

    undiscountedMonthlyCharge = basket.entries[0].options
      .filter(
        (option: BasketEntry) => option.product.id === optionId
      )[0]
      ?.recurringCharges[0]?.unitAmountNetDiscounted?.toFixed(2);

    if (monthlyCharge === undefined) {
      monthlyCharge = 0;
    }

    basketId = basket.id;
  }

  if (basket !== null && typeof basket.id !== 'undefined') {
    basketTotal = basket.entries[0].options
      .filter(
        (option: BasketEntry) => option.product.id === optionId
      )[0]
      ?.recurringCharges[0]?.amountNet.toFixed(2);

    undiscountedBasketTotal = basket.entries[0].options
      .filter(
        (option: BasketEntry) => option.product.id === optionId
      )[0]
      ?.recurringCharges[0]?.undiscountedAmountNet?.toFixed(2);

    discountDifference = basket.entries[0].options
      .filter(
        (option: BasketEntry) => option.product.id === optionId
      )[0]
      ?.recurringCharges[0]?.amountNetDiscounts?.toFixed(2)

    isDiscounted = discountDifference > 0;

    if (basketTotal === undefined) {
      basketTotal = 0;
    }

    basketId = basket.id;
  }

  const onSubmit = () => {
    if (inputReduceValue === '') {
      /**
       * Add requested start date
       */
      dispatch(apiCallAction(ACTION_CONST.API_BASKET_PUT_ACTIVATION_DATE,
        {uuid: basket?.entries[0]?.uuid, basketId: basket.id, activationDate}
      )).then(() => {
        dispatch(
          apiCallAction(ACTION_CONST.API_SEND_ORDER, {
            basketId: basketId,
          })
        ).then(() => {
          showToast(ToastTypes.SUCCESS, selected === 'increase' ? translationsConfirm.increaseSuccess() : translationsConfirm.reduceSuccess(), translationsConfirm.increaseSuccessText());
          loadExtensions();
        }).catch(() => {
          showToast(ToastTypes.ERROR, translationsConfirm.orderError(), translationsConfirm.tryAgain());
        });
      }).catch(() => {
        showToast(ToastTypes.ERROR, translationsConfirm.orderError(), translationsConfirm.tryAgain());
      })
    } else if (unusedExtensionIds && inputReduceValue <= unusedExtensionIds.length) {
      dispatch(
        apiCallAction(ACTION_CONST.API_SEND_TERMINATION,
          {
            selectedPartnerCustomerId: customerId,
            ...terminateContractsBody(
              unusedExtensionIds.slice(0, inputReduceValue),
              endOfMonth, userData?.legalEntity.id, userData?.sub, 10048
            )
          })
      ).then(() => {
        showToast(ToastTypes.SUCCESS, selected === 'increase' ? translationsConfirm.increaseSuccess() : translationsConfirm.reduceSuccess(), translationsConfirm.reduceSuccessText());
        loadExtensions();
      }).catch(() => {
        showToast(ToastTypes.ERROR, translationsConfirm.orderError(), translationsConfirm.tryAgain());
      });
    }
    showModal(false);
    setEditModal(true);
  }

  const backToEditExtensions = () => {
    backToEditModal(true);
  };

  return (
    <>
      {extensionsSummaryModal && (
        <CpxModal
          onCancel={() => cancelModal(false)}
          onBack={() => backToEditExtensions()}
          onConfirm={() => confirmToConfirmationPage()}
          className={'confirmExtensions'}
        >
          <h3 className={cs('modalTitle', `modalTitle-le--${theme}`)}>
            {translations.confirmation()}
          </h3>
          <p className={cs('modalInfoText', `modalInfoText-le--${theme}`)}>{translations.confirmText()}</p>
            {(isDiscounted && inputIncreaseValue) &&
                <div className={cs(`discountInfo`, `discountInfo-le--${theme}`)}>
                    <Info/>
                    <div>
                      <p className={cs('infoTitle', `infoTitle-le--${theme}`)}>
                        {translationsCommon.discountedInfoText(currency(discountDifference, basket?.currency?.description) + "")}</p>
                    </div>
                </div>}
            <>
            {inputIncreaseValue ? (
              <>
                <div>
                    <NfoActivationDate setActivationDate={setActivationDate} className={'basketView'}/>
                  {basket && (
                    <article className={'modalTable'}>
                      <CpxPaginatedTable
                        allTitleClass={`allTitle-le--${theme}`}
                        id="modalTable"
                        tableHeader={[
                          translations.difference(),
                          translations.unitPrice(),
                          translations.amount(),
                        ]}
                        tableData={[
                          {
                            id: 'confirmDialog',
                            cellData: [
                              <p>+{inputIncreaseValue}</p>,
                              <div className={cs('amount-discount-wrapper')}><p className={cs({ ['line-through']: isDiscounted })}>{currency(monthlyCharge, basket?.currency?.description)}</p>
                                <p>{isDiscounted && currency(undiscountedMonthlyCharge, basket?.currency?.description)}</p></div>, // TODO
                              <div className={cs('amount-discount-wrapper')}><p className={cs({ ['line-through']: isDiscounted })}>{currency(undiscountedBasketTotal, basket?.currency?.description)}</p>
                                <p>{isDiscounted && currency(basketTotal, basket?.currency?.description)}</p></div>,
                            ],
                          },
                        ]}
                      />
                    </article>
                  )}
                </div>
              </>
            ) : null}
          </>

          <div className={cs(`${inputValue}`, `${inputValue}-le--${theme}`)}>
            <Info/>
            {inputIncreaseValue ? (
              <>
                <div>
                  <h5 className={cs('infoTitle', `infoTitle-le--${theme}`)}>
                    {translations.infoIncreaseTitle()}
                  </h5>
                  <p className={cs('modalInfoText', `modalInfoText-le--${theme}`)}>{translations.infoIncreaseText()}</p>
                </div>
              </>
            ) : (
              <>
                <div className={'infoReduce'}>
                  <h5 className={cs('infoTitle', `infoTitle-le--${theme}`)}>
                    {translations.infoReduceTitle(inputReduceValue)}
                  </h5>
                  <p className={cs('modalInfoText', `modalInfoText-le--${theme}`)}>{translations.infoIncreaseText()}</p>
                </div>
              </>
            )}
          </div>
        </CpxModal>
      )}
    </>
  );
};
