import cs from "classnames";
import React, { useState } from "react";
import "./textFieldModal.scss";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useTheme } from "../../../../../core/utility/themeContext";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";
import { useTranslations } from "../../../../utils/helper/utils";
import { createValidatorSchema, requiredCheck } from "../../../../utils/validation";
import { clearError } from "../../../../redux/actions/error.action";
import { CpxFormikForm } from "../../../../../core/components/formikForm.component";
import { CpxInputWithLabel } from "../../../../../core/components/inputWithLabel.component";
import { useSelectError, useSelectPartners } from "../../../../redux/store";
import { Alert } from "../../../../components/common/Alert/Alert.component";
import { CpxModal } from "../../../../../core/components/modal.component";
import { apiCallAction } from "../../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../../constants/action.constants";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

type Props = {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  textFieldLabel?: string;
  onSubmition: Function;
  error: string;
  showOverview?: boolean;
  setError: any;
}

export enum PAGES {
  ID_INPUT,
  OVERVIEW
}

type AppDispatch = ThunkDispatch<any, any, AnyAction>;


export const TextFieldModal = ({ setShowModal, textFieldLabel, onSubmition, error, showOverview = false, setError }: Props) => {
  const theme = useTheme();
  const dispatch: AppDispatch = useDispatch();
  const translation = useTranslations(TRANSLATIONS.stepper.newCustomer);
  const translationsCommon = useTranslations(TRANSLATIONS.common);
  const internalClassName = "textFieldModal";
  const ErrorsOfBE = useSelectError();
  const [currentPage, setCurrentPage] = useState(PAGES.ID_INPUT);
  const [customerName, setCustomerName] = useState('');
  const [customerId, setCustomerId] = useState<number>();
  const partner = useSelectPartners();



  const requiredMsg = translation.fieldRequired();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      inputValue: ""
    },
    validationSchema: createValidatorSchema({
      inputValue: requiredCheck(requiredMsg),
    }),
    onSubmit: async (values: any) => {
      onSubmition(values.inputValue);
    }
  });

  const renderContent = () => {
    switch (currentPage) {
      case PAGES.ID_INPUT:
        return (
          <CpxFormikForm handleSubmit={() => {}}
                         initialValues={formik.initialValues}
                         id={'textField-modal-data-form'}>
            <CpxModal
              confirmText={translationsCommon.takeOver()}
              onCancel={() => {
                setShowModal(false);
                dispatch(clearError());
              }
              }
              formId={'textField-modal-data-form'}
              onConfirm={handleNextStep}
            >
              <h4 className={cs('modalTitle', `modalTitle-le--${theme}`)}>
                {"Migration"}
              </h4>
              <div className={`${internalClassName}-content`}>
                <CpxInputWithLabel
                  id="inputValue"
                  name={"inputValue"}
                  className={`${internalClassName}-input`}
                  onChange={formik.handleChange}
                  value={formik.values.inputValue}
                  error={formik.touched?.inputValue && formik.errors?.inputValue}
                >
                  {textFieldLabel && <p className='activation-date-content--text'>{textFieldLabel}</p>}
                </CpxInputWithLabel>
              </div>


              <div className={'errorField'}>{error}</div>
              {ErrorsOfBE?.errorData && ErrorsOfBE?.errorData?.length > 0 && <Alert errors={ErrorsOfBE?.errorData}/>}
            </CpxModal>
          </CpxFormikForm>
      )
      case PAGES.OVERVIEW:
      default:
        return (<CpxModal
          confirmText={translationsCommon.takeOver()}
          onCancel={() => {
            setShowModal(false);
            dispatch(clearError());
          }
          }
          onBack={handlePrevStep}
          formId={'textField-modal-data-form'}
          onConfirm={handleNextStep}
        >
          <h4 className={cs('modalTitle', `modalTitle-le--${theme}`)}>
            {translation.migrationConfirmation()}
          </h4>
          <div className={`${internalClassName}-content`}>
            <div></div>
            <div>{customerId && customerId} - {customerName}</div>
          </div>


          <div className={'errorField'}>{error}</div>
          {ErrorsOfBE?.errorData && ErrorsOfBE?.errorData?.length > 0 && <Alert errors={ErrorsOfBE?.errorData}/>}
        </CpxModal>);
    }
  }

  const handleNextStep = () => {
    if(!showOverview){
      formik.handleSubmit();
      return;
    }

    setError('');
    dispatch(clearError());
    switch (currentPage) {
      case PAGES.ID_INPUT:
        //get information
        dispatch(apiCallAction(ACTION_CONST.API_GET_OE_MIGRATION_CUSTOMER, { customerId: formik.values.inputValue, partnerVoNumber: partner[0]?.voNumber})).then((res: any) => {
          setCustomerName(res?.customer?.companyName1)
          setCustomerId(+formik?.values?.inputValue)
          setCurrentPage(PAGES.OVERVIEW);
        });
        break;
      case PAGES.OVERVIEW:
        formik.handleSubmit();
        return;
    }

  }

  const handlePrevStep = () => {
    setCurrentPage(PAGES.ID_INPUT)
  }




  return (
    <>{renderContent()}</>
  )
}
