import './progressBar.scss';
import cs from 'classnames';
import { PropsWithoutRef } from 'react';
import { useTheme } from '../utility/themeContext';

type Props = PropsWithoutRef<JSX.IntrinsicElements['div']> & {
  current: number;
  max: number;
  isWarningColor?: boolean;
  useSecurityBehaviour?: boolean | false;
};

export const CpxProgressBar = ({
  max,
  current,
  className,
  isWarningColor,
  useSecurityBehaviour,
  ...props
}: Props) => {
  const theme = useTheme();
  const percent = (current * 100) / max;
  return (
    <div
      className={cs(
        className,
        { securityProgressBar: useSecurityBehaviour },
        { [`securityProgressBar-le--${theme}`]: useSecurityBehaviour },
        { progressBar: !useSecurityBehaviour },
        { [`progressBar-le--${theme}`]: !useSecurityBehaviour }
      )}
      {...props}
    >
      <div
        className={cs(
          { securityProgressBarInner: useSecurityBehaviour },
          { progressBarInner: !useSecurityBehaviour },
          { [`progressBarInner-le--${theme}`]: !useSecurityBehaviour },
          { overThreshold: isWarningColor },
        )}
        style={{ width: percent + '%' }}
      />
    </div>
  );
};
