import cs from 'classnames';
import './detailBox.scss';
import { useTheme } from '../../../../../../../core/utility/themeContext';

type Props = {
  label: any;
  children: any;
  leftIcon?: any;
  rightIcon?: any;
  labelClassName?: any;
  boxClassName?: any;
};

export const NfoDetailBox = ({
  label,
  children,
  leftIcon,
  rightIcon,
  labelClassName,
  boxClassName,
}: Props) => {
  const internalClassName = 'detailBox';
  const theme = useTheme();
  const leftIconClassName = leftIcon
    ? `${internalClassName}__textbox`
    : `${internalClassName}__withoutLeftIcon`;

  return (
    <div
      className={cs(
        internalClassName,
        `${internalClassName}-le--${theme}`,
        boxClassName
      )}
    >
      {leftIcon}
      <div
        className={cs(
          `${internalClassName}__textbox-le--${theme}`,

          leftIconClassName
        )}
      >
        <div>
          <h5 className={cs('label', labelClassName)}>{label}</h5>
          <p className={'subtext'}>{children}</p>
        </div>
        <div>{rightIcon}</div>
      </div>
    </div>
  );
};
