import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { routes } from "../../../constants/routes/routes";
import { useSelectedMainContract, useSelectLang, useSelectMainProduct, useSelectPackages, useSelectPartnerCustomer } from "../../../redux/store";
import { TRANSLATIONS } from "../../../constants/transitions/uiTranslations";
import { useGetAmountOfNumbers, useGetAmountOfSites, useTranslations, } from "../../../utils/helper/utils";
import { NfoFigureBox } from "../../../components/common/settings/figureBox/figureBox.component";
import { CpxTitle } from "../../../../core/components/title/title.component";
import { CpxSubtitle } from "../../../../core/components/title/subtitle.component";
import "./settings.scss";
import { CENTREXX_PRODUCT_IDS, NCONNECT_VOICE_PRODUCT_IDS } from "../../../constants/configs/config.constants";
import { apiCallAction } from "../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../constants/action.constants";
import { selectCurrentLanguage } from "../../../../core/uiLanguage/lang.slice";
import { IPartnerCustomer } from "../../../constants/types/types.constants";

export const NfoSettingsPage = () => {
  const dispatch = useDispatch();
  const translations = useTranslations(TRANSLATIONS.settings);
  const sites = useGetAmountOfSites() || '-';
  const numbers = useGetAmountOfNumbers();
  const mainProductId = useSelectMainProduct();
  const internalClassName = "settings";
  const packages = useSelectPackages()
    .reduce((prev, cur: { quantity: number }) => prev + (cur.quantity || 0), 0);
  const currentLanguage = selectCurrentLanguage(useSelectLang());
  const selectedPartnerCustomer: IPartnerCustomer = useSelectPartnerCustomer() as IPartnerCustomer;
  const selectedMainContract = useSelectedMainContract();

  useEffect(() => {
    if(selectedMainContract){
      dispatch(apiCallAction(ACTION_CONST.API_GET_CLIS, { contractId: selectedMainContract.contractId, selectedPartnerCustomerId: selectedPartnerCustomer?.id }));
      dispatch(apiCallAction(ACTION_CONST.API_GET_PACKAGES, { contractId: selectedMainContract.contractId, selectedPartnerCustomerId: selectedPartnerCustomer?.id }));
    }
  }, [dispatch, selectedMainContract]);

  useEffect(() => {
    dispatch(apiCallAction(ACTION_CONST.API_GET_ADDRESSES,  { language: currentLanguage, selectedPartnerCustomerId: selectedPartnerCustomer?.id}));
  }, [dispatch, currentLanguage]);
  return (
    <>
      <CpxTitle
        title={translations.title()}
        internalClassName={internalClassName}
      />
      <CpxSubtitle
        subtitle={translations.subheading()}
        internalClassName={internalClassName}
      />
      <div className={`${internalClassName}-container`}>
        <NfoFigureBox
          heading={translations.sites()}
          moreLinkTo={routes.customerSites.path}
          figure={sites + ""}
        />
        <NfoFigureBox
          heading={translations.numbers()}
          moreLinkTo={routes.customerNumbers.path}
          figure={numbers + ""}
        />
        {CENTREXX_PRODUCT_IDS.includes(mainProductId) &&
        <NfoFigureBox
          heading={translations.flatrates()}
          moreLinkTo={routes.customerFlatrate.path}
          figure={packages + ""}
        />
        }
        {NCONNECT_VOICE_PRODUCT_IDS.includes(mainProductId) &&
        <NfoFigureBox
          heading={translations.packages()}
          moreLinkTo={routes.customerPackages.path}
          figure={packages + ""}
        />
        }
        {/* <NfoFigureBox
          heading={translations.limits()}
          moreLinkTo={routes.limits.path}
          figure="110"
        /> */}
      </div>
    </>
  );
};
