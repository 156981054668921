import { TelcoData } from "compax-api";

export const terminateContractsBody = (
  contractIds: [],
  terminationDate: Date,
  client: number,
  lastUser: number,
  terminationWorkflowId?: number

) => {
  return {
    contracts: contractIds,
    header: {
      client: client,
      externalOrderId: 'string',
      lastUser: lastUser,
      marketingCampaignId: 'string',
      orderEntryOrganisation: {
        distributionsChannel: 0,
        salesPartner: 0,
      },
      salesOrganisation: {
        distributionsChannel: 0,
        salesPartner: 0,
      },
      source: 2,
    },
    requestedTerminationDate: terminationDate,
    terminationReasonId: 0,
    terminationWorkflowId: terminationWorkflowId
  };
};

export const editNotesBody = (
  notes: string
) => {
  return {
    notes: notes,
    };
};

export const addOptionsCustomDataBody = (
  customData: any,
) => {
  return {
    customData: customData
  };
};

export const addTelcoData = (telcoData : TelcoData) => {
  return telcoData;
}


