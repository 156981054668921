import cs from 'classnames';
import { PortingIndividualNumberForm } from "./portingIndividualNumberForm";
import { PortingNumberBlockForm } from "./portingNumberBlockForm";
import { CpxSwitchWithText } from "../../../../../../../core/components/switchWithText.component";
import React from "react";
import './portingNumberSwitch.scss'
import { useTranslations } from "../../../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../../../constants/transitions/uiTranslations";
import { selectCurrentLanguage } from "../../../../../../../core/uiLanguage/lang.slice";
import { useSelectLang } from "../../../../../../redux/store";
import { CLIENTS_IDS } from "../../../../../../constants/configs/config.constants";

type Props = {
  values: any;
  touched: any;
  errors: any;
  handleChange: any;
  allNumbersIsChecked: any;
  setAllNumbersIsChecked: any;
  formik: any;
  callNumberConfigurations: any;
  setCallNumberConfigurations: any;
  setShortenOnConsole: any;
  shortenOnConsole: any;
  selectedCallNumberType: any;
  setSelectedCallNumberType: any;
  client:any
}

export const PortingNumberSwitch = ({
                                      values,
                                      touched,
                                      errors,
                                      handleChange,
                                      allNumbersIsChecked,
                                      setAllNumbersIsChecked,
                                      formik,
                                      callNumberConfigurations,
                                      setCallNumberConfigurations,
                                      setShortenOnConsole,
                                      shortenOnConsole,
                                      selectedCallNumberType,
                                      setSelectedCallNumberType,
                                      client
                                    }: Props) => {
  const translation = useTranslations(TRANSLATIONS.stepper.newCustomer);
  const currentLanguage = selectCurrentLanguage(useSelectLang());
  const internalClassName = "portingNumberSwitch"


  const handleSwitchChange = () => {
    if (client.id !== CLIENTS_IDS.AUT) {
      formik.setFieldValue('multipleSubscriberNumbers.localAreaCode', '');
      formik.setFieldValue('multipleSubscriberNumbers.callNumberConfigurations', [{}]);


      setSelectedCallNumberType({ multipleSubscriberNumbers: !selectedCallNumberType.multipleSubscriberNumbers, numberBlock: !selectedCallNumberType.numberBlock });
      /* Reset states */
      setAllNumbersIsChecked(false);
      setCallNumberConfigurations([{ callNumber: '' }]);

    }
  }

  return (
    <div className={internalClassName}>
      <CpxSwitchWithText
        className={cs(
          `${internalClassName}--switch`,
          { 'switch-en': currentLanguage === "EN" },
        )}
        text={{ left: translation.multipleSubscriberNumbers(), right: translation.numberBlock() }}
        checked={selectedCallNumberType.numberBlock}
        onChange={handleSwitchChange}
      />
      {selectedCallNumberType.multipleSubscriberNumbers &&
        <PortingIndividualNumberForm values={values.multipleSubscriberNumbers} touched={touched.multipleSubscriberNumbers} errors={errors.multipleSubscriberNumbers} handleChange={handleChange} allNumbersIsChecked={allNumbersIsChecked}
                                     setAllNumbersIsChecked={setAllNumbersIsChecked} formik={formik} callNumberConfigurations={callNumberConfigurations} setCallNumberConfigurations={setCallNumberConfigurations}/>}
      {selectedCallNumberType.numberBlock &&
        <PortingNumberBlockForm values={values.numberBlock} touched={touched.numberBlock} errors={errors.numberBlock} handleChange={handleChange} formik={formik} setShortenOnConsole={setShortenOnConsole} shortenOnConsole={shortenOnConsole} client={client}/>}
    </div>
  );
}