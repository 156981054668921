import { ACTION_CONST } from "../../constants/action.constants";
import { IAction } from "../../constants/types/types.constants";

const initialState = {
  options: []
};

function productsReducer(state = initialState, action: IAction) {
  switch (action.type) {
    case ACTION_CONST.API_GET_OPTIONS:
      return state = {
        ...state,
        options: action.data,
      }

    default:
      return state;
  }
}

export default productsReducer;
