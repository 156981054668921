import { useSelectApiCallStatus, useSelectLang, useSelectUserRole } from "../../../redux/store";
import { TRANSLATIONS } from "../../../constants/transitions/uiTranslations";
import cs from "classnames";
import { useGetTitle, useTranslations } from "../../../utils/helper/utils";
import "./header.scss";
import { ReactComponent as LogoNFON } from "../../../assets/images/logo_nfon.svg";
import { ReactComponent as LogoDTS } from "../../../assets/images/logo_dts.svg";
import { ReactComponent as LogoTEF } from "../../../assets/images/logo_tef.svg";
import { NfoToast } from "./toast/toast.component";
import { NfoUserNavigation } from "./userNavigation/userNavigation.component";
import { useTheme } from "../../../../core/utility/themeContext";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { LEGAL_ENTITIES } from "../../../constants/configs/config.constants";
import { NotificationComponent } from "./notification/notificationComponent";
import { ACTION_CONST } from "../../../constants/action.constants";
import { apiCallAction } from "../../../redux/actions/apiCall.action";
import { useDispatch } from "react-redux";

export const NfoHeader = () => {
  const { loggedIn } = useSelectApiCallStatus()
  const translations = useTranslations(TRANSLATIONS.header);
  const userValueTyp = useGetTitle();
  const theme = useTheme();
  const internalClassName = "header";
  const role = useSelectUserRole();
  const lang = useSelectLang();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(apiCallAction(ACTION_CONST.API_GET_USER));
  }, [lang])

  const getLegalEntityLogo = () => {
    switch(theme) {
      case LEGAL_ENTITIES.DTS:
        return <LogoDTS title={translations.altLogo()}/>
        break;
      case LEGAL_ENTITIES.NFON:
        return <LogoNFON title={translations.altLogo()}/>
        break;
      case LEGAL_ENTITIES.TEF:
        return <LogoTEF title={translations.altLogo()}/>
        break;
      default:
        return <LogoNFON title={translations.altLogo()}/>
        break;
    }
  }

  return (
    <div className={cs(`${internalClassName}-wrapper`, `${internalClassName}-wrapper-le--${theme}`)}>
      <header
        className={cs(internalClassName, `${internalClassName}-le--${theme}`)}
      >
        <div className={"container"}>
          <Link to={"/login"}>
            <div className={cs("logo", `logo-le--${theme}`)}>
              {getLegalEntityLogo()}
            </div>
          </Link>
          <h1 className={cs("title", `title-le--${theme}`)}>{userValueTyp}</h1>
          {loggedIn && <NfoUserNavigation className={"utilities"}/>}
          <NfoToast/>
        </div>
      </header>
      <NotificationComponent/>
    </div>
  );
};
