import cs from "classnames";
import { CpxButton } from "../../../../../core/components/button.component";
import React, { useEffect, useState } from "react";
import { useTheme } from "../../../../../core/utility/themeContext";
import { useTranslations } from "../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";
import { apiCallAction } from "../../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../../constants/action.constants";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useDispatch } from "react-redux";
import { IDocument } from "../../../../constants/types/types.constants";
import { CpxPaginatedTable } from "../../../../../core/components/paginatedTable.component";
import { CpxIcon } from "../../../../../core/components/icon.component";
import { ICONS } from "../../../../constants/configs/config.constants";
import './numberPortingDocumentForm.scss';
import { clearError } from "../../../../redux/actions/error.action";

type Props = {
  parentClassName: string;
  currentDocument: IDocument;
  setCurrentDocument: Function;
  handleDeleteDocument: Function;
}

type AppDispatch = ThunkDispatch<any, any, AnyAction>;

export const NumberPortingDocumentFormComponent = ({ parentClassName, currentDocument, setCurrentDocument, handleDeleteDocument }: Props) => {
  const theme = useTheme();
  const translation = useTranslations(TRANSLATIONS.stepper.newCustomer);
  const translationsPortingNumbers = useTranslations(TRANSLATIONS.portingNumbersModal);
  const dispatch: AppDispatch = useDispatch();
  const hiddenFileInput = React.useRef(null as any);

  const [documents, setDocuments] = useState([{...currentDocument}])

  useEffect(() => {
    dispatch(clearError());
  }, [])

  const handleDownloadFile = (e: any) => {
    currentDocument.documentId &&
    dispatch(apiCallAction(ACTION_CONST.API_DOCUMENTS_DOWNLOAD_DOCUMENT, { documentId: currentDocument.documentId })).then((res) => {
      const link = document.createElement("a");
      link.href = `data:${res.mimeType};base64,${res.file}`;
      link.download = res.documentName;
      link.click();
      link.remove();
    });
  }
  
  const handleUploadFile = (event: any) => {
    const fileUploaded = event.target.files[0];
    const formData = new FormData();
    formData.append('file', fileUploaded);

    //api call to upload file
    dispatch(apiCallAction(ACTION_CONST.API_DOCUMENTS_POST_DOCUMENT, {
      documentTypeId: currentDocument.documentTypeId,
      convertPdfTo: "jpg",
      file: fileUploaded,
      stepper: "PORTING_FORM",
    }, true))
      .then(
        (res: any) => {
          setCurrentDocument({...currentDocument, fileName: fileUploaded.name, documentId: res, uploaded: true});
        }
      );

    dispatch(clearError());
  };

  const handleClickFakeButton = () => {
    hiddenFileInput?.current?.click();
  };

  const documentsTableRowData = () => ({
    cellData: [
      <div>
        <p className={cs(`${parentClassName}-label`, `${parentClassName}-label-le--${theme}`)}>{translationsPortingNumbers.portingForm()} *</p>
      </div>,
      (currentDocument?.uploaded) ?
        <div className={cs(`${parentClassName}-downloadDocument`, `${parentClassName}-downloadDocument-le--${theme}`)}><a target="_blank" onClick={e => handleDownloadFile(e)} className={cs("tableFirstChild", `tableFirstChild-font-le--${theme}`)}>{currentDocument.fileName}</a></div> :
        <><input onChange={handleUploadFile} type='file' ref={hiddenFileInput} accept="application/pdf" hidden/><CpxButton type="button" onClick={() => handleClickFakeButton()}>{translation.documentsUploadFile()}</CpxButton></>,
      <div className={cs(`${parentClassName}-actions`, `${parentClassName}-actions-le--${theme}`)}>
        <CpxButton className={"iconOnly"} type={'button'} onClick={() => handleDeleteDocument()}><CpxIcon icon={ICONS.DELETE}/></CpxButton>
      </div>
    ],
  });

  return (
    <div className={`${parentClassName}-section-container`}>
      <h4 className={`${parentClassName}-subheading ${parentClassName}-subheading-le--${theme}`}>{translationsPortingNumbers.documents()}</h4>
      <CpxPaginatedTable
        id={`${parentClassName}-documentsTable`}
        tableHeader={[
          translationsPortingNumbers.document(),
          translation.documentsTableHeadingFileName(),
          "",
        ]}
        tableData={documents?.map(documentsTableRowData)}
      />
    </div>
  )
}