import { ACTION_CONST } from "../../constants/action.constants";
import { IAction } from "../../constants/types/types.constants";

const initialState = {
  data: {}
};

function userReducer(state = initialState, action: IAction) {
  switch (action.type) {
    case ACTION_CONST.SET_USER_DATA:
    case ACTION_CONST.API_GET_USER:
      return state = {
        ...state,
        data: action.data,
      }
    default:
      return state;
  }
}

export default userReducer;
