import React, { useEffect, useState } from 'react';
import { CpxPaginatedTable } from '../../../../core/components/paginatedTable.component';
import { NfoStatus } from '../../../../core/components/status.component';
import { COUNTRY_CODES, FEATURES_FLAG, ID_DATA_ENTITY, ORDER_TYPE, USER_FUNCTIONS, } from '../../../constants/configs/config.constants';
import { TRANSLATIONS } from '../../../constants/transitions/uiTranslations';
import { routes } from '../../../constants/routes/routes';
import { useTranslations } from '../../../utils/helper/utils';
import { SearchBox } from '../../../components/common/search/searchBox.component';
import { useTheme } from '../../../../core/utility/themeContext';
import { useDispatch } from 'react-redux';
import { ACTION_CONST } from '../../../constants/action.constants';
import { apiCallAction } from '../../../redux/actions/apiCall.action';
import { useCurrentOrderType, useSelectFeatures, useSelectLang, useSelectPartnerCustomers, useSelectPartners, useSelectUser, useStep1Data, } from '../../../redux/store';
import { CpxButton } from '../../../../core/components/button.component';
import { deleteSelectedPartnerCustomer, saveSelectedPartnerCustomer } from '../../../redux/actions/partnerCustomers.action';
import { useHistory } from 'react-router-dom';
import { CpxTitle } from '../../../../core/components/title/title.component';
import cs from 'classnames';
import './customers.scss';
import { ReactComponent as Add } from "../../../assets/icons/add.svg";
import { NfoEmptyState } from "../../../../core/emptyState.component";
import { formatDate } from "../../../../core/utils";
import { deleteStepData, saveStep1Data, saveStep2Data } from "../../../redux/actions/step.action";
import { TextFieldModal } from "./textFieldModal/textFieldModal.component";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

type AppDispatch = ThunkDispatch<any, any, AnyAction>;
export const NfoCustomersPartnerPage = () => {
  const translations = useTranslations(TRANSLATIONS.customers);
  const translationsSearchBox = useTranslations(TRANSLATIONS.partnerCustomer);
  const theme = useTheme();
  const internalClassName = 'partner-customers';
  const dispatch = useDispatch();
  const history = useHistory();
  const partnerCustomers: any = useSelectPartnerCustomers();
  const features = useSelectFeatures();
  const [filteredItems, setFilteredItems] = useState(partnerCustomers);
  const language = useSelectLang().selected;
  const currentOrderType = useCurrentOrderType();
  const user = useSelectUser();
  const step1Data = useStep1Data();

  const [showModal, setShowModal] = useState<boolean>(false);
  const dispatchRes: AppDispatch = useDispatch();
  const usePartner = useSelectPartners();

  const [error, setError] = useState('');

  useEffect(() => {
    dispatch(apiCallAction(ACTION_CONST.API_GET_PARTNER_CUSTOMERS));
  }, []);

  useEffect(() => {
    dispatch(apiCallAction(ACTION_CONST.API_PORTAL_FEATURE, { featureName: FEATURES_FLAG.PORTAL_ORDER_ENTRY }))
    dispatch(apiCallAction(ACTION_CONST.API_PORTAL_FEATURE, { featureName: FEATURES_FLAG.PORTAL_ORDER_ENTRY_MIGRATION }))
  }, [])

  const getCountryCode = (id: any) => {
    return COUNTRY_CODES[id] !== undefined ? COUNTRY_CODES[id] : '';
  };

  const onClickPartnerCustomer = (e: any, partnerCustomerData: any) => {
    dispatch(saveSelectedPartnerCustomer(partnerCustomerData));
    history.push(routes.partnerCustomersOverview.path);
  };

  const getFilteredItems = (data: any) => {
    setFilteredItems(data);
  };

  const getMigrationCustomerInfo = (customerId: string) => {
    setError('');

    if (!usePartner[0]?.voNumber) {
      setError(translations.partnerVOErrorMessage())
      return;
    }

     dispatchRes(apiCallAction(ACTION_CONST.API_GET_OE_MIGRATION_CUSTOMER, { customerId: customerId, partnerVoNumber: usePartner[0]?.voNumber})).then((res: any) => {
       const mig = {
         ...res,
         migrationCustomerId: customerId,
         partnerVoNumber:usePartner[0]?.voNumber
       }

       dispatch(saveStep1Data(mig));
       dispatch(saveStep2Data({ siteAddresses: mig?.customDataPortal?.siteAddresses, formdata: {} }))
       history.push(routes.partnerCustomersNewCustomer.path);
     })

  }

  const tableHeader = [
    { label: translations.tableHeaderCustomer(), sort: true, sortBy: 'companyName', sortMethod: 'string' },
    { label: translations.tableHeaderLocation(), sort: true, sortBy: 'mainAddress.city', sortMethod: 'string' },
    { label: translations.tableHeaderCustomerId(), sort: true, sortBy: 'customerId', sortMethod: 'number' },
    { label: translations.tableHeaderCreatedAt(), sort: true, sortBy: 'createDate', sortMethod: 'date' },
    { label: translations.tableHeaderStatus(), sort: true, sortBy: 'status.id', sortMethod: 'number' },
  ]

  const customerTableData = (partnerCustomer: any) => {
    return {
      id: '' + partnerCustomer.id,
      cellData: [
        <div
          className={cs('tableFirstChild', `tableFirstChild-font-le--${theme}`)}
        >
          <a
            className={cs(
              'partnerCustomerButton',
              `partnerCustomerButton-font-le--${theme}`
            )}
            onClick={e => onClickPartnerCustomer(e, partnerCustomer)}
          >
            {partnerCustomer?.companyName}
          </a>
        </div>,
        <>
          {partnerCustomer.mainAddress.city +
            ', ' +
            getCountryCode(partnerCustomer.mainAddress.country.id)}
        </>,
        <>{partnerCustomer.customerId}</>,
        <>{formatDate(new Date(partnerCustomer.createDate as Date), language)}</>,
        <NfoStatus
          entity={{ id: ID_DATA_ENTITY.CUSTOMER }}
          status={{ id: partnerCustomer.status.id }}
        />,
      ],
    };
  };

  return (
    <>
      {showModal && ( <TextFieldModal setShowModal={setShowModal} textFieldLabel={translations.customerId()} onSubmition={getMigrationCustomerInfo} error={error} setError={setError}></TextFieldModal>
      )}
      <div className={cs(`${internalClassName}`, 'customersTitle', `customersTitle-le--${theme}`)}>
        <CpxTitle
          title={translations.title()}
          internalClassName={internalClassName}
        />
        <div className={"buttonGroup"}>
          {user?.functions?.includes(USER_FUNCTIONS.PORTAL_MIGRATE_CUSTOMER) && features.PORTAL_ORDER_ENTRY_MIGRATION &&
            <CpxButton
              buttonType={"white"}
              className={"buttonIcons"}
              onClick={() => {
                setError('')
                dispatch(deleteSelectedPartnerCustomer());
                if (currentOrderType === ORDER_TYPE.ORDER_NEW_MAIN_CONTRACT || !step1Data?.migrationCustomerId) {
                  dispatch(deleteStepData());
                }
                if (!!step1Data?.migrationCustomerId && currentOrderType === ORDER_TYPE.ORDER_NEW_CUSTOMER) {
                  history.push(routes.partnerCustomersNewCustomer.path);
                } else {
                setShowModal(true);
                }
              }}
            >
              <Add/>
              {translations.migration()}
            </CpxButton>
          }
        {user?.functions?.includes(USER_FUNCTIONS.PORTAL_CREATE_CUSTOMER) && features.PORTAL_ORDER_ENTRY &&
          <CpxButton
            className={"buttonIcons"}
            onClick={() => {
              if(currentOrderType === ORDER_TYPE.ORDER_NEW_MAIN_CONTRACT || step1Data?.migrationCustomerId) {
                dispatch(deleteStepData());
              }
              dispatch(deleteSelectedPartnerCustomer());
              history.push(routes.partnerCustomersNewCustomer.path);
            }}
          >
            <Add/>
            {translations.addCustomer()}
          </CpxButton>
        }
        </div>
      </div>
      <NfoEmptyState
        data={partnerCustomers}
        button={user?.functions?.includes(USER_FUNCTIONS.PORTAL_CREATE_CUSTOMER) && features.PORTAL_ORDER_ENTRY &&
          <CpxButton
            className={"buttonIcons"}
            onClick={() => {
              dispatch(deleteSelectedPartnerCustomer());
              history.push(routes.partnerCustomersNewCustomer.path);
            }}
          >
            <Add/>
            {translations.addCustomer()}
          </CpxButton>
        }
        pageTitle={translations.title()}
        subtitle={translations.emptyStateSubTitle()}
        boxStyling={true}
      >
        {partnerCustomers && !partnerCustomers.errorCode && (
          <>
            <SearchBox
              theme={theme}
              placeholder={
                translationsSearchBox.customerName() +
                ', ' +
                translationsSearchBox.customerNumber() +
                ', ' +
                translationsSearchBox.customerSite() +
                ' ...'
              }
              id={'partnerCustomersTable'}
              data={partnerCustomers && partnerCustomers}
              getFilteredItems={getFilteredItems}
              shouldBeSearched={['companyName', 'mainAddress.city', 'customerId']}
            />

            {filteredItems && filteredItems instanceof Array && (
              <article className={cs(`customers`, `customers-le--${theme}`)}>
                <CpxPaginatedTable
                  id="customersTable"
                  tableHeader={tableHeader}
                  tableData={filteredItems.map(customerTableData)}
                  itemsPerPage={[10, 20]}
                  pagination="both"
                  itemsPerPageLabel={translations.paginationSelectLabel()}
                  sliceToString={(from: number, to: number, total: number) =>
                    translations.paginationSliceInfo(
                      from + '',
                      to + '',
                      total + ''
                    )
                  }
                  rowData={filteredItems && filteredItems}
                  getSortedRowsBack={(sortedRows: any) => setFilteredItems(sortedRows?.slice())}
                />
              </article>
            )}
          </>
        )}
      </NfoEmptyState>
    </>
  );
};
