import { PropsWithoutRef, useEffect, useRef } from "react";
import cs from "classnames";
import "./checkboxWithIndeterminate.scss";
import { useTheme } from "../utility/themeContext";

type Props = Omit<PropsWithoutRef<JSX.IntrinsicElements["input"]>,
  "onChange"> & {
  onChange?: (checked: boolean) => void;
};


export const CpxCheckboxWithIndeterminate = ({
                                               checked,
                                               className,
                                               onChange,
                                               ...props
                                             }: Props) => {
  const checkRef = useRef<HTMLInputElement>(null);
  const theme = useTheme();
  const inputClass = theme === 'dts' ? 'checkboxWithIndeterminateDTS' : 'checkboxWithIndeterminate';

  useEffect(() => {
    if (!!checkRef && !!checkRef.current) {
      checkRef.current.indeterminate = checked === undefined;
    }
  }, [checked, checkRef]);

  return (
    <div className={'checkboxWrapper'}>
      <input
        className={cs(inputClass, className)}
        {...props}
        type="checkbox"
        ref={checkRef}
        checked={checked === true}
        onChange={() => !!onChange && onChange(checked !== true)}
      />
    </div>
  );
};
