import React, { useEffect, useState } from "react";
import { Address, AddressPortal, Basket, BasketEntry, LnpPhoneNumberBlock, Phone, SelectableBasketOption, TelcoData } from "compax-api";
import { useTheme } from "../../../../../../../core/utility/themeContext";
import { useTranslations } from "../../../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../../../constants/transitions/uiTranslations";
import { CpxModal } from "../../../../../../../core/components/modal.component";
import cs from "classnames";
import { useOrderEntryBasket, useSelectCarrier, useSelectCountries, useSelectError, useSelectLang, useSelectUserLegalEntity, useStep1Data, useStep2Data, useStep3Data } from "../../../../../../redux/store";
import { PortingDisplayAddressForm } from "./portingDisplayAddressForm";
import { CpxDropdownWithLabel } from "../../../../../../../core/components/dropdown.component";
import { CpxInputWithLabel } from "../../../../../../../core/components/inputWithLabel.component";
import { CpxSwitch } from "../../../../../../../core/components/switch.component";
import { PortingNumberSwitch } from "./portingNumberSwitch";
import { IDocument, ProductOptionModalType, SelectOption } from "../../../../../../constants/types/types.constants";
import { apiCallAction } from "../../../../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../../../../constants/action.constants";
import { selectCurrentLanguage } from "../../../../../../../core/uiLanguage/lang.slice";
import '../../../../../../../core/components/addressInputGroup.scss';
import './portingNumbersForm.scss';
import { useFormik } from "formik";
import { createValidatorSchema, createValidatorSchemaArray, MaxLengthCheck, numberCheck, requiredCheck } from "../../../../../../utils/validation";
import { CpxFormikForm } from "../../../../../../../core/components/formikForm.component";
import { CLIENTS_IDS, COUNTRY_IDS, ID_SALUTATIONS, INPUT_MAX_LENGTHS } from "../../../../../../constants/configs/config.constants";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { Alert } from "../../../../../common/Alert/Alert.component";
import { clearError } from "../../../../../../redux/actions/error.action";
import { NfoActivationDate } from "../../activationDate/activationDate.component";
import { addTelcoData } from "../../../../../../utils/helper/requestBodyHelper";
import { NumberPortingDocumentFormComponent } from "../../../../../customer/numbers/numberPortingDocumentForm/numberPortingDocumentForm.component";


type Props = {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  option: SelectableBasketOption;
  selectOptionOfOption: Function;
  type?: ProductOptionModalType;
  entry?: BasketEntry;
}

type CallNumberConfiguration = {
  callNumber: string;
}

type AppDispatch = ThunkDispatch<any, any, AnyAction>;

export const PortingNumbersForms = ({ setShowModal, option, selectOptionOfOption, entry}: Props) => {

  const dispatchTemp: AppDispatch = useDispatch();
  const dispatch = useDispatch();
  const currentLang = selectCurrentLanguage(useSelectLang());
  const theme = useTheme();
  const translation = useTranslations(TRANSLATIONS.stepper.newCustomer);
  const translationsCommon = useTranslations(TRANSLATIONS.common);
  const translationsPortingNumbers = useTranslations(TRANSLATIONS.portingNumbersModal);
  const requiredMsg = translation.fieldRequired();
  const { mainAddress, customer } = useStep1Data();
  const dataOfStep2 = useStep2Data();
  const countries = useSelectCountries();
  const currentProviders = useSelectCarrier();
  const ErrorsOfBE = useSelectError();
  const client = useSelectUserLegalEntity();
  const basket = useOrderEntryBasket();
  const step3Data = useStep3Data();
  const [error, setError] = useState('');


  const locations = [{ ...mainAddress, ...customer }, ...dataOfStep2?.siteAddresses];
  const internalClassName = 'porting-numbers-modal';
  const [currentDocument, setCurrentDocument] = useState<IDocument>(entry?.documents && entry.documents.length > 0 ? {label: '', fileName: entry.documents[0].documentName || '', documentTypeId: entry.documents[0]?.documentType?.id || 7, documentId: entry.documents[0].id || -1, type: null, uploaded: true, entries: entry.uuid ? [entry.uuid] : []} : { label: "", fileName: null, documentTypeId: 7, documentId: null, type: null, uploaded: false, entries: []});

  useEffect(() => {
    dispatch(apiCallAction(ACTION_CONST.API_GET_CARRIER));
  }, [currentLang]);

  const getCountryById = (id: number) => {
    return countries?.filter((country: any) => country.id === id)[0]?.description;
  }

  const currentProvidersOptions = Array.isArray(currentProviders) ? currentProviders.map((currentProvider) => {
    return {
      id: currentProvider.id,
      name: currentProvider.description,
      data: currentProvider,
    }
  }) : [];

  const handleDeleteDocument = () => {
    currentDocument.documentId && dispatch(apiCallAction(ACTION_CONST.API_DOCUMENTS_DELETE_DOCUMENT, { documentId: currentDocument.documentId }));
    setCurrentDocument({ label: "", fileName: null, documentTypeId: 7, documentId: null, type: null, uploaded: false, entries: [] })
  }

  const locationOptions = Array.isArray(locations) ? locations.map((location, index: number) => {

    const fullName = location.companyName2 ? `${location.companyName1} ${location.companyName2}` : `${location.companyName1}`;

    return {
      id: index,
      name: `${fullName}, ${location.street} ${location.houseNumber} ${location.zip} ${location.city} ${getCountryById(+location.country.id)}`,
      data: location,
    }

  }) : [];

  const DEFAULT_SELECT_OPTION: SelectOption = {
    id: '',
    name: translationsCommon.defaultSelect(),
  }

  const findIndexOfLocation = (siteAddress: Address) => {

    let index = locations.findIndex((location: any) => {

      const { country, zip, city, street, houseNumber, companyName1, companyName2, salutation } = location;

      if(!siteAddress) {
        return -1;
      }

      return (+country.id === siteAddress.country.id
        && zip === siteAddress.zip
        && city === siteAddress.city
        && street === siteAddress.street
        && houseNumber === siteAddress.houseNumber
        && (companyName1 === siteAddress.firstName || companyName1 === siteAddress.companyName)
        && (companyName2 === siteAddress.lastName || companyName2 === siteAddress.companyName2)
        && +salutation.id === siteAddress.salutation?.id);
    });
    return index;
  }

  const [isRecourse, setIsRecourse] = useState(entry?.telcoData?.lnpData?.resourceTransfer || false);
  const [isConfirmed, setConfirmation] = useState(entry?.telcoData?.lnpData?.contractTermination || false);
  const [selectedLocation, setSelectLocation] = useState<AddressPortal>(locations[entry?.telcoData?.siteAddress ? findIndexOfLocation(entry?.telcoData?.siteAddress) : 0]);
  const [selectedCallNumberType, setSelectedCallNumberType] = useState(entry ? {multipleSubscriberNumbers: !!entry.telcoData?.lnpData?.portPhoneNumbers, numberBlock: !!entry.telcoData?.lnpData?.portPhoneNumberBlock } : { multipleSubscriberNumbers: client.id !== CLIENTS_IDS.AUT, numberBlock: client.id === CLIENTS_IDS.AUT });


  /* Multiple Subscriber Numbers (MSN) States */
  const [allNumbersIsChecked, setAllNumbersIsChecked] = useState(entry?.telcoData?.lnpData?.portAllNumbers || false);
  const [shortenOnConsole, setShortenOnConsole] = useState(entry?.telcoData?.lnpData?.portPhoneNumberBlock?.shortenOnConsole || false);
  const [callNumberConfigurations, setCallNumberConfigurations] = useState<CallNumberConfiguration[]>(entry?.telcoData?.lnpData?.portPhoneNumbers ? entry?.telcoData?.lnpData?.portPhoneNumbers.map(portingNumber => { return { callNumber: portingNumber.cli || '' }}) : [{ callNumber: '' }]);

  const createMultipleSubscriberNumbers = (portingNumbers: Array<Phone>, portAllNumbers: boolean): any => {

    return {
      localAreaCode: portingNumbers?.length > 0 ? portingNumbers[0].areaCode : '',
      portAllNumbers: portAllNumbers,
      callNumberConfigurations: portingNumbers.map(portingNumber => { return { callNumber: portingNumber.cli }}),
    };
  }

  const createPortPhoneNumberBlock = (portingNumberblock: LnpPhoneNumberBlock): any => {

    return {
      localAreaCode: portingNumberblock.areaCode || '',
      extensionNumber: portingNumberblock.extensionNumber || '',
      console: portingNumberblock.consoleNumber || '',
      from: portingNumberblock.blockFrom !== undefined ? portingNumberblock.blockFrom : (client.id !== CLIENTS_IDS.AUT ? '' : '0'),
      to: portingNumberblock.blockTo !== undefined ? portingNumberblock.blockTo : (client.id !== CLIENTS_IDS.AUT ? '' : '99999'),
      shortenOnConsole: shortenOnConsole
    };
  }

  const addPortingDocument = (uuid: string | undefined) => {
    return dispatchTemp(apiCallAction(ACTION_CONST.API_BASKET_PUT_DOCUMENT,
      {
        basketId: basket?.id,
        id: currentDocument.documentId,
        documentName: currentDocument.fileName,
        documentType: { id: currentDocument.documentTypeId },
        entries: [uuid]
      }
    ))
  }

  const addOptionToBasket = (values: any, singlePorting: boolean) => {
    const portingNumberObject: TelcoData = createPortingObject(values, singlePorting);
    if(entry){
      dispatchTemp(apiCallAction(ACTION_CONST.API_BASKET_ADD_TELCO_DATA, {...addTelcoData(portingNumberObject), basketId: basket?.id, uuid: entry.uuid})).then(() => {
        if(entry?.documents?.some(document => document.id !== currentDocument.documentId)){
          dispatchTemp(apiCallAction(ACTION_CONST.API_BASKET_DELETE_DOCUMENT,
            {
              basketId: basket?.id,
              basketDocumentRemoves: [{ entries: [entry?.uuid], documentId: entry?.documents[0]?.id }]
            }
          )).then(() => addPortingDocument(entry.uuid).then(() => setShowModal(false)))
        } else {
          setShowModal(false);
        }
      })
    }else {

      selectOptionOfOption(option, ProductOptionModalType.TELCO_DATA, [portingNumberObject]).then((res: Basket) => {
        let options = res.entries[step3Data?.activeIndex]?.options?.find((o: BasketEntry) => o.uuid === option.parentUuid)?.options;
        addPortingDocument(options && options[options.length - 1].uuid);
      });

    }
  }



  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      selectedConnectionAddress: {
        id: entry?.telcoData?.siteAddress ? findIndexOfLocation(entry.telcoData.siteAddress) : 0,
      },
      isCancelConnection: entry?.telcoData?.lnpData?.contractTermination || isConfirmed,
      currentProvider: entry?.telcoData?.lnpData?.currentCarrier || {
        id: currentProvidersOptions[-1]?.id
      },
      currentCarrierNonDe:  entry?.telcoData?.lnpData?.currentCarrierNonDe || '',
      oldCustomerNr: entry?.telcoData?.lnpData?.referenceNumberPartner || '',
      isResourceAcquisition: entry?.telcoData?.lnpData?.resourceTransfer || isRecourse,
      /* Call Numbers */
      multipleSubscriberNumbers: entry?.telcoData?.lnpData?.portPhoneNumbers && entry?.telcoData?.lnpData?.portAllNumbers !== undefined ? createMultipleSubscriberNumbers(entry?.telcoData?.lnpData?.portPhoneNumbers, entry?.telcoData?.lnpData?.portAllNumbers) : {
        localAreaCode: '',
        portAllNumbers: allNumbersIsChecked,
        callNumberConfigurations: callNumberConfigurations.map((configuration: any) => configuration),
      },
      numberBlock: entry?.telcoData?.lnpData?.portPhoneNumberBlock ? createPortPhoneNumberBlock(entry?.telcoData?.lnpData?.portPhoneNumberBlock) : {
        localAreaCode: '',
        extensionNumber: '',
        console: '',
        from: client.id !== CLIENTS_IDS.AUT ? '' : '0',
        to: client.id !== CLIENTS_IDS.AUT ? '' : '99999',
        shortenOnConsole: shortenOnConsole
      },
      scheduledDate: entry?.telcoData?.lnpData?.scheduledDate || calculateFutureDate(20)
    },
    validationSchema: createValidatorSchema({
      currentCarrierNonDe: MaxLengthCheck(INPUT_MAX_LENGTHS.currentCarrierNonDe, (client.id !== CLIENTS_IDS.NFON && client.id !== CLIENTS_IDS.DTS) && requiredCheck(requiredMsg)),
      currentProvider: createValidatorSchema({
        id: (client.id === CLIENTS_IDS.NFON || client.id === CLIENTS_IDS.DTS) && requiredCheck(requiredMsg),
      }),
      oldCustomerNr: MaxLengthCheck(INPUT_MAX_LENGTHS.default, false),
      multipleSubscriberNumbers: createValidatorSchema({
        localAreaCode: MaxLengthCheck(INPUT_MAX_LENGTHS.contact.areaCode, selectedCallNumberType.multipleSubscriberNumbers && numberCheck(requiredMsg, translation.invalidLocalAreaCodeValidation())),
        callNumberConfigurations: createValidatorSchemaArray({
          callNumber: MaxLengthCheck(INPUT_MAX_LENGTHS.callNumber, selectedCallNumberType.multipleSubscriberNumbers && numberCheck(requiredMsg, translation.invalidCallNumberValidation()))
        })
      }),
      numberBlock: createValidatorSchema({
        localAreaCode: MaxLengthCheck(INPUT_MAX_LENGTHS.contact.areaCode, selectedCallNumberType.numberBlock && numberCheck(requiredMsg, translation.invalidLocalAreaCodeValidation())),
        extensionNumber: MaxLengthCheck(INPUT_MAX_LENGTHS.callNumber, selectedCallNumberType.numberBlock && numberCheck(requiredMsg, translation.extensionNumber())),
        console: MaxLengthCheck(INPUT_MAX_LENGTHS.console, selectedCallNumberType.numberBlock && numberCheck(requiredMsg, translation.console())),
        from: MaxLengthCheck(INPUT_MAX_LENGTHS.callNumber,selectedCallNumberType.numberBlock && numberCheck(requiredMsg, translation.from())),
        to: MaxLengthCheck(INPUT_MAX_LENGTHS.callNumber,selectedCallNumberType.numberBlock && numberCheck(requiredMsg, translation.to())),
      })
    }),
    onSubmit: async (values: any) => {
      dispatch(clearError());

      if (+selectedLocation.country.id === COUNTRY_IDS.DE) {

        const localAreaCode: string = selectedCallNumberType.multipleSubscriberNumbers ? values.multipleSubscriberNumbers.localAreaCode : values.numberBlock.localAreaCode;

        dispatchTemp(apiCallAction(ACTION_CONST.API_VALIDATE_LOCAL_AREA_CODE, {
          countryId: COUNTRY_IDS.DE,
          onkz: localAreaCode,
          stepper: "PORTING_FORM"
        })).then(() => {
          addOptionToBasket(values, selectedCallNumberType.multipleSubscriberNumbers);

        })
          .catch((error) => {
            console.log(error.errorData[0]);
          })

      } else {
        addOptionToBasket(values, selectedCallNumberType.multipleSubscriberNumbers);

      }
    }
  });

  const createPortingObject = (values: any, singlePorting: boolean): TelcoData => {

    const telcoData: TelcoData = {};

    const BUSINESS_DAYS = 20;

    telcoData.lnpData = {
      currentCarrierNonDe: (client.id !== CLIENTS_IDS.NFON && client.id !== CLIENTS_IDS.DTS) ? values.currentCarrierNonDe : undefined,
      currentCarrier: (client.id === CLIENTS_IDS.NFON || client.id === CLIENTS_IDS.DTS)  ? { id: +values.currentProvider.id } : undefined,
      portPhoneNumbers: singlePorting ? createPortingNumberList(
        values.multipleSubscriberNumbers.callNumberConfigurations,
        values.multipleSubscriberNumbers.localAreaCode) : undefined,
      contractTermination: values.isCancelConnection,
      portAllNumbers: values.multipleSubscriberNumbers.portAllNumbers,
      resourceTransfer: values.isResourceAcquisition,
      installationAddress: createAddress(selectedLocation),
      portPhoneNumberBlock: !singlePorting ? createPhoneNumberBlock(values.numberBlock) : undefined,
      referenceNumberPartner: values.oldCustomerNr,
      scheduledDate: values.scheduledDate,
      dateMode: values.scheduledDate.split('T')[0] == calculateFutureDate(20).toISOString().split('T')[0] ? 1 : 3

    }

    telcoData.siteAddress = createAddress(selectedLocation);
    return telcoData;
  }


  function calculateFutureDate(numDays: number) {
    const currentDate = new Date();
    let count = 0;

    while (count < numDays) {
      currentDate.setDate(currentDate.getDate() + 1);
      const dayOfWeek = currentDate.getDay();

      // Check if the current day is a weekend (Saturday or Sunday)
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        count++;
      }
    }

    return currentDate;
  }

  const createPhoneNumberBlock = (numberBlock: any): LnpPhoneNumberBlock => {

    const blockTo = Number(numberBlock.to);
    const blockFrom = Number(numberBlock.from);
    const result: any = blockTo - blockFrom + 1;

    return {
      blockFrom: numberBlock.from,
      blockTo: numberBlock.to,
      blockSize: result,
      extensionNumber: numberBlock.extensionNumber,
      consoleNumber: numberBlock.console,
      areaCode: numberBlock.localAreaCode,
      shortenOnConsole: numberBlock.shortenOnConsole

    };
  }

  const createPortingNumberList = (portingNumbers: any, localAreaCode: string): Array<Phone> => {

    const phones: Array<Phone> = new Array<Phone>();
    portingNumbers?.map((number: any) => {
      let thePhone: Phone = {
        phoneType: { id: 2 },
        areaCode: localAreaCode,
        cli: number.callNumber
      };
      phones.push(thePhone);
    })


    return phones;
  }



  const createAddress = (selectedAddress: AddressPortal) => {

    let company = selectedAddress.salutation?.id && (parseInt(String(selectedAddress.salutation.id)) === ID_SALUTATIONS.COMPANY);

    let theAddress: any = {
      salutation: selectedAddress.salutation,
      companyName: company ? selectedAddress.companyName1 : null,
      companyName2: company ? selectedAddress.companyName2 : null,
      firstName: !company ? selectedAddress.companyName1 : null,
      lastName: !company ? selectedAddress.companyName2 : null,
      country: selectedAddress.country,
      street: selectedAddress.street,
      houseNumber: selectedAddress.houseNumber,
      zip: selectedAddress.zip,
      city: selectedAddress.city,
      city2: selectedAddress.city2,
      city3: selectedAddress.city3,
      district: selectedAddress.district ? selectedAddress.district : selectedAddress.province ? selectedAddress.province : selectedAddress.county,
      buildingPart: selectedAddress.additionalAddress
    };

    return theAddress;
  }

  const onChangeSelectLocation = (e: any) => {
    formik.handleChange(e);
    formik.setFieldValue('selectedConnectionAddress', locations[e.target.value])
    setSelectLocation(locations[e.target.value]);
  }

  const onChangeSelectedCurrentProvider = (e: any) => {
    formik.handleChange(e);
  }

  const onChangeResourceCheckBox = () => {
    formik.setFieldValue('isResourceAcquisition', !isRecourse);
    setIsRecourse(x => !x);
  }

  const onChangeCancelConnection = () => {
    formik.setFieldValue('isCancelConnection', !isConfirmed);
    setConfirmation(x => !x);
  }

  const cancelModal = () => {
    setError('')
    if (entry) {
      if (!currentDocument.uploaded) {
        setError(translationsPortingNumbers.uploadPortingDoc());
        return;
      }
      if (entry?.documents?.some(document => document.id !== currentDocument.documentId)) {
        dispatchTemp(apiCallAction(ACTION_CONST.API_BASKET_DELETE_DOCUMENT,
          {
            basketId: basket?.id,
            basketDocumentRemoves: [{ entries: [entry?.uuid], documentId: entry?.documents[0]?.id }]
          }
        )).then(() => addPortingDocument(entry.uuid).then(() => setShowModal(false)))
        return;
      }
    }
    setShowModal(false);
  }

  return (
    <CpxFormikForm handleSubmit={formik.handleSubmit}
                   initialValues={formik.initialValues}
                   id={'porting-numbers-modal-data-form'}
    >
      <CpxModal
        confirmText={translationsPortingNumbers.add()}
        onCancel={() => cancelModal()}
        formId={'porting-numbers-modal-data-form'}
        className={'porting-numbers-modal'}
        size={'large'}
        disableButton={!currentDocument?.uploaded || Object.keys(formik.errors).length > 0}
      >

        <h3 className={cs('modalTitle', `modalTitle-le--${theme}`)}>
          {option.product.displayValue}
        </h3>

        <p className={cs('modalTitle', `subheading-le--${theme}`)}>
          {translationsPortingNumbers.portingSubLine()}
        </p>

        <CpxDropdownWithLabel
          id={`selectedConnectionAddress.id`}
          name={`selectedConnectionAddress.id`}
          required={true}
          onChange={onChangeSelectLocation}
          defaultOption={locationOptions[-1]}
          options={locationOptions}
          value={formik.values.selectedConnectionAddress.id}
        >
          {translationsPortingNumbers.sites()}
        </CpxDropdownWithLabel>
        <h4 className={`${internalClassName}-subheading ${internalClassName}-subheading-le--${theme}`}>{translationsPortingNumbers.connection()}</h4>
        <div className={`${internalClassName}-switch-container`}>
          <CpxSwitch
            id={'isCancelConnection'}
            name={'isCancelConnection'}
            className={"switch"}
            onChange={onChangeCancelConnection}
            checked={isConfirmed}
          />
          {translationsPortingNumbers.switchContent()}
        </div>
        <div className={'addressInputGroup-container'}>
          <div className={'addressInputGroup'}>
            <div className={'addressInputGroup'}>
              {(client.id === CLIENTS_IDS.NFON || client.id === CLIENTS_IDS.DTS) ? (<CpxDropdownWithLabel
                id={'currentProvider.id'}
                name={`currentProvider.id`}
                required={true}
                onChange={onChangeSelectedCurrentProvider}
                options={currentProvidersOptions}
                defaultOption={DEFAULT_SELECT_OPTION}
                value={formik.values?.currentProvider?.id}
                error={formik.errors?.currentProvider?.id}
              >
                {translationsPortingNumbers.currentProvider()}
              </CpxDropdownWithLabel>) : (
                <CpxInputWithLabel
                  id={'currentCarrierNonDe'}
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values?.currentCarrierNonDe}
                  error={formik.touched?.currentCarrierNonDe && formik.errors?.currentCarrierNonDe}
                  required
                >
                  {translationsPortingNumbers.currentProvider()}
                </CpxInputWithLabel>
              )}
              <CpxInputWithLabel
                id={'oldCustomerNr'}
                type="text"
                onChange={formik.handleChange}
                value={formik.values?.oldCustomerNr}
                error={formik.touched?.oldCustomerNr && formik.errors?.oldCustomerNr}
              >
                {translationsPortingNumbers.customerNr()}
              </CpxInputWithLabel>
            </div>
          </div>
        </div>
        {/*<CpxCheckboxGroup
          name="resourceCheckBox"
          options={[
            {
              name: 'resourceCheckBox',
              title: translationsPortingNumbers.resourceCheckBox(),
              checked: isRecourse,
            },
          ]}
          onChange={onChangeResourceCheckBox}
          className={`${internalClassName}-label-le--${theme} ${internalClassName}-checkbox ${internalClassName}-checkbox-le--${theme}`}
        />*/}
        <div className={`${internalClassName}-section-container`}>
          <h4 className={`${internalClassName}-subheading ${internalClassName}-subheading-le--${theme}`}>{translationsPortingNumbers.addressHeadline()}</h4>
          <p className={cs('modalTitle', `subheading-le--${theme}`)}>{translationsPortingNumbers.connectionSubtitle()}</p>
          <PortingDisplayAddressForm selectedLocation={selectedLocation} getCountryById={getCountryById}/>
        </div>
        <div className={`${internalClassName}-section-container`}>
          <h4 className={`${internalClassName}-subheading ${internalClassName}-subheading-le--${theme}`}>{translationsPortingNumbers.callNumberTitle()}</h4>
          <PortingNumberSwitch
            values={formik.values}
            touched={formik.touched}
            errors={formik.errors}
            handleChange={formik.handleChange}
            allNumbersIsChecked={allNumbersIsChecked}
            setAllNumbersIsChecked={setAllNumbersIsChecked}
            shortenOnConsole={shortenOnConsole}
            setShortenOnConsole={setShortenOnConsole}
            selectedCallNumberType={selectedCallNumberType}
            setSelectedCallNumberType={setSelectedCallNumberType}
            client={client}
            formik={formik}
            callNumberConfigurations={callNumberConfigurations}
            setCallNumberConfigurations={setCallNumberConfigurations}
          />
        </div>
        <NfoActivationDate setActivationDate={(date: Date) => formik.values.scheduledDate = date} isPortingDate={true} portingDate={(entry?.telcoData?.lnpData?.dateMode === 3 && entry?.telcoData?.lnpData?.scheduledDate?.toString()) || undefined}/>
        <NumberPortingDocumentFormComponent parentClassName={internalClassName} currentDocument={currentDocument} setCurrentDocument={setCurrentDocument} handleDeleteDocument={handleDeleteDocument}/>
        {ErrorsOfBE?.errorData && ErrorsOfBE?.errorData?.length > 0 &&
          ErrorsOfBE?.requestData?.currentData?.stepper === "PORTING_FORM" && <Alert errors={ErrorsOfBE?.errorData}/>}
        {error && <Alert errors={[error]}/>}
      </CpxModal>
    </CpxFormikForm>
  )
}